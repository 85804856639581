/* eslint-disable react/jsx-curly-newline */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/styles';
import parse from 'html-react-parser';
// Material UI imports
import {
  Box,
  FormControlLabel,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { ApplicationContext } from '../../store/ApplicationState';
// Component imports
import Products from './Products';
import { ReactComponent as ArrowUp } from '../../assets/arrowupsvg.svg';
import { ReactComponent as ArrowDown } from '../../assets/arrowdownsvg.svg';
// Import Utility
import {
  MOBILE_BREAKPOINT,
  CHECKBOX_STATES_CHECKED,
  CHECKBOX_STATES_INDETERMINATE,
  CHECKBOX_STATES_EMPTY,
} from '../../utility/staticText';

const Treaments = ({
  recommendation,
  index,
  isFinalRecomTreatments,
  sectionCode,
  pathway,
  changeArrowIcon,
}) => {
  const {
    state: { showProducts, switchState, switchAllTherapy },
    wndRecommState: { treatmentList },
    wndRecommDispatch,
  } = useContext(ApplicationContext);
  const theme = useTheme();
  const matchesMobileWidth = useMediaQuery(
    theme.breakpoints.down(MOBILE_BREAKPOINT)
  );

  function selectAllTreatment(updatedProductList) {
    const sa = updatedProductList
      .filter((x) => x.isActive !== 0)
      .every((x) => x.selected);
    let isProdCheck = true;
    updatedProductList
      .filter((x) => x.isActive !== 0)
      .forEach((x) => {
        if (!x.relatedProducts.every((y) => y.selectedProduct)) {
          isProdCheck = false;
          return isProdCheck;
        }
        return isProdCheck;
      });
    const noneSelected = updatedProductList
      .filter((x) => x.isActive !== 0)
      .every((x) => !x.selected);
    let isProdnoneSelected = true;
    updatedProductList
      .filter((x) => x.isActive !== 0)
      .forEach((x) => {
        if (!x.relatedProducts.every((y) => !y.selectedProduct)) {
          isProdnoneSelected = false;
          return isProdnoneSelected;
        }
        return isProdnoneSelected;
      });
    if (sa && isProdCheck) {
      wndRecommDispatch({
        selectAllTreatment: CHECKBOX_STATES_CHECKED,
      });
    }
    if (noneSelected && isProdnoneSelected) {
      wndRecommDispatch({
        selectAllTreatment: CHECKBOX_STATES_EMPTY,
      });
    }
  }
  const handleTreatmentSelection = (e, recomm) => {
    const tCode = recomm.treatmentCode;

    const updatedlist = treatmentList.map((x) =>
      x.sectionCode === sectionCode && x.treatmentCode === tCode
        ? {
            ...x,
            selected: !x.selected,
          }
        : x
    );
    const updatedProductList = updatedlist.map((groupe) =>
      groupe.treatmentCode === tCode && groupe.selected === false
        ? {
            ...groupe,
            relatedProducts: groupe.relatedProducts.map((element) =>
              element.treatmentCode === tCode
                ? {
                    ...element,
                    selectedProduct: false,
                  }
                : element
            ),
          }
        : groupe
    );
    wndRecommDispatch({
      treatmentList: updatedProductList,
      selectAllTreatment: CHECKBOX_STATES_INDETERMINATE,
    });

    selectAllTreatment(updatedProductList);
  };

  const handleProductSelection = (e, tCode, productDetail) => {
    if (e.target.checked) {
      const updatedList = treatmentList.map((item) =>
        item.sectionCode === sectionCode && item.treatmentCode === tCode
          ? {
              ...item,
              selected: true,
            }
          : item
      );

      const updatedProductList = updatedList.map((groupe) => ({
        ...groupe,
        relatedProducts: groupe.relatedProducts.map((element) =>
          groupe.sectionCode === sectionCode &&
          element.productCode === productDetail.productCode &&
          element.treatmentCode === tCode
            ? {
                ...element,
                selectedProduct: !element.selectedProduct,
              }
            : element
        ),
      }));
      wndRecommDispatch({
        treatmentList: updatedProductList,
        selectAllTreatment: CHECKBOX_STATES_INDETERMINATE,
      });

      selectAllTreatment(updatedProductList);
    } else {
      const updatedProductList = treatmentList.map((groupe) => ({
        ...groupe,
        relatedProducts: groupe.relatedProducts.map((element) =>
          groupe.sectionCode === sectionCode &&
          element.productCode === productDetail.productCode &&
          element.treatmentCode === tCode
            ? {
                ...element,
                selectedProduct: !element.selectedProduct,
              }
            : element
        ),
      }));
      wndRecommDispatch({
        treatmentList: updatedProductList,
        selectAllTreatment: CHECKBOX_STATES_INDETERMINATE,
      });
      selectAllTreatment(updatedProductList);
    }
  };
  return (
    <div className="treatment-descriptor">
      <Box className="trtContainer mobTrtContainer">
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          {isFinalRecomTreatments === false && treatmentList && (
            <Box className="trtCheckboxContainer mobTrtCheckboxContainer">
              <FormControlLabel
                value={index}
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <input
                    type="checkbox"
                    className="chkbox mobChkbox trtCheckbox mobTrtCheckbox"
                    checked={recommendation.selected}
                    onClick={(e) =>
                      handleTreatmentSelection(e, recommendation, index)
                    }
                  />
                }
              />
            </Box>
          )}
          <Box className="trtImage hide print-unhide">
            <img
              id={recommendation.treatmentCode}
              alt="treatment"
              src={recommendation.treatmentImageUrl}
              style={{
                width: '84px',
                height: '83.52px',
                objectFit: 'cover',
              }}
            />
          </Box>
          <Box className="treatments mobTreatments">
            <div className="mobRowFlex recommSvgAlign">
              <Typography
                style={{
                  minHeight: '24px',
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '20px',
                  lineHeight: '24px',
                  color: '#323234',
                }}
              >
                {recommendation.treatmentDescription}
              </Typography>
              <div className="hideDiv mobRowFlex no-disp">
                {recommendation.imgUp ? (
                  <ArrowDown
                    onClick={() => changeArrowIcon(index, sectionCode)}
                    className="svgAlignTrt no-disp"
                  />
                ) : (
                  <ArrowUp
                    onClick={() => changeArrowIcon(index, sectionCode)}
                    className="svgAlignTrt no-disp"
                  />
                )}
              </div>
            </div>
            <Box style={{ minHeight: !matchesMobileWidth && '60px' }}>
              <Typography className="trtDescriptor mobTrtDescriptor hide print-unhide">
                {parse(recommendation.treatmentDescriptor || '')}
              </Typography>
            </Box>
            <Box className="no-disp">
              <Typography
                className={`${
                  recommendation.imgUp ? 'transitionOpen' : 'transitionClose'
                } trtDescriptor mobTrtDescriptor hideDiv mobRowFlex`}
              >
                {parse(recommendation.treatmentDescriptor || '')}
              </Typography>
            </Box>
            {pathway === 'GOAL' && !switchState && (
              <Products
                treatmentCode={recommendation.treatmentCode}
                isFinalRecomTreatments={isFinalRecomTreatments}
                sectionCode={recommendation.sectionCode}
                productDetails={
                  recommendation && recommendation.relatedProducts
                }
                handleProductSelection={handleProductSelection}
              />
            )}
            {pathway === 'CHARACTERISTICS' && !showProducts && (
              <Products
                treatmentCode={recommendation.treatmentCode}
                isFinalRecomTreatments={isFinalRecomTreatments}
                sectionCode={recommendation.sectionCode}
                productDetails={
                  recommendation && recommendation.relatedProducts
                }
                handleProductSelection={handleProductSelection}
              />
            )}
            {pathway === 'AllTherapy' && !switchAllTherapy && (
              <Products
                treatmentCode={recommendation.treatmentCode}
                isFinalRecomTreatments={isFinalRecomTreatments}
                sectionCode={recommendation.sectionCode}
                productDetails={
                  recommendation && recommendation.relatedProducts
                }
                handleProductSelection={handleProductSelection}
              />
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

Treaments.propTypes = {
  recommendation: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  index: PropTypes.string.isRequired,
  isFinalRecomTreatments: PropTypes.bool.isRequired,
  sectionCode: PropTypes.string.isRequired,
  pathway: PropTypes.string.isRequired,
  changeArrowIcon: PropTypes.func.isRequired,
};
export default Treaments;
