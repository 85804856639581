// React imports
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
// Material UI imports
import { Box, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
// Component imports
import QuestionHeader from '../../components/questionHeader';
import QuestionDescription from '../../components/questionDescription';
import CustomButton from '../../components/customButton';
import CustomTextField from '../../components/customTextField';
import AlertDialog from '../../components/alertBox';
// Utility imports
import {
  fetchSiteCoreContents,
  setTooltipText,
  checkValidation,
  createBody,
  capturePageData,
  captureMiniValidationData,
} from '../../utility/utils';
import { createObj } from '../../utility/saveAssessment';

import {
  STATUS_ENTERED,
  STATUS_NOT_ENTERED,
  STATUS_CURRENT,
  MOBILE_BREAKPOINT,
} from '../../utility/staticText';
// Store imports
import { ApplicationContext } from '../../store/ApplicationState';
import useFullPageLoader from '../../hooks/useFullPageLoader';
// validate size input using regex
function regexIt(value) {
  const regex = '^[0-9]{0,9}$';
  const reg = new RegExp(regex);
  const res = reg.test(value);
  return res;
}
// create request body
function createJsonBody(attCode, numOfDays) {
  const body = {
    attributeCode: attCode,
    numberOfDays: numOfDays,
  };
  const reqBody = JSON.stringify(body);
  return reqBody;
}
// calculate wound age
async function calculateWoundAge(reqBody) {
  const url = `${process.env.REACT_APP_API_HOST}api/configuration/calculateWoundAge`;
  try {
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        ClientID: process.env.REACT_APP_CLIENTID,
      },
      body: reqBody,
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return new Error('Error while fetching wound age');
  }
}

const WoundAge = ({ attInfo }) => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const theme = useTheme();
  const matchesMobileWidth = useMediaQuery(
    theme.breakpoints.down(MOBILE_BREAKPOINT)
  );
  const { contentID, attCode } = attInfo.filter(
    (att) => att.pageCode === 'WDAGE'
  )[0];
  const [open, setOpen] = useState(false);
  const [questionTextValue, setQuestionTextValue] = useState();
  const [questionImageURLValue, setQuestionImageURLValue] = useState();
  const [
    questionDescriptionTextValue,
    setQuestionDescriptionTextValue,
  ] = useState();
  const [infoTitleValue, setInfoTitleValue] = useState();
  const [infoDescriptionValue, setInfoDescriptionValue] = useState();
  const [infoURLValue, setInfoURLValue] = useState();
  const [infoURLTitleValue, setInfoURLTitleValue] = useState();
  const [enterButtonTextValue, setEnterButtonTextValue] = useState();
  // eslint-disable-next-line no-unused-vars
  const [attributeName, setAttributeName] = useState('');

  const {
    state: {
      activeStep,
      pageData,
      errorMessage,
      totalStepper,
      minimumValidationData,
    },
    dispatch,
    wndAgeState: { numOfDays, ageParameterCode },
    wndAgeDispatch,
  } = useContext(ApplicationContext);

  const [alertOpen, setAlertOpen] = useState(false);

  const handleClose = () => {
    setAlertOpen(false);
  };
  const tooltipTitle = setTooltipText(
    infoTitleValue,
    infoDescriptionValue,
    infoURLValue,
    infoURLTitleValue
  );

  function validateNextButton() {
    if (activeStep + 1 === totalStepper) {
      dispatch({ showNextButton: false });
    }
  }

  // handle submit
  function handleSubmit() {
    const validateBody = [];
    const body = createBody(numOfDays, attInfo[0] && attInfo[0].pageCode);
    validateBody.push(body);
    const isValid = checkValidation(validateBody);
    if (isValid) {
      if (pageData[activeStep + 1].enabled) {
        dispatch({ activeStep: activeStep + 1 });
        if (totalStepper !== activeStep + 1) {
          pageData[activeStep + 1].isActiveStep = true;
        }
        if (activeStep + 1 === totalStepper) {
          dispatch({ lastPage: activeStep });
        }
        validateNextButton();
        dispatch({ previousPage: activeStep });
      }
    } else {
      setAlertOpen(true);
    }
  }

  const captureWoundAgeData = (paraCode, selectedData) => {
    const saveObj = [];
    saveObj.push(createObj('woundAge', paraCode === null ? '' : paraCode));
    const updatedPageData = capturePageData(
      attCode,
      paraCode,
      selectedData,
      pageData,
      attributeName,
      activeStep,
      `${selectedData.parameterDesc} days`,
      saveObj
    );
    dispatch({ pageData: updatedPageData });
  };
  // handle on blur
  const handleOnBlur = (e) => {
    const { value } = e.target;
    const selectedData = { hasRules: false, parameterDesc: value };
    captureWoundAgeData(ageParameterCode, selectedData);
  };
  // handle age change
  const handleAgeChange = async (e) => {
    const { value } = e.target;
    let ageCode = '';
    const res = regexIt(value);
    setAttributeName('');
    if (res) {
      wndAgeDispatch({ numOfDays: value });
      const body = createJsonBody(attCode, value);
      const response = await calculateWoundAge(body);
      ageCode = response.item && response.item.parameterCode;
      wndAgeDispatch({ ageParameterCode: ageCode });
      const selectedData = { hasRules: false, parameterDesc: value };
      captureWoundAgeData(ageCode, selectedData);
    } else if (numOfDays) {
      wndAgeDispatch({ numOfDays });
      const body = createJsonBody(attCode, numOfDays);
      const response = await calculateWoundAge(body);
      ageCode = response.item && response.item.parameterCode;
      wndAgeDispatch({ ageParameterCode: ageCode });
      const selectedData = { hasRules: false, parameterDesc: value };
      captureWoundAgeData(ageCode, selectedData);
    } else {
      wndAgeDispatch({ numOfDays: '' });
    }
  };

  const handleInfoClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const validateToggle = () => {
      pageData.map((item, idx) => {
        if (idx === activeStep) {
          if (numOfDays) {
            pageData[idx].status = STATUS_ENTERED;
            const updateMinimumValidationData = captureMiniValidationData(
              [attInfo[0].attCode][0],
              minimumValidationData,
              true
            );
            dispatch({ updateMinimumValidationData });
          } else {
            pageData[idx].status =
              pageData[idx].isActiveStep || pageData[activeStep].firstPage
                ? STATUS_CURRENT
                : STATUS_NOT_ENTERED;
            const updateMinimumValidationData = captureMiniValidationData(
              [attInfo[0].attCode][0],
              minimumValidationData,
              false
            );
            dispatch({ updateMinimumValidationData });
          }
          dispatch({ pageData });
        }
        return true;
      });
    };
    validateToggle();
  }, [activeStep, dispatch, numOfDays, pageData]);

  useEffect(() => {
    showLoader();
    // site core content
    fetchSiteCoreContents(contentID).then((typeContent) => {
      const {
        questionText,
        questionImage,
        questionDescriptionText,
        enterButtonText,
      } = typeContent.item.contentDetailsLists[0].contentItems[0].woundCharacteristics;
      const {
        infoTitle,
        infoDescription,
        infoURL,
        infoURLTitle,
      } = typeContent.item.contentDetailsLists[0].contentItems[0].infoDetails;

      setQuestionDescriptionTextValue(
        questionDescriptionText.replace(/<[^>]+>/g, '')
      );
      setQuestionImageURLValue(questionImage);
      setQuestionTextValue(questionText.replace(/<[^>]+>/g, ''));
      setEnterButtonTextValue(enterButtonText);
      setInfoTitleValue(infoTitle.replace(/<[^>]+>/g, ''));
      setInfoDescriptionValue(infoDescription);
      setInfoURLValue(infoURL);
      setInfoURLTitleValue(infoURLTitle);
      hideLoader();
    });
  }, []);

  return (
    <Box className="attributeContainer mobAttributeContainer">
      {loader}
      <QuestionHeader
        questionTextValue={questionTextValue}
        questionImageURLValue={questionImageURLValue}
        handleInfoClick={handleInfoClick}
        tooltipTitle={tooltipTitle}
        open={open}
        setRef={() => {
          setOpen(false);
        }}
      />
      <QuestionDescription
        questionDescriptionTextValue={questionDescriptionTextValue}
      />
      <CustomTextField
        inputVariant="outlined"
        inputType="text"
        inputLabel="Days"
        handleChange={handleAgeChange}
        onBlur={handleOnBlur}
        inputValue={numOfDays}
        containerBoxStyle={{ padding: matchesMobileWidth ? '16px 0' : '15px' }}
      />
      <Box>
        <CustomButton
          value={enterButtonTextValue}
          handleSubmit={handleSubmit}
          width="100px"
        />
      </Box>
      <AlertDialog
        isOpen={alertOpen}
        handleClose={handleClose}
        popUpPositiveButton={handleClose}
        title="Notification"
      >
        {errorMessage}
      </AlertDialog>
    </Box>
  );
};

WoundAge.propTypes = {
  attInfo: PropTypes.arrayOf(
    PropTypes.shape({
      attCode: PropTypes.string,
      attOrder: PropTypes.number,
      pageCode: PropTypes.string,
      contentID: PropTypes.string,
    })
  ).isRequired,
};

export default WoundAge;
