import wndAgeInitialState from '../initialState/wndAgeInitialState';

const wndAgeReducer = (state, action) => {
  switch (action.type) {
    case 'RESET_AGE_STATE':
      return {
        ...wndAgeInitialState,
      };
    default: {
      return {
        ...state,
        ...action,
      };
    }
  }
};

export default wndAgeReducer;
