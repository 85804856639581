async function deletAccountApi(reqBody) {
  const url = `${process.env.REACT_APP_ADMIN_API}api/user/requestToDeleteUserData`;

  try {
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      // mode: 'no-cors',
      headers: {
        AdminGatewayAuthorization:
          process.env.REACT_APP_FUNCTION_ADMIN_API_KEY ?? '',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reqBody),
    });
    if (response.status === 200) {
      const data = await response.json();
      return data;
    }
    throw new Error('Request failed');
  } catch (err) {
    return new Error('Error while submitting delete request');
  }
}
export default deletAccountApi;
