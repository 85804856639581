// React imports
import React, { useEffect, useRef } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
// Material UI imports
import { ThemeProvider } from '@material-ui/core/styles';
import ScrollToTop from 'react-router-scroll-top';
import { useClearCache } from 'react-clear-cache';
import theme from './theme';
// component imports
import Header from './components/header';
import Footer from './components/footer';
import Home from './home';
import WoundTherapyGuide from './components/woundTherapyGuide';
import WoundGoal from './woundGoal';
import { ApllicationProvider } from './store/ApplicationState';
import { WoundRecommendation } from './components/recommendation';
import WoundTherapySelection from './components/woundTherapySelection';
import { ProductDetail } from './productDetail';
import AllTherapies from './allTherapies';

// css import
import './common.css';
import './commonMobile.css';
import DeleteAccount from './mwh_DeleteAccount/mwh_DeleteAccount';

function App() {
  const headerRef = useRef(null);

  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  const handleBeforeUnload = (e) => {
    // Cancel the event
    e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    // Chrome requires returnValue to be set
    e.returnValue = '';
  };

  if (!isLatestVersion) {
    emptyCacheStorage();
  }
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ScrollToTop>
            <Header ref={headerRef} />
            <div
              style={{
                marginTop:
                  headerRef &&
                  headerRef.current &&
                  headerRef.current.clientHeight,
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <ApllicationProvider>
                <Switch>
                  <Route path="/home" component={Home} exact />
                  <Redirect exact from="/" to="/home" />
                </Switch>
                <Switch>
                  <Route
                    path="/woundTherapyGuide"
                    component={WoundTherapyGuide}
                    exact
                  />
                </Switch>
                <Switch>
                  <Route path="/woundGoal" component={WoundGoal} exact />
                </Switch>
                <Switch>
                  <Route
                    path="/woundRecommendation"
                    component={WoundRecommendation}
                    exact
                  />
                </Switch>
                <Switch>
                  <Route
                    path="/woundTherapySelection"
                    component={WoundTherapySelection}
                    exact
                  />
                </Switch>
                <Switch>
                  <Route
                    path="/productDetail"
                    component={ProductDetail}
                    exact
                  />
                </Switch>
                <Switch>
                  <Route
                    path="/deleteAccount"
                    component={DeleteAccount}
                    exact
                  />
                </Switch>
                <Switch>
                  <Route path="/allTherapies" component={AllTherapies} exact />
                </Switch>
                <Footer />
              </ApllicationProvider>
            </div>
          </ScrollToTop>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
