import wndOdorInitialState from '../initialState/wndOdorInitialState';

const wndOdorReducer = (state, action) => {
  switch (action.type) {
    case 'RESET_ODOR_STATE':
      return {
        ...wndOdorInitialState,
      };
    default: {
      return {
        ...state,
        ...action,
      };
    }
  }
};

export default wndOdorReducer;
