// React imports
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// Material UI imports
import { Box, makeStyles } from '@material-ui/core';
// Utility imports
import {
  fetchSiteCoreContents,
  fetchWoundParametersContentByArray,
  capturePageData,
} from '../utility/utils';
// Component imports
import CustomButton from '../components/customButton';
import QuestionHeader from '../components/questionHeader';
import QuestionDescription from '../components/questionDescription';
// Store imports
import { ApplicationContext } from '../store/ApplicationState';
import CustomLargeDropDown from '../components/customLargeDropDown/CustomLargeDropDown';
import {
  WOUNDGOAL_PAGECODE,
  CHECKBOX_STATES_EMPTY,
} from '../utility/staticText';
import { createObj, SaveAssessmentDetail } from '../utility/saveAssessment';
import useFullPageLoader from '../hooks/useFullPageLoader';
// common js import
import { getWoundRules } from '../api/common';

const useStyles = makeStyles(() => ({
  container: {
    margin: '0px',
  },
  bGroup: {
    borderRadius: '0',
    margin: '10px 20px 0 0',
  },
  dropDown0: {
    alignSelf: 'center',
    width: '480px',
    minHeight: '48px',
    borderRadius: '2px',

    '@media(max-width: 1050px)': {
      minWidth: '200px',
      minHeight: '48px',
    },
    '@media(max-width: 960px)': {
      width: '100%',
      marginBottom: '59px',
    },
    '@media (max-width: 765px)': { minWidth: '200px' },
  },
  dropDown1: {
    alignSelf: 'center',
    width: '190px',
    minHeight: '48px',
    borderRadius: '2px',
    '@media(max-width: 1050px)': {
      minWidth: '200px',
      minHeight: '48px',
    },
    '@media(max-width: 960px)': {
      width: '100%',
      maxWidth: '190px',
      marginBottom: '47px',
    },
    '@media (max-width: 765px)': { minWidth: '200px' },
  },
  buttonBox: {
    marginTop: '50px',
  },
  marginBox: { marginRight: '10px' },
}));

function Goal({ attInfo }) {
  const classes = useStyles();
  const exudateLevel = 'EXL';
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [questionTextValue, setQuestionTextValue] = useState();
  const [
    questionDescriptionTextValue,
    setQuestionDescriptionTextValue,
  ] = useState();
  const [buttonTextValue, setButtonTextValue] = useState();
  const {
    state: { goalPageData, forceNew, sessionID },
    wndGoalState: { allParams },
    wndGoalDispatch,
    wndRecommDispatch,
    dispatch,
  } = useContext(ApplicationContext);
  let {
    wndGoalState: { goalParameterList },
  } = useContext(ApplicationContext);

  const history = useHistory();

  const handleSubmit = () => {
    history.push({
      pathname: '/woundRecommendation',
    });
    dispatch({ isGoalPathWay: true });
    wndRecommDispatch({
      treatmentList: [],
      selectAllTreatment: CHECKBOX_STATES_EMPTY,
    });
    if (goalPageData !== undefined && goalPageData.length > 0) {
      const { data } = goalPageData[0];
      if (data) {
        const assessmentObj = [];
        const result = Object.keys(data).map((key) => [data[key]]);
        result.forEach((tes) => {
          const paramObj = tes[0].saveAssessmentObj;
          if (paramObj) {
            paramObj.map((x) => assessmentObj.push(x));
          }
        });

        const obj2 = createObj('sessionID', sessionID);
        const obj3 = createObj('treatmentPathway', 'THRGL');
        const obj5 = createObj(
          'userID',
          JSON.parse(sessionStorage.getItem('userID'))
        );
        const obj6 = createObj(
          'clientType',
          JSON.parse(sessionStorage.getItem('clientType'))
        );
        assessmentObj.push(obj2, obj3, obj5, obj6);
        if (forceNew && forceNew) {
          const obj4 = createObj('forceNew', forceNew && forceNew);
          assessmentObj.push(obj4);
        }
        SaveAssessmentDetail(assessmentObj);
        dispatch({ forceNew: false });
      }
    }
  };

  const combineAttAndParameter = (paraList, attributeList) => {
    paraList.forEach((pl) => {
      const { attOrder } = attributeList.filter(
        (x) => x.attCode === pl.attributeCode
      )[0];
      Object.assign(pl, { attOrder });
    });
    return paraList;
  };
  async function loadAll() {
    const allAttCode = [];
    attInfo.forEach((element) => {
      allAttCode.push(element.attCode);
    });
    if (!goalParameterList) {
      const response = await fetchWoundParametersContentByArray(allAttCode);
      wndGoalDispatch({
        goalParameterList: combineAttAndParameter(
          response.item.parameters,
          attInfo
        ),
        allParams: combineAttAndParameter(response.item.parameters, attInfo),
      });
    }
  }
  async function executeRules(param) {
    goalParameterList = JSON.parse(JSON.stringify(allParams));
    const reqBody = {
      parameterCode: param.parameterCode,
    };
    const response = await getWoundRules(JSON.stringify(reqBody));
    if (response.item.captureRules.length > 0) {
      const { nextStep } = response.item.captureRules[0];
      const exlArr = nextStep.split(':');

      const filteredArr = goalParameterList[1].parameterGroup.filter((para) =>
        exlArr.find((code) => para.parameterCode === code)
      );
      goalParameterList[1].parameterGroup = filteredArr;
      dispatch({ goalParameterList });
    }
  }

  async function handleChange(event) {
    const { name, value } = event.target;
    const item = goalParameterList.find((x) => x.attributeName === name);
    item.selectedValue = value;
    item.selectedBoolean = true;
    const allParamItem = allParams.find((x) => x.attributeName === name);
    allParamItem.selectedValue = value;
    allParamItem.selectedBoolean = true;
    const param = item.parameterGroup.filter(
      (x) => x.parameterCode === value
    )[0];
    if (param.hasRules) {
      await executeRules(param);
      const goalList = goalParameterList.filter(
        (x) => x.attributeCode === exudateLevel
      )[0];
      const isPresent = goalList.parameterGroup.some(
        (gl) => goalList.selectedValue === gl.parameterCode
      );
      if (!isPresent) {
        goalList.selectedBoolean = false;
        goalList.selectedValue = '';
      }
      dispatch({ goalParameterList });
    } else if (item.attributeCode !== exudateLevel) {
      goalParameterList = JSON.parse(JSON.stringify(allParams));
    }
    wndGoalDispatch({ goalParameterList, [name]: value });
    goalParameterList.forEach((element) => {
      if (element.selectedBoolean === true) {
        element.parameterGroup.forEach((items) => {
          if (items.parameterCode === value) {
            const buildDisplayText = `${items.parameterDesc}`;
            const saveObj = [];
            const dictName =
              element.attributeCode === exudateLevel
                ? 'ExudateLevel'
                : 'TherapyGoal';
            saveObj.push(createObj(dictName, items.parameterCode));
            const updatedPageData = capturePageData(
              element.attributeCode,
              items.parameterCode,
              items,
              goalPageData,
              element.attributeName,
              0,
              buildDisplayText,
              saveObj
            );
            dispatch({ goalPageData: updatedPageData });
          }
        });
      }
    });
  }
  useEffect(() => {
    loadAll();
  }, []);

  useEffect(() => {
    showLoader();
    const { contentID } = attInfo.filter(
      (att) => att.pageCode === WOUNDGOAL_PAGECODE
    )[0];
    fetchSiteCoreContents(contentID).then((typeContent) => {
      const {
        questionText,
        questionDescriptionText,
        viewRecommendtationsText,
      } = typeContent.item.contentDetailsLists[0].contentItems[0].woundCharacteristics;

      setQuestionDescriptionTextValue(
        questionDescriptionText.replace(/<[^>]+>/g, '')
      );
      setQuestionTextValue(questionText.replace(/<[^>]+>/g, ''));
      setButtonTextValue(viewRecommendtationsText);
      hideLoader();
    });
  }, []);

  return (
    <Box className={classes.container}>
      {loader}
      <QuestionHeader questionTextValue={questionTextValue} showInfo={false} />
      <QuestionDescription
        questionDescriptionTextValue={questionDescriptionTextValue}
      />
      <Box className="goalDrpdwnBox mobGoalDrpdwnBox">
        {goalParameterList &&
          goalParameterList
            .sort((a, b) => a.attOrder - b.attOrder)
            .map((rec, idx) => (
              <div className={classes.marginBox}>
                <CustomLargeDropDown
                  dropDownStyle={
                    idx === 1 ? classes.dropDown1 : classes.dropDown0
                  }
                  selectData={rec}
                  menuData={rec.parameterGroup.sort(
                    (a, b) => a.sortPriority - b.sortPriority
                  )}
                  handleChange={handleChange}
                />
              </div>
            ))}
      </Box>
      {goalParameterList &&
        goalParameterList.filter((x) => x.selectedBoolean === true).length ===
          2 && (
          <Box className="goalBtnBox mobGoalBtnBox">
            <CustomButton
              value={buttonTextValue}
              handleSubmit={handleSubmit}
              width="320px"
              height="56px"
              fontWeight="700"
            />
          </Box>
        )}
      {goalParameterList &&
        goalParameterList.length === 1 &&
        goalParameterList.filter((x) => x.selectedBoolean === true).length ===
          1 && (
          <Box className="goalBtnBox mobGoalBtnBox">
            <CustomButton
              value={buttonTextValue}
              handleSubmit={handleSubmit}
              width="320px"
              height="56px"
              fontWeight="700"
            />
          </Box>
        )}
      {goalParameterList &&
        goalParameterList.length === 1 &&
        goalParameterList.filter((x) => x.selectedBoolean === true).length !==
          1 && (
          <Box className="goalBtnBox mobGoalBtnBox">
            <CustomButton
              value={buttonTextValue}
              width="320px"
              height="56px"
              fontWeight="700"
              disableNextPrev
            />
          </Box>
        )}
      {goalParameterList &&
        goalParameterList.length === 2 &&
        goalParameterList.filter((x) => x.selectedBoolean === true).length !==
          2 && (
          <Box className="goalBtnBox mobGoalBtnBox">
            <CustomButton
              value={buttonTextValue}
              width="320px"
              height="56px"
              fontWeight="700"
              disableNextPrev
            />
          </Box>
        )}
    </Box>
  );
}
Goal.propTypes = {
  attInfo: PropTypes.arrayOf(
    PropTypes.shape({
      attCode: PropTypes.string,
      attOrder: PropTypes.number,
      pageCode: PropTypes.string,
      contentID: PropTypes.string,
      attName: PropTypes.string,
    })
  ).isRequired,
};
export default Goal;
