/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
// Material UI imports
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import CustomLabel from '../customLabel/CustomLabel';
import { fetchSiteCoreContents } from '../../utility/utils';
import { ApplicationContext } from '../../store/ApplicationState';
import useFullPageLoader from '../../hooks/useFullPageLoader';
import { CHECKBOX_STATES_EMPTY } from '../../utility/staticText';

const useStyles = makeStyles(() => ({
  root: {
    textAlignLast: 'center',
    marginTop: '7em',
    margin: '20px',
  },
  bGroup: {
    borderRadius: '0',
    margin: '30px 0px 0px 0px',
  },
}));

const Recommendation = ({ attInfo }) => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const classes = useStyles();
  const history = useHistory();
  const [positiveHeader, setPositiveHeader] = useState('');
  const [negativeHeader, setNegativeHeader] = useState('');
  const [positiveBody, setPositiveBody] = useState('');
  const [negativeBody, setNegativeBody] = useState('');
  const [positiveImageURL, setPositiveImageURL] = useState('');
  const [negativeImageURL, setNegativeImageURL] = useState('');
  const [
    viewRecommendationButtonText,
    setViewRecommendationButtonText,
  ] = useState('');
  const {
    state: { pageData, isMinimumAttributeFilled },
    dispatch,
    wndRecommDispatch,
  } = useContext(ApplicationContext);

  function handleFinalButtonClick() {
    history.push({
      pathname: '/woundRecommendation',
    });
    wndRecommDispatch({
      treatmentList: [],
      selectAllTreatment: CHECKBOX_STATES_EMPTY,
    });
    pageData
      .filter((x) => x.showInShell)
      .map((da) => {
        if (da.data) {
          // eslint-disable-next-line no-empty
          if (
            Object.prototype.hasOwnProperty.call(da.data, 'SOI') ||
            Object.prototype.hasOwnProperty.call(da.data, 'TSP')
          ) {
            Object.keys(da.data).forEach((key) => {
              da.data[key].map((x) => (x.showPopup = false));
            });
          } else {
            const result = Object.keys(da.data).map((key) => [da.data[key]]);
            result.map((x) => (x[0].showPopup = false));
          }
        }
      });

    dispatch({ pageData });
  }

  useEffect(() => {
    // site core content
    showLoader();
    fetchSiteCoreContents(attInfo.contentID).then((typeContent) => {
      const {
        recommendationPositiveHeader,
        recommendationNegativeHeader,
        recommendationPositiveBody,
        recommendationNegativeBody,
        recommendationsButtonText,
        recommendationPositiveImage,
        recommendationNegativeImage,
      } = typeContent.item.contentDetailsLists[0].contentItems[0].recommendationStatusDetails;
      setPositiveHeader(recommendationPositiveHeader.replace(/<[^>]+>/g, ''));
      setNegativeHeader(recommendationNegativeHeader.replace(/<[^>]+>/g, ''));
      setPositiveBody(recommendationPositiveBody);
      setNegativeBody(recommendationNegativeBody);
      setViewRecommendationButtonText(recommendationsButtonText);
      setPositiveImageURL(recommendationPositiveImage);
      setNegativeImageURL(recommendationNegativeImage);
      hideLoader();
    });
  }, []);

  return (
    <Box className={classes.root}>
      {loader}
      <Box>
        <img
          alt=""
          src={isMinimumAttributeFilled ? positiveImageURL : negativeImageURL}
          height="86.11px"
          width="86.11px"
        />
        <CustomLabel
          labelStyle={{ fontWeight: 'bold', fontSize: '25px' }}
          value={isMinimumAttributeFilled ? positiveHeader : negativeHeader}
          boxStyle={{ marginTop: '0px', textAlign: 'center', width: 'auto' }}
        />
        <CustomLabel
          boxStyle={{ marginTop: '30px' }}
          labelStyle={{ fontSize: '16px' }}
          value={isMinimumAttributeFilled ? positiveBody : negativeBody}
        />
        {isMinimumAttributeFilled && (
          <Button
            variant="contained"
            color="primary"
            className={`${classes.bGroup} buttonStyle`}
            onClick={handleFinalButtonClick}
          >
            {viewRecommendationButtonText}
          </Button>
        )}
      </Box>
    </Box>
  );
};

Recommendation.propTypes = {
  attInfo: PropTypes.arrayOf(
    PropTypes.shape({
      contentID: PropTypes.string,
    })
  ).isRequired,
};
export default Recommendation;
