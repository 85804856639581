import wndSizeInitialState from '../initialState/wndSizeInitialState';

const WndSizeReducer = (state, action) => {
  switch (action.type) {
    case 'RESET_SIZE_STATE':
      return {
        ...wndSizeInitialState,
      };
    default: {
      return {
        ...state,
        ...action,
      };
    }
  }
};

export default WndSizeReducer;
