/* eslint-disable no-param-reassign */
/* eslint-disable dot-notation */
import { Box, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import React, { useEffect, useContext, useState } from 'react';
// import Custom component
import CustomDropDown from '../../components/customDropDown/CustomDropDown';
import CustomLabel from '../../components/customLabel/CustomLabel';
import QuestionDescription from '../../components/questionDescription';
import QuestionHeader from '../../components/questionHeader';
import RadioButton from '../../components/radioButton';
import AlertDialog from '../../components/alertBox';
import { ReactComponent as ArrowUp } from '../../assets/arrowupsvg.svg';
import { ReactComponent as ArrowDown } from '../../assets/arrowdownsvg.svg';
// import Store
import { ApplicationContext } from '../../store/ApplicationState';
// Import Utility
import {
  STATUS_CURRENT,
  STATUS_ENTERED,
  STATUS_NOT_ENTERED,
  STATUS_VISITED,
  WND_CAP_RULE,
  MOBILE_BREAKPOINT,
} from '../../utility/staticText';

import {
  fetchSiteCoreContents,
  fetchWoundParametersContentByArray,
  setTooltipText,
  fetchWoundRules,
  checkPopup,
  capturePageData,
  captureMiniValidationData,
} from '../../utility/utils';
import { createObj } from '../../utility/saveAssessment';
import useFullPageLoader from '../../hooks/useFullPageLoader';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
    maxWidth: '732px',
    margin: '10px 0',
  },
  appearanceBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '0px',
    marginBottom: '24px',
  },
  fullDiv: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  halfDiv1: {
    width: '50%',
    display: 'flex',
  },
  halfDiv2: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  halfDiv2Inner: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const Appearances = ({ attInfo }) => {
  const theme = useTheme();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const matchesMobileWidth = useMediaQuery(
    theme.breakpoints.down(MOBILE_BREAKPOINT)
  );
  const classes = useStyles();
  const { contentID } = attInfo
    .sort((a, b) => a.attOrder - b.attOrder)
    .filter((att) => att.pageCode === 'WDAPP')[0];
  const [open, setOpen] = useState(false);
  const [openEscharDry, setOpenEscharDry] = useState(false);
  const [questionTextValue, setQuestionTextValue] = useState();
  const [questionTextValue2, setQuestionText2Value] = useState();
  const [questionImageURLValue, setQuestionImageURLValue] = useState();
  const [
    questionDescriptionTextValue,
    setQuestionDescriptionTextValue,
  ] = useState();
  const [infoTitleValue, setInfoTitleValue] = useState();
  const [infoDescriptionValue, setInfoDescriptionValue] = useState();
  const [infoURLValue, setInfoURLValue] = useState();
  const [infoURLTitleValue, setInfoURLTitleValue] = useState();
  const [infoTitleValue2, setInfoTitleValue2] = useState();
  const [infoDescriptionValue2, setInfoDescriptionValue2] = useState();
  const [infoURLValue2, setInfoURLValue2] = useState();
  const [infoURLTitleValue2, setInfoURLTitleValue2] = useState();
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState('');
  const [popUpTitle, setPopUpTitle] = useState('');
  const [escharWarning, setEscharWarning] = useState('');
  const tooltipTitle = setTooltipText(
    infoTitleValue,
    infoDescriptionValue,
    infoURLValue,
    infoURLTitleValue
  );
  const tooltipTitle2 = setTooltipText(
    infoTitleValue2,
    infoDescriptionValue2,
    infoURLValue2,
    infoURLTitleValue2
  );
  const {
    state: { activeStep, pageData, minimumValidationData },
    dispatch,
    wndAppearanceState: {
      parameterList,
      ESCDR,
      EPH,
      SLO,
      ESC,
      HYG,
      GRA,
      totalAppearance,
      escharList,
      escharGreater75,
      escharGreaterZero,
      isAppearanceValid,
    },
    wndAppearanceDispatch,
  } = useContext(ApplicationContext);

  // site core content
  fetchSiteCoreContents(contentID).then((typeContent) => {
    const {
      question2Text,
      questionText,
      questionImage,
      questionDescriptionText,
      escharWarningText,
    } = typeContent.item.contentDetailsLists[0].contentItems[0].woundCharacteristics;
    const {
      infoTitle,
      infoDescription,
      infoURL,
      infoURLTitle,
    } = typeContent.item.contentDetailsLists[0].contentItems[0].infoDetails;
    const secondaryInfoDetail =
      typeContent.item.contentDetailsLists[0].contentItems[0]
        .secondaryInfoDetails;
    setQuestionDescriptionTextValue(
      questionDescriptionText.replace(/<[^>]+>/g, '')
    );
    setQuestionImageURLValue(questionImage);
    setQuestionTextValue(questionText.replace(/<[^>]+>/g, ''));
    setQuestionText2Value(question2Text.replace(/<[^>]+>/g, ''));
    setInfoTitleValue(infoTitle.replace(/<[^>]+>/g, ''));
    setInfoDescriptionValue(infoDescription);
    setEscharWarning(escharWarningText);
    setInfoURLValue(infoURL);
    setInfoURLTitleValue(infoURLTitle);
    setInfoTitleValue2(secondaryInfoDetail.infoTitle.replace(/<[^>]+>/g, ''));
    setInfoDescriptionValue2(secondaryInfoDetail.infoDescription);
    setInfoURLValue2(secondaryInfoDetail.infoURL);
    setInfoURLTitleValue2(secondaryInfoDetail.infoURLTitle);
  });

  const handleInfoClick = () => {
    setOpen(!open);
  };

  const handleInfoClickEschar = () => {
    setOpenEscharDry(!openEscharDry);
  };

  const handlePopUpClose = () => {
    setPopUpOpen(false);
  };

  const validateToggle = (total, isEscharDry, escharDry) => {
    wndAppearanceDispatch({ isAppearanceValid: false });
    if (total === 0) {
      pageData[activeStep].status =
        pageData[activeStep].isActiveStep || pageData[activeStep].firstPage
          ? STATUS_CURRENT
          : STATUS_NOT_ENTERED;
    } else if (total === 100) {
      pageData[activeStep].status = STATUS_ENTERED;
      wndAppearanceDispatch({ isAppearanceValid: true });
    } else if (total !== 100) {
      pageData[activeStep].status = STATUS_VISITED;
    }
    if (isEscharDry) {
      wndAppearanceDispatch({ isAppearanceValid: false });
      if (escharDry !== '' && total === 100) {
        pageData[activeStep].status = STATUS_ENTERED;
        wndAppearanceDispatch({ isAppearanceValid: true });
      } else {
        pageData[activeStep].status = STATUS_VISITED;
      }
    }
    dispatch({ pageData });
  };

  const calculateTotal = (paraList) => {
    let total = 0;
    paraList.forEach((element) => {
      total = element.selectedValue
        ? parseInt(element.selectedValue.replace(/\D/g, ''), 10) + total
        : total;
    });
    paraList.forEach((element) => {
      if (total === 100) {
        const updateMinimumValidationData = captureMiniValidationData(
          element.attributeCode,
          minimumValidationData,
          true
        );
        dispatch({ updateMinimumValidationData });
      } else {
        const updateMinimumValidationData = captureMiniValidationData(
          element.attributeCode,
          minimumValidationData,
          false
        );
        dispatch({ updateMinimumValidationData });
      }
    });
    wndAppearanceDispatch({ totalAppearance: total });
    return total;
  };

  const checkRule = (name, val) => {
    const att = parameterList.filter((x) => x.attributeCode === name);
    const result = att[0].parameterGroup.filter((x) => x.parameterCode === val);
    return result[0].hasRules;
  };
  async function executeRule(hasRule, value) {
    if (hasRule) {
      wndAppearanceDispatch({
        ESCDR: '',
      });
      const response = await fetchWoundRules(value);
      const isWndCapRule = response.item.captureRules.filter(
        (x) => x.ruleType === WND_CAP_RULE
      );
      if (isWndCapRule.length > 0) {
        wndAppearanceDispatch({
          escharGreater75: isWndCapRule[0].activateWoundCapture,
        });
        dispatch({ disableNextPrev: isWndCapRule[0].activateWoundCapture });
      }
      const isPopUp = await checkPopup(response);
      if (isPopUp[0]) {
        setPopUpOpen(true);
        setPopUpMessage(isPopUp[1]);
        setPopUpTitle(isPopUp[2]);
      }
    }
  }
  const handleChange = async (event) => {
    const { name, value } = event.target;
    const item = parameterList.find((x) => x.attributeCode === name);
    item.selectedValue = value;
    item.selectedBoolean = true;
    wndAppearanceDispatch({ parameterList, [name]: value });
    const hasRule = checkRule(name, value);
    await executeRule(hasRule, value);
    parameterList.forEach((element) => {
      if (element.selectedBoolean === true) {
        element.parameterGroup.forEach((items) => {
          if (items.parameterCode === value) {
            const buildDisplayText = `${
              items.parameterDesc
            } ${element.attributeName.replace(/[^a-zA-Z ]/g, '')}`;
            const saveObj = [];
            saveObj.push(
              createObj(
                element.attributeName.replace(/[^a-zA-Z ]/g, ''),
                items.parameterCode
              )
            );
            const updatedPageData = capturePageData(
              element.attributeCode,
              items.parameterCode,
              items,
              pageData,
              element.attributeName,
              activeStep,
              buildDisplayText,
              saveObj
            );
            dispatch({ pageData: updatedPageData });
          }
        });
      }
    });
  };

  const handleRadioChange = (event) => {
    wndAppearanceDispatch({ ESCDR: event.target.value });
    if (escharGreaterZero) {
      escharList.forEach((element) => {
        element.parameterGroup.forEach((items) => {
          if (items.parameterCode === event.target.value) {
            const buildDisplayText = `${element.attributeName}: ${items.parameterDesc}`;

            if (
              Object.prototype.hasOwnProperty.call(
                pageData[activeStep].data,
                'ESCDR'
              )
            ) {
              // eslint-disable-next-line dot-notation
              pageData[activeStep].data['ESCDR'].saveAssessmentObj.forEach(
                (x, idx) => {
                  if (x.key === 'EscardryIntactFlag') {
                    pageData[activeStep].data[
                      'ESCDR'
                    ].displayText = buildDisplayText;
                    pageData[activeStep].data['ESCDR'].saveAssessmentObj[
                      idx
                    ].value = items.parameterCode === 'ESDRY';
                  }
                }
              );
            }
            dispatch({ pageData });
          }
        });
      });
    }
  };

  const combineAttAndParameter = (paraList, attributeList) => {
    paraList.forEach((pl) => {
      const { attOrder } = attributeList.filter(
        (x) => x.attCode === pl.attributeCode
      )[0];
      Object.assign(pl, { attOrder });
    });
    return paraList;
  };

  useEffect(() => {
    if (!parameterList) {
      showLoader();
      wndAppearanceDispatch({ totalAppearance: 0 });
      const allAttCode = [];
      attInfo.forEach((element) => {
        allAttCode.push(element.attCode);
      });
      fetchWoundParametersContentByArray(allAttCode).then((response) => {
        wndAppearanceDispatch({
          escharList: response.item.parameters.filter(
            (x) => x.attributeCode === 'ESCDR'
          ),
        });
        const paraList = combineAttAndParameter(
          response.item.parameters,
          attInfo
        );
        if (paraList) {
          paraList.forEach((element) => {
            element.selectedBoolean = true;
            element.selectedValue = element.parameterGroup.sort(
              (a, b) => a.sortPriority - b.sortPriority
            )[0].parameterCode;
            element.imgActive = true;
          });
          wndAppearanceDispatch({ parameterList: paraList });
        }
        wndAppearanceDispatch({
          parameterList: paraList,
        });
        async function rule(name, value, paramList) {
          const att = paramList.filter((x) => x.attributeCode === name);
          const result = att[0].parameterGroup.filter(
            (x) => x.parameterCode === value
          );
          await executeRule(result[0].hasRules, value);
        }
        let total = 0;
        if (paraList) {
          paraList.forEach((element) => {
            rule(element.attributeCode, element.selectedValue, paraList);
          });
          total = calculateTotal(paraList);
        }
        validateToggle(total, ESCDR);
        const allParameterExceptEschar = response.item.parameters.filter(
          (x) => x.attributeCode !== 'ESCDR'
        );
        allParameterExceptEschar.forEach((element) => {
          const items = element.parameterGroup[0];
          const buildDisplayText = `${
            items.parameterDesc
          } ${element.attributeName.replace(/[^a-zA-Z ]/g, '')}`;
          const saveObj = [];
          saveObj.push(
            createObj(
              element.attributeName.replace(/[^a-zA-Z ]/g, ''),
              items.parameterCode
            )
          );
          const updatedPageData = capturePageData(
            element.attributeCode,
            items.parameterCode,
            items,
            pageData,
            element.attributeName,
            activeStep,
            buildDisplayText,
            saveObj
          );
          dispatch({ pageData: updatedPageData });
        });
        hideLoader();
      });
    } else {
      parameterList.forEach((element) => {
        element.imgActive = true;
      });
      wndAppearanceDispatch({ parameterList });
    }
  }, [ESCDR]);

  const changeArrowIcon = (e, ix) => {
    parameterList.sort((a, b) => a.attOrder - b.attOrder)[
      ix
    ].imgActive = !parameterList[ix].imgActive;
    dispatch({ parameterList });
  };

  useEffect(() => {
    let total = 0;
    if (parameterList) {
      total = calculateTotal(parameterList);
    }
    validateToggle(total, ESCDR);
  }, [
    ESCDR,
    EPH,
    SLO,
    ESC,
    HYG,
    GRA,
    isAppearanceValid,
    minimumValidationData,
  ]);

  return (
    <Box className={classes.container}>
      {loader}
      <QuestionHeader
        questionTextValue={questionTextValue}
        questionImageURLValue={questionImageURLValue}
        handleInfoClick={handleInfoClick}
        tooltipTitle={tooltipTitle}
        open={open}
        setRef={() => {
          setOpen(false);
        }}
      />
      <QuestionDescription
        questionDescriptionTextValue={questionDescriptionTextValue}
      />
      <Box className="allAppearanceBox mobAllAppearanceBox">
        {parameterList &&
          parameterList
            .filter((x) => x.attributeCode !== 'ESCDR')
            .sort((a, b) => a.attOrder - b.attOrder)
            .map((rec, ix) => (
              <Box className="appearanceBox mobAppearanceBox">
                <div className="appearanceContainer">
                  <Box className="descriptionBox mobDescriptionBox">
                    <div className="mobRowFlex">
                      <CustomLabel
                        labelStyle={{
                          fontWeight: 'bold',
                          fontStyle: 'normal',
                          fontSize: '20px',
                          lineHeight: '24px',
                          color: '#323234',
                          margin: '10px 0',
                        }}
                        value={rec.attributeName.replace(/[^a-zA-Z ]/g, '')}
                      />
                      <div className="hideDiv mobRowFlex">
                        {rec.imgActive ? (
                          <ArrowDown
                            onClick={(e) => changeArrowIcon(e, ix)}
                            className="svgAlign"
                          />
                        ) : (
                          <ArrowUp
                            onClick={(e) => changeArrowIcon(e, ix)}
                            className="svgAlign"
                          />
                        )}
                      </div>
                    </div>
                    <CustomLabel
                      labelStyle={{
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontSize: '16px',
                        lineHeight: '20px',
                        color: '#323234',
                      }}
                      boxStyle={{
                        display: matchesMobileWidth ? 'none' : 'flex',
                        alignItems: 'center',
                      }}
                      value={rec.parameterGroup[0].parameterDescriptor}
                    />
                  </Box>
                  <CustomDropDown
                    dropDownStyle={{
                      width: '104px',
                      margin: matchesMobileWidth ? '0' : '0 40px',
                      background: '#FFFFFF',
                      boxSizing: 'border-box',
                      boxShadow: 'inset 0px 1px 2px rgba(0, 0, 0, 0.1)',
                      borderRadius: '2px',
                      top: matchesMobileWidth ? '0px' : '27px',
                    }}
                    selectData={rec}
                    menuData={rec.parameterGroup.sort(
                      (a, b) => a.sortPriority - b.sortPriority
                    )}
                    handleChange={handleChange}
                  />
                </div>
                <div
                  className={`${
                    rec.imgActive ? 'transitionClose' : 'transitionOpen'
                  } hideDiv mobRowFlex`}
                >
                  {parse(rec.parameterGroup[0].parameterDescriptor || '')}
                </div>
              </Box>
            ))}
      </Box>
      <div className={classes.fullDiv}>
        <Box className={classes.halfDiv1} />
        <Box className={classes.halfDiv2}>
          <Box className={classes.halfDiv2Inner}>
            <CustomLabel
              labelStyle={{
                fontWeight: 'bold',
                padding: '18px',
                textAlign: 'end',
                fontSize: '20px',
                lineHeight: '24px',
              }}
              value="Total"
            />
            <CustomLabel
              labelStyle={{
                fontWeight: 'bold',
                padding: '18px',
                textAlign: 'end',
                fontSize: '16px',
              }}
              value={`${totalAppearance}%`}
            />
          </Box>
          {totalAppearance !== 100 && (
            <Box className="appearanceMsgBox mobAppearanceMsgBox">
              <CustomLabel
                labelStyle={{
                  textAlign: 'end',
                  color: 'red',
                  fontSize: '16px',
                  lineHeight: '26px',
                }}
                value=" The appearance values must total 100%"
              />
            </Box>
          )}
        </Box>
      </div>

      {escharGreaterZero && (
        <Box style={{ marginTop: '20px' }}>
          <QuestionHeader
            questionTextValue={questionTextValue2}
            questionImageURLValue={questionImageURLValue}
            handleInfoClick={handleInfoClickEschar}
            tooltipTitle={tooltipTitle2}
            open={openEscharDry}
            setRef={() => {
              setOpenEscharDry(false);
            }}
          />
          <RadioButton
            value={ESCDR}
            handleRadioChange={handleRadioChange}
            showDescriptor={false}
            groupValue={escharList && escharList[0].parameterGroup}
            labelStyle={{ maxWidth: '60%', minWidth: '40%' }}
          />
        </Box>
      )}
      {escharGreater75 && (
        <Box className="mobWarningBox warningBox">
          <Typography>
            {parse(escharWarning && escharWarning, {
              replace(node) {
                let returnValue = '';
                if (node.parent && node.parent.name === 'span') {
                  returnValue = (
                    <NavLink
                      to="/allTherapies"
                      underline="always"
                      style={{
                        fontSize: '16px',
                        lineHeight: '20px',
                        color: '#0049BD',
                      }}
                    >
                      browse all therapies
                    </NavLink>
                  );
                }
                return returnValue;
              },
            })}
          </Typography>
        </Box>
      )}
      <AlertDialog
        isOpen={popUpOpen}
        handleClose={handlePopUpClose}
        popUpPositiveButton={handlePopUpClose}
        title={popUpTitle}
        buttonName="Got it"
      >
        {popUpMessage}
      </AlertDialog>
    </Box>
  );
};
Appearances.propTypes = {
  attInfo: PropTypes.arrayOf(
    PropTypes.shape({
      attCode: PropTypes.string,
      attOrder: PropTypes.number,
      pageCode: PropTypes.string,
      contentID: PropTypes.string,
    })
  ).isRequired,
};

export default Appearances;
