/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-curly-newline */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import parse from 'html-react-parser';
// Material UI imports
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  AppBar,
  Toolbar,
} from '@material-ui/core';

// Custom Component imports
import useDynamicRefs from 'use-dynamic-refs';
import CustomLabel from '../customLabel/CustomLabel';
import CustomLine from '../customLine/CustomLine';
import AlertDialog from '../alertBox/AlertBox';
import TreatmentOptions from './TreatmentOptions';
import CustomLargeButton from '../customLargeButton';
import IndeterminateCheckBox from '../indeterminateCheckBox';
// Utility imports
import { ApplicationContext } from '../../store/ApplicationState';
import useOutsideclick from '../../utility/useOutsideclick';

import {
  STATUS_CURRENT,
  STATUS_ENTERED,
  STATUS_VISITED,
  STATUS_COMPLETED,
  STATUS_GRAY,
  MOBILE_BREAKPOINT,
  CHECKBOX_STATES_CHECKED,
  CHECKBOX_STATES_EMPTY,
  CHECKBOX_STATES_INDETERMINATE,
} from '../../utility/staticText';
// api imports
import {
  getActivePageConfig,
  saveFinalRecommendtation,
  // saveFinalRecommendtation,
} from '../../api/common';
import getCmsContent from '../../api/cms';

import LightTooltip from '../lightTooltip/LightTooltip';
import CustomButton from '../customButton';
import useFullPageLoader from '../../hooks/useFullPageLoader';

function getContentID(pageConfigList, goalPathway) {
  if (goalPathway) {
    const pageObj = pageConfigList.filter((page) => page.pageCode === 'WDGLR');
    return pageObj[0].contentID;
  }
  const pageObj = pageConfigList.filter((page) => page.pageCode === 'TROPT');
  return pageObj[0].contentID;
}
async function fetchWoundRecommendationContents(contentId) {
  let content = JSON.parse(localStorage.getItem(contentId));
  if (!content) {
    const body = {
      token: '',
      contentId,
    };
    content = await getCmsContent(body);
    localStorage.setItem(contentId, JSON.stringify(content));
  }
  return content;
}

const useStyles = makeStyles(() => ({
  shellStyle: {
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 24px 32px 24px',
    backgroundColor: '#F8F8FA',
    boxShadow:
      '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12)',
    minHeight: 'auto',
    borderRadius: '4px',
  },
  goalQuestionStyle: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'auto',
    marginTop: '48px',
  },
  startOverStyle: {
    marginTop: '30px',
    textAlign: '-webkit-center',
    cursor: 'pointer',
  },
  buttonStyle: {
    fontSize: '16px',
    'font-weight': '700',
  },
  shellWidth: {
    width: '260px',
  },
  shellBox: {
    margin: '15px 0px 15px 0px',
  },
  shellBoxDisplay: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-end',
  },
  mainBox: {
    minHeight: 'calc(100vh - 145px)',
    width: '100%',
  },

  shellDisplayGoalStyle1: {
    textAlign: 'right',
    fontSize: '16px',
    'font-weight': '700',
    color: '#0049BD',
    cursor: 'pointer',
  },
  goalAttributeStyle: {
    fontSize: '16px',
    color: '#76767A',
    fontWeight: '500',
    textAlign: 'left',
    float: 'left',
  },
  shellBox1: {
    margin: '15px 0px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
}));

const WoundRecommendation = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const theme = useTheme();
  const matchesMobileWidth = useMediaQuery(
    theme.breakpoints.down(MOBILE_BREAKPOINT)
  );
  const classes = useStyles();
  const history = useHistory();
  const [startOverPopUp, setstartOverPopUp] = useState(false);
  const [pageTitle, setPageTitle] = useState('');
  const [pageIntroduction, setPageIntroduction] = useState('');
  const [instruction, setInstruction] = useState('');
  const [instructionTitle, setInstructionTitle] = useState('');
  const [navButtonText, setNavButtonText] = useState('');
  const [finalButtonText, setfinalButtonText] = useState('');
  const [redInfoIcon, setRedInfoIcon] = useState('');
  const [toolTipMessage, setToolTipMessage] = useState(null);
  const [finalButtonOpen, setFinalButtonOpen] = useState(false);
  const [finalToolTipMessage, setFinalToolTipMessage] = useState(false);
  const [backWardArrowIcon, setbackWardArrowIcon] = useState('');
  const [questionPassToGoalText, setQuestionPassToGoalText] = useState('');
  const [passToWCButtonText, setPassToWCButtonText] = useState('');
  const [questionPassWCText, setQuestionPassWCText] = useState('');
  const [switchTopText, setSwitchTopText] = useState('');
  const [switchBottomText, setSwitchBottomText] = useState('');
  const [infoparameterCode, setInfoParameterCode] = useState('');
  const [getRef, setRef] = useDynamicRefs();

  const {
    state: {
      goalPageData,
      pageData,
      totalStepper,
      activeStep,
      lastPage,
      isGoalPathWay,
      treatmentPathway,
      disclaimerLegalText,
      sessionID,
    },
    wndRecommState: { treatmentList, selectAllTreatment },
    wndRecommDispatch,
    dispatch,
    wndAgeDispatch,
    wndTypeDispatch,
    wndSizeDispatch,
    wndExudateDispatch,
    wndInfectionDispatch,
    wndAppearanceDispatch,
    wndPainDispatch,
    wndCompressionDispatch,
    wndOdorDispatch,
    wndEdgesDispatch,
    wndPeriwoundDispatch,
    wndGoalDispatch,
  } = useContext(ApplicationContext);
  if (pageData.length === 0 && isGoalPathWay === false) {
    history.push('/home');
  }
  const handleClose = () => {
    setstartOverPopUp(false);
  };
  const startOverClick = () => {
    setstartOverPopUp(true);
  };
  const resetWoundCaptureData = () => {
    dispatch({ type: 'RESET_STATE' });
    wndAgeDispatch({ type: 'RESET_AGE_STATE' });
    wndTypeDispatch({ type: 'RESET_TYPE_STATE' });
    wndSizeDispatch({ type: 'RESET_SIZE_STATE' });
    wndExudateDispatch({ type: 'RESET_EXUDATE_STATE' });
    wndInfectionDispatch({ type: 'RESET_INFECTION_STATE' });
    wndAppearanceDispatch({ type: 'RESET_APPEARANCE_STATE' });
    wndPainDispatch({ type: 'RESET_PAIN_STATE' });
    wndCompressionDispatch({ type: 'RESET_COMPRESSION_STATE' });
    wndOdorDispatch({ type: 'RESET_ODOR_STATE' });
    wndEdgesDispatch({ type: 'RESET_EDGES_STATE' });
    wndPeriwoundDispatch({ type: 'RESET_PERI_STATE' });
    wndGoalDispatch({ type: 'RESET_Goal_STATE' });
    dispatch({ forceNew: true });
  };

  const handleStartOver = () => {
    resetWoundCaptureData();
    history.push('/home');
  };

  const reffinal = useRef(null);
  useOutsideclick(reffinal, () => {
    setFinalButtonOpen(false);
  });
  const updateStatus = (index) => {
    if (pageData[index].enabled) {
      const updatedData = pageData.map((page, ix) =>
        ix === index &&
        index !== totalStepper &&
        page.status === '' &&
        page.enabled
          ? { ...page, status: STATUS_VISITED, isActiveStep: true }
          : page
      );
      if (index !== totalStepper) {
        updatedData[index].status =
          updatedData[index].status === STATUS_ENTERED
            ? STATUS_ENTERED
            : STATUS_CURRENT;
      }
      updatedData[totalStepper].isActiveStep = false;

      if (
        updatedData[activeStep].status !== STATUS_ENTERED &&
        updatedData[activeStep].status !== STATUS_GRAY &&
        updatedData[activeStep].status !== STATUS_COMPLETED &&
        updatedData[activeStep].enabled
      ) {
        updatedData[activeStep].status = STATUS_VISITED;
      }
      dispatch({ pageData: updatedData });
    }
  };

  const landStepperPage = (idx) => {
    updateStatus(idx);
    if (idx === totalStepper) {
      dispatch({ showNextButton: false });
    } else {
      dispatch({ showNextButton: true });
    }
    pageData[totalStepper].isActiveStep = true;
    dispatch({ activeStep: idx });
    history.push('/woundTherapyGuide');
  };
  const landGoalPage = () => {
    history.push('/woundGoal');
  };

  function handleInfoClick(idx, popupTitle, popupMessage, pCode) {
    setInfoParameterCode(pCode);
    setToolTipMessage(
      <div
        style={{
          padding: '16px 16px 25px 16px',
          lineHeight: '20px',
          color: '#323234',
        }}
      >
        <p style={{ fontSize: '20px', fontWeight: 'bold' }}>
          {parse(popupTitle || '')}
        </p>
        <div style={{ fontSize: '16px', marginTop: '8px' }}>
          {parse(popupMessage || '')}
        </div>
      </div>
    );
    pageData
      .filter((x) => x.showInShell)
      .map((da) => {
        if (da.data) {
          if (
            Object.prototype.hasOwnProperty.call(da.data, 'SOI') ||
            Object.prototype.hasOwnProperty.call(da.data, 'WOE')
          ) {
            Object.keys(da.data).forEach((key) => {
              da.data[key].map((x) =>
                x.parameterCode === pCode
                  ? (x.showPopup = !x.showPopup)
                  : (x.showPopup = false)
              );
            });
          } else {
            const result = Object.keys(da.data).map((key) => [da.data[key]]);
            result.map((x) =>
              x[0].parameterCode === pCode
                ? (x[0].showPopup = !x[0].showPopup)
                : (x[0].showPopup = false)
            );
          }
        }
      });

    dispatch({ pageData });
  }

  function handleClickOutside(event) {
    if (infoparameterCode && infoparameterCode) {
      const ref = getRef(infoparameterCode);
      if (ref.current && !ref.current.contains(event.target)) {
        pageData
          .filter((x) => x.showInShell)
          .map((da) => {
            if (da.data) {
              Object.keys(da.data).forEach((key) => {
                if (Array.isArray(da.data[key])) {
                  da.data[key].map((x) =>
                    x.parameterCode === infoparameterCode
                      ? (x.showPopup = false)
                      : x
                  );
                } else {
                  const result = Object.keys(da.data).map((key1) => [
                    da.data[key1],
                  ]);
                  result.map((x) =>
                    x[0].parameterCode === infoparameterCode
                      ? (x[0].showPopup = false)
                      : x
                  );
                }
              });
            }
          });

        dispatch({ pageData });
      }
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [infoparameterCode]);
  function handleInfoFinalButtonClick() {
    setFinalButtonOpen(!finalButtonOpen);
    setFinalToolTipMessage(
      <div>
        <h3>Select a treatment</h3>
        <Typography style={{ fontSize: '15px' }}>
          Select at least one treatment to finalize your recommendation.
        </Typography>
      </div>
    );
  }
  function createBody(selectedList) {
    const obj = [];
    selectedList.forEach((res) => {
      const selectedProductCode = [];
      res.relatedProducts.forEach((displayData) => {
        if (displayData.selectedProduct) {
          selectedProductCode.push(displayData.productCode);
        }
      });
      const selectProd = selectedProductCode.join();

      const treatmentFinalCode = {
        sectionCode: res.sectionCode,
        treatmentCode: res.treatmentCode,
        productCode: selectProd.replace(/,/g, ':'),
      };
      obj.push(treatmentFinalCode);
    });
    const reqBody = {
      sessionID,
      treatmentList: obj,
      treatmentPathWay: treatmentPathway,
    };
    return reqBody;
  }

  function handleFinalButtonClick() {
    if (isGoalPathWay) {
      history.push('/woundTherapySelection');
      saveFinalRecommendtation(
        JSON.stringify(createBody(treatmentList.filter((x) => x.selected)))
      );
    } else {
      history.push('/woundTherapySelection');
      saveFinalRecommendtation(
        JSON.stringify(createBody(treatmentList.filter((x) => x.selected)))
      );
      pageData
        .filter((x) => x.showInShell)
        .map((da) => {
          if (da.data) {
            if (
              Object.prototype.hasOwnProperty.call(da.data, 'SOI') ||
              Object.prototype.hasOwnProperty.call(da.data, 'TSP') ||
              Object.prototype.hasOwnProperty.call(da.data, 'WOE')
            ) {
              Object.keys(da.data).forEach((key) => {
                da.data[key].map((x) => (x.showPopup = false));
              });
            } else {
              const result = Object.keys(da.data).map((key) => [da.data[key]]);
              result.map((x) => (x[0].showPopup = false));
            }
          }
        });

      dispatch({ pageData });
    }
  }

  function getDisplayText(data, idx) {
    let returnDisplayTextValue = null;
    if (data) {
      if (
        Object.prototype.hasOwnProperty.call(data, 'SOI') ||
        Object.prototype.hasOwnProperty.call(data, 'TSP') ||
        Object.prototype.hasOwnProperty.call(data, 'WOE')
      ) {
        Object.keys(data).forEach((key) => {
          returnDisplayTextValue = data[key].map((displayData, index) => (
            <Box flexGrow={1} display="flex">
              <CustomLabel
                onClick={() => landStepperPage(idx)}
                boxStyle={{ width: '100%' }}
                labelStyle={{
                  textAlign: 'right',
                  fontSize: '16px',
                  fontWeight: '700',
                  color: '#0049BD',
                  cursor: 'pointer',
                }}
                value={displayData.displayText}
              />
              {displayData.hasRule && (
                <span style={{ position: 'relative', top: '2px' }}>
                  <LightTooltip
                    title={toolTipMessage}
                    ref={setRef(displayData.parameterCode)}
                    arrow
                    placement="top"
                    style={{ marginLeft: '2px' }}
                    open={displayData.showPopup}
                    onClick={() =>
                      handleInfoClick(
                        index,
                        displayData.popUpTitle,
                        displayData.popUpMessage,
                        displayData.parameterCode
                      )
                    }
                  >
                    <img
                      alt="info"
                      src={redInfoIcon}
                      height="20px"
                      width="20px"
                      value={displayData.parameterCode}
                    />
                  </LightTooltip>
                </span>
              )}
            </Box>
          ));
        });
        return returnDisplayTextValue;
      }
      const result = Object.keys(data)
        .filter((x) => x !== 'DEPTH')
        .map((key) => [data[key]]);
      returnDisplayTextValue = result.map((lineGroup, ind) => (
        <Box style={{ display: 'flex' }}>
          <CustomLabel
            onClick={() => landStepperPage(idx)}
            boxStyle={{ width: '100%' }}
            labelStyle={{
              textAlign: 'right',
              fontSize: '16px',
              fontWeight: '700',
              color: '#0049BD',
              cursor: 'pointer',
            }}
            value={lineGroup[0].displayText}
          />
          {lineGroup[0].hasRule && (
            <span style={{ position: 'relative', top: '2px' }}>
              <LightTooltip
                title={toolTipMessage}
                ref={setRef(lineGroup[0].parameterCode)}
                arrow
                placement="top"
                style={{ marginLeft: '2px' }}
                open={lineGroup[0].showPopup}
                onClick={() =>
                  handleInfoClick(
                    ind,
                    lineGroup[0].popUpTitle,
                    lineGroup[0].popUpMessage,
                    lineGroup[0].parameterCode
                  )
                }
              >
                <img
                  alt="info"
                  src={redInfoIcon}
                  height="20px"
                  width="20px"
                  value={lineGroup[0].parameterCode}
                />
              </LightTooltip>
            </span>
          )}
        </Box>
      ));
      return returnDisplayTextValue;
    }
    return (
      <CustomLabel
        onClick={() => landStepperPage(idx)}
        boxStyle={{ width: '50%' }}
        labelStyle={{
          textAlign: 'right',
          fontSize: '16px',
          fontWeight: '700',
          color: '#0049BD',
          cursor: 'pointer',
        }}
        value="Blank"
      />
    );
  }

  useEffect(() => {
    showLoader();
    const updatedList = treatmentList.map((x) => ({ ...x, imgUp: false }));
    wndRecommDispatch({
      treatmentList: updatedList,
    });
  }, []);

  useEffect(() => {
    getActivePageConfig().then((content) => {
      const contentID = getContentID(
        content.item.pageConfigListObj,
        isGoalPathWay
      );
      fetchWoundRecommendationContents(contentID).then((woundPageContent) => {
        const {
          woundRecommendationPageTitle,
          woundRecommendationIntroduction,
          woundRecommendationInstruction,
          woundRecommendationTitle,
          navigationButtonText,
          finalizeButtonText,
          redArrowIcon,
          backwardArrowIcon,
          woundRecommendationSwitchTop,
          woundRecommendationSwitchBottom,
        } = woundPageContent.item.contentDetailsLists[0].contentItems[0].woundRecommendationDetails;
        if (isGoalPathWay) {
          const {
            questionPassToGoal,
            passToGoalButtonText,
            questionPassToGoalTitle,
          } = woundPageContent.item.contentDetailsLists[0].contentItems[0].woundRecommendationDetails;
          setQuestionPassToGoalText(questionPassToGoal);
          setPassToWCButtonText(passToGoalButtonText);
          setQuestionPassWCText(questionPassToGoalTitle);
        }
        setPageTitle(woundRecommendationPageTitle.replace(/<[^>]+>/g, ''));
        setPageIntroduction(
          woundRecommendationIntroduction.replace(/<[^>]+>/g, '')
        );
        setInstruction(woundRecommendationInstruction);
        setInstructionTitle(woundRecommendationTitle);
        setNavButtonText(navigationButtonText);
        setfinalButtonText(finalizeButtonText);
        setRedInfoIcon(redArrowIcon);
        setbackWardArrowIcon(backwardArrowIcon);
        setSwitchTopText(woundRecommendationSwitchTop);
        setSwitchBottomText(woundRecommendationSwitchBottom);
        hideLoader();
      });
    });
  }, []);

  const handleClick = () => {
    if (isGoalPathWay) {
      history.push('/woundGoal');
    } else {
      dispatch({ activeStep: lastPage });
      dispatch({ showNextButton: true });
      history.push('/woundTherapyGuide');
    }
  };

  const handleProvideWound = () => {
    dispatch({
      activeStep: 0,
      isGoalPathWay: false,
      treatmentPathway: 'WNDCH',
    });
    history.push('/woundTherapyGuide');
  };

  function returnShellBlockJSX(label, idx) {
    return (
      <>
        <Box className={classes.shellBox1}>
          <CustomLabel
            boxStyle={{ width: matchesMobileWidth ? '100%' : '30%' }}
            labelStyle={{
              fontSize: '16px',
              color: '#76767A',
              fontWeight: '400px',
            }}
            value={label.pageTitle}
          />
          <Box className={classes.shellBoxDisplay}>
            {getDisplayText(label.data, idx)}
          </Box>
        </Box>
        {idx !== totalStepper - 1 && (
          <CustomLine lineStyle={{ border: '0.5px solid #C4C4C4' }} />
        )}
      </>
    );
  }
  function returnShellBlockGoalJSX(label) {
    let returnDisplayTextValue = null;
    if (label.data) {
      const result = Object.keys(label.data).map((key) => [label.data[key]]);
      returnDisplayTextValue = result.map((displayData, index) => (
        <>
          <Box className={classes.shellBox}>
            <Typography className={classes.goalAttributeStyle} display="inline">
              {' '}
              {displayData[0].attributeName}{' '}
            </Typography>
            <Typography
              className={classes.shellDisplayGoalStyle1}
              onClick={() => landGoalPage()}
            >
              {' '}
              {displayData[0].displayText}
            </Typography>
          </Box>
          {index !== 2 - 1 && (
            <CustomLine lineStyle={{ border: '0.5px solid #C4C4C4' }} />
          )}
        </>
      ));

      return returnDisplayTextValue;
    }
    return returnDisplayTextValue;
  }

  function getUpdatedTreatmentList(value) {
    const updatedlist = treatmentList.map((x) => ({
      ...x,
      selected: value,
    }));
    const updatedProductList = updatedlist.map((groupe) => ({
      ...groupe,
      relatedProducts: groupe.relatedProducts.map((element) => ({
        ...element,
        selectedProduct: value,
      })),
    }));
    return updatedProductList;
  }

  function setAlltreatment() {
    let updatedChecked;

    if (selectAllTreatment === CHECKBOX_STATES_CHECKED) {
      updatedChecked = CHECKBOX_STATES_EMPTY;
      const updatedProductList = getUpdatedTreatmentList(false);
      wndRecommDispatch({
        treatmentList: updatedProductList,
      });
    } else if (selectAllTreatment === CHECKBOX_STATES_EMPTY) {
      updatedChecked = CHECKBOX_STATES_CHECKED;
      const updatedProductList = getUpdatedTreatmentList(true);
      wndRecommDispatch({
        treatmentList: updatedProductList,
      });
    } else if (selectAllTreatment === CHECKBOX_STATES_INDETERMINATE) {
      updatedChecked = CHECKBOX_STATES_CHECKED;
      const updatedProductList = getUpdatedTreatmentList(true);
      wndRecommDispatch({
        treatmentList: updatedProductList,
      });
    }
    wndRecommDispatch({ selectAllTreatment: updatedChecked });
  }

  function returnSelectAllTreatment() {
    let returnValue = null;
    returnValue = (
      <IndeterminateCheckBox
        label="Select all treatments"
        value={selectAllTreatment}
        onChange={setAlltreatment}
      />
    );
    return returnValue;
  }

  return (
    <Box className={classes.mainBox}>
      {loader}

      <div className="recommRoot mobRecommRoot ">
        <div className="largeButtonStyle mobLargeButtonStyle mobMargin">
          <CustomLargeButton
            handleClick={handleClick}
            buttonStyle={{
              borderColor: '#0049BD',
              width: matchesMobileWidth ? '100%' : 'auto',
              height: '48px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            buttonText={navButtonText}
            buttonLeftSideIcon={backWardArrowIcon}
          />
        </div>
        <div className="pageIntroStyle mobPageIntroStyle mobMargin">
          <CustomLabel
            labelStyle={{
              fontSize: matchesMobileWidth ? '28px' : '48px',
              marginTop: matchesMobileWidth ? '0' : '24px',
              fontWeight: '700',
              lineHeight: matchesMobileWidth ? '32px' : '56px',
            }}
            value={pageTitle}
          />
        </div>
        <div className="pageIntroStyle mobPageIntroStyle mobMargin">
          <CustomLabel
            labelStyle={{
              fontSize: '16px',
              lineHeight: '20px',
              fontWeight: matchesMobileWidth ? 'normal' : '400',
            }}
            boxStyle={{ marginTop: matchesMobileWidth ? '16px' : '20px' }}
            value={pageIntroduction}
          />
        </div>
        <div className="charactersticsStyle mobCharactersticsStyle">
          <Box className="charctersticsBoxStyle mobCharctersticsBoxStyle">
            <Box className={classes.shellStyle}>
              <Box className={classes.shellWidth}>
                <CustomLabel
                  labelStyle={{ fontSize: '18px', fontWeight: '700' }}
                  value="Wound characteristics"
                />
              </Box>
              {isGoalPathWay
                ? goalPageData
                    .filter((x) => x.showInShell)
                    .map((label, idx) => returnShellBlockGoalJSX(label, idx))
                : pageData
                    .filter((x) => x.showInShell)
                    .map((label, idx) => returnShellBlockJSX(label, idx))}
              <Box className={classes.startOverStyle}>
                <CustomLabel
                  labelStyle={{
                    fontSize: '16px',
                    fontWeight: '700',
                    lineHeight: '20px',
                    color: '#0049BD',
                  }}
                  onClick={startOverClick}
                  value="Start over"
                />
              </Box>
            </Box>
            {isGoalPathWay && (
              <Box className={classes.goalQuestionStyle}>
                <Box className="mobMargin">
                  <CustomLabel
                    boxStyle={{ marginTop: '5px' }}
                    labelStyle={{ lineHeight: '20px', fontSize: '20px' }}
                    value={questionPassWCText}
                  />
                  <CustomLabel
                    boxStyle={{ marginTop: '5px' }}
                    labelStyle={{ lineHeight: '20px', fontSize: '16px' }}
                    value={questionPassToGoalText}
                  />
                  <CustomButton
                    value={passToWCButtonText}
                    backgroundColor="white"
                    color="#0049BD"
                    borderColor="#0049BD"
                    fontWeight="bold"
                    width={matchesMobileWidth ? '100%' : '308px'}
                    fontSize="10px"
                    handleSubmit={handleProvideWound}
                    containerBoxStyle={{ marginTop: '16px' }}
                    isSecondary
                  />
                </Box>
              </Box>
            )}
          </Box>
          <div className="mainContentStyle mobMainContentStyle mobMargin">
            <div style={{ marginLeft: matchesMobileWidth ? '0px' : '15px' }}>
              <div>
                <CustomLabel
                  labelStyle={{
                    fontSize: '24px',
                    lineHeight: '28px',
                    fontWeight: 700,
                  }}
                  boxStyle={{
                    marginTop: matchesMobileWidth ? '0px' : '25px',
                  }}
                  value={instructionTitle}
                />
                <CustomLabel
                  labelStyle={{
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: 400,
                  }}
                  boxStyle={{ marginBottom: '45px', marginTop: '16px' }}
                  value={instruction}
                />
              </div>
              <TreatmentOptions
                AllTherapyPath={false}
                isFinalRecommendation={false}
                topSwitch={switchTopText}
                bottomSwitch={switchBottomText}
                pathway={isGoalPathWay ? 'GOAL' : 'CHARACTERISTICS'}
              />

              {treatmentList.filter((x) => x.isActive !== 0 && x.selected)
                .length > 0 && (
                <AppBar className="recommFloatBar recommMobFloatBar">
                  <Toolbar className="recommToolBar recommMobileToolBar">
                    {returnSelectAllTreatment()}
                    <Button
                      variant="contained"
                      color="primary"
                      className="btnFinalize mobBtnFinalize buttonStyle"
                      onClick={handleFinalButtonClick}
                    >
                      <Typography className="finalButtonTextStyle finalMobButtonTextStyle">
                        {finalButtonText}
                      </Typography>
                    </Button>
                  </Toolbar>
                </AppBar>
              )}
            </div>
          </div>
        </div>

        <div className="disclaimerFloatingBox mobDisclaimerFloatingBox">
          <p className="disclaimerText">{parse(disclaimerLegalText || '')}</p>
        </div>
      </div>

      {treatmentList.filter((x) => x.isActive !== 0 && x.selected).length ===
        0 && (
        <AppBar className="recommFloatBar recommMobFloatBar">
          <Toolbar className="recommToolBar recommMobileToolBar">
            {returnSelectAllTreatment()}
            <span>
              <LightTooltip
                title={finalToolTipMessage}
                arrow
                ref={reffinal}
                placement="top"
                style={{ marginLeft: '2px' }}
                open={finalButtonOpen}
                onClick={() => handleInfoFinalButtonClick()}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className="btnFinalize mobBtnFinalize buttonStyle"
                >
                  <Typography className="finalButtonTextStyle finalMobButtonTextStyle">
                    {finalButtonText}
                  </Typography>
                </Button>
              </LightTooltip>
            </span>
          </Toolbar>
        </AppBar>
      )}

      <AlertDialog
        isOpen={startOverPopUp}
        handleClose={handleClose}
        popUpPositiveButton={handleStartOver}
        title="Start the assessment over?"
        buttonName="Got it"
        closeStyle={{ padding: '15px' }}
        smallDialog
      >
        Your current answers will not be saved.
      </AlertDialog>
    </Box>
  );
};

export default WoundRecommendation;
