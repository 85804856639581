/* eslint-disable react/jsx-curly-newline */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Material UI imports
import { Box } from '@material-ui/core';
// Component imports
import QuestionHeader from '../../components/questionHeader';
import RadioButton from '../../components/radioButton';
// Utility imports
import {
  fetchSiteCoreContents,
  setTooltipText,
  fetchWoundParametersContent,
} from '../../utility/utils';

const ExudateType = ({
  attCode,
  contentID,
  selectedParameterCode,
  handleRadioChange,
}) => {
  const [open, setOpen] = useState(false);
  const [questionTextValue, setQuestionTextValue] = useState();
  const [questionImageURLValue, setQuestionImageURLValue] = useState();
  const [infoTitleValue, setInfoTitleValue] = useState();
  const [infoDescriptionValue, setInfoDescriptionValue] = useState();
  const [infoURLValue, setInfoURLValue] = useState();
  const [infoURLTitleValue, setInfoURLTitleValue] = useState();
  const [parameterList, setParameterList] = useState([]);
  const [attributeName, setAttributeName] = useState([]);
  const handleInfoClick = () => {
    setOpen(!open);
  };

  const tooltipTitle = setTooltipText(
    infoTitleValue,
    infoDescriptionValue,
    infoURLValue,
    infoURLTitleValue
  );
  // site core content
  fetchSiteCoreContents(contentID).then((typeContent) => {
    const {
      question2Text,
      questionImage,
    } = typeContent.item.contentDetailsLists[0].contentItems[0].woundCharacteristics;
    const {
      infoTitle,
      infoDescription,
      infoURL,
      infoURLTitle,
    } = typeContent.item.contentDetailsLists[0].contentItems[0].secondaryInfoDetails;

    setQuestionImageURLValue(questionImage);
    setQuestionTextValue(question2Text.replace(/<[^>]+>/g, ''));
    setInfoTitleValue(infoTitle.replace(/<[^>]+>/g, ''));
    setInfoDescriptionValue(infoDescription);
    setInfoURLValue(infoURL);
    setInfoURLTitleValue(infoURLTitle);
  });

  useEffect(() => {
    fetchWoundParametersContent(attCode).then((response) => {
      setAttributeName(response.item.parameters[0].attributeName);
      setParameterList(response.item.parameters[0].parameterGroup);
    });
  }, [attCode]);

  return (
    <Box>
      <QuestionHeader
        questionTextValue={questionTextValue}
        questionImageURLValue={questionImageURLValue}
        handleInfoClick={handleInfoClick}
        tooltipTitle={tooltipTitle}
        open={open}
        setRef={() => {
          setOpen(false);
        }}
      />
      <RadioButton
        value={selectedParameterCode}
        handleRadioChange={(event) =>
          handleRadioChange(event, parameterList, attributeName)
        }
        groupValue={parameterList && parameterList}
      />
    </Box>
  );
};

ExudateType.propTypes = {
  attCode: PropTypes.string.isRequired,
  contentID: PropTypes.string.isRequired,
  selectedParameterCode: PropTypes.string.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
};
export default ExudateType;
