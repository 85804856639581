import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
// Material UI imports
import { Box } from '@material-ui/core';
import QuestionHeader from '../../components/questionHeader';
import QuestionDescription from '../../components/questionDescription';
import {
  fetchSiteCoreContents,
  fetchWoundParametersContentByArray,
  combineAttAndParameter,
  capturePageData,
  captureMiniValidationData,
} from '../../utility/utils';
import { ApplicationContext } from '../../store/ApplicationState';
import RadioButton from '../../components/radioButton';
import CustomButton from '../../components/customButton';
import {
  ERROR_MESSAGE,
  STATUS_CURRENT,
  STATUS_ENTERED,
  STATUS_NOT_ENTERED,
} from '../../utility/staticText';
import AlertDialog from '../../components/alertBox';
import { createObj } from '../../utility/saveAssessment';
import useFullPageLoader from '../../hooks/useFullPageLoader';

const WoundPain = ({ attInfo }) => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { contentID } = attInfo.filter((att) => att.pageCode === 'WDPAI')[0];
  const [questionTextValue, setQuestionTextValue] = useState();
  const [questionImageURLValue, setQuestionImageURLValue] = useState();
  const [
    questionDescriptionTextValue,
    setQuestionDescriptionTextValue,
  ] = useState();
  const [enterButtonTextValue, setEnterButtonTextValue] = useState();
  const [alertOpen, setAlertOpen] = useState(false);
  const {
    state: { activeStep, pageData, totalStepper, minimumValidationData },
    dispatch,
    wndPainState: { painParameterList, selectedPain },
    wndPainDispatch,
  } = useContext(ApplicationContext);
  const [attributeName, setAttributeName] = useState([]);

  // site core content
  fetchSiteCoreContents(contentID).then((typeContent) => {
    const {
      questionText,
      questionImage,
      questionDescriptionText,
      enterButtonText,
    } = typeContent.item.contentDetailsLists[0].contentItems[0].woundCharacteristics;

    setQuestionDescriptionTextValue(
      questionDescriptionText.replace(/<[^>]+>/g, '')
    );
    setQuestionImageURLValue(questionImage);
    setQuestionTextValue(questionText.replace(/<[^>]+>/g, ''));
    setEnterButtonTextValue(enterButtonText);
  });

  const handleRadioChange = (event) => {
    const { value } = event.target;
    const selectedData = painParameterList[0].parameterGroup.filter(
      (x) => x.parameterCode === value
    )[0];
    wndPainDispatch({ selectedPain: event.target.value });
    const saveObj = [];
    saveObj.push(createObj('painCode', value));
    const updatedPageData = capturePageData(
      attInfo[0].attCode,
      value,
      selectedData,
      pageData,
      attributeName,
      activeStep,
      selectedData.parameterDesc,
      saveObj
    );
    dispatch({ pageData: updatedPageData });
    const updateMinimumValidationData = captureMiniValidationData(
      [attInfo[0].attCode][0],
      minimumValidationData,
      true
    );
    dispatch({ updateMinimumValidationData });
  };

  function validateNextButton() {
    if (activeStep + 1 === totalStepper) {
      dispatch({ showNextButton: false });
    }
  }

  const handleSubmit = () => {
    if (selectedPain) {
      if (pageData[activeStep + 1].enabled) {
        if (activeStep < totalStepper) {
          dispatch({ activeStep: activeStep + 1 });
          if (totalStepper !== activeStep + 1) {
            pageData[activeStep + 1].isActiveStep = true;
          }
          validateNextButton();
        }
        if (activeStep === totalStepper) {
          dispatch({ lastPage: activeStep });
        }
        dispatch({ previousPage: activeStep });
      }
    } else {
      setAlertOpen(true);
    }
  };

  const handleClose = () => {
    setAlertOpen(false);
  };

  const validateToggle = () => {
    if (selectedPain) {
      pageData[activeStep].status = STATUS_ENTERED;
    } else {
      pageData[activeStep].status =
        pageData[activeStep].isActiveStep || pageData[activeStep].firstPage
          ? STATUS_CURRENT
          : STATUS_NOT_ENTERED;
    }
    dispatch({ pageData });
    if (!painParameterList) {
      showLoader();
      const allAttCode = [];
      attInfo.forEach((element) => {
        allAttCode.push(element.attCode);
      });
      fetchWoundParametersContentByArray(allAttCode).then((response) => {
        setAttributeName(response.item.parameters[0].attributeName);
        wndPainDispatch({
          painParameterList: combineAttAndParameter(
            response.item.parameters,
            attInfo
          ),
        });
        hideLoader();
      });
    }
  };

  useEffect(() => {
    validateToggle();
  }, [selectedPain]);

  return (
    <Box className="attributeContainer mobAttributeContainer">
      {loader}
      <QuestionHeader
        questionTextValue={questionTextValue}
        questionImageURLValue={questionImageURLValue}
        showInfo={false}
      />
      <QuestionDescription
        questionDescriptionTextValue={questionDescriptionTextValue}
      />
      {painParameterList && (
        <RadioButton
          value={selectedPain}
          handleRadioChange={handleRadioChange}
          showDescriptor={false}
          groupValue={painParameterList && painParameterList[0].parameterGroup}
        />
      )}
      <Box>
        <CustomButton
          value={enterButtonTextValue}
          handleSubmit={handleSubmit}
          width="100px"
        />
      </Box>
      <AlertDialog
        isOpen={alertOpen}
        handleClose={handleClose}
        popUpPositiveButton={handleClose}
        title="Notification"
      >
        {ERROR_MESSAGE}
      </AlertDialog>
    </Box>
  );
};

WoundPain.propTypes = {
  attInfo: PropTypes.arrayOf(
    PropTypes.shape({
      attCode: PropTypes.string,
      attOrder: PropTypes.number,
      pageCode: PropTypes.string,
      contentID: PropTypes.string,
    })
  ).isRequired,
};

export default WoundPain;
