import { saveAssessment } from '../api/common';

function createObj(name, value) {
  return {
    key: name,
    value,
  };
}
async function SaveAssessmentDetail(Obj) {
  const tesObj = { assessmentObj: Obj };
  return saveAssessment(JSON.stringify(tesObj));
}

export { createObj, SaveAssessmentDetail };
