import { React, useContext, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { WOUNDGOAL_PAGECODE } from '../utility/staticText';

// Component imports
import SectionBreadcrumbs from '../components/sectionBreadcrumbs';

import Goal from './Goal';
import { getActivePageConfig, getPageConfigAttributes } from '../api/common';
import { ApplicationContext } from '../store/ApplicationState';
import { fetchSiteCoreContents } from '../utility/utils';
import useStyles from './WoundGoal.style';
import useFullPageLoader from '../hooks/useFullPageLoader';

const WoundGoal = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [headerTitleLabel, setheaderTitleLabel] = useState('');
  const [introductionTextLabel, setIntroductionTextLabel] = useState('');
  const {
    state: { goalPageData, disclaimerLegalText },
    dispatch,
  } = useContext(ApplicationContext);
  function CreateComponentFromName(Name, props) {
    const Components = {
      Goal,
    };
    const Component = Components[Name];
    return <Component attInfo={props} />;
  }
  async function getPageConfiguration() {
    showLoader();
    const responses = await getActivePageConfig();
    return responses;
  }

  async function getPageConfigurationAttribute(reqBody) {
    const responses = await getPageConfigAttributes(reqBody);
    hideLoader();
    return responses;
  }

  function filterAttributeByPagiID(pageID, attributeData) {
    const att = attributeData.filter((param) => param.pageId === pageID);
    return att;
  }

  useEffect(() => {
    const allPages = [];
    function createData(pageConfig, pageConfigAtt) {
      pageConfig.forEach((pageConfigRec) => {
        const params = filterAttributeByPagiID(
          pageConfigRec.pageId,
          pageConfigAtt
        );
        if (params.length !== 0) {
          const props = [];
          params.forEach((param) => {
            const propsInfo = {
              attName: params && param.attributeTypeName,
              attOrder: params && param.attributeOrder,
              attCode: param && param.attributeTypeCode,
              pageCode: pageConfigRec && pageConfigRec.pageCode,
              contentID: pageConfigRec && pageConfigRec.contentID,
            };
            props.push(propsInfo);
          });
          const obj = {
            showLeftNav: false,
            showInShell: true,
            pageId: pageConfigRec.pageId,
            pageTitle: pageConfigRec.pageTitle,
            pageOrder: pageConfigRec.pageOrder,
            isActive: pageConfigRec.isActive,
            componentName: pageConfigRec.componentName,
            contentID: pageConfigRec.contentID,
            status: '',
            isActiveStep: false,
            attributeTypeCode: params.attributeTypeCode,
            attributeOrder: params.attributeOrder,
            link: CreateComponentFromName(pageConfigRec.componentName, props),
            showStar: false,
            enabled: false,
          };
          allPages.push(obj);
        }
      });
    }
    const allpageID = [];
    let pageConfigData = [];
    let pageConfigAttData = [];
    getPageConfiguration().then((result) => {
      pageConfigData = result.item.pageConfigListObj;
      const characteristicsPageData = pageConfigData.filter(
        (x) => x.pageCode === WOUNDGOAL_PAGECODE
      );
      characteristicsPageData.forEach((element) => {
        allpageID.push(element.pageId);
      });
      getPageConfigurationAttribute(allpageID).then((data) => {
        pageConfigAttData = data.item.pageConfigAttributeListObj;
        createData(characteristicsPageData, pageConfigAttData);
        fetchSiteCoreContents(allPages[0].contentID).then((WTGContents) => {
          const {
            headerTitle,
            introductionText,
          } = WTGContents.item.contentDetailsLists[0].contentItems[0].woundCharacteristics;
          setheaderTitleLabel(headerTitle);
          setIntroductionTextLabel(introductionText);
        });
        if (goalPageData.length === 0 || goalPageData === 'undefined') {
          dispatch({ goalPageData: allPages });
        }
      });
    });
  }, []);

  return (
    <Box style={useStyles.mainBox}>
      {loader}
      <div className="recommRoot mobRecommRoot">
        <div className="breadCrumbStyle mobMargin">
          <SectionBreadcrumbs />
        </div>
        <div className="goalHeader mobGoalHeader mobMargin">
          {parse(headerTitleLabel)}
        </div>
        <div className="goalIntro mobGoalIntro mobMargin">
          {parse(introductionTextLabel)}
        </div>
        <div className="goalCard mobGoalCard mobPadding">
          {goalPageData && goalPageData.length > 0 && goalPageData[0].link}
        </div>

        <div className="goalLegalText mobPadding">
          <Typography style={useStyles.legalFont}>
            {parse(disclaimerLegalText)}
          </Typography>
        </div>
      </div>
    </Box>
  );
};
WoundGoal.propTypes = {
  push: PropTypes.func.isRequired,
};
export default WoundGoal;
