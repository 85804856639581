async function getActivePageConfig() {
  const url = `${process.env.REACT_APP_API_HOST}api/woundAlgorithm/getActivePageConfiguration`;
  try {
    const response = await fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        ClientID: process.env.REACT_APP_CLIENTID,
      },
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return new Error('Something went wrong!');
  }
}

async function getPageConfigAttributes(reqBody) {
  const url = `${process.env.REACT_APP_API_HOST}api/woundAlgorithm/getPageConfigAttributes`;
  try {
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        ClientID: process.env.REACT_APP_CLIENTID,
      },
      body: JSON.stringify({ pageId: reqBody }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return new Error('Error while fetching page config!');
  }
}

async function getWoundParameters(reqBody) {
  const url = `${process.env.REACT_APP_API_HOST}api/woundAlgorithm/getWoundParameters`;
  try {
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        ClientID: process.env.REACT_APP_CLIENTID,
      },
      body: reqBody,
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return new Error('Error while fetching Wound Parameter for Attributes!');
  }
}

async function getWoundRules(reqBody) {
  const url = `${process.env.REACT_APP_API_HOST}api/woundAlgorithm/getWoundRule`;
  try {
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        ClientID: process.env.REACT_APP_CLIENTID,
      },
      body: reqBody,
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return new Error('Error while fetching page config!');
  }
}

async function getRecommendations(reqBody) {
  const url = `${process.env.REACT_APP_API_HOST}api/woundAlgorithm/getTreatmentRecommendations`;
  try {
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        ClientID: process.env.REACT_APP_CLIENTID,
      },
      body: reqBody,
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return new Error('Error while getting wound size calculation!');
  }
}

async function getMinimumSectionValidation() {
  const url = `${process.env.REACT_APP_API_HOST}api/woundAlgorithm/getMinimumSectionsAttributes`;
  try {
    const response = await fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        ClientID: process.env.REACT_APP_CLIENTID,
      },
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return new Error(
      'Error while getting minimum sections and attributes for validation!'
    );
  }
}

async function getRelatedProductParameters(body) {
  const url = `${process.env.REACT_APP_API_HOST}api/woundAlgorithm/getRelatedProductParameters`;
  try {
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        ClientID: process.env.REACT_APP_CLIENTID,
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return new Error('Something went wrong!');
  }
}
async function saveAssessment(reqBody) {
  const url = `${process.env.REACT_APP_API_HOST}api/saveAssessment/saveWTGAssessment`;
  try {
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        ClientID: process.env.REACT_APP_CLIENTID,
      },
      body: reqBody,
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return new Error('Error while saving wound Assessment');
  }
}

async function saveFinalRecommendtation(reqBody) {
  const url = `${process.env.REACT_APP_API_HOST}api/saveAssessment/saveFinalRecommendtations`;
  try {
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        ClientID: process.env.REACT_APP_CLIENTID,
      },
      body: reqBody,
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return new Error('Error while saving wound Assessment');
  }
}

async function getWoundDepth(reqBody) {
  const url = `${process.env.REACT_APP_API_HOST}api/woundAlgorithm/getWoundDepth`;
  try {
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        ClientID: process.env.REACT_APP_CLIENTID,
      },
      body: JSON.stringify({ woundDepthReq: reqBody }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return new Error('Error while getting wound depth calculation!');
  }
}
export {
  getActivePageConfig,
  getPageConfigAttributes,
  getWoundParameters,
  getWoundRules,
  getRecommendations,
  getMinimumSectionValidation,
  getRelatedProductParameters,
  saveAssessment,
  saveFinalRecommendtation,
  getWoundDepth,
};
