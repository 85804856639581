import wndGoalInitialState from '../initialState/wndGoalInitialState';

const wndGoalReducer = (state, action) => {
  switch (action.type) {
    case 'RESET_Goal_STATE':
      return {
        ...wndGoalInitialState,
      };
    default: {
      return {
        ...state,
        ...action,
      };
    }
  }
};

export default wndGoalReducer;
