import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Material UI imports
import { Box } from '@material-ui/core';
// Component imports
import QuestionHeader from '../../components/questionHeader';
import CustomTextField from '../../components/customTextField';
// Utility imports
import { fetchSiteCoreContents } from '../../utility/utils';

const InfectionOther = ({
  contentID,
  attCode,
  handleInfectionOtherChange,
  infectionOtherText,
  movePlaceholder,
}) => {
  const [questionTextValue, setQuestionTextValue] = useState();
  const [questionImageURLValue, setQuestionImageURLValue] = useState();
  const [questionResponseTextValue, setQuestionResponseTextValue] = useState();

  // site core content
  fetchSiteCoreContents(contentID).then((typeContent) => {
    const {
      question2Text,
      question2Image,
      question2ResponseText,
    } = typeContent.item.contentDetailsLists[0].contentItems[0].woundCharacteristics;

    setQuestionResponseTextValue(question2ResponseText.replace(/<[^>]+>/g, ''));
    setQuestionImageURLValue(question2Image);
    setQuestionTextValue(question2Text.replace(/<[^>]+>/g, ''));
  });
  useEffect(() => {}, [attCode]);

  return (
    <Box>
      <QuestionHeader
        questionTextValue={questionTextValue}
        questionImageURLValue={questionImageURLValue}
        showInfo={false}
      />

      <CustomTextField
        inputVariant="outlined"
        inputType="text"
        handleChange={handleInfectionOtherChange}
        inputValue={infectionOtherText}
        placeHolderText={questionResponseTextValue}
        inputStyle={{ width: '100%', maxWidth: '573px' }}
        movePlaceholder={movePlaceholder}
      />
    </Box>
  );
};

InfectionOther.propTypes = {
  attCode: PropTypes.string.isRequired,
  contentID: PropTypes.string.isRequired,
  handleInfectionOtherChange: PropTypes.func.isRequired,
  infectionOtherText: PropTypes.string.isRequired,
  movePlaceholder: PropTypes.bool.isRequired,
};

export default InfectionOther;
