import React from 'react';
import {
  makeStyles,
  Box,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(() => ({
  root: {
    color: '#76767A',
    fontSize: '16px',
    fontWeight: '400',
    '@media(max-width: 1050px)': {
      fontSize: '14px',
      lineHeight: '18px',
      color: '#76767A',
    },
  },
  shrink: {
    marginTop: '-15px',
    left: '-13px',
    transform: 'translate(14px, -6px) scale(1) !important',
  },
  selectBackground: {
    background: 'white',
  },
}));

const CustomExpandMoreICon = withStyles(() => ({
  root: {
    fontSize: '1.9rem',
    top: '13px',
    right: '0px',
    width: '28px',
    height: '28px',
    marginRight: '8px',
  },
}))(ExpandMoreIcon);

export default function CustomLargeDropDown({
  dropDownStyle,
  boxStyle,
  handleChange,
  selectData,
  menuData,
}) {
  const classes = useStyles();
  const MenuProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        maxHeight: '316px',
        zIndex: '0',
        border: '1px solid #D0D0D3',
        boxShadow:
          '0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.5)',
        borderRadius: '2px',
      },
    },
  };
  return (
    <Box style={boxStyle}>
      <FormControl variant="outlined" className={dropDownStyle}>
        <InputLabel
          classes={{
            root: classes.root,
            shrink: classes.shrink,
          }}
          htmlFor="drp-native-simple"
        >
          {selectData.attributeName}
        </InputLabel>
        <Select
          className={classes.selectBackground}
          labelId="demo-simple-select-filled-label"
          id="drp-native-simple"
          value={selectData.selectedValue}
          name={selectData.attributeCode}
          onChange={handleChange}
          inputProps={{
            name: selectData.attributeName,
            id: 'drp-native-simple',
          }}
          IconComponent={CustomExpandMoreICon}
          MenuProps={MenuProps}
        >
          {menuData.map((ele) => (
            <MenuItem key={`${ele.parameterCode}`} value={ele.parameterCode}>
              {ele.parameterDesc}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
CustomLargeDropDown.defaultProps = {
  dropDownStyle: {
    alignSelf: 'none',
    width: 'auto',
    marginLeft: '0px',
    background: '#FFFFFF',
    border: '1px solid #B4B4B8',
    'box-sizing': 'border-box',
    'box-shadow': 'inset 0px 1px 2px rgba(0, 0, 0, 0.1)',
    'border-radius': '2px',
  },
  boxStyle: {
    width: 'auto',
  },
};
CustomLargeDropDown.propTypes = {
  selectData: PropTypes.string.isRequired,
  menuData: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  dropDownStyle: PropTypes.objectOf(PropTypes.string.isRequired),
  boxStyle: PropTypes.objectOf(PropTypes.string.isRequired),
};
