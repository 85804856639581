// React imports
import React from 'react';
import {
  Button,
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  title: {},
  close: {
    backgroundColor: '#0049BD',
    transition: 'none',
    color: '#fff',
    margin: '0px 32px 30px 0px',
    padding: '14px 39px 14px 39px',
    fontSize: '16px',
    height: '48px',
  },
  closeButton: {
    '&.MuiIconButton-edgeEnd': {
      float: 'right',
    },
  },
  paper: { width: '640px', minHeight: '208px', maxHeight: 'auto' },
  paperSmall: { width: '480px', minHeight: '176px', maxHeight: 'auto' },
  clbutton: {
    transition: 'none',
    '&:hover': {
      color: 'blue',
    },
  },
  closeSmall: {
    backgroundColor: '#0049BD',
    transition: 'none',
    color: '#fff',
    margin: '0px 32px 30px 0px',
    padding: '14px 18px 14px 18px',
    fontSize: '16px',
    height: '48px',
  },
});

const AlertDialog = ({
  isOpen,
  handleClose,
  popUpPositiveButton,
  title,
  children,
  fullscreen,
  buttonName,
  smallDialog,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        fullScreen={fullscreen}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
        classes={{ paper: smallDialog ? classes.paperSmall : classes.paper }}
      >
        <DialogTitle id="alert-dialog-title">
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Typography
                style={{
                  color: 'black',
                  fontWeight: 'bold',
                  fontSize: '24px',
                }}
              >
                {title}
              </Typography>
            </Box>
            <Box>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                edge="end"
                className={classes.clbutton}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography
              style={{ color: 'black', fontSize: '16px', lineHeight: '20px' }}
            >
              {children}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="buttonStyle"
            classes={{
              root: smallDialog ? classes.closeSmall : classes.close,
            }}
            onClick={popUpPositiveButton}
            color="primary"
            size="large"
          >
            {buttonName}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertDialog;

AlertDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  popUpPositiveButton: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  fullscreen: PropTypes.bool,
  buttonName: PropTypes.string,
  smallDialog: PropTypes.string,
};
AlertDialog.defaultProps = {
  fullscreen: false,
  buttonName: 'Close',
  smallDialog: false,
};
