export default {
  woundAppearance: {
    parameterList: [],
    EPH: 'EPH0',
    SLO: 'SLO0',
    ESC: 'ESC0',
    HYG: 'HYG0',
    GRA: 'GRN0',
    ESCDR: 'ESDRN',
    totalAppearance: 0,
    escharList: [],
    escharGreaterZero: false,
    isAppearanceValid: false,
    escharGreater75: false,
  },
};
