import wndExudateInitialState from '../initialState/wndExudateInitialState';

const wndExudateReducer = (state, action) => {
  switch (action.type) {
    case 'RESET_EXUDATE_STATE':
      return {
        ...wndExudateInitialState,
      };
    default: {
      return {
        ...state,
        ...action,
      };
    }
  }
};

export default wndExudateReducer;
