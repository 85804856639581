/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import parse from 'html-react-parser';

export const CheckBox = ({
  handleCheckBoxChange,
  groupValue,
  showDescriptor,
  rowAlign,
  containerBoxStyle,
  groupStyle,
  labelStyle,
}) => (
  <Box style={containerBoxStyle} display="flex">
    <FormGroup
      aria-label="WTG"
      name="WTG"
      rowAlign={rowAlign}
      style={groupStyle}
    >
      {groupValue &&
        groupValue.map((row, idx) => (
          <FormControlLabel
            value={row.parameterCode}
            control={
              <input
                type="checkbox"
                onClick={() => handleCheckBoxChange(idx)}
                className="chkbox mobChkbox"
                name={row.parameterCode}
                value={row.parameterCode}
                checked={row.selected}
                disabled={row.disabled}
              />
            }
            label={
              <div
                style={{
                  display: 'flex',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '16px',
                  lineHeight: '20px',
                  color: row.selected ? '#323234' : '#656569',
                  padding: '3px 0 24px 0',
                }}
              >
                <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                  {row.parameterDesc}
                </div>
                <div
                  style={{
                    display: 'flex',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: row.selected ? '#323234' : '#656569',
                  }}
                >
                  <div style={{ display: 'flex', whiteSpace: 'pre' }}>
                    {showDescriptor && row.parameterDescriptor !== '' && ` - `}
                  </div>
                  <div>
                    {showDescriptor &&
                      row.parameterDescriptor !== '' &&
                      parse(row.parameterDescriptor || '')}
                  </div>
                </div>
              </div>
            }
            style={labelStyle}
          />
        ))}
    </FormGroup>
  </Box>
);

CheckBox.defaultProps = {
  showDescriptor: true,
  rowAlign: false,
  containerBoxStyle: {
    width: '100%',
  },
  groupStyle: {
    width: '100%',
  },
  labelStyle: {
    paddingTop: '12px',
  },
};
CheckBox.propTypes = {
  handleCheckBoxChange: PropTypes.func.isRequired,
  groupValue: PropTypes.arrayOf.isRequired,
  showDescriptor: PropTypes.bool,
  rowAlign: PropTypes.bool,
  containerBoxStyle: PropTypes.objectOf(PropTypes.string.isRequired),
  groupStyle: PropTypes.objectOf(PropTypes.string.isRequired),
  labelStyle: PropTypes.objectOf(PropTypes.string.isRequired),
};
export default CheckBox;
