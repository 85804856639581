import wndInfectionInitalState from '../initialState/wndInfectionInitalState';

const wndInfectionReducer = (state, action) => {
  switch (action.type) {
    case 'RESET_INFECTION_STATE':
      return {
        ...wndInfectionInitalState,
      };
    default: {
      return {
        ...state,
        ...action,
      };
    }
  }
};

export default wndInfectionReducer;
