// React imports
import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
// Material UI imports
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// component imports
import TreamentSection from './TreatmentSection';
import { ApplicationContext } from '../../store/ApplicationState';
import Slider from '../slider';

async function getTreatmentSections() {
  const url = `${process.env.REACT_APP_API_HOST}api/woundAlgorithm/getTreatmentSections?appKey=WTG`;
  try {
    const response = await fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        ClientID: process.env.REACT_APP_CLIENTID,
      },
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return new Error('Error while fetching Treatment Sections!');
  }
}

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '0',
    width: '100%',
  },
}));

const TreatmentOptions = ({
  isFinalRecommendation,
  topSwitch,
  bottomSwitch,
  pathway,
}) => {
  const classes = useStyles();
  const {
    wndRecommState: { treatmentList },
    state: { treatmentSections, showProducts, switchState, switchAllTherapy },
    dispatch,
  } = useContext(ApplicationContext);
  const handleChange = () => {
    if (pathway === 'GOAL') {
      dispatch({ switchState: !switchState });
    }
    if (pathway === 'CHARACTERISTICS') {
      dispatch({ showProducts: !showProducts });
    }
    if (pathway === 'AllTherapy') {
      dispatch({ switchAllTherapy: !switchAllTherapy });
    }
  };

  useEffect(() => {
    getTreatmentSections().then((sect) => {
      dispatch({ treatmentSections: sect.item.treatmentSections });
    });
  }, [JSON.stringify(treatmentList)]);
  function getDisplaySilderTop(checkedValue) {
    return (
      <div className="bottomSlider mobBottomSlider">
        <Slider
          handleChange={handleChange}
          checked={checkedValue}
          title={topSwitch}
        />
      </div>
    );
  }
  function getDisplaySilderBottom(checkedValue) {
    return (
      <div className="bottomSlider mobBottomSlider">
        <Slider
          handleChange={handleChange}
          checked={checkedValue}
          title={bottomSwitch}
        />
      </div>
    );
  }
  return (
    <Box className={classes.container}>
      <div className="page-break" />
      {pathway === 'GOAL' && getDisplaySilderTop(switchState)}
      {pathway === 'CHARACTERISTICS' && getDisplaySilderTop(showProducts)}
      {pathway === 'AllTherapy' && getDisplaySilderTop(switchAllTherapy)}
      {treatmentSections &&
        treatmentSections.map((section, idx) => (
          <TreamentSection
            AllTherapyPath
            section={section}
            isFinalRecommSec={isFinalRecommendation}
            index={idx}
            pathway={pathway}
            treatmentSections={treatmentSections}
          />
        ))}
      {pathway === 'GOAL' && getDisplaySilderBottom(switchState)}
      {pathway === 'CHARACTERISTICS' && getDisplaySilderBottom(showProducts)}
      {pathway === 'AllTherapy' && getDisplaySilderBottom(switchAllTherapy)}
    </Box>
  );
};
TreatmentOptions.propTypes = {
  isFinalRecommendation: PropTypes.bool.isRequired,
  topSwitch: PropTypes.string.isRequired,
  bottomSwitch: PropTypes.string.isRequired,
  pathway: PropTypes.string.isRequired,
};
export default TreatmentOptions;
