import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[2],
    fontSize: '16px',
    pointerEvents: 'auto !important',
    border: '1px solid #c7bdbd',
  },
  arrow: {
    '&::before': {
      boxShadow: theme.shadows[2],
      backgroundColor: theme.palette.common.white,
    },
  },
  tooltipPlacementBottom: {
    marginTop: '9.25px !important',
  },
}))(Tooltip);

export default LightTooltip;
