// get Sitecore content
async function getCmsContent(contentReq) {
  const url = `${process.env.REACT_APP_API_HOST}api/cms/getWoundThearpyGuideContent`;
  try {
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        ClientID: process.env.REACT_APP_CLIENTID,
      },
      body: JSON.stringify(contentReq),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return new Error('Something went wrong!');
  }
}
export default getCmsContent;
