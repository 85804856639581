import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
// Material UI imports
import { Box, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
// Component imports
import QuestionHeader from '../../components/questionHeader';
import QuestionDescription from '../../components/questionDescription';
import CheckBox from '../../components/checkBox';
import { ApplicationContext } from '../../store/ApplicationState';
// Utility imports
import { MOBILE_BREAKPOINT } from '../../utility/staticText';
import {
  fetchSiteCoreContents,
  setTooltipText,
  fetchWoundParametersContent,
} from '../../utility/utils';
import useFullPageLoader from '../../hooks/useFullPageLoader';

const Infection = ({ contentID, attCode, handleCheckBoxChange }) => {
  const theme = useTheme();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const matchesMobileWidth = useMediaQuery(
    theme.breakpoints.down(MOBILE_BREAKPOINT)
  );
  const [open, setOpen] = useState(false);
  const [questionTextValue, setQuestionTextValue] = useState();
  const [questionImageURLValue, setQuestionImageURLValue] = useState();
  const [
    questionDescriptionTextValue,
    setQuestionDescriptionTextValue,
  ] = useState();
  const [infoTitleValue, setInfoTitleValue] = useState();
  const [infoDescriptionValue, setInfoDescriptionValue] = useState();
  const [infoURLValue, setInfoURLValue] = useState();
  const [infoURLTitleValue, setInfoURLTitleValue] = useState();

  const {
    wndInfectionState: { woundInfectionList },
    wndInfectionDispatch,
  } = useContext(ApplicationContext);

  const handleInfoClick = () => {
    setOpen(!open);
  };

  const tooltipTitle = setTooltipText(
    infoTitleValue,
    infoDescriptionValue,
    infoURLValue,
    infoURLTitleValue
  );
  // site core content
  fetchSiteCoreContents(contentID).then((typeContent) => {
    const {
      questionText,
      questionImage,
      questionDescriptionText,
    } = typeContent.item.contentDetailsLists[0].contentItems[0].woundCharacteristics;
    const {
      infoTitle,
      infoDescription,
      infoURL,
      infoURLTitle,
    } = typeContent.item.contentDetailsLists[0].contentItems[0].infoDetails;

    setQuestionDescriptionTextValue(
      questionDescriptionText.replace(/<[^>]+>/g, '')
    );
    setQuestionImageURLValue(questionImage);
    setQuestionTextValue(questionText.replace(/<[^>]+>/g, ''));
    setInfoTitleValue(infoTitle.replace(/<[^>]+>/g, ''));
    setInfoDescriptionValue(infoDescription);
    setInfoURLValue(infoURL);
    setInfoURLTitleValue(infoURLTitle);
  });
  useEffect(() => {
    showLoader();
    fetchWoundParametersContent(attCode).then((response) => {
      const infectarr = response.item.parameters[0].parameterGroup
        .sort((a, b) => (a.sortPriority > b.sortPriority ? 1 : -1))
        .map((x) => ({
          ...x,
          selected: false,
        }));
      if (woundInfectionList === undefined)
        wndInfectionDispatch({
          woundInfectionList: infectarr,
        });
      hideLoader();
    });
  }, []);

  return (
    <Box>
      {loader}
      <QuestionHeader
        questionTextValue={questionTextValue}
        questionImageURLValue={questionImageURLValue}
        handleInfoClick={handleInfoClick}
        tooltipTitle={tooltipTitle}
        open={open}
        setRef={() => {
          setOpen(false);
        }}
      />
      <QuestionDescription
        questionDescriptionTextValue={questionDescriptionTextValue}
      />
      <CheckBox
        handleCheckBoxChange={handleCheckBoxChange}
        groupValue={woundInfectionList && woundInfectionList}
        showDescriptor={false}
        labelStyle={{
          width: matchesMobileWidth ? '100%' : '49%',
          pointerEvents: 'none',
        }}
        groupStyle={{ flexDirection: 'row', minWidth: '100%' }}
        containerBoxStyle={{
          margin: matchesMobileWidth ? '25px 0px' : '25px',
        }}
      />
    </Box>
  );
};

Infection.propTypes = {
  attCode: PropTypes.string.isRequired,
  contentID: PropTypes.string.isRequired,
  handleCheckBoxChange: PropTypes.func.isRequired,
};

export default Infection;
