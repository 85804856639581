const useStyles = {
  stepStyle: {
    marginTop: '50px',
    display: 'flex',
    'flex-direction': 'column',
    'align-items': 'center',
    padding: '72px 72px 120px',
    'border-radius': '4px',
    minHeight: '626px',
    maxWidth: '1040px',
    backgroundColor: '#F8F8FA',
    boxShadow:
      '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12)',
  },
  legalText: {
    maxWidth: '1040px',
    minHeight: '92px',
    marginTop: '69px',
  },
  legalFont: {
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
  },
  mainBox: { minHeight: 'calc(100vh - 145px)' },
  breadCrumbStyle: {
    paddingTop: '15px',
  },
};

export default useStyles;
