import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
// Material UI imports
import { Box, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
// Component imports
import QuestionHeader from '../../components/questionHeader';
import QuestionDescription from '../../components/questionDescription';
import CheckBox from '../../components/checkBox';
import AlertDialog from '../../components/alertBox';
import CustomButton from '../../components/customButton';
import WoundPeriOther from './WoundPeriOther';
// Utility imports
import {
  fetchSiteCoreContents,
  setTooltipText,
  fetchWoundParametersContent,
  checkValidation,
  createBody,
  fetchWoundRules,
  checkPopup,
  captureMiniValidationData,
} from '../../utility/utils';
import {
  STATUS_CURRENT,
  STATUS_ENTERED,
  STATUS_NOT_ENTERED,
  STATUS_VISITED,
  WND_CAP_RULE,
  WND_UNK_RULE,
  MOBILE_BREAKPOINT,
} from '../../utility/staticText';
// Store imports
import { ApplicationContext } from '../../store/ApplicationState';
import useFullPageLoader from '../../hooks/useFullPageLoader';

function getPageStatus(
  periwoundCodes,
  periwoundOther,
  showPeriwoundOtherTextbox,
  isActiveStep
) {
  let status = '';
  if (
    periwoundCodes &&
    periwoundCodes.some((x) => x.selected) &&
    !showPeriwoundOtherTextbox
  ) {
    status = STATUS_ENTERED;
  } else if (showPeriwoundOtherTextbox && periwoundOther === '') {
    status = STATUS_VISITED;
  } else if (showPeriwoundOtherTextbox && periwoundOther !== '') {
    status = STATUS_ENTERED;
  } else {
    status = isActiveStep ? STATUS_CURRENT : STATUS_NOT_ENTERED;
  }
  return status;
}

const WoundPeriWound = ({ attInfo }) => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const theme = useTheme();
  const matchesMobileWidth = useMediaQuery(
    theme.breakpoints.down(MOBILE_BREAKPOINT)
  );
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [questionTextValue, setQuestionTextValue] = useState();
  const [questionImageURLValue, setQuestionImageURLValue] = useState();
  const [
    questionDescriptionTextValue,
    setQuestionDescriptionTextValue,
  ] = useState();
  const [infoTitleValue, setInfoTitleValue] = useState();
  const [infoDescriptionValue, setInfoDescriptionValue] = useState();
  const [infoURLValue, setInfoURLValue] = useState();
  const [infoURLTitleValue, setInfoURLTitleValue] = useState();
  const [enterButtonTextValue, setEnterButtonTextValue] = useState();
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState('');
  const [popUpTitle, setPopUpTitle] = useState('');
  const {
    state: {
      activeStep,
      pageData,
      errorMessage,
      totalStepper,
      minimumValidationData,
    },
    dispatch,
    wndPeriwoundState: {
      periwoundList,
      periwoundOtherText,
      showPeriwoundOtherLabel,
      showPeriwoundOtherTextbox,
      periwoundOtherParamCode,
      periwoundUnknownParamCode,
    },
    wndPeriwoundDispatch,
  } = useContext(ApplicationContext);

  const [attribute] = attInfo.sort((a, b) => a.attOrder - b.attOrder);

  const handleInfoClick = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setAlertOpen(false);
  };

  const capturePeriwoundData = (selectedCodes) => {
    delete pageData[activeStep].data;
    const updateMinimumValidationDataStart = captureMiniValidationData(
      [attInfo[0].attCode][0],
      minimumValidationData,
      true
    );
    dispatch({ updateMinimumValidationDataStart });
    const allelement = [];
    selectedCodes.forEach((element) => {
      let isPopup = false;
      let Title = '';
      let Message = '';
      if (element.hasRules) {
        fetchWoundRules(element.parameterCode).then((response) => {
          checkPopup(response).then((popup) => {
            if (popup[0]) {
              isPopup = true;
              [Title, Message] = popup;
            }
          });
        });
      }

      allelement.push({
        attributeName: '',
        parameterCode: element.parameterCode,
        hasRule: isPopup,
        parameterdesc: element.parameterDesc,
        OtherText: '',
        displayText: element.parameterDesc,
        popUpTitle: Title,
        popUpMessage: Message,
        showPopup: false,
      });
      const data = {
        [attInfo[0].attCode]: allelement,
      };
      Object.assign(pageData[activeStep], { data });

      const updateMinimumValidationData = captureMiniValidationData(
        [attInfo[0].attCode][0],
        minimumValidationData,
        true
      );
      dispatch({ updateMinimumValidationData });
    });

    if (
      selectedCodes.filter((x) => x.parameterCode === periwoundOtherParamCode)
        .length > 0
    ) {
      // eslint-disable-next-line array-callback-return
      pageData[activeStep].data[attInfo[0].attCode].map((x) => {
        if (x.parameterCode === periwoundOtherParamCode) {
          Object.assign(x, {
            OtherText: periwoundOtherText,
            displayText: `Other: ${periwoundOtherText}`,
          });
        }
      });
    }
    if (selectedCodes.length === 0) {
      const data = {
        [attInfo[0].attCode]: [
          {
            attributeName: '',
            parameterCode: '',
            hasRule: false,
            parameterdesc: '',
            OtherText: '',
            displayText: 'Blank',
            popUpTitle: '',
            popUpMessage: '',
            showPopup: false,
          },
        ],
      };

      Object.assign(pageData[activeStep], { data });
    }
    dispatch({ pageData });
  };

  const executeRulesIfAny = async (selectedValue) => {
    const ruleBasedParameters = periwoundList.filter(
      (x) => x.parameterCode === selectedValue
    );
    const param = ruleBasedParameters[0];
    const { hasRules } = param;
    let isPopUp;
    let isWndCapRule;
    let isWndUnkRule;
    let unknownParamCode;
    if (hasRules) {
      const response = await fetchWoundRules(param.parameterCode);
      isPopUp = await checkPopup(response);
      isWndCapRule = response.item.captureRules.filter(
        (x) => x.ruleType === WND_CAP_RULE
      );
      isWndUnkRule = response.item.captureRules.filter(
        (x) => x.ruleType === WND_UNK_RULE
      );

      if (isPopUp && isPopUp[0]) {
        setPopUpOpen(true);
        setPopUpMessage(isPopUp[1]);
        setPopUpTitle(isPopUp[2]);
      }
      if (
        isWndCapRule &&
        isWndCapRule[0] &&
        isWndCapRule[0].activateWoundCapture
      ) {
        wndPeriwoundDispatch({
          showPeriwoundOtherTextbox: isWndCapRule[0].activateWoundCapture,
          periwoundOtherParamCode: selectedValue,
        });
      }
      if (
        isWndUnkRule &&
        isWndUnkRule[0] &&
        isWndUnkRule[0].disableOtherParams
      ) {
        const updatedPeriList = periwoundList.map((x) => ({
          ...x,
          selected: x.parameterCode === selectedValue,
          disabled: x.parameterCode !== selectedValue,
        }));
        unknownParamCode = selectedValue;
        wndPeriwoundDispatch({
          disableOtherParams: isWndUnkRule[0].disableOtherParams,
          periwoundUnknownParamCode: selectedValue,
          periwoundOtherParamCode: '',
          showPeriwoundOtherTextbox: false,
          periwoundOtherText: '',
          periwoundList: updatedPeriList,
        });
      }
    }
    return [isWndUnkRule, unknownParamCode];
  };

  async function handlePeriwoundChange(idx) {
    periwoundList[idx].selected = !periwoundList[idx].selected;
    let [isWndUnk, unknownParamCode] = [false, ''];
    if (periwoundList[idx].selected) {
      [isWndUnk, unknownParamCode] = await executeRulesIfAny(
        periwoundList[idx].parameterCode
      );
    }
    if (
      periwoundList[idx].selected === false &&
      periwoundList[idx].parameterCode === periwoundOtherParamCode
    ) {
      wndPeriwoundDispatch({
        showPeriwoundOtherTextbox: false,
        periwoundOtherText: '',
      });
    }
    if (
      periwoundList[idx].selected === false &&
      periwoundList[idx].parameterCode === periwoundUnknownParamCode
    ) {
      periwoundList.forEach((x, ix) => {
        periwoundList[ix].disabled = false;
      });
      wndPeriwoundDispatch({
        disableOtherParams: false,
        periwoundUnknownParamCode: '',
        periwoundList,
      });
    }
    if (isWndUnk && isWndUnk[0] && isWndUnk[0].disableOtherParams) {
      const selectedData = periwoundList.filter(
        (x) => x.parameterCode === unknownParamCode
      );
      capturePeriwoundData(selectedData);
    } else {
      const selectedData = periwoundList.filter((x) => x.selected);
      capturePeriwoundData(selectedData);
    }
  }

  function handlePeriwoundOtherChange(e) {
    const { value } = e.target;
    wndPeriwoundDispatch({
      periwoundOtherText: value,
      showPeriwoundOtherLabel: !!value,
    });
    if (pageData[activeStep].data) {
      pageData[activeStep].data[attInfo[0].attCode].forEach((x) => {
        if (x.parameterCode === periwoundOtherParamCode) {
          Object.assign(x, {
            OtherText: value,
            displayText: `Other: ${value}`,
          });
        }
      });
      dispatch({ pageData });
    }
  }

  function validateNextButton() {
    if (activeStep + 1 === totalStepper) {
      dispatch({ showNextButton: false });
    }
  }

  const handlePopUpClose = () => {
    setPopUpOpen(false);
  };

  // handle submit
  function handleSubmit() {
    const { length } = periwoundList.filter((x) => x.selected);
    const validateBody = [];
    const body1 = createBody(length, 'WDPRI');
    validateBody.push(body1);
    if (periwoundList.some((x) => x.selected) && showPeriwoundOtherTextbox) {
      const body2 = createBody(periwoundOtherText, 'WDPRI');
      validateBody.push(body2);
    }

    const isValid = checkValidation(validateBody);
    if (isValid) {
      if (pageData[activeStep + 1].enabled) {
        if (activeStep < totalStepper) {
          dispatch({ activeStep: activeStep + 1 });
          if (totalStepper !== activeStep + 1) {
            pageData[activeStep + 1].isActiveStep = true;
          }
        }
        if (activeStep === totalStepper) {
          dispatch({ lastPage: activeStep });
        }
        validateNextButton();
        dispatch({ previousPage: activeStep });
      }
    } else {
      setAlertOpen(true);
    }
  }

  const tooltipTitle = setTooltipText(
    infoTitleValue,
    infoDescriptionValue,
    infoURLValue,
    infoURLTitleValue
  );

  // site core content
  fetchSiteCoreContents(attribute.contentID).then((periContent) => {
    const {
      questionText,
      questionImage,
      questionDescriptionText,
      enterButtonText,
    } = periContent.item.contentDetailsLists[0].contentItems[0].woundCharacteristics;
    const {
      infoTitle,
      infoDescription,
      infoURL,
      infoURLTitle,
    } = periContent.item.contentDetailsLists[0].contentItems[0].infoDetails;

    setQuestionDescriptionTextValue(
      questionDescriptionText.replace(/<[^>]+>/g, '')
    );
    setQuestionImageURLValue(questionImage);
    setQuestionTextValue(questionText.replace(/<[^>]+>/g, ''));
    setInfoTitleValue(infoTitle.replace(/<[^>]+>/g, ''));
    setInfoDescriptionValue(infoDescription);
    setInfoURLValue(infoURL);
    setInfoURLTitleValue(infoURLTitle);
    setEnterButtonTextValue(enterButtonText);
  });

  useEffect(() => {
    showLoader();
    fetchWoundParametersContent(attribute.attCode).then((response) => {
      const periArr = response.item.parameters[0].parameterGroup
        .sort((a, b) => a.sortPriority - b.sortPriority)
        .map((x) => ({
          ...x,
          selected: false,
          disabled: false,
        }));
      if (periwoundList.length === 0) {
        wndPeriwoundDispatch({
          periwoundList: periArr,
        });
      }
      hideLoader();
    });
  }, []);

  useEffect(() => {
    const validateToggle = () => {
      pageData.forEach((item, idx) => {
        if (idx === activeStep) {
          pageData[idx].status = getPageStatus(
            periwoundList && periwoundList,
            periwoundOtherText,
            showPeriwoundOtherTextbox,
            pageData[idx].isActiveStep || pageData[activeStep].firstPage
          );
          dispatch({ pageData });
        }
      });
    };
    validateToggle();
  }, [activeStep, dispatch, JSON.stringify(periwoundList), periwoundOtherText]);

  return (
    <Box className="attributeContainer mobAttributeContainer">
      {loader}
      <QuestionHeader
        questionTextValue={questionTextValue}
        questionImageURLValue={questionImageURLValue}
        handleInfoClick={handleInfoClick}
        tooltipTitle={tooltipTitle}
        open={open}
        setRef={() => {
          setOpen(false);
        }}
      />
      <QuestionDescription
        questionDescriptionTextValue={questionDescriptionTextValue}
      />
      <CheckBox
        handleCheckBoxChange={handlePeriwoundChange}
        groupValue={periwoundList && periwoundList}
        showDescriptor
        labelStyle={{ width: '100%', pointerEvents: 'none' }}
        containerBoxStyle={{
          margin: matchesMobileWidth ? '25px 0px' : '25px',
        }}
      />
      {showPeriwoundOtherTextbox && (
        <WoundPeriOther
          contentID={attribute.contentID}
          handlePeriwoundOtherChange={handlePeriwoundOtherChange}
          periwoundOtherText={periwoundOtherText}
          movePlaceholder={showPeriwoundOtherLabel}
        />
      )}

      <CustomButton
        value={enterButtonTextValue}
        handleSubmit={handleSubmit}
        width="100px"
      />
      <AlertDialog
        isOpen={alertOpen}
        handleClose={handleClose}
        popUpPositiveButton={handleClose}
        title="Notification"
      >
        {errorMessage}
      </AlertDialog>
      <AlertDialog
        isOpen={popUpOpen}
        handleClose={handlePopUpClose}
        popUpPositiveButton={handlePopUpClose}
        title={popUpTitle}
        buttonName="Got it"
      >
        {popUpMessage}
      </AlertDialog>
    </Box>
  );
};

WoundPeriWound.propTypes = {
  attInfo: PropTypes.arrayOf(
    PropTypes.shape({
      attCode: PropTypes.string,
      attOrder: PropTypes.number,
      pageCode: PropTypes.string,
      contentID: PropTypes.string,
    })
  ).isRequired,
};

export default WoundPeriWound;
