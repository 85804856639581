import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

export default function CustomButton({
  value,
  handleSubmit,
  backgroundColor,
  color,
  borderColor,
  fontWeight,
  containerBoxStyle,
  width,
  height,
  transition,
  disableNextPrev,
  isSecondary,
}) {
  const useStyles = makeStyles({
    root: {
      border: disableNextPrev ? null : `1px solid ${borderColor}`,
      backgroundColor: disableNextPrev ? '#E4E4E8' : backgroundColor,
      color: disableNextPrev ? '#76767A' : color,
      borderRadius: '2px',
      boxSizing: 'border-box',
      borderColor,
      width,
      height,
      transition,
    },
  });
  const classes = useStyles();
  return (
    <Box className={`${containerBoxStyle}`}>
      <Button
        className={isSecondary ? 'secondaryButtonStyle' : 'buttonStyle'}
        classes={{
          root: classes.root,
        }}
        variant="contained"
        onClick={handleSubmit}
        disabled={disableNextPrev}
      >
        <Typography
          style={{
            fontWeight,
            fontSize: '16px',
            lineHeight: '20px',
          }}
        >
          {value}
        </Typography>
      </Button>
    </Box>
  );
}

CustomButton.defaultProps = {
  color: 'white',
  backgroundColor: '#0049BD',
  borderColor: '#0049BD',
  fontWeight: 'normal',
  width: '122px',
  height: '48px',
  transition: 'none',
  containerBoxStyle: 'enterBtnBox mobEnterBtnBox',
  isSecondary: false,
};

CustomButton.propTypes = {
  value: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  fontWeight: PropTypes.string,
  borderColor: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  containerBoxStyle: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  transition: PropTypes.string,
  disableNextPrev: PropTypes.bool.isRequired,
  isSecondary: PropTypes.bool,
};
