import WndTypeInitalState from '../initalState/WndTypeInitalState';

const WndTypeReducer = (state, action) => {
  switch (action.type) {
    case 'RESET_TYPE_STATE':
      return {
        ...WndTypeInitalState,
      };
    default: {
      return {
        ...state,
        ...action,
      };
    }
  }
};

export default WndTypeReducer;
