import { Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import CustomTextField from '../components/customTextField';
import {
  LASTNAME_REQUIRED,
  ZIPCODE_REQUIRED,
  ZIPCODE_REQUIRED_WITH_FIVE,
} from '../utility/staticText';
import CustomButton from '../components/customButton/CustomButton';
import deletAccountApi from '../api/deleteAccountApi';
import './mwh_DeleteAccount.css';
import 'react-datepicker/dist/react-datepicker.css';
import {
  validateName,
  validateSerialNumber,
  validateUserName,
  validateZipCode,
} from '../utils/deleteUserValidation';

const initialUserInfomation = {
  UserName: '',
  FirstName: '',
  LastName: '',
  DateOfBirth: '',
  ZipCode: '',
  SerialNumber: '',
};

const initialErrorObj = {
  UserName: false,
  FirstName: false,
  LastName: false,
  DateOfBirth: false,
  ZipCode: false,
  SerialNumber: false,
};

const DeleteAccount = () => {
  const [userInfomation, setUserInfomation] = useState(initialUserInfomation);
  const [userFormErrorObj, setUserFormErrorObj] = useState(initialErrorObj);
  const [apiResponse, setApiResponse] = useState(null);

  const messageRef = useRef(null);
  const firstNameRef = useRef(null);

  const handleDateChange = (date) => {
    const dateString = date;
    setUserInfomation((p) => ({ ...p, DateOfBirth: dateString }));
    if (dateString) {
      setUserFormErrorObj((p) => ({
        ...p,
        DateOfBirth: false,
      }));
    }
  };

  const focusedFieldId = 'firstName';
  const field = document.getElementById(focusedFieldId);
  if (field) {
    field.focus();
  }
  const handleFocus = (event) => {
    sessionStorage.setItem('focusedFieldId', event.target.id);
  };

  useEffect(() => {
    const handleRefresh = () => {
      window.scrollTo(0, 0);
    };
    window.addEventListener('beforeunload', handleRefresh);
    return () => {
      window.removeEventListener('beforeunload', handleRefresh);
    };
  }, []);

  const handleUserInformtionChange = (e, name) => {
    const { value } = e.target;
    const nameValidation = ['FirstName', 'LastName'];
    const userNameValidation = 'UserName';
    const zipCodeValidation = 'ZipCode';
    const serialNumberValidation = 'SerialNumber';
    const tempErrorObj = userFormErrorObj;
    if (nameValidation.includes(name)) {
      if (validateName(value)) {
        tempErrorObj.LastName = false;
        setUserInfomation((p) => ({ ...p, [name]: e.target.value }));
      }
    } else if (userNameValidation.includes(name)) {
      if (validateUserName(value)) {
        tempErrorObj.UserName = false;
        setUserInfomation((p) => ({ ...p, [name]: e.target.value.trim() }));
      }
    } else if (zipCodeValidation.includes(name)) {
      if (validateZipCode(value)) {
        tempErrorObj.ZipCode = false;
        setUserInfomation((p) => ({ ...p, [name]: e.target.value }));
      }
    } else if (serialNumberValidation.includes(name)) {
      if (validateSerialNumber(value)) {
        tempErrorObj.SerialNumber = false;
        setUserInfomation((p) => ({ ...p, [name]: e.target.value.trim() }));
      }
    }
    setUserFormErrorObj(tempErrorObj);
  };

  const handleDeleteUser = async () => {
    if (
      userInfomation.LastName === '' ||
      userInfomation.ZipCode === '' ||
      userInfomation.ZipCode.length < 5 ||
      userInfomation.DateOfBirth === null ||
      userInfomation.DateOfBirth === '' ||
      userInfomation.DateOfBirth > new Date()
    ) {
      if (userInfomation.LastName === '') {
        setUserFormErrorObj((p) => ({ ...p, LastName: true }));
      }
      if (userInfomation.ZipCode === '' || userInfomation.ZipCode.length < 5) {
        setUserFormErrorObj((p) => ({ ...p, ZipCode: true }));
      }
      if (
        userInfomation.DateOfBirth === null ||
        userInfomation.DateOfBirth === '' ||
        userInfomation.DateOfBirth > new Date()
      ) {
        setUserFormErrorObj((p) => ({
          ...p,
          DateOfBirth: true,
        }));
      }
    } else {
      const reqBody = {
        ...userInfomation,
        FirstName: userInfomation.FirstName.trim(),
        LastName: userInfomation.LastName.trim(),
      };
      const response = await deletAccountApi(reqBody);
      if (response && response.succeeded) {
        setApiResponse(true);
        setUserInfomation(initialUserInfomation);
        messageRef.current.scrollIntoView({ behavior: 'smooth' });
        setTimeout(() => {
          document.getElementById('firstNameFocus').focus();
          window.scrollTo({ top: 0, behavior: 'smooth' });
          setApiResponse(null);
        }, 2000);
      } else {
        setApiResponse(false);
        messageRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <div className="recommRoot mobRecommRoot container">
      <div>
        <Typography variant="h4" className="heading">
          Remove my account
        </Typography>
        <Grid item xs={12} md={10}>
          <CustomTextField
            ref={firstNameRef}
            id="firstNameFocus"
            autoFocus={handleFocus}
            inputVariant="outlined"
            inputType="text"
            showErrorLabel={userFormErrorObj.FirstName}
            inputLabel="First Name"
            inputValue={userInfomation.FirstName}
            placeHolderText="First Name"
            handleChange={(e) => handleUserInformtionChange(e, 'FirstName')}
            inputStyle={{
              width: '100%',
              maxWidth: '573px',
            }}
          />
        </Grid>
        <Grid item xs={12} md={10}>
          <CustomTextField
            inputVariant="outlined"
            inputType="text"
            showErrorLabel={userFormErrorObj.LastName}
            inputLabel="Last Name"
            errorLabel={LASTNAME_REQUIRED}
            errorLabelStyle={{ fontSize: '13px', paddingTop: '5px' }}
            isRequired
            inputValue={userInfomation.LastName}
            placeHolderText="Last Name"
            handleChange={(e) => handleUserInformtionChange(e, 'LastName')}
            inputStyle={{
              width: '100%',
              maxWidth: '573px',
            }}
          />
        </Grid>
        <Grid item xs={12} md={10} className="datefBirthMainGrid">
          <div style={{ padding: '15px 0' }}>
            <Typography
              className={
                userFormErrorObj.DateOfBirth
                  ? 'error-message-label'
                  : 'label-style'
              }
            >
              Date of Birth
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <Grid xs={12} md={11} className="datePickerGrid">
              <DatePicker
                className={userFormErrorObj.DateOfBirth ? 'error' : ''}
                selected={userInfomation.DateOfBirth}
                inputLabel="DateOfBirth"
                onChange={(e) => handleDateChange(e)}
                dateFormat="MM-dd-yyyy"
                placeholderText="MM-DD-YYYY"
                toggleCalendarOnIconClick
              />
            </Grid>
            {userFormErrorObj.DateOfBirth &&
              (userInfomation.DateOfBirth === null ||
                userInfomation.DateOfBirth === '') && (
                <Typography
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '13px',
                    lineHeight: '18px',
                    color: '#9c0000',
                    paddingTop: '5px',
                  }}
                >
                  Date of Birth is required.
                </Typography>
              )}
            {userFormErrorObj.DateOfBirth &&
              userInfomation.DateOfBirth > new Date() && (
                <Typography
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '13px',
                    lineHeight: '18px',
                    color: '#9c0000',
                    paddingTop: '5px',
                  }}
                >
                  Date of Birth should not be future date .
                </Typography>
              )}
          </div>
        </Grid>
        <Grid item xs={12} md={10}>
          <CustomTextField
            inputVariant="outlined"
            inputType="text"
            showErrorLabel={userFormErrorObj.ZipCode}
            inputLabel="Zip Code"
            errorLabel={
              userInfomation.ZipCode.length < 5 &&
              userInfomation.ZipCode.length >= 1
                ? ZIPCODE_REQUIRED_WITH_FIVE
                : ZIPCODE_REQUIRED
            }
            isRequired
            errorLabelStyle={{ fontSize: '13px', paddingTop: '5px' }}
            inputValue={userInfomation.ZipCode}
            placeHolderText="Zip Code"
            handleChange={(e) => handleUserInformtionChange(e, 'ZipCode')}
            inputStyle={{
              width: '100%',
              maxWidth: '573px',
            }}
          />
        </Grid>
        <Grid item xs={12} md={10}>
          <CustomTextField
            inputVariant="outlined"
            inputType="text"
            inputLabel=" User Name"
            inputValue={userInfomation.UserName}
            placeHolderText="User Name"
            handleChange={(e) => handleUserInformtionChange(e, 'UserName')}
            inputStyle={{
              width: '100%',
              maxWidth: '573px',
            }}
          />
        </Grid>
        <Grid item xs={12} md={10}>
          <CustomTextField
            inputVariant="outlined"
            inputType="text"
            inputLabel=" Serial Number"
            inputValue={userInfomation.SerialNumber}
            placeHolderText="Serial Number"
            handleChange={(e) => handleUserInformtionChange(e, 'SerialNumber')}
            inputStyle={{
              width: '100%',
              maxWidth: '573px',
            }}
          />
        </Grid>
        <Grid xs={12} md={5} className="button-Style">
          <div className="submit-button">
            <CustomButton
              value="Submit"
              handleSubmit={handleDeleteUser}
              width="40%"
            />
            {apiResponse === false && (
              <Typography className="failuremessage" ref={messageRef}>
                Request failed. Please try again.
              </Typography>
            )}
            {apiResponse === true && (
              <Typography className="successmessage" ref={messageRef}>
                Successfully Submitted
              </Typography>
            )}
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default DeleteAccount;
