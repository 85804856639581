/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
// Material UI imports
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { NavLink, Redirect } from 'react-router-dom';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/CheckSharp';
import StarIcon from '@material-ui/icons/Star';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Box, ListItem, useMediaQuery } from '@material-ui/core';
import CustomButton from '../customButton';
// Import Utility
import {
  STATUS_VISITED,
  STATUS_ENTERED,
  STATUS_GRAY,
  STATUS_COMPLETED,
  STATUS_CURRENT,
  WOUNDGOAL_PAGECODE,
  WOUND_EXUDATE_ATTR_CODE,
  MOBILE_BREAKPOINT,
  DEPTH_UNKNOWN,
  CHECKBOX_STATES_CHECKED,
} from '../../utility/staticText';
import { ApplicationContext } from '../../store/ApplicationState';
import {
  captureMiniValidationData,
  capturePageData,
  checkParameters,
  fetchSiteCoreContents,
  fetchWoundParametersContent,
} from '../../utility/utils';
import useOutsideclick from '../../utility/useOutsideclick';
// Import Common API
import {
  getActivePageConfig,
  getPageConfigAttributes,
  getMinimumSectionValidation,
} from '../../api/common';
// Import Components
import SectionBreadcrumbs from '../sectionBreadcrumbs';
import {
  WoundAge,
  WoundAppearance,
  WoundCompression,
  WoundEdges,
  WoundExudate,
  WoundInfection,
  WoundOdor,
  WoundPain,
  WoundPeriWound,
  WoundSize,
  WoundType,
} from '../../characteristics';
import { createObj, SaveAssessmentDetail } from '../../utility/saveAssessment';
import Recommendation from '../recommendation/Recommendation';
import CustomLabel from '../customLabel/CustomLabel';
import useStylesWTG from './WoundTherapyGuide.style';
import LightTooltip from '../lightTooltip';
import getCmsContent from '../../api/cms';
import useFullPageLoader from '../../hooks/useFullPageLoader';

const useStyles = makeStyles(() => ({
  stepperIconStyles: {
    width: 20,
    height: 20,
    display: 'flex',
    borderRadius: '50%',
    backgroundColor: '#E4E4E8',
    alignItems: 'center',
  },
  enteredIconStyles: {
    width: 20,
    height: 20,
    display: 'flex',
    borderRadius: '50%',
    backgroundColor: '#0049BD',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
  visitedIconStyles: {
    width: 12,
    height: 12,
    display: 'flex',
    borderRadius: '50%',
    backgroundColor: '#fff',
    border: '4px solid #0049BD',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
  currentIconStyles: {
    width: 12,
    height: 12,
    display: 'flex',
    borderRadius: '50%',
    border: 'solid 4px #E4E4E8',
    backgroundColor: '#0049BD',
  },
  completedIconStyles: {
    width: 19,
    height: 19,
    display: 'flex',
    borderRadius: '50%',
    backgroundColor: '#0049BD',
  },
  pageLabelStyle: {
    marginLeft: '4px',
    lineHeight: '0px',
    fontWeight: 'normal',
    color: '#CCCCD0',
    letterSpacing: '0.2px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: '#262628',
    },
  },
  pageLabelStyleReq: {
    marginLeft: '4px',
    lineHeight: '0px',
    fontWeight: 'normal',
    color: '#656569',
    letterSpacing: '0.2px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: '#262628',
    },
  },
  currentLabelStyle: {
    marginLeft: '4px',
    lineHeight: '0px',
    letterSpacing: '0.2px',
    display: 'flex',
    fontWeight: 'bold',
    color: '#323234',
    alignItems: 'center',
    '&:hover': {
      color: '#0049BD',
      fontWeight: 'bold',
    },
  },
  completedLabelStyle: {
    marginLeft: '4px',
    fontSize: '18px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    display: 'flex',
    fontWeight: 'bold',
    color: '#0049BD',
    alignItems: 'center',
    textDecorationLine: 'underline',
  },
  labelStyle: {
    marginLeft: '4px',
    lineHeight: '0px',
    fontWeight: 'normal',
    color: '#CCCCD0',
    letterSpacing: '0.2px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: '#0049BD',
      fontWeight: 'bold',
    },
  },
  labelStyleReq: {
    marginLeft: '4px',
    lineHeight: '0px',
    fontWeight: 'normal',
    color: '#656569',
    letterSpacing: '0.2px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: '#0049BD',
      fontWeight: 'bold',
    },
  },
  line: {
    width: 4,
    height: 10,
    marginLeft: 11.3,
    border: 0,
    backgroundColor: '#E4E4E8',
  },
  iconStyle: {
    fontSize: '16px',
    position: 'relative',
  },
  stepStyle: {
    width: '100%',
    backgroundColor: '#F8F8FA',
    boxShadow:
      '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12)',
  },
  grayIconStyle: {
    pointerEvents: 'none',
    fontSize: '1rem',
    position: 'relative',
    zIndex: 1200,
  },
  disabledStyle: {
    width: 15,
    height: 15,
    display: 'flex',
    borderRadius: '50%',
    border: 'solid 2px gray',
    fontSize: '1rem',
    position: 'relative',
    backgroundColor: 'gray',
  },
  grayDisabledStyle: {
    height: '1px',
    width: '19px',
  },
}));

const ColorlibConnector = withStyles({
  line: {
    width: 4,
    height: 43.2,
    marginLeft: 11.1,
    border: 0,
    backgroundColor: '#E4E4E8',
  },
  active: {
    '& $line': {
      backgroundColor: '#0049BD',
    },
  },
  vertical: {
    zIndex: 9,
    position: 'relative',
    top: '-14px',
    height: '15px',
  },
})(StepConnector);

function getContentID(pageConfigList) {
  const pageObj = pageConfigList.filter((page) => page.pageCode === 'WTG');
  return pageObj[0].contentID;
}

async function fetchWTGContents(contentId) {
  let content = JSON.parse(localStorage.getItem(contentId));
  if (!content) {
    const body = {
      token: '',
      contentId,
    };
    content = await getCmsContent(body);
    localStorage.setItem(contentId, JSON.stringify(content));
  }
  return content;
}

const WoundTherapyGuide = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [headerTitleLabel, setheaderTitleLabel] = useState('');
  const [introductionTextLabel, setIntroductionTextLabel] = useState('');
  const [subHeader, setSubHeader] = useState('');
  const [browseAllTherapyText, setBrowseAllTherapyText] = useState('');
  const [browseAllTherapyLink, setBrowseAllTherapyLink] = useState('');
  const [displayStepper, setDisplayStepper] = useState(false);
  const [goHome, setGoHome] = useState(false);
  const [open, setOpen] = useState(false);
  const recommendationPageCode = 'WDREC';
  const classes = useStyles();
  const theme = useTheme();
  const matchesMobileWidth = useMediaQuery(
    theme.breakpoints.down(MOBILE_BREAKPOINT)
  );
  const ref = useRef(null);
  useOutsideclick(ref, () => {
    setOpen(false);
  });

  const {
    state: {
      activeStep,
      pageData,
      totalStepper,
      showNextButton,
      minimumValidationData,
      treatmentPathway,
      previousPage,
      firstLoad,
      disableNextPrev,
      forceNew,
      disclaimerLegalText,
      sessionID,
    },
    dispatch,
    wndExudateState: {
      selectedExudateTypeParameterCode,
      selectedExudateAmountParameterCode,
    },
    wndSizeState: { woundDepth },
    wndExudateDispatch,
    wndRecommDispatch,
    wndPeriwoundState: { periwoundOtherParamCode },
    wndInfectionState: { otherParameterCode },
    wndAgeDispatch,
    wndTypeDispatch,
    wndSizeDispatch,
    wndInfectionDispatch,
    wndAppearanceState: { totalAppearance },
    wndAppearanceDispatch,
    wndPainDispatch,
    wndCompressionDispatch,
    wndOdorDispatch,
    wndEdgesState: { edgesOtherParamCode },
    wndEdgesDispatch,
    wndPeriwoundDispatch,
    wndGoalDispatch,
  } = useContext(ApplicationContext);

  const updateStatus = (index) => {
    if (pageData[index].enabled) {
      const updatedData = pageData.map((page, ix) =>
        ix <= index &&
        (page.status === '' ||
          page.status === STATUS_COMPLETED ||
          page.status === STATUS_CURRENT)
          ? { ...page, status: STATUS_VISITED, isActiveStep: true }
          : page
      );
      if (index !== totalStepper) {
        updatedData[index].status =
          updatedData[index].status === STATUS_ENTERED
            ? STATUS_ENTERED
            : STATUS_CURRENT;
      }

      if (
        updatedData[activeStep].status !== STATUS_ENTERED &&
        updatedData[activeStep].status !== STATUS_GRAY &&
        updatedData[activeStep].status !== STATUS_COMPLETED &&
        updatedData[activeStep].enabled
      ) {
        updatedData[activeStep].status = STATUS_VISITED;
      }
      dispatch({ pageData: updatedData });
    }
  };

  const handleClick = (index) => {
    if (pageData[index].enabled && index !== activeStep) {
      if (index === totalStepper) {
        dispatch({ lastPage: activeStep });
      }
      updateStatus(index);
      dispatch({ previousPage: activeStep });
      dispatch({ activeStep: index });

      dispatch({ showNextButton: index !== totalStepper });
    }
  };

  const getStylesByStatus = (status) => {
    switch (status) {
      case STATUS_ENTERED:
        return classes.enteredIconStyles;
      case STATUS_VISITED:
        return classes.visitedIconStyles;
      case STATUS_GRAY:
        return classes.disabledStyle;
      case STATUS_COMPLETED:
        return classes.completedIconStyles;
      case STATUS_CURRENT:
        return classes.currentIconStyles;
      default:
        return classes.stepperIconStyles;
    }
  };

  const getLabelStyles = (status, showStar) => {
    if (status === STATUS_ENTERED) {
      return showStar ? classes.labelStyleReq : classes.labelStyle;
    }
    if (status === STATUS_VISITED) {
      return showStar ? classes.labelStyleReq : classes.labelStyle;
    }
    if (status === STATUS_CURRENT) {
      return classes.currentLabelStyle;
    }
    if (status === STATUS_COMPLETED) {
      return classes.completedLabelStyle;
    }

    return showStar ? classes.pageLabelStyleReq : classes.pageLabelStyle;
  };

  const renderStepperIcon = (status) => {
    switch (status) {
      case STATUS_ENTERED:
        return <CheckIcon className={classes.iconStyle} />;
      case STATUS_VISITED:
        return <FiberManualRecordIcon className={classes.iconStyle} />;
      case STATUS_GRAY:
        return <FiberManualRecordIcon className={classes.grayDisabledStyle} />;
      default:
        return '';
    }
  };

  async function getPageConfiguration() {
    const responses = await getActivePageConfig();
    return responses;
  }
  async function getMinimumValidationCriteria() {
    const responses = await getMinimumSectionValidation();
    return responses;
  }
  async function getPageConfigurationAttribute(reqBody) {
    const responses = await getPageConfigAttributes(reqBody);
    return responses;
  }

  function filterAttributeByPagiID(pageID, attributeData) {
    const att = attributeData.filter((param) => param.pageId === pageID);
    return att;
  }

  function CreateComponentFromName(Name, props) {
    const Components = {
      WoundAge,
      WoundAppearance,
      WoundCompression,
      WoundEdges,
      WoundExudate,
      WoundInfection,
      WoundOdor,
      WoundPain,
      WoundPeriWound,
      WoundSize,
      WoundType,
      Recommendation,
    };
    const Component = Components[Name];
    return <Component attInfo={props} />;
  }

  const handlePrevious = () => {
    const index = activeStep - 1;
    if (index >= 0) {
      updateStatus(index);

      if (pageData[index].enabled) {
        if (activeStep === 0) {
          setGoHome(true);
        } else {
          dispatch({ showNextButton: true });
          dispatch({ activeStep: activeStep - 1 });
          dispatch({ previousPage: activeStep });
        }
      }
    }
  };

  const handleNext = () => {
    const index = activeStep + 1;
    updateStatus(index);
    if (pageData[index].enabled) {
      dispatch({
        activeStep: activeStep + 1,
      });

      if (activeStep + 1 === totalStepper) {
        dispatch({ lastPage: activeStep });
        dispatch({ showNextButton: false });
      }
      dispatch({ previousPage: activeStep });
    }
  };

  const validateMinimumSectionsAttributes = () => {
    let allSelected = false;
    if (pageData.length > 0) {
      minimumValidationData.forEach((element) => {
        if (!allSelected) {
          allSelected = element.allMinimumAttr.every((x) => x.isValidated);
          if (allSelected) {
            const page = pageData.find(
              (x) => x.pageCode === recommendationPageCode
            );
            page.status = STATUS_COMPLETED;
            dispatch({ pageData });
            const updatedPage = pageData.map((pg) =>
              !pg.showStar ? { ...pg, enabled: true } : pg
            );
            dispatch({ pageData: updatedPage, isMinimumAttributeFilled: true });
          } else {
            const page = pageData.find(
              (x) => x.pageCode === recommendationPageCode
            );
            page.status = STATUS_GRAY;
            const updatedPage = pageData.map((pg) =>
              !pg.showStar ? { ...pg, enabled: false } : pg
            );
            dispatch({ pageData: updatedPage, isMinimumAttributeFilled: true });
          }
        }
      });
    }
  };

  const checkMarkStar = (minimumAttr, attrList) =>
    minimumAttr[0].allMinimumAttr.some(
      (item) => attrList.attCode === item.attr
    );

  const updateShowStar = (allPageData, minimumAttr) => {
    const primaryMiniAttr = minimumAttr.filter(
      (x) => x.sectionCode === 'PRIMA'
    );
    allPageData.forEach((pageDataItem, idx) => {
      const itemPageData = pageDataItem.link.props.attInfo;
      if (Array.isArray(itemPageData)) {
        itemPageData.forEach((pageItemsAttrCodes) => {
          const result = checkMarkStar(primaryMiniAttr, pageItemsAttrCodes);
          if (result) {
            pageData[idx].showStar = true;
            pageData[idx].enabled = true;
          }
          dispatch({ pageData });
        });
      }
    });
  };
  function findFirstPage() {
    let marked = false;
    pageData
      .sort((a, b) => a.pageOrder - b.pageOrder)
      .forEach((x, ix) => {
        if (!marked && x.showStar) {
          pageData[ix].firstPage = true;
          marked = true;
        }
      });
    if (!marked && pageData.length > 0) {
      pageData[0].firstPage = true;
    }
  }

  const updateExudateMinimumValidation = (
    attrCode,
    minimumValidation,
    bool
  ) => {
    let updateMinimumValidationData = [];
    updateMinimumValidationData = captureMiniValidationData(
      attrCode,
      minimumValidation,
      bool
    );
    dispatch({ updateMinimumValidationData });
  };

  const updateExudatePageData = (allPage) => {
    const allPageData = allPage;
    wndExudateDispatch({
      showExudateType: selectedExudateAmountParameterCode !== 'NONE',
    });
    allPageData.forEach((item, idx) => {
      if (item.pageCode === 'WDEXD') {
        allPageData[idx].isActiveStep = true;
        allPageData[idx].status = checkParameters(
          [
            selectedExudateTypeParameterCode,
            selectedExudateAmountParameterCode,
          ],
          allPageData[idx].isActiveStep
        );
        fetchWoundParametersContent(WOUND_EXUDATE_ATTR_CODE).then(
          (response) => {
            const selectedData = response.item.parameters[0].parameterGroup.filter(
              (x) => x.parameterCode === selectedExudateAmountParameterCode
            )[0];
            if (
              Object.prototype.hasOwnProperty.call(allPageData[idx], 'data')
            ) {
              delete allPageData[idx].data;
            }
            const saveObj = [];
            if (selectedExudateAmountParameterCode === 'NONE') {
              saveObj.push(createObj('ExudateTypeCode', ''));
            }
            saveObj.push(
              createObj('ExudateAmtCode', selectedExudateAmountParameterCode)
            );
            const buildDisplayText = `${selectedData.parameterDesc} amount`;
            const updatedPageData = capturePageData(
              WOUND_EXUDATE_ATTR_CODE,
              selectedExudateAmountParameterCode,
              selectedData,
              allPageData,
              response.item.parameters[0].attributeName,
              idx,
              buildDisplayText,
              saveObj
            );
            dispatch({ pageData: updatedPageData });
          }
        );
      }
    });
  };

  const handleInfoClick = () => {
    setOpen(!open);
  };

  const resetWoundCaptureData = () => {
    dispatch({ type: 'RESET_STATE' });
    wndAgeDispatch({ type: 'RESET_AGE_STATE' });
    wndTypeDispatch({ type: 'RESET_TYPE_STATE' });
    wndSizeDispatch({ type: 'RESET_SIZE_STATE' });
    wndExudateDispatch({ type: 'RESET_EXUDATE_STATE' });
    wndInfectionDispatch({ type: 'RESET_INFECTION_STATE' });
    wndAppearanceDispatch({ type: 'RESET_APPEARANCE_STATE' });
    wndPainDispatch({ type: 'RESET_PAIN_STATE' });
    wndCompressionDispatch({ type: 'RESET_COMPRESSION_STATE' });
    wndOdorDispatch({ type: 'RESET_ODOR_STATE' });
    wndEdgesDispatch({ type: 'RESET_EDGES_STATE' });
    wndPeriwoundDispatch({ type: 'RESET_PERI_STATE' });
    wndGoalDispatch({ type: 'RESET_Goal_STATE' });
  };

  const onLinkClick = () => {
    resetWoundCaptureData();
    dispatch({
      isGoalPathWay: false,
      isAllTherapyWay: true,
      treatmentPathway: 'THRAL',
      forceNew: true,
    });
    wndRecommDispatch({
      treatmentList: [],
      selectAllTreatment: CHECKBOX_STATES_CHECKED,
    });
  };

  function setToolTipCustom(
    infoTitleValue,
    infoDescriptionValue,
    infoURLValue,
    infoURLTitleValue
  ) {
    return (
      <div style={{ margin: '8px' }}>
        <CustomLabel
          labelStyle={useStylesWTG.allTherapyTitle}
          value={infoTitleValue || ''}
        />
        <CustomLabel
          labelStyle={useStylesWTG.toolTipDesp}
          value={infoDescriptionValue || ''}
        />
        <NavLink
          onClick={onLinkClick}
          underline="always"
          style={{
            fontSize: '16px',
            lineHeight: '20px',
            color: '#0049BD',
            marginTop: '10px',
          }}
          to={infoURLValue}
        >
          {parse(infoURLTitleValue || '')}
        </NavLink>
      </div>
    );
  }

  const tooltipTitle = setToolTipCustom(
    '',
    browseAllTherapyText,
    '/allTherapies',
    browseAllTherapyLink
  );

  useEffect(() => {
    showLoader();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  useEffect(() => {
    const allPages = [];
    async function createData(pageConfig, pageConfigAtt) {
      pageConfig.forEach((pageConfigRec, ix) => {
        const params = filterAttributeByPagiID(
          pageConfigRec.pageId,
          pageConfigAtt
        );
        if (params.length !== 0) {
          const props = [];
          params.forEach((param) => {
            const propsInfo = {
              attName: params && param.attributeTypeName,
              attOrder: params && param.attributeOrder,
              attCode: param && param.attributeTypeCode,
              pageCode: pageConfigRec && pageConfigRec.pageCode,
              contentID: pageConfigRec && pageConfigRec.contentID,
            };
            props.push(propsInfo);
          });
          const obj = {
            showLeftNav: true,
            showInShell: true,
            pageId: pageConfigRec.pageId,
            pageTitle: pageConfigRec.pageTitle,
            pageOrder: pageConfigRec.pageOrder,
            isActive: pageConfigRec.isActive,
            componentName: pageConfigRec.componentName,
            contentID: pageConfigRec.contentID,
            status: '',
            isActiveStep: ix === pageData.findIndex((x) => x.enabled),
            attributeTypeCode: params.attributeTypeCode,
            attributeOrder: params.attributeOrder,
            link: CreateComponentFromName(pageConfigRec.componentName, props),
            showStar: false,
            enabled: false,
            firstPage: false,
            pageCode: pageConfigRec.pageCode,
          };
          allPages.push(obj);
        }
      });

      if (
        !firstLoad &&
        pageData.length > 0 &&
        pageData.some((x) => x.enabled)
      ) {
        dispatch({
          activeStep:
            pageData.findIndex((x) => x.enabled) > -1
              ? pageData.findIndex((x) => x.enabled)
              : 0,
          treatmentPathway: 'WNDCH',
        });
        dispatch({ firstLoad: true });
        if (
          pageData[activeStep].pageCode === 'WDAPP' &&
          totalAppearance === 100
        ) {
          pageData[activeStep].status = STATUS_ENTERED;
        } else {
          pageData[activeStep].status = STATUS_CURRENT;
        }
      }
      const recommendation = pageConfig.filter(
        (x) => x.pageCode === recommendationPageCode
      )[0];
      const recommendationObj = {
        showLeftNav: true,
        showInShell: false,
        pageId: recommendation.pageId,
        pageTitle: recommendation.pageTitle,
        pageOrder: recommendation.pageOrder,
        isActive: recommendation.isActive,
        componentName: recommendation.componentName,
        contentID: recommendation.contentID,
        status: STATUS_GRAY,
        isActiveStep: false,
        pageCode: recommendation.pageCode,
        link: CreateComponentFromName(recommendation.componentName, {
          contentID: recommendation.contentID,
        }),
        showStar: false,
        enabled: false,
        firstPage: false,
      };
      allPages.push(recommendationObj);
      if (pageData.length === 0) {
        if (
          selectedExudateAmountParameterCode &&
          selectedExudateAmountParameterCode.length > 0
        ) {
          updateExudatePageData(allPages);
        }
        dispatch({ pageData: allPages });
        dispatch({ totalStepper: allPages.length - 1 });
      }

      fetchSiteCoreContents(allPages[0].contentID).then((WTGContents) => {
        const {
          headerTitle,
          introductionText,
          subHeaderAllTherapy,
        } = WTGContents.item.contentDetailsLists[0].contentItems[0].woundCharacteristics;
        setheaderTitleLabel(headerTitle.replace(/<[^>]+>/g, ''));
        setIntroductionTextLabel(introductionText.replace(/<[^>]+>/g, ''));
        setSubHeader(subHeaderAllTherapy);
      });
      getActivePageConfig().then((content) => {
        const contentID = getContentID(content.item.pageConfigListObj);
        fetchWTGContents(contentID).then((WTGContent) => {
          const {
            browseAllTherapyToolTipDesc,
            browseAllTherapyToolTipLink,
          } = WTGContent.item.contentDetailsLists[0].contentItems[0].woundTherapyGuideMain;
          setBrowseAllTherapyLink(browseAllTherapyToolTipLink);
          setBrowseAllTherapyText(browseAllTherapyToolTipDesc);
        });
      });
    }
    const allpageID = [];
    let pageConfigData = [];
    let pageConfigAttData = [];
    let minimumSectionsData = [];
    getPageConfiguration().then((result) => {
      pageConfigData = result.item.pageConfigListObj;
      const characteristicsPageData = pageConfigData.filter(
        (x) => x.pageCode !== WOUNDGOAL_PAGECODE
      );
      characteristicsPageData.forEach((element) => {
        allpageID.push(element.pageId);
      });

      getPageConfigurationAttribute(allpageID).then((data) => {
        pageConfigAttData = data.item.pageConfigAttributeListObj;
        createData(characteristicsPageData, pageConfigAttData).then(() => {
          setDisplayStepper(true);
          if (minimumValidationData.length === 0) {
            getMinimumValidationCriteria().then((res) => {
              const minimumValidation = [];
              minimumSectionsData = res.item.minimumValidationSections;
              minimumSectionsData.forEach((element) => {
                const allMinimumAttr = [];
                element.attrcode.forEach((attritem) => {
                  allMinimumAttr.push({
                    attr: attritem,
                    isValidated: false,
                  });
                });
                const objects = {
                  sectionCode: element.sectionCode,
                  allMinimumAttr,
                };
                minimumValidation.push(objects);
              });
              updateShowStar(pageData, minimumValidation);
              dispatch({ minimumValidationData: minimumValidation });
              if (
                selectedExudateAmountParameterCode &&
                selectedExudateAmountParameterCode.length > 0
              ) {
                updateExudateMinimumValidation(
                  WOUND_EXUDATE_ATTR_CODE,
                  minimumValidation,
                  true
                );
              }
            });
          }
        });
        hideLoader();
      });
    });
    validateMinimumSectionsAttributes();
    findFirstPage();
  }, [JSON.stringify(pageData)]);

  useEffect(() => {
    validateMinimumSectionsAttributes();
  }, [JSON.stringify(minimumValidationData)]);

  useEffect(() => {
    if (pageData !== undefined && pageData.length > 0) {
      const { data } = pageData[previousPage];
      const obj = [];

      let infOtherObj = createObj('SignsOfInfectionOtherDesc', null);
      let periOtherObj = createObj('tissuePeripheryOtherDesc', null);
      let edgeOtherObj = createObj('woundEdgesOtherDesc', null);
      let depthUnknownFlag = createObj('depthUnknown', null);
      if (data) {
        const res = Object.keys(data).map((key) => [data[key]]);
        const userID = JSON.parse(sessionStorage.getItem('userID'));
        const clientType = JSON.parse(sessionStorage.getItem('clientType'));
        const assessmentObj = [];
        if (Object.prototype.hasOwnProperty.call(data, 'SOI')) {
          Object.keys(data).forEach((key) => {
            if (data[key].some((x) => x.parameterCode === otherParameterCode)) {
              infOtherObj = createObj(
                'SignsOfInfectionOtherDesc',
                data[key].filter(
                  (x) => x.parameterCode === otherParameterCode
                )[0].OtherText
              );
            } else {
              infOtherObj = createObj('SignsOfInfectionOtherDesc', '');
            }
            data[key].forEach((displayData) => {
              obj.push(displayData.parameterCode);
            });
          });
          const sa = obj.join();
          const obj1 = createObj('SignsOfInfectionCode', sa.replace(/,/g, ':'));
          const obj2 = createObj('sessionID', sessionID);
          const obj3 = createObj(
            'treatmentPathway',
            treatmentPathway && treatmentPathway
          );
          const obj5 = createObj('userID', userID);
          const obj6 = createObj('clientType', clientType);
          assessmentObj.push(obj1, obj2, obj3, obj5, obj6, infOtherObj);
          if (forceNew) {
            const obj4 = createObj('forceNew', forceNew && forceNew);
            assessmentObj.push(obj4);
          }
          SaveAssessmentDetail(assessmentObj);
          dispatch({ forceNew: false });
        } else if (Object.prototype.hasOwnProperty.call(data, 'TSP')) {
          Object.keys(data).forEach((key) => {
            if (
              data[key].some((x) => x.parameterCode === periwoundOtherParamCode)
            ) {
              periOtherObj = createObj(
                'tissuePeripheryOtherDesc',
                data[key].filter(
                  (x) => x.parameterCode === periwoundOtherParamCode
                )[0].OtherText
              );
            } else {
              periOtherObj = createObj('tissuePeripheryOtherDesc', '');
            }
            data[key].forEach((displayData) => {
              obj.push(displayData.parameterCode);
            });
          });
          const sa = obj.join();
          const obj1 = createObj('tissuePeripheryCode', sa.replace(/,/g, ':'));
          const obj2 = createObj('sessionID', sessionID);
          const obj3 = createObj(
            'treatmentPathway',
            treatmentPathway && treatmentPathway
          );
          const obj5 = createObj('userID', userID);
          const obj6 = createObj('clientType', clientType);
          assessmentObj.push(obj1, obj2, obj3, periOtherObj, obj5, obj6);
          if (forceNew) {
            const obj4 = createObj('forceNew', forceNew && forceNew);
            assessmentObj.push(obj4);
          }
          SaveAssessmentDetail(assessmentObj);
          dispatch({ forceNew: false });
        } else if (Object.prototype.hasOwnProperty.call(data, 'WOE')) {
          Object.keys(data).forEach((key) => {
            if (
              data[key].some((x) => x.parameterCode === edgesOtherParamCode)
            ) {
              edgeOtherObj = createObj(
                'woundEdgesOtherDesc',
                data[key].filter(
                  (x) => x.parameterCode === edgesOtherParamCode
                )[0].OtherText
              );
            } else {
              edgeOtherObj = createObj('woundEdgesOtherDesc', '');
            }
            data[key].forEach((displayData) => {
              obj.push(displayData.parameterCode);
            });
          });
          const sa = obj.join();
          const obj1 = createObj('woundEdgesCode', sa.replace(/,/g, ':'));
          const obj2 = createObj('sessionID', sessionID);
          const obj3 = createObj(
            'treatmentPathway',
            treatmentPathway && treatmentPathway
          );
          const obj5 = createObj('userID', userID);
          const obj6 = createObj('clientType', clientType);
          assessmentObj.push(obj1, obj2, obj3, edgeOtherObj, obj5, obj6);

          if (forceNew) {
            const obj4 = createObj('forceNew', forceNew && forceNew);
            assessmentObj.push(obj4);
          }
          SaveAssessmentDetail(assessmentObj);
          dispatch({ forceNew: false });
        } else if (
          (!Object.prototype.hasOwnProperty.call(data, 'WS') &&
            res[0][0].parameterCode) ||
          Object.prototype.hasOwnProperty.call(data, 'WA')
        ) {
          const result = Object.keys(data).map((key) => [data[key]]);
          result.forEach((tes) => {
            const paramObj = tes[0].saveAssessmentObj;
            if (paramObj) {
              paramObj.map((x) => assessmentObj.push(x));
            }
          });
          if (assessmentObj.length > 0) {
            const obj2 = createObj('sessionID', sessionID);
            const obj3 = createObj(
              'treatmentPathway',
              treatmentPathway && treatmentPathway
            );
            const obj5 = createObj('userID', userID);
            const obj6 = createObj('clientType', clientType);
            assessmentObj.push(obj2, obj3, obj5, obj6);
            if (forceNew) {
              const obj4 = createObj('forceNew', forceNew && forceNew);
              assessmentObj.push(obj4);
            }
            SaveAssessmentDetail(assessmentObj);
            dispatch({ forceNew: false });
          }
        } else if (
          Object.prototype.hasOwnProperty.call(data, 'WS') &&
          data.WS.parameterCode !== '' &&
          Object.prototype.hasOwnProperty.call(data, 'DEPTH')
        ) {
          const result = Object.keys(data).map((key) => [data[key]]);
          result.forEach((tes) => {
            const paramObj = tes[0].saveAssessmentObj;
            if (paramObj) {
              paramObj.map((x) => assessmentObj.push(x));
            }
          });
          if (assessmentObj.length > 0) {
            const obj2 = createObj('sessionID', sessionID);
            const obj3 = createObj(
              'treatmentPathway',
              treatmentPathway && treatmentPathway
            );
            const obj5 = createObj('userID', userID);
            const obj6 = createObj('clientType', clientType);
            assessmentObj.push(obj2, obj3, obj5, obj6);
            if (forceNew) {
              const obj4 = createObj('forceNew', forceNew && forceNew);
              assessmentObj.push(obj4);
            }
            Object.keys(data).forEach((key) => {
              if (Array.isArray(data[key])) {
                if (key === 'DEPTH') {
                  if (
                    data[key].some((x) => x.parameterCode === DEPTH_UNKNOWN)
                  ) {
                    depthUnknownFlag = createObj('depthUnknown', true);
                  } else {
                    depthUnknownFlag = createObj('depthUnknown', false);
                  }
                  data[key].forEach((paraCode) => {
                    obj.push(paraCode.parameterCode);
                  });
                  const sa = obj ? obj.join() : null;
                  const obj1 = createObj(
                    'depthCode',
                    sa ? sa.replace(/,/g, ':') : null
                  );
                  assessmentObj.push(obj1, depthUnknownFlag);
                }
              }
            });
            SaveAssessmentDetail(assessmentObj);
            dispatch({ forceNew: false });
          }
        } else if (
          Object.prototype.hasOwnProperty.call(data, 'WS') &&
          data.WS.parameterCode === '' &&
          Object.prototype.hasOwnProperty.call(data, 'DEPTH')
        ) {
          Object.keys(data).forEach((key) => {
            const assessmentObjs = [];
            if (Array.isArray(data[key])) {
              if (key === 'DEPTH') {
                if (data[key].some((x) => x.parameterCode === DEPTH_UNKNOWN)) {
                  depthUnknownFlag = createObj('depthUnknown', true);
                } else {
                  depthUnknownFlag = createObj('depthUnknown', false);
                }
                data[key].forEach((paraCode) => {
                  obj.push(paraCode.parameterCode);
                });
                const sa = obj ? obj.join() : null;
                const obj1 = createObj(
                  'depthCode',
                  sa ? sa.replace(/,/g, ':') : null
                );
                const obj2 = createObj('sessionID', sessionID);
                const obj3 = createObj(
                  'treatmentPathway',
                  treatmentPathway && treatmentPathway
                );
                const obj5 = createObj(
                  'WoundDepth',
                  woundDepth.length > 0 ? woundDepth : '0'
                );
                const obj6 = createObj('WoundWidth', '0');
                const obj7 = createObj('WoundLength', '0');
                const obj8 = createObj('userID', userID);
                const obj9 = createObj('clientType', clientType);
                assessmentObjs.push(
                  obj1,
                  obj2,
                  obj3,
                  obj5,
                  obj6,
                  obj7,
                  depthUnknownFlag,
                  obj8,
                  obj9
                );
                if (forceNew) {
                  const obj4 = createObj('forceNew', forceNew && forceNew);
                  assessmentObjs.push(obj4);
                }

                SaveAssessmentDetail(assessmentObjs);
                dispatch({ forceNew: false });
              }
            }
          });
        } else if (
          Object.prototype.hasOwnProperty.call(data, 'WS') &&
          !Object.prototype.hasOwnProperty.call(data, 'DEPTH')
        ) {
          const result = Object.keys(data).map((key) => [data[key]]);
          result.forEach((tes) => {
            const paramObj = tes[0].saveAssessmentObj;
            if (paramObj) {
              paramObj.map((x) => assessmentObj.push(x));
            }
          });
          if (assessmentObj.length > 0) {
            const obj2 = createObj('sessionID', sessionID);
            const obj3 = createObj(
              'treatmentPathway',
              treatmentPathway && treatmentPathway
            );
            const obj5 = createObj('userID', userID);
            const obj6 = createObj('clientType', clientType);
            assessmentObj.push(obj2, obj3, obj5, obj6);
            if (forceNew) {
              const obj4 = createObj('forceNew', forceNew && forceNew);
              assessmentObj.push(obj4);
            }
            SaveAssessmentDetail(assessmentObj);
            dispatch({ forceNew: false });
          }
        }
      }
    }
  }, [activeStep]);

  return (
    <Box style={{ minHeight: 'calc(100vh - 145px)', width: '100%' }}>
      {loader}
      {displayStepper && (
        <div className="stepRoot mobStepRoot">
          <div
            style={{
              display:
                matchesMobileWidth && activeStep !== 0 ? 'none' : 'block',
              margin: matchesMobileWidth ? '0 20px 24px' : '0 0 30px',
            }}
          >
            <div className="stepBreadcrumb mobStepBreadcrumb">
              <SectionBreadcrumbs />
            </div>
            <div>
              <Typography className="stepHeadTitle mobStepHeadTitle">
                {headerTitleLabel}
              </Typography>
            </div>
            <div>
              <Typography className="font1 mobFont1">
                {introductionTextLabel}
              </Typography>
            </div>
            <div>
              <Typography style={useStylesWTG.subHeader}>
                {parse(subHeader, {
                  replace(node) {
                    let returnValue = '';
                    if (node.parent && node.data === 'browse all therapies') {
                      returnValue = (
                        <LightTooltip
                          title={tooltipTitle}
                          ref={ref}
                          arrow
                          placement="top"
                          style={{ marginLeft: '2px' }}
                          onClick={handleInfoClick}
                          open={open}
                        >
                          <a
                            underline="always"
                            style={{
                              cursor: 'pointer',
                              fontSize: '16px',
                              lineHeight: '20px',
                              color: '#0049BD',
                            }}
                          >
                            browse all therapies
                          </a>
                        </LightTooltip>
                      );
                    }
                    return returnValue;
                  },
                })}
              </Typography>
            </div>
          </div>
          <div className="stepDiv mobStepDiv">
            <div className="stepContainer mobStepContainer">
              <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
                Characteristics
              </Typography>
              <Stepper
                style={{
                  marginTop: '10px',
                  padding: '0px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
                nonLinear
                orientation="vertical"
                activeStep={activeStep}
                connector={<ColorlibConnector />}
              >
                {pageData
                  .filter((x) => x.showLeftNav)
                  .map((label, index) => (
                    <Step
                      // eslint-disable-next-line react/no-array-index-key
                      key={index.toString()}
                      active={label.isActiveStep}
                      className={
                        label.status === STATUS_GRAY || !label.enabled
                          ? classes.grayIconStyle
                          : ''
                      }
                      style={{
                        pointerEvents: disableNextPrev ? 'none' : 'all',
                      }}
                    >
                      <ListItem
                        style={{
                          fontSize: '1rem',
                          height: '40px',
                          zIndex: label.status === STATUS_CURRENT ? 2 : 12,
                        }}
                        button
                        onClick={() => handleClick(index)}
                      >
                        <Typography
                          className={getStylesByStatus(label.status)}
                          style={{
                            color:
                              label.status === STATUS_GRAY ? 'gray' : 'white',
                            right: '1px',
                            position:
                              label.status === '' ? 'relative' : 'relative',
                          }}
                        >
                          {renderStepperIcon(label.status)}
                        </Typography>
                        <Typography
                          style={{
                            fontWeight:
                              index === activeStep && label.enabled
                                ? 'bold'
                                : 'normal',
                          }}
                          className={getLabelStyles(
                            label.status,
                            label.enabled
                          )}
                        >
                          {label.pageTitle}
                          {label.showStar && (
                            <StarIcon
                              style={{
                                fontSize: '0.5rem',
                                color: '#CB0000',
                                marginLeft: '3px',
                                position: 'relative',
                                top: '-6',
                              }}
                            />
                          )}
                        </Typography>
                      </ListItem>
                    </Step>
                  ))}
              </Stepper>
            </div>
            <div className={classes.stepStyle}>
              <div>{pageData[activeStep].link}</div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: matchesMobileWidth && '0 20px',
            }}
          >
            <div className="stepPrevNextBtnBox mobStepPrevNextBtnBox">
              <Box className="stepNumber mobStepNumber">
                <p className="mobStepVal">
                  {`Step ${activeStep + 1} of ${totalStepper + 1}`}
                </p>
              </Box>
              <Box style={{ display: 'flex', flexDirection: 'row' }}>
                {!pageData[activeStep].firstPage && (
                  <CustomButton
                    value="Previous"
                    backgroundColor="white"
                    color="#0049BD"
                    borderColor="#0049BD"
                    fontWeight="bold"
                    handleSubmit={handlePrevious}
                    disableNextPrev={disableNextPrev}
                    containerBoxStyle="nxtBtnBox"
                    width={matchesMobileWidth ? '96px' : '110px'}
                    isSecondary
                  />
                )}
                {showNextButton && (
                  <CustomButton
                    value="Next"
                    backgroundColor="white"
                    color="#0049BD"
                    borderColor="#0049BD"
                    fontWeight="bold"
                    handleSubmit={handleNext}
                    disableNextPrev={disableNextPrev}
                    containerBoxStyle="prvBtnBox"
                    width={matchesMobileWidth ? '96px' : '110px'}
                    isSecondary
                  />
                )}
              </Box>
            </div>
            <div className="stepBrowseAll mobStepBrowseAll">
              or
              <NavLink to="/allTherapies">&nbsp;browse all therapies.</NavLink>
            </div>
          </div>
          <div className="disclaimerBox mobDisclaimerBox">
            <p className="disclaimerText">{parse(disclaimerLegalText || '')}</p>
          </div>
        </div>
      )}
      {goHome && <Redirect to="/home" />}
    </Box>
  );
};

WoundTherapyGuide.propTypes = {
  push: PropTypes.func.isRequired,
};

export default WoundTherapyGuide;
