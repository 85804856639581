// React imports
import React from 'react';
import PropTypes from 'prop-types';
// Material UI imports
import { Typography, Button } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// Store imports

const CustomLargeButton = ({
  buttonText,
  handleClick,
  buttonLeftSideIcon,
  buttonStyle,
}) => (
  <Button
    variant="outlined"
    color="Primary"
    className="secondaryButtonStyle"
    style={buttonStyle}
    startIcon={
      <img alt="info" src={buttonLeftSideIcon} height="20px" width="20px" />
    }
    onClick={handleClick}
  >
    <Typography
      style={{
        maxWidth: '242px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '20px',
      }}
    >
      {buttonText}
    </Typography>
  </Button>
);

CustomLargeButton.defaultProps = {
  buttonLeftSideIcon: <ArrowBackIosIcon />,
  buttonStyle: { borderColor: '#0049BD', width: '266px', height: '48px' },
};

CustomLargeButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  handleClick: PropTypes.string.isRequired,
  buttonLeftSideIcon: PropTypes.string,
  buttonStyle: PropTypes.string,
};
export default CustomLargeButton;
