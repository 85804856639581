/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import parse from 'html-react-parser';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    top: '-1px',
    pointerEvents: 'auto',
  },
  icon: {
    borderRadius: '100px',
    width: 24,
    height: 24,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#fffff !important',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',

    'input:hover ~ &': {
      backgroundColor: '#D1E9FF',
      border: '1px solid #1E64D0',
      borderRadius: '100px',
      boxShadow: 'none',
    },
    'input:focus ~ &': {
      backgroundColor: '#FFFF',
      border: '1px solid #76767A',
      borderRadius: '100px',
      outline: '3px solid #87B7F3 !important',
      boxShadow: 'none',
    },
    'input:hover:focus ~ &': {
      backgroundColor: '#D1E9FF !important',
      border: '1px solid #1E64D0 !important',
      borderRadius: '100px',
      outline: '3px solid #87B7F3 !important',
      boxShadow: 'none',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
    'input:checked ~ &': {
      border: '1px solid #002B8C !important',
    },

    'input:active ~ &': {
      backgroundColor: '#FFFFF',
      border: '1px solid #002B8C',
      borderRadius: '100px',
      boxShadow: 'none',
    },
  },
  checkedIcon: {
    backgroundColor: '#ffff',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 24,
      height: 24,
      backgroundImage: 'radial-gradient(#0049BD,#0049BD 48%,transparent 54%)',
      content: '""',
    },
    border: '1px solid #0049BD',
    'input:focus:checked ~ &': {
      '&:before': {
        display: 'block',
        width: 24,
        height: 24,
        backgroundImage: 'radial-gradient(#1E64D0,#1E64D0 48%,transparent 54%)',
        content: '""',
      },
      backgroundColor: '#FFFF',
      border: '1px solid #76767A',
      borderRadius: '100px',
      outline: '3px solid #87B7F3 !important',
      boxShadow: 'none',
    },
    'input:hover:focus:checked ~ &': {
      '&:before': {
        display: 'block',
        width: 24,
        height: 24,
        backgroundImage: 'radial-gradient(#1E64D0,#1E64D0 48%,transparent 54%)',
        content: '""',
      },
      backgroundColor: '#D1E9FF !important',
      border: '1px solid #1E64D0 !important',
      borderRadius: '100px',
      outline: '3px solid #87B7F3 !important',
      boxShadow: 'none',
    },
    'input:hover:checked ~ &': {
      '&:before': {
        display: 'block',
        width: 24,
        height: 24,
        backgroundImage: 'radial-gradient(#1E64D0,#1E64D0 48%,transparent 54%)',
        content: '""',
      },
      backgroundColor: '#D1E9FF',
      border: '1px solid #1E64D0',
      borderRadius: '100px',
      boxShadow: 'none',
    },
    'input:active:checked ~ &': {
      '&:before': {
        display: 'block',
        width: 24,
        height: 24,
        backgroundImage: 'radial-gradient(#002B8C,#002B8C 48%,transparent 54%)',
        content: '""',
      },
      backgroundColor: '#FFFFF',
      border: '1px solid #002B8C',
      borderRadius: '100px',
      boxShadow: 'none',
    },
    'input:focus:active:checked ~ &': {
      '&:before': {
        display: 'block',
        width: 24,
        height: 24,
        backgroundImage: 'radial-gradient(#002B8C,#002B8C 48%,transparent 54%)',
        content: '""',
      },
      backgroundColor: '#FFFFF',
      border: '1px solid #002B8C',
      borderRadius: '100px',
      boxShadow: 'none',
    },
    'input:checked ~ &': {
      border: '1px solid #0049BD !important',
      boxShadow: 'none',
    },
  },
  checked: {
    '&, & + $label': {
      color: 'black',
    },
  },
  descriptor: {
    display: 'flex',
  },
  description: {
    display: 'flex',
    whiteSpace: 'nowrap',
  },
  descriptorContainer: {
    display: 'flex',
  },
  dash: {
    display: 'flex',
    whiteSpace: 'pre',
  },
});
export const RadioButton = ({
  value,
  handleRadioChange,
  groupValue,
  showDescriptor,
  rowAlign,
  groupStyle,
  labelStyle,
}) => {
  const classes = useStyles();
  return (
    <Box className="paramContainer mobParamContainer">
      <RadioGroup
        aria-label="WTG"
        name="WTG"
        value={value}
        onChange={handleRadioChange}
        row={rowAlign}
        style={groupStyle}
      >
        {groupValue
          .sort((a, b) => a.sortPriority - b.sortPriority)
          .map((row) => (
            <FormControlLabel
              value={row.parameterCode}
              control={
                <Radio
                  key={row.parameterCode}
                  className={classes.root}
                  disableRipple
                  disableFocusRipple
                  checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)} />
                  }
                  classes={{ checked: classes.checked }}
                  icon={<span className={classes.icon} />}
                />
              }
              label={
                <div className={classes.descriptorContainer}>
                  <div className={classes.description}>
                    <b>{row.parameterDesc}&nbsp;</b>
                  </div>
                  <div className={classes.descriptor}>
                    <div className={classes.dash}>
                      {showDescriptor &&
                        row.parameterDescriptor !== '' &&
                        ` - `}
                    </div>
                    <div>
                      {showDescriptor &&
                        row.parameterDescriptor !== '' &&
                        parse(row.parameterDescriptor || '')}
                    </div>
                  </div>
                </div>
              }
              style={labelStyle}
            />
          ))}
      </RadioGroup>
    </Box>
  );
};

RadioButton.defaultProps = {
  showDescriptor: true,
  rowAlign: false,
  groupStyle: {
    width: '100%',
  },
  labelStyle: {
    padding: '12px 0',
    maxWidth: '100%',
    minWidth: '40%',
    color: '#656569',
    pointerEvents: 'none',
  },
};
RadioButton.propTypes = {
  value: PropTypes.string.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  groupValue: PropTypes.arrayOf.isRequired,
  showDescriptor: PropTypes.bool,
  rowAlign: PropTypes.bool,
  groupStyle: PropTypes.objectOf(PropTypes.string.isRequired),
  labelStyle: PropTypes.objectOf(PropTypes.string.isRequired),
};
export default RadioButton;
