// React imports
import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
// Material UI imports
import { Box } from '@material-ui/core';

const SafetyStatementBox = ({ safetyStatementValue }) => (
  <Box className="sftyStmtContainer mobSftyStmtContainer">
    <p className="homeSafetyStmt mobHomeSafetyStmt">
      {parse(safetyStatementValue || '')}
    </p>
  </Box>
);

SafetyStatementBox.propTypes = {
  safetyStatementValue: PropTypes.string.isRequired,
};
export default SafetyStatementBox;
