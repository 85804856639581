import React from 'react';
import PropTypes from 'prop-types';
// Material UI imports
import { Box, Switch } from '@material-ui/core';

const Slider = ({ handleChange, checked, title }) => (
  <Box className="switchContainer hide">
    <p className="slider titleStyle">{title}</p>
    <div className="switch">
      <Switch
        defaultChecked={checked}
        color="default"
        inputProps={{ 'aria-label': 'checkbox with default color' }}
        disableFocusRipple
        disableTouchRipple
        disableRipple
        onChange={handleChange}
        checked={checked}
        style={{ padding: '9px' }}
      />
    </div>
  </Box>
);

Slider.propTypes = {
  handleChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};
export default Slider;
