import wndPeriInitialState from '../initialState/wndPeriInitialState';

const wndPeriwoundReducer = (state, action) => {
  switch (action.type) {
    case 'RESET_PERI_STATE':
      return {
        ...wndPeriInitialState,
      };
    default: {
      return {
        ...state,
        ...action,
      };
    }
  }
};

export default wndPeriwoundReducer;
