/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel } from '@material-ui/core';
import {
  CHECKBOX_STATES_CHECKED,
  CHECKBOX_STATES_EMPTY,
  CHECKBOX_STATES_INDETERMINATE,
} from '../../utility/staticText';

export const IndeterminateCheckBox = ({ label, value, onChange }) => {
  const checkboxRef = useRef();

  useEffect(() => {
    if (value === CHECKBOX_STATES_CHECKED) {
      checkboxRef.current.checked = true;
      checkboxRef.current.indeterminate = false;
    } else if (value === CHECKBOX_STATES_EMPTY) {
      checkboxRef.current.checked = false;
      checkboxRef.current.indeterminate = false;
    } else if (value === CHECKBOX_STATES_INDETERMINATE) {
      checkboxRef.current.checked = false;
      checkboxRef.current.indeterminate = true;
    }
  }, [value]);

  return (
    <div className="mobTrtBar">
      <FormControlLabel
        className="allTrtLabel"
        control={
          <input
            type="checkbox"
            className="chkboxInd mobChkbox chkboxIndTrt"
            ref={checkboxRef}
            onChange={onChange}
          />
        }
        label={<div className="allTrtText allTrtMobText">{label}</div>}
      />
    </div>
  );
};

IndeterminateCheckBox.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
export default IndeterminateCheckBox;
