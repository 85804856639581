import React from 'react';
import PropTypes from 'prop-types';

export default function CustomLine({ lineStyle }) {
  return (
    <div style={{ width: '100%' }}>
      <hr style={lineStyle} />
    </div>
  );
}

CustomLine.defaultProps = {
  lineStyle: {
    border: '1 px solid #C4C4C4',
  },
};

CustomLine.propTypes = {
  lineStyle: PropTypes.objectOf(PropTypes.string.isRequired),
};
