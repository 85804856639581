// Material UI imports
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    PrivateSwitchBase: {
      root: {
        padding: '0 10px 0 0',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '::-webkit-scrollbar': {
          width: '5px',
        },
        '::-webkit-scrollbar-track': {
          background: '#f2f2f5',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#ccccd0',
        },
      },
    },
    MuiStepConnector: {
      vertical: {
        padding: '0px',
      },
      active: {
        zIndex: '999 !important',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: '#fff',
          border: '1px solid #0049BD',
          boxSizing: 'border-box',
          boxShadow: 'inset 0px 1px 2px rgba(0, 0, 0, 0.1);',
          borderRadius: '2px',
        },
      },
      selectMenu: {
        whiteSpace: 'pre-wrap',
      },
    },
    MuiListItem: {
      root: {
        paddingTop: '0px',
        paddingBottom: '0px',
        '&$selected': {
          backgroundColor: '#e8f4ff',
        },
      },
    },
    MuiTouchRipple: {
      root: {
        display: 'none',
      },
    },
    MuiMenuItem: {
      root: {
        whiteSpace: 'normal',
      },
    },
    MuiTableCell: {
      root: {
        padding: '10px',
      },
    },

    MuiSwitch: {
      root: {
        width: '54px',
        height: '34px',
        padding: '7px',
        color: '#656569',
      },
      thumb: {
        width: '16px',
        height: '16px',
      },
      track: {
        borderRadius: '10px',
        backgroundColor: '#656569',
        opacity: 1,

        '$checked$checked + &': {
          opacity: 1,
          backgroundColor: '#0049BD',
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: '0px',
        marginRight: '0px',
        alignItems: 'flex-start',
        '@media(max-width: 926px)': {
          margin: '0px',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '32px 32px 0px 32px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '10px 32px 32px 32px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '0px',
      },
    },
    MuiDialogContentText: {
      root: {
        marginBottom: '0px',
      },
    },
    MuiIconButton: {
      root: {
        padding: '12px',
      },
    },
    MuiInputBase: {
      root: {
        lineHeight: 'inherit',
      },
      input: {
        height: 'auto',
        '&:focus': {
          outline: '3px solid #87B7F3',
        },
        '&:hover': {
          background: '#E8F4FF',
          border: '1px solid #0049BD',
          boxSizing: 'border-box',
          boxShadow: 'inset 0px 1px 2px rgba(0, 0, 0, 0.1)',
          borderRadius: '2px',
        },
        '&:focus:hover': {
          background: '#E8F4FF',
          border: '1px solid #0049BD',
          boxSizing: 'border-box',
          boxShadow: 'inset 0px 1px 2px rgba(0, 0, 0, 0.1)',
          borderRadius: '2px',
          outline: '3px solid #87B7F3',
        },
        '&:disabled': {
          background: '#F2F2F5',
          border: '1px solid #B4B4B8',
          boxSizing: 'border-box',
          borderRadius: '2px',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '@media(max-width: 926px)': {
          minHeight: '48px',
          maxHeight: '68px',
        },
        '&:hover $notchedOutline': {
          borderColor: '#0049BD',
        },
        borderRadius: '2px',
      },
      input: {
        '@media(max-width: 926px)': {
          padding: '14px',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        padding: 0,
        maxWidth: '320px',
      },
      touch: {
        padding: '0px',
      },
    },
    MuiButton: {
      startIcon: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiAvatar: {
      root: {
        width: 'auto',
        height: 'auto',
      },
      img: {
        width: 'auto',
        height: 'auto',
      },
    },
  },
  palette: {
    primary: {
      main: '#0049BD',
    },
    secondary: {
      main: '#00a1cf',
    },
    error: {
      main: '#ff0000',
    },
    success: {
      main: '#00ac31',
    },
    info: {
      main: '#f26700',
    },
  },
  typography: {
    fontFamily: '3MCircularTT,Arial, Helvetica, sans-serif',
    button: {
      textTransform: 'none',
      fontSize: '1.2rem',
    },
    '@media (max-width:390px)': {
      fontSize: '1.5rem',
      color: 'red',
    },
  },
  shape: {
    borderRadius: '3px',
    border: '1px',
  },
});

export default theme;
