export default {
  treatmentList: [],
  showSection: false,
  treatmentProducts: [],
  selectedProductID: null,
  selectedProductURL: '',
  selectedTreatmentcode: null,
  isFinalRecommendtation: false,
  selectAllTreatment: 'Empty',
};
