/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable array-callback-return */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';

// Material UI imports
import { Box, Button, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { ApplicationContext } from '../../store/ApplicationState';

// Component imports
import SelectedTreatmentPdf from '../../pdf';
import { createObj, SaveAssessmentDetail } from '../../utility/saveAssessment';
import { MOBILE_BREAKPOINT } from '../../utility/staticText';

const ReturnWoundRecommendation = ({
  buttonText,
  backIcon,
  printIcon,
  downloadIcon,
  pageTitle,
  pageIntroduction,
  disclaimerText,
  copyRight,
  woundCare,
}) => {
  const {
    state: {
      pageData,
      showProducts,
      isGoalPathWay,
      goalPageData,
      isAllTherapyWay,
      switchState,
      switchAllTherapy,
      treatmentPathway,
      sessionID,
    },
    dispatch,
    wndRecommState: { treatmentList, treatmentProducts },
  } = useContext(ApplicationContext);
  const theme = useTheme();
  const matchesMobileWidth = useMediaQuery(
    theme.breakpoints.down(MOBILE_BREAKPOINT)
  );
  function handleClick() {
    pageData
      .filter((x) => x.showInShell)
      .map((da) => {
        if (da.data) {
          // eslint-disable-next-line no-empty
          if (
            Object.prototype.hasOwnProperty.call(da.data, 'SOI') ||
            Object.prototype.hasOwnProperty.call(da.data, 'TSP')
          ) {
            Object.keys(da.data).forEach((key) => {
              da.data[key].map((x) => (x.showPopup = false));
            });
          } else {
            const result = Object.keys(da.data).map((key) => [da.data[key]]);
            result.map((x) => (x[0].showPopup = false));
          }
        }
      });

    dispatch({ pageData, backToRecommendation: true });
  }
  function saveExportValue(type, value) {
    const obj = [];
    let obj1 = null;
    if (type === 'Print') {
      obj1 = createObj('isExportPrint', value);
    } else if (type === 'Pdf') {
      obj1 = createObj('isExportPDF', value);
    }
    const obj2 = createObj('sessionID', sessionID);
    const obj3 = createObj(
      'treatmentPathway',
      treatmentPathway && treatmentPathway
    );
    obj.push(obj1, obj2, obj3);
    SaveAssessmentDetail(obj);
  }

  function handlePrint() {
    saveExportValue('Print', true);
    window.print();
  }
  function handlePDF() {
    saveExportValue('Pdf', true);
  }
  function getDisplayPDF(showProd) {
    return (
      <PDFDownloadLink
        document={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <SelectedTreatmentPdf
            title={pageTitle}
            woundCharacterData={isGoalPathWay ? goalPageData : pageData}
            intro={pageIntroduction}
            treatments={treatmentList}
            disclaimerText={disclaimerText}
            copyRight={copyRight}
            woundCare={woundCare}
            showProducts={!showProd}
            treatmentProducts={treatmentProducts && treatmentProducts}
            isGoalPage={isGoalPathWay}
            isAllTherapy={isAllTherapyWay}
          />
        }
        fileName="FinalizedRecommendation.pdf"
        style={{ textDecoration: 'none' }}
      >
        <Button
          color="Primary"
          className="teritaryButtonStyle"
          startIcon={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <img alt="info" src={downloadIcon} height="20px" width="20px" />
          }
          onClick={handlePDF}
        >
          <Typography
            style={{
              maxWidth: '242px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '16px',
              lineHeight: '20px',
              color: '#0049BD',
            }}
          >
            Save as PDF
          </Typography>
        </Button>
      </PDFDownloadLink>
    );
  }
  return (
    <div className="button-footer">
      <Box
        display="flex"
        p={1}
        style={{ padding: matchesMobileWidth ? '8px' : '0px' }}
        className="btnContainer mobBtnContainer mobPadding"
      >
        <Box p={1} flexGrow={1} className="btnBoxLeft">
          <NavLink
            to={isAllTherapyWay ? 'allTherapies' : '/woundRecommendation'}
            className="largeButton"
          >
            <Button
              variant="outlined"
              color="Primary"
              className="btnReturn mobBtnReturn secondaryButtonStyle"
              onClick={handleClick}
              startIcon={
                <img alt="info" src={backIcon} height="20px" width="20px" />
              }
            >
              <Typography
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  lineHeight: '20px',
                }}
              >
                {buttonText}
              </Typography>
            </Button>
          </NavLink>
        </Box>
        <Box className="btnBoxRight mobBtnBoxRight">
          <Box p={1} className="btn-box">
            {isAllTherapyWay && getDisplayPDF(switchAllTherapy)}
            {isGoalPathWay && getDisplayPDF(switchState)}
            {!isGoalPathWay && !isAllTherapyWay && getDisplayPDF(showProducts)}
          </Box>
          <Box p={1} className="btn-box">
            <Button
              color="Primary"
              className="teritaryButtonStyle"
              startIcon={
                <img alt="info" src={printIcon} height="20px" width="20px" />
              }
              onClick={handlePrint}
            >
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                  color: '#0049BD',
                }}
              >
                Print
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

ReturnWoundRecommendation.propTypes = {
  buttonText: PropTypes.string.isRequired,
  backIcon: PropTypes.string.isRequired,
  downloadIcon: PropTypes.func.isRequired,
  printIcon: PropTypes.func.isRequired,
  pageTitle: PropTypes.string.isRequired,
  pageIntroduction: PropTypes.string.isRequired,
  disclaimerText: PropTypes.string.isRequired,
  copyRight: PropTypes.string.isRequired,
  woundCare: PropTypes.string.isRequired,
};

export default ReturnWoundRecommendation;
