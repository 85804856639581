// React imports
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
// import PropTypes from 'prop-types';
// Material UI imports
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import parse from 'html-react-parser';
import useDynamicRefs from 'use-dynamic-refs';
import { ReactComponent as NavMenuBlue } from '../../assets/navmenubluesvg.svg';
import woundHealingLogo from '../../assets/myWoundHealingLogo.svg';
// api imports
import getCmsContent from '../../api/cms';
import LightTooltip from '../lightTooltip';

// fetch header contents
async function fetchHeaderContents(contentId) {
  let content = JSON.parse(localStorage.getItem(contentId));
  if (!content) {
    const body = {
      token: '',
      contentId,
    };
    content = await getCmsContent(body);
    localStorage.setItem(contentId, JSON.stringify(content));
  }
  return content;
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  bottombar: {
    position: 'static',
    height: '8px',
    background: 'linear-gradient(270deg, #00A1CF 0%, #0049BD 100%)',
    // eslint-disable-next-line no-useless-computed-key
    ['@media(max-width: 926px)']: {
      height: '4px',
    },
  },
  titleUrl: {
    width: '125px',
    height: '20px',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: '20px',
    color: '#323234',
    textDecoration: 'none',
  },
  vertical2: {
    width: '2px',
    height: '16px',
    display: 'inline-block',
    background: 'black',
  },
  helpUrl: {
    width: '35px',
    height: '20px',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: '20px',
    color: '#323234',
    textDecoration: 'none',
  },
  leftBox: {
    display: 'flex',
    alignItems: 'center',
  },
  optionDiv: {
    maxWidth: '200px',
    maxHeight: '44px',
    display: 'flex',
    alignItems: 'center',
    padding: '12px 16px',
    '&:hover': {
      backgroundColor: '#E8F4FF',
      textDecoration: 'underline',
      textDecorationSkipInk: 'none',
    },
    '&:hover a': {
      color: '#002B8C !important',
    },
  },
  optionLink: {
    textDecoration: 'none',
    color: '#323234 !important',
    fontSize: '16px',
    lineHeight: '20px',
    width: '168px',
  },
  menuDiv: {
    fontSize: '16px',
    lineHeight: '20px',
    color: '#323234',
    width: '200px',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '3.83px',
    marginBottom: '4.17px',
  },
  removePointer: {
    cursor: 'none',
  },
}));

const Header = () => {
  const classes = useStyles();
  const [appName, setAppName] = useState();
  const [logo, setLogo] = useState();
  const [helpText, setHelpText] = useState('Help');
  const [helpUrl, setHelpUrl] = useState();
  const [wcTitle, setWcTitle] = useState();
  const [wcUrl, setWcUrl] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  const [wceLabel, setWceLabel] = useState();
  const [getRef, setRef] = useDynamicRefs();

  // set tooltip text
  const setTooltipText = (
    helpTitle,
    helpUrlVal,
    woundCareUrl,
    woundCareEduLabel
  ) => (
    <div className={classes.menuDiv}>
      <div className={classes.optionDiv}>
        <a
          href={woundCareUrl}
          target="_blank"
          rel="noreferrer"
          className={classes.optionLink}
        >
          {parse(woundCareEduLabel || '')}
        </a>
      </div>
      <div className={classes.optionDiv}>
        <a
          href={helpUrl}
          target="_blank"
          rel="noreferrer"
          className={classes.optionLink}
        >
          {parse(helpTitle || '')}
        </a>
      </div>
    </div>
  );
  const tooltipTitle = setTooltipText('Help', helpUrl, wcUrl, wceLabel);

  useEffect(() => {
    fetchHeaderContents('WTGHEADER').then((contents) => {
      const {
        applicationName,
        helpURL,
        // helpTitle,
        headerImageURL,
        woundCareURL,
        woundCareTitle,
        woundCareEducationLabel,
      } = contents.item.contentDetailsLists[0].contentItems[0].headerDetails;
      setAppName(applicationName.replace(/<[^>]+>/g, ''));
      setHelpText('Help');
      setHelpUrl(helpURL);
      setWcTitle(woundCareTitle);
      setWcUrl(woundCareURL);
      setLogo(headerImageURL);
      setWceLabel(woundCareEducationLabel);
    });
  });

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  function handleClickOutsideMenu(event) {
    if (menuOpen) {
      const ref = getRef(menuOpen);
      if (ref.current && !ref.current.contains(event.target)) {
        setMenuOpen(false);
      }
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClickOutsideMenu, true);
    return () => {
      document.removeEventListener('click', handleClickOutsideMenu, true);
    };
  }, [menuOpen]);
  return (
    <div className={classes.root}>
      <AppBar className="appBar mobAppBar">
        <Toolbar disableGutters className="toolbar mobToolbar">
          <Box className={classes.leftBox}>
            {window.location.pathname === '/deleteAccount' ? (
              <div style={{ position: 'relative', right: '10px' }}>
                <img
                  className="logomwh mobLogomwh"
                  alt="logo"
                  src={woundHealingLogo}
                />
              </div>
            ) : (
              <NavLink to="/home">
                <div>
                  <img className="logo mobLogo" alt="logo" src={logo && logo} />
                </div>
              </NavLink>
            )}
            <div>
              <Box className="vertical1 mobVertical1" />
            </div>
            <div className="app-name">
              {window.location.pathname === '/deleteAccount' ? (
                <Typography variant="h6" className={`title mobTitle `}>
                  My Wound Healing
                </Typography>
              ) : (
                <NavLink to="/home" className="title mobTitle">
                  <Typography variant="h6" className="title mobTitle">
                    {appName && appName}
                  </Typography>
                </NavLink>
              )}
            </div>
          </Box>

          {window.location.pathname !== '/deleteAccount' && (
            <>
              {' '}
              <Box className="rightBox mobRightBox">
                <div className="wound-care">
                  <Box>
                    <a
                      className={classes.titleUrl}
                      href={wcUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {wcTitle}
                    </a>
                  </Box>
                </div>
                <div className="help">
                  <Box className={classes.vertical2} />
                </div>
                <div className="help">
                  <Box style={{ paddingLeft: '20px' }}>
                    <a
                      className={classes.helpUrl}
                      href={helpUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {helpText}
                    </a>
                  </Box>
                </div>
              </Box>
              <Box className="hideDiv mobMargin mobHamMenu no-disp">
                <LightTooltip
                  title={tooltipTitle}
                  ref={setRef(menuOpen)}
                  arrow
                  placement="top"
                  open={menuOpen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  disableTriggerFocus
                  onClick={handleMenuClick}
                >
                  <NavMenuBlue
                    style={{ filter: !menuOpen && 'brightness(0)' }}
                  />
                </LightTooltip>
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
      <div className={classes.bottombar} />
    </div>
  );
};
export default Header;
