// React imports
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// Material UI imports
import { Box } from '@material-ui/core';
// Component imports
import ExudateAmount from './ExudateAmount';
import ExudateType from './ExudateType';
import AlertDialog from '../../components/alertBox';
import CustomButton from '../../components/customButton';
// Utility imports
import {
  checkParameters,
  fetchSiteCoreContents,
  checkValidation,
  createBody,
  fetchWoundRules,
  checkPopup,
  capturePageData,
  capturePageDataAsync,
  captureMiniValidationData,
} from '../../utility/utils';
// // Store imports
import { ApplicationContext } from '../../store/ApplicationState';
import { createObj } from '../../utility/saveAssessment';

const WoundExudate = ({ attInfo }) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState('');
  const [popUpTitle, setPopUpTitle] = useState('');
  const [enterButtonTextValue, setEnterButtonTextValue] = useState();
  const exudateAmountAttrCode = 'EXA';
  const exudateTypeAttrCode = 'EXT';
  const {
    state: {
      activeStep,
      pageData,
      errorMessage,
      totalStepper,
      minimumValidationData,
    },
    dispatch,
    wndExudateState: {
      showExudateType,
      selectedExudateAmountParameterCode,
      selectedExudateTypeParameterCode,
    },
    wndExudateDispatch,
  } = useContext(ApplicationContext);

  const [att1, att2] = attInfo
    .sort((a, b) => a.attOrder - b.attOrder)
    .filter((att) => att.pageCode === 'WDEXD');

  // site core content
  fetchSiteCoreContents(att1.contentID).then((typeContent) => {
    const {
      enterButtonText,
    } = typeContent.item.contentDetailsLists[0].contentItems[0].woundCharacteristics;
    setEnterButtonTextValue(enterButtonText);
  });

  function validateNextButton() {
    if (activeStep + 1 === totalStepper) {
      dispatch({ showNextButton: false });
    }
  }
  function handleExudateAmountChange(event, exudateAmountList, attributeName) {
    wndExudateDispatch({
      selectedExudateAmountParameterCode: event.target.value,
    });
    wndExudateDispatch({
      selectedExudateTypeParameterCode:
        event.target.value !== 'NONE' ? '' : null,
    });
    wndExudateDispatch({
      showExudateType: event.target.value !== 'NONE',
    });
    const selectedData = exudateAmountList.filter(
      (x) => x.parameterCode === event.target.value
    )[0];
    if (Object.prototype.hasOwnProperty.call(pageData[activeStep], 'data')) {
      delete pageData[activeStep].data;
    }
    const saveObj = [];
    if (event.target.value === 'NONE') {
      saveObj.push(createObj('ExudateTypeCode', ''));
    }
    saveObj.push(createObj('ExudateAmtCode', event.target.value));
    const buildDisplayText = `${selectedData.parameterDesc} amount`;
    const updatedPageData = capturePageData(
      att1.attCode,
      event.target.value,
      selectedData,
      pageData,
      attributeName,
      activeStep,
      buildDisplayText,
      saveObj
    );
    dispatch({ pageData: updatedPageData });
    let updateMinimumValidationData = [];
    updateMinimumValidationData = captureMiniValidationData(
      att1.attCode,
      minimumValidationData,
      true
    );
    dispatch({ updateMinimumValidationData });
    if (att2) {
      updateMinimumValidationData = captureMiniValidationData(
        att2.attCode,
        minimumValidationData,
        false
      );
      dispatch({ updateMinimumValidationData });
    }
  }
  const handlePopUpClose = () => {
    setPopUpOpen(false);
  };

  async function handleExudateTypeChange(
    event,
    exudateTypeList,
    attributeName
  ) {
    const selecetedData = exudateTypeList.filter(
      (x) => x.parameterCode === event.target.value
    )[0];
    const attributeCode = att2 === undefined ? att1.attCode : att2.attCode;
    if (selecetedData.hasRules) {
      const rules = await fetchWoundRules(event.target.value);
      const isPopUp = await checkPopup(rules);
      if (isPopUp[0]) {
        setPopUpOpen(true);
        setPopUpMessage(isPopUp[1]);
        setPopUpTitle(isPopUp[2]);
      }
    }
    wndExudateDispatch({
      selectedExudateTypeParameterCode: event.target.value,
    });
    const saveObj = [];
    saveObj.push(createObj('ExudateTypeCode', event.target.value));
    const updatedPageData = await capturePageDataAsync(
      attributeCode,
      event.target.value,
      selecetedData,
      pageData,
      attributeName,
      activeStep,
      selecetedData.parameterDesc,
      saveObj
    );
    dispatch({ pageData: updatedPageData });

    const updateMinimumValidationData = captureMiniValidationData(
      attributeCode,
      minimumValidationData,
      false
    );
    dispatch({ updateMinimumValidationData });
  }

  const handleClose = () => {
    setAlertOpen(false);
  };

  // handle submit
  function handleSubmit() {
    const validateBody = [];
    const body1 = createBody(selectedExudateAmountParameterCode, 'WDEXD');
    const body2 = createBody(selectedExudateTypeParameterCode, 'WDEXD');
    if (att1.attCode === exudateAmountAttrCode) {
      validateBody.push(body1);
    }

    if (
      selectedExudateAmountParameterCode !== 'NONE' &&
      selectedExudateAmountParameterCode
    ) {
      if (selectedExudateTypeParameterCode) {
        validateBody.push(body2);
      }
    }
    const isValid = checkValidation(validateBody);
    if (isValid) {
      if (pageData[activeStep + 1].enabled) {
        if (activeStep < totalStepper) {
          dispatch({ activeStep: activeStep + 1 });
          if (totalStepper !== activeStep + 1) {
            pageData[activeStep + 1].isActiveStep = true;
          }
        }
        if (activeStep === totalStepper) {
          dispatch({ lastPage: activeStep });
        }
        validateNextButton();
        dispatch({ previousPage: activeStep });
      }
    } else {
      setAlertOpen(true);
    }
  }

  useEffect(() => {
    const validateToggle = () => {
      pageData.forEach((item, idx) => {
        if (idx === activeStep) {
          if (att2 === undefined && att1.attCode === exudateTypeAttrCode) {
            pageData[idx].status = checkParameters(
              [selectedExudateTypeParameterCode],
              pageData[idx].isActiveStep || pageData[activeStep].firstPage
            );
          } else if (
            att2 === undefined &&
            att1.attCode === exudateAmountAttrCode
          ) {
            pageData[idx].status = checkParameters(
              [selectedExudateAmountParameterCode],
              pageData[idx].isActiveStep || pageData[activeStep].firstPage
            );
          } else {
            pageData[idx].status = checkParameters(
              [selectedExudateAmountParameterCode],
              pageData[idx].isActiveStep || pageData[activeStep].firstPage
            );
          }

          dispatch({ pageData });
        }
      });
    };
    validateToggle();
  }, [
    activeStep,
    dispatch,
    pageData,
    selectedExudateAmountParameterCode,
    selectedExudateTypeParameterCode,
  ]);

  return (
    <Box className="attributeContainer mobAttributeContainer">
      {att1.attCode === exudateAmountAttrCode && (
        <ExudateAmount
          contentID={att1.contentID}
          attCode={att1.attCode}
          selectedParameterCode={selectedExudateAmountParameterCode}
          handleRadioChange={handleExudateAmountChange}
        />
      )}

      {showExudateType && att2 && (
        <ExudateType
          contentID={att2.contentID}
          attCode={att2.attCode}
          selectedParameterCode={selectedExudateTypeParameterCode}
          handleRadioChange={handleExudateTypeChange}
        />
      )}
      {att1.attCode === exudateTypeAttrCode && (
        <ExudateType
          contentID={att1.contentID}
          attCode={att1.attCode}
          selectedParameterCode={selectedExudateTypeParameterCode}
          handleRadioChange={handleExudateTypeChange}
        />
      )}
      <CustomButton
        value={enterButtonTextValue}
        handleSubmit={handleSubmit}
        width="100px"
      />
      <AlertDialog
        isOpen={alertOpen}
        handleClose={handleClose}
        popUpPositiveButton={handleClose}
        title="Notification"
      >
        {errorMessage}
      </AlertDialog>
      <AlertDialog
        isOpen={popUpOpen}
        handleClose={handlePopUpClose}
        popUpPositiveButton={handlePopUpClose}
        title={popUpTitle}
        buttonName="Got it"
      >
        {popUpMessage}
      </AlertDialog>
    </Box>
  );
};

WoundExudate.propTypes = {
  attInfo: PropTypes.arrayOf(
    PropTypes.shape({
      attCode: PropTypes.string,
      attOrder: PropTypes.number,
      pageCode: PropTypes.string,
      contentID: PropTypes.string,
    })
  ).isRequired,
};

export default WoundExudate;
