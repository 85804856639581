/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
// Material UI imports
import {
  Box,
  Typography,
  makeStyles,
  FormControlLabel,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
// Utility imports
import CustomLabel from '../customLabel/CustomLabel';
import { ApplicationContext } from '../../store/ApplicationState';
import {
  RELATED_PRODUCTS,
  SELECTED_PRODUCTS,
  MOBILE_BREAKPOINT,
} from '../../utility/staticText';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  typoStyle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '20px',
    marginTop: '12px',
  },
  boxStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '12px',
    '@media(max-width: 926px)': {
      marginTop: '8px',
    },
  },
  secondaryBoxStyle: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '120px',
    alignItems: 'center',
    justifyContent: 'center',
  },

  checkboxStyle: {
    background: '#ffffff',
    minWidth: '22px',
    minHeight: '22px',
    border: '1px solid #B4B4B8',
    borderRadius: '1px',
    padding: '0',
  },
  prodImgStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '64px',
    height: '64px',
    background: '#F8F8FA',
    marginLeft: '20px',
    objectFit: 'cover',
  },
  prodImgStyle1: {
    width: '44.8px',
    height: '44.54px',
    objectFit: 'cover',
  },
}));

const Products = ({
  treatmentCode,
  isFinalRecomTreatments,
  productDetails,
  handleProductSelection,
}) => {
  const theme = useTheme();
  const matchesMobileWidth = useMediaQuery(
    theme.breakpoints.down(MOBILE_BREAKPOINT)
  );
  const classes = useStyles();
  const history = useHistory();
  const { wndRecommDispatch } = useContext(ApplicationContext);

  const clickOfProduct = (productID, productURL, trCode) => {
    wndRecommDispatch({
      selectedProductID: productID,
      selectedProductURL: productURL,
      selectedTreatmentcode: trCode,
    });
    history.push('/productDetail');
  };

  useEffect(() => {
    wndRecommDispatch({
      isFinalRecommendtation: isFinalRecomTreatments,
    });
  }, []);

  function getDisplayText(prod) {
    return (
      <Box className={classes.boxStyle}>
        {!isFinalRecomTreatments && (
          <Box className={classes.secondaryBoxStyle}>
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <input
                  type="checkbox"
                  className="chkbox mobChkbox trtCheckbox mobPrdCheckbox"
                  checked={prod.selectedProduct}
                  onClick={(e) =>
                    handleProductSelection(e, treatmentCode, prod)
                  }
                />
              }
            />
          </Box>
        )}

        <Box key={prod.productCode} className={classes.prodImgStyle}>
          <img
            alt="treatment"
            src={prod.productThumbnail}
            className={classes.prodImgStyle1}
          />
        </Box>
        <CustomLabel
          labelStyle={{
            cursor: 'pointer',
            lineHeight: '18px',
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#0049BD',
            paddingLeft: '8px',
            maxWidth: matchesMobileWidth ? 'auto' : '100%',
          }}
          value={prod.productDesc}
          onClick={() =>
            clickOfProduct(prod.productID, prod.productURL, prod.treatmentCode)
          }
        />
      </Box>
    );
  }

  function getHeaderDisplayText(headerName) {
    return (
      <Box style={{ display: 'flex' }}>
        <Typography className={classes.typoStyle}>{headerName}</Typography>
      </Box>
    );
  }
  function getProducts(isFinalRecom, prodDetails) {
    return isFinalRecom && prodDetails && prodDetails.length > 0;
  }

  return (
    <Box className={classes.container}>
      {getProducts(isFinalRecomTreatments, productDetails) &&
        productDetails.filter((x) => x.selectedProduct).length > 0 &&
        getHeaderDisplayText(SELECTED_PRODUCTS)}
      {getProducts(isFinalRecomTreatments, productDetails) &&
        productDetails.filter((x) => x.selectedProduct).length > 0 &&
        productDetails
          .filter((x) => x.selectedProduct)
          .map((prod) => getDisplayText(prod))}
      {getProducts(!isFinalRecomTreatments, productDetails) &&
        getHeaderDisplayText(RELATED_PRODUCTS)}
      {getProducts(!isFinalRecomTreatments, productDetails) &&
        productDetails.map((prod) => getDisplayText(prod))}
      {getProducts(isFinalRecomTreatments, productDetails) &&
        productDetails.filter((x) => !x.selectedProduct).length > 0 &&
        getHeaderDisplayText(RELATED_PRODUCTS)}
      {getProducts(isFinalRecomTreatments, productDetails) &&
        productDetails
          .filter((x) => !x.selectedProduct)
          .map((prod) => getDisplayText(prod))}
    </Box>
  );
};

Products.propTypes = {
  treatmentCode: PropTypes.string.isRequired,
  isFinalRecomTreatments: PropTypes.bool.isRequired,
  productDetails: PropTypes.string.isRequired,
  handleProductSelection: PropTypes.func.isRequired,
};

export default Products;
