import React from 'react';
import { uniq } from 'underscore';
import PropTypes from 'prop-types';
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';
import parse from 'html-react-parser';
import Html from 'react-pdf-html';
import { RELATED_PRODUCTS, SELECTED_PRODUCTS } from '../utility/staticText';

Font.register({
  family: '3MCircularTT',
  fonts: [
    { src: '/3MCircularTT-Regular.ttf' },
    { src: '/3MCircularTT-Italic.ttf', fontStyle: 'italic' },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: '3MCircularTT',
  },
  title: {
    fontSize: '10px',
  },
  shellStyle: {
    padding: '32px 48px 35px 20px',
    backgroundColor: '#f8f8fa',
    maxWidth: '100%',
    minHeight: '300px',
  },
  flexBoxGoal: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    maxHeight: '280px',
    width: '100%',
  },
  flexForRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    borderTop: '1px solid #ccccd0',
    padding: '16px 0',
  },
  flexForRowGoal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
  },
  flexForColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  section: {
    flexDirection: 'row',
  },
  sectionName: {
    flexDirection: 'row',
    fontWeight: 'bold',
    fontSize: '20px',
    marginBottom: 10,
  },
  header: {
    fontSize: 30,
    marginBottom: 20,
    fontFamily: '3MCircularTT',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  introStyle: {
    fontWeight: 'bold',
    fontSize: 10,
    marginBottom: 20,
    fontFamily: '3MCircularTT',
  },
  secDescriptor: {
    fontSize: '10px',
    marginBottom: '20px',
  },
});

const SelectedTreatmentPdf = ({
  title,
  treatments,
  disclaimerText,
  intro,
  copyRight,
  woundCare,
  showProducts,
  woundCharacterData,
  isGoalPage,
  isAllTherapy,
}) => {
  let sections = [];

  const getUniqSections = () => {
    sections = uniq(
      treatments.filter((x) => x.selected),
      true,
      (x) => x.sectionCode
    );
    sections = sections.filter((x) => x.sectionName);
  };

  function getDisplayText(data) {
    let returnDisplayTextValue = null;
    if (data) {
      if (
        Object.prototype.hasOwnProperty.call(data, 'SOI') ||
        Object.prototype.hasOwnProperty.call(data, 'TSP') ||
        Object.prototype.hasOwnProperty.call(data, 'WOE')
      ) {
        Object.keys(data).forEach((key) => {
          returnDisplayTextValue = data[key].map((displayData) => (
            <View
              style={{ flexDirection: 'row', justifyContent: 'flex-end' }}
              wrap
            >
              <Text
                style={{
                  width: '100%',
                  textAlign: 'right',
                  fontSize: '10px',
                  fontWeight: 'bold',
                  lineHeight: '1px',
                  color: '#000',
                  paddingVertical: '2',
                }}
                wrap
              >
                {displayData.displayText}
              </Text>
              {displayData.hasRule && (
                <Image
                  alt="info"
                  src="\altertIcon.png"
                  style={{
                    width: '15px',
                    height: '15px',
                  }}
                />
              )}
            </View>
          ));
        });
        return returnDisplayTextValue;
      }
      const result = Object.keys(data).map((key) => [data[key]]);
      returnDisplayTextValue = result.map((lineGroup) => (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}
        >
          <Text
            style={{
              width: '80%',
              textAlign: 'right',
              fontSize: '10px',
              fontWeight: 'bold',
              lineHeight: '1.5px',
              color: '#000',
              paddingVertical: '2',
            }}
          >
            {lineGroup[0].displayText}
          </Text>
          {lineGroup[0].hasRule && (
            <Image
              alt="info"
              src="\altertIcon.png"
              style={{
                width: '15px',
                height: '15px',
              }}
            />
          )}
        </View>
      ));
      return returnDisplayTextValue;
    }
    return (
      <div style={{ width: '100%' }}>
        <Text
          style={{
            textAlign: 'right',
            fontSize: '10px',
            fontWeight: 'bold',
            lineHeight: '1.5px',
            color: '#000',
          }}
        >
          Blank
        </Text>
      </div>
    );
  }
  function returnShellBlockGoalJSX(label) {
    let returnDisplayTextValue = null;
    if (label.data) {
      const result = Object.keys(label.data).map((key) => [label.data[key]]);
      returnDisplayTextValue = result.map((displayData, idx) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '50%',
            justifyContent: 'space-evenly',
          }}
        >
          <Text
            style={
              idx === 0
                ? {
                    fontSize: '10px',
                    marginRight: '15px',
                    color: '#656569',
                    fontWeight: '400',
                  }
                : {
                    fontSize: '10px',
                    marginLeft: '55px',
                    color: '#656569',
                    fontWeight: '400',
                  }
            }
          >
            {displayData[0].attributeName}
          </Text>
          <Text
            style={{
              fontSize: '10px',
              maxWidth: '230px',
              fontWeight: '700',
              lineHeight: '1.5px',
              color: '#000',
            }}
          >
            {displayData[0].displayText}
          </Text>
        </div>
      ));

      return returnDisplayTextValue;
    }
    return returnDisplayTextValue;
  }
  function getProductHeaderDisplayText(headerName) {
    return (
      <Text
        style={{
          fontSize: '16px',
          fontWeight: 'bold',
          paddingTop: '10px',
        }}
      >
        {headerName}
      </Text>
    );
  }

  function getProductDisplayText(prod) {
    return (
      <View
        style={{
          flexDirection: 'row',
          display: 'flex',
          alignContent: 'left',
          justifyContent: 'left',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '64px',
            maxHeight: '64px',
            background: '#F8F8FA',
            padding: '9px',
            margin: '0',
          }}
        >
          <Image
            style={{
              maxWidth: '42px',
              maxHeight: '40px',
            }}
            src={prod.productImageBase64}
          />
        </View>
        <Text
          style={{
            fontSize: '12px',
            padding: '1px',
            color: '#0049BD',
          }}
        >
          {prod.productDesc}
        </Text>
      </View>
    );
  }
  const getPdfShellHeight = () => {
    let shellHeight = '280px';
    if (woundCharacterData && woundCharacterData.length > 0) {
      const soiData = woundCharacterData.filter((x) => {
        let retVal = false;
        if (x.data) {
          retVal = Object.prototype.hasOwnProperty.call(x.data, 'SOI');
        }
        return retVal;
      });
      const edgesData = woundCharacterData.filter((x) => {
        let retVal = false;
        if (x.data) {
          retVal = Object.prototype.hasOwnProperty.call(x.data, 'WOE');
        }
        return retVal;
      });
      const tspData = woundCharacterData.filter((x) => {
        let retVal = false;
        if (x.data) {
          retVal = Object.prototype.hasOwnProperty.call(x.data, 'TSP');
        }
        return retVal;
      });
      if (
        (soiData.length > 0 && soiData[0].data.SOI.length > 3) ||
        (edgesData.length > 0 && edgesData[0].data.WOE.length > 3) ||
        (tspData.length > 0 && tspData[0].data.TSP.length > 3)
      ) {
        shellHeight = '315px';
        if (
          soiData[0].data.SOI.length +
            edgesData[0].data.WOE.length +
            tspData[0].data.TSP.length >
          19
        ) {
          shellHeight = '380px';
        }
      }
    }

    return shellHeight;
  };
  getUniqSections();
  return (
    <Document>
      <Page style={styles.body}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '20px',
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Image
              src="/3mlogo.png"
              style={{
                height: '15px',
                width: '25px',
              }}
            />
            <div
              style={{
                backgroundColor: '#B4B4B8',
                height: '15px',
                width: '1px',
                margin: '0 10px 0 10px',
              }}
            />
            <Text
              style={{
                fontSize: '15px',
                fontWeight: 'bold',
              }}
            >
              Wound Therapy Guide
            </Text>
            <Text style={{ left: '250px', fontSize: '8px' }}>{woundCare}</Text>
          </View>
        </View>
        <div
          style={{
            height: '5px',
            backgroundColor: '#0049BD',
            width: '100%',
            marginBottom: '30px',
          }}
        />
        <Text style={styles.header}>{parse(title || '')}</Text>
        <Text style={{ fontSize: '10px', marginBottom: '20px' }}>
          {parse(intro || '')}
        </Text>
        {isGoalPage && (
          <div
            style={{
              padding: '20px 20px 35px 20px',
              backgroundColor: '#fff',
              maxWidth: '100%',
              minHeight: '100px',
            }}
          >
            <Text style={{ fontSize: '15px', fontWeight: 'bold' }}>
              Wound Characterstics
            </Text>
            <div
              style={{
                marginTop: '30px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
              }}
            >
              {woundCharacterData
                .filter((x) => x.showInShell)
                .map((label, idx) => returnShellBlockGoalJSX(label, idx))}
            </div>
            <hr
              style={{
                border: '0.5px solid #C4C4C4',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            />
          </div>
        )}
        {!isGoalPage && !isAllTherapy && (
          <div style={styles.shellStyle}>
            <div>
              <div>
                <Text style={{ fontSize: '15px', fontWeight: 'bold' }}>
                  Wound characteristics
                </Text>
              </div>
              <div
                style={{
                  marginTop: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  maxHeight: getPdfShellHeight(),
                  width: '100%',
                }}
              >
                {woundCharacterData
                  .filter((x) => x.showInShell)
                  .map((label) => (
                    <div style={{ width: '33%', marginRight: '10px' }}>
                      <div style={styles.flexForRow}>
                        <div>
                          <Text
                            style={{
                              fontSize: '10px',
                              color: '#76767A',
                            }}
                          >
                            {label.pageTitle}
                          </Text>
                        </div>
                        <div style={styles.flexForColumn}>
                          {getDisplayText(label.data)}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
        {sections &&
          sections.length > 0 &&
          sections
            .sort((a, b) => a.sectionOrder - b.sectionOrder)
            .map((sect) => (
              <View>
                {treatments.filter(
                  (t) => t.sectionCode === sect.sectionCode && t.isActive === 1
                ).length > 0 && (
                  <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <View style={styles.section}>
                      <Text style={styles.sectionName}>
                        {`${sect.sectionName}`}
                      </Text>
                    </View>
                    <Text>
                      <Html style={styles.secDescriptor} collapse={false}>
                        {sect.sectionDescription}
                      </Html>
                    </Text>
                  </div>
                )}
                {treatments &&
                  treatments.length > 0 &&
                  treatments
                    .filter(
                      (trt) =>
                        trt.selected &&
                        trt.sectionCode === sect.sectionCode &&
                        trt.isActive === 1
                    )
                    .map((slctdTrt) => (
                      <View
                        style={{
                          marginBottom: '20px',
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                        wrap={false}
                      >
                        <View
                          style={{
                            display: 'flex',
                            justifyContent: 'left',
                            flexDirection: 'row',
                          }}
                        >
                          <View
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                              backgroundColor: '#F8F8FA',
                              maxWidth: '100px',
                              maxHeight: '100px',
                              padding: '0 5px',
                              margin: '0 5px',
                            }}
                          >
                            {slctdTrt.treatmentImageBase64 !== '' && (
                              <Image
                                style={{
                                  width: '84px',
                                  height: '83.52px',
                                  objectFit: 'cover',
                                }}
                                src={slctdTrt.treatmentImageBase64}
                              />
                            )}
                          </View>
                          <View style={{ padding: '0 100px 10px 10px' }}>
                            <Text
                              style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                marginBottom: '6px',
                              }}
                            >
                              {`${slctdTrt.treatmentDescription}`}
                            </Text>
                            <Text>
                              <Html
                                style={{
                                  fontSize: '10px',
                                }}
                                collapse={false}
                              >
                                {slctdTrt.treatmentDescriptor}
                              </Html>
                            </Text>
                            {showProducts &&
                              slctdTrt &&
                              slctdTrt.relatedProducts.length > 0 &&
                              slctdTrt.relatedProducts.filter(
                                (prod) => prod.selectedProduct
                              ).length > 0 &&
                              getProductHeaderDisplayText(SELECTED_PRODUCTS)}
                            {showProducts &&
                              slctdTrt &&
                              slctdTrt.relatedProducts.length > 0 &&
                              slctdTrt.relatedProducts
                                .filter((x) => x.selectedProduct)
                                .map((prod) => getProductDisplayText(prod))}
                            {showProducts &&
                              slctdTrt &&
                              slctdTrt.relatedProducts.length > 0 &&
                              slctdTrt.relatedProducts.filter(
                                (prod) => !prod.selectedProduct
                              ).length > 0 &&
                              getProductHeaderDisplayText(RELATED_PRODUCTS)}

                            {showProducts &&
                              slctdTrt &&
                              slctdTrt.relatedProducts.length > 0 &&
                              slctdTrt.relatedProducts
                                .filter((x) => !x.selectedProduct)
                                .map((prod) => getProductDisplayText(prod))}
                          </View>
                        </View>
                      </View>
                    ))}
              </View>
            ))}
        <View wrap={false} style={{ marginTop: '20px' }}>
          <View>
            <Text
              style={{
                fontSize: 8,
                fontWeight: 'bold',
              }}
            >
              {parse(disclaimerText || '')}
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 30,
            }}
          >
            <Image
              src="/3mlogo.png"
              style={{
                float: 'left',
                height: '10px',
                width: '25px',
              }}
            />
            <Text
              style={{
                left: 40,
                fontSize: 8,
              }}
            >
              {copyRight}
            </Text>
            <Text
              style={{
                left: 310,
                fontSize: 8,
              }}
            >
              {woundCare}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

SelectedTreatmentPdf.propTypes = {
  title: PropTypes.string.isRequired,
  treatments: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  woundCharacterData: PropTypes.objectOf(PropTypes.string.isRequired)
    .isRequired,
  intro: PropTypes.string.isRequired,
  disclaimerText: PropTypes.string.isRequired,
  copyRight: PropTypes.string.isRequired,
  woundCare: PropTypes.string.isRequired,
  showProducts: PropTypes.bool.isRequired,
  isGoalPage: PropTypes.bool.isRequired,
  isAllTherapy: PropTypes.bool.isRequired,
};

export default SelectedTreatmentPdf;
