import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

// Material UI imports
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
// Utility imports
import CustomLabel from '../components/customLabel/CustomLabel';
import { ApplicationContext } from '../store/ApplicationState';
import { MOBILE_BREAKPOINT } from '../utility/staticText';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    maxWidth: '700px',
    margin: '56px 0',
  },
  shellStyle: {
    display: 'flex',
    flexDirection: 'column',
    padding: '32px',
    backgroundColor: '#F8F8FA',
    boxShadow:
      '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12)',
    minHeight: 'auto',
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '64px',
    maxHeight: '64px',
    background: '#fff',
    padding: '19.2px',
  },
}));
async function getLinkedProducts(reqBody) {
  const url = `${process.env.REACT_APP_API_HOST}api/woundAlgorithm/getProductsLinkedWithTreatments`;
  try {
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        ClientID: process.env.REACT_APP_CLIENTID,
      },
      body: reqBody,
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return new Error('Error while fetching products!');
  }
}
const RelatedProduct = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMobileWidth = useMediaQuery(
    theme.breakpoints.down(MOBILE_BREAKPOINT)
  );
  const history = useHistory();
  const [products, setProducts] = useState([]);
  const {
    wndRecommState: {
      selectedTreatmentcode,
      selectedProductURL,
      selectedProductID,
    },
    wndRecommDispatch,
  } = useContext(ApplicationContext);

  const clickOfProduct = (productID, productURL) => {
    wndRecommDispatch({
      selectedProductID: productID,
      selectedProductURL: productURL,
    });
    history.push('/productDetail');
  };
  function createBody(tCode) {
    const body = {
      appKey: 'WTG',
      treatmentCodes: [{ treatmentCode: tCode }],
    };
    return JSON.stringify(body);
  }

  useEffect(() => {
    const body = createBody(selectedTreatmentcode);
    getLinkedProducts(body).then((x) => {
      const sa = x.item.products.filter(
        (y) => y.productID !== selectedProductID
      );
      setProducts(sa);
    });
  }, [selectedProductID, selectedProductURL, selectedTreatmentcode]);

  return (
    <Box className={classes.container}>
      {products && products.length > 0 && (
        <Box className={classes.shellStyle}>
          <Box style={{ display: 'flex' }}>
            <Typography
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: matchesMobileWidth ? '20px' : '24px',
                lineHeight: '28px',
                padding: '16px',
                color: '#323234',
                paddingBottom: '16px',
              }}
            >
              Related 3M wound therapy products
            </Typography>
          </Box>
          <Grid
            style={{
              display: 'flex',
              flexDirection: matchesMobileWidth ? 'column' : 'row',
              marginLeft: '20px',
            }}
            container
          >
            {products &&
              products.length > 0 &&
              products.map((prod) => (
                <Grid
                  item
                  xs={matchesMobileWidth ? 12 : 6}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingBottom: '8px',
                  }}
                >
                  <Box
                    key={prod.productCode}
                    className={classes.imageContainer}
                  >
                    <img
                      alt="treatment"
                      src={prod.productThumbnail}
                      style={{
                        maxWidth: '44.8px',
                        maxHeight: '44.5px',
                      }}
                    />
                  </Box>
                  <CustomLabel
                    labelStyle={{
                      cursor: 'pointer',
                      lineHeight: '18px',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      color: '#0049BD',
                      paddingLeft: '8px',
                      'word-break': 'break-word',
                    }}
                    value={prod.productDesc}
                    onClick={
                      () => clickOfProduct(prod.productID, prod.productURL)
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default RelatedProduct;
