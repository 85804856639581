import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { has, isArray } from 'underscore';
import parse from 'html-react-parser';
// Material UI imports
import { Box, Typography } from '@material-ui/core';
// component imports
import Treatments from './Treatments';
// Store imports
import { ApplicationContext } from '../../store/ApplicationState';
import useFullPageLoader from '../../hooks/useFullPageLoader';
import { CHECKBOX_STATES_CHECKED } from '../../utility/staticText';

async function getTreatmentsBySection(reqBody) {
  const url = `${process.env.REACT_APP_API_HOST}api/woundAlgorithm/getRecommendationsBySection`;
  try {
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        ClientID: process.env.REACT_APP_CLIENTID,
      },
      body: reqBody,
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return new Error('Error while fetching Treatment Recommendations!');
  }
}

async function getAllTreatmentsBySection(sectionCode) {
  const url = `${process.env.REACT_APP_API_HOST}api/woundAlgorithm/getAllTreatments`;
  try {
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        ClientID: process.env.REACT_APP_CLIENTID,
      },
      body: sectionCode,
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return new Error('Error while fetching Treatment Recommendations!');
  }
}

async function getLinkedProducts(reqBody) {
  const url = `${process.env.REACT_APP_API_HOST}api/woundAlgorithm/getProductsLinkedWithTreatments`;
  try {
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        ClientID: process.env.REACT_APP_CLIENTID,
      },
      body: reqBody,
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return new Error('Error while fetching products!');
  }
}

function createRequestBody(tCode) {
  const body = {
    appKey: 'WTG',
    treatmentCodes: [{ treatmentCode: tCode }],
  };
  return JSON.stringify(body);
}

function createBody(appData, sectionCode) {
  const hasData = appData.filter((x) => has(x, 'data'));
  const paramCodes = [];
  hasData.forEach((dat) => {
    const { data } = dat;
    Object.keys(data).forEach((x) => {
      const valueArr = [];
      if (isArray(data[x])) {
        data[x].forEach((param) => {
          valueArr.push(param.parameterCode);
        });
      } else {
        valueArr.push(data[x].parameterCode);
      }
      if (valueArr.filter((y) => y !== '').length > 0) {
        paramCodes.push({ key: x, value: valueArr });
      }
    });
  });
  const body = {
    appKey: 'WTG',
    paramCodes,
    sectionCode,
  };
  return body;
}

const TreatmentSection = ({ section, isFinalRecommSec, pathway }) => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [showSection, setShowSection] = useState(false);
  const [promiseState, setPromiseState] = useState(false);
  const {
    state: {
      pageData,
      backToRecommendation,
      goalPageData,
      isGoalPathWay,
      isAllTherapyWay,
    },
    dispatch,
    wndRecommState: { treatmentList },
    wndRecommDispatch,
  } = useContext(ApplicationContext);

  useEffect(() => {
    if (isFinalRecommSec !== true && !backToRecommendation) {
      showLoader();
      let body = '';
      if (isGoalPathWay) {
        body = createBody(goalPageData, section.sectionCode);
      } else {
        body = createBody(pageData, section.sectionCode);
      }
      if (isAllTherapyWay) {
        if (treatmentList.length === 0) {
          const body1 = { sectionCode: section.sectionCode };
          getAllTreatmentsBySection(JSON.stringify(body1)).then((recomm) => {
            if (recomm.item.treatments && recomm.item.treatments.length > 0) {
              const updatedValue = recomm.item.treatments.map((x) => ({
                ...x,
                selected: true,
                sectionName: section.sectionName,
                sectionDescription: section.sectionDescription,
                sectionOrder: section.sectionOrder,
                relatedProducts: null,
                imgUp: false,
              }));

              updatedValue.forEach(async (element, idx) => {
                const reqBody = createRequestBody(element.treatmentCode);
                const linkedProd = await getLinkedProducts(reqBody);
                const productValue = linkedProd.item.products.map((item) => ({
                  ...item,
                  selectedProduct: true,
                }));
                Object.assign(element, { relatedProducts: productValue });
                if (updatedValue.length - 1 === idx) {
                  setPromiseState(true);
                  hideLoader();
                }
              });
              treatmentList.push(...updatedValue);
              wndRecommDispatch({
                treatmentList,
                selectAllTreatment: CHECKBOX_STATES_CHECKED,
              });
              if (
                treatmentList.filter(
                  (t) =>
                    t.sectionCode === section.sectionCode && t.isActive === 1
                ).length > 0
              ) {
                setShowSection(true);
              } else {
                setShowSection(false);
              }
            } else {
              hideLoader();
            }
          });
        } else {
          setPromiseState(true);
          hideLoader();
        }
      } else if (treatmentList.length === 0) {
        getTreatmentsBySection(JSON.stringify(body)).then((recomm) => {
          if (
            recomm.item.treatments &&
            recomm.item.treatments.length > 0 &&
            recomm.item.treatments !== undefined
          ) {
            const updatedValue = recomm.item.treatments.map((x) => ({
              ...x,
              selected: true,
              sectionName: section.sectionName,
              sectionDescription: section.sectionDescription,
              sectionOrder: section.sectionOrder,
              relatedProducts: null,
            }));
            updatedValue.forEach(async (element, idx) => {
              const reqBody = createRequestBody(element.treatmentCode);
              const linkedProd = await getLinkedProducts(reqBody);
              const productValue = linkedProd.item.products.map((item) => ({
                ...item,
                selectedProduct: true,
              }));
              Object.assign(element, { relatedProducts: productValue });
              if (updatedValue.length - 1 === idx) {
                setPromiseState(true);
                hideLoader();
              }
            });
            treatmentList.push(...updatedValue);
            wndRecommDispatch({
              treatmentList,
              selectAllTreatment: CHECKBOX_STATES_CHECKED,
            });

            if (
              treatmentList.filter(
                (t) => t.sectionCode === section.sectionCode && t.isActive === 1
              ).length > 0
            ) {
              setShowSection(true);
            } else {
              setShowSection(false);
            }
          } else {
            hideLoader();
          }
        });
      } else {
        setPromiseState(true);
        hideLoader();
      }
    }

    if (
      backToRecommendation &&
      treatmentList.filter((x) => x.sectionCode === section.sectionCode)
        .length > 0
    ) {
      setPromiseState(true);
      hideLoader();
      if (
        treatmentList.filter(
          (t) => t.sectionCode === section.sectionCode && t.isActive === 1
        ).length > 0
      ) {
        setShowSection(true);
      } else {
        setShowSection(false);
      }
      dispatch({
        backToRecommendation: false,
      });
    }
    if (
      isFinalRecommSec === true &&
      treatmentList.filter(
        (x) => x.sectionCode === section.sectionCode && x.selected === true
      ).length > 0 &&
      !backToRecommendation
    ) {
      setPromiseState(true);
      hideLoader();
      if (
        treatmentList.filter(
          (t) => t.sectionCode === section.sectionCode && t.isActive === 1
        ).length > 0
      ) {
        setShowSection(true);
      } else {
        setShowSection(false);
      }
    }
  }, []);
  const changeArrowIcon = (ix, secCode) => {
    if (!isFinalRecommSec) {
      treatmentList.filter(
        (x) => x.sectionCode === secCode && x.isActive !== 0
      )[ix].imgUp = !treatmentList.filter(
        (x) => x.sectionCode === secCode && x.isActive !== 0
      )[ix].imgUp;
      wndRecommDispatch({
        treatmentList,
      });
    } else {
      treatmentList.filter(
        (x) => x.sectionCode === secCode && x.isActive !== 0 && x.selected
      )[ix].imgUp = !treatmentList.filter(
        (x) => x.sectionCode === secCode && x.isActive !== 0 && x.selected
      )[ix].imgUp;
      wndRecommDispatch({
        treatmentList,
      });
    }
  };
  return (
    <Box className="sectionContainer mobSectionContainer">
      {loader}
      {promiseState && (
        <>
          {showSection && (
            <>
              <Box className="section mobSection">
                <Typography className="sectionName mobSectionName">
                  {section.sectionName}
                </Typography>
              </Box>
              <Box className="sectionDesc hide print-unhide">
                <Typography
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#323234',
                  }}
                >
                  {parse(section.sectionDescription)}
                </Typography>
              </Box>
            </>
          )}

          {isFinalRecommSec &&
            treatmentList &&
            treatmentList.length > 0 &&
            treatmentList
              .filter(
                (x) =>
                  x.sectionCode === section.sectionCode &&
                  x.selected === true &&
                  x.isActive === 1
              )
              .map((recomm, idx) => (
                <Treatments
                  recommendation={recomm}
                  index={idx}
                  isFinalRecomTreatments
                  sectionCode={section.sectionCode}
                  pathway={pathway}
                  changeArrowIcon={changeArrowIcon}
                />
              ))}

          {!isFinalRecommSec &&
            treatmentList &&
            treatmentList.length > 0 &&
            treatmentList
              .filter(
                (x) => x.sectionCode === section.sectionCode && x.isActive === 1
              )
              .map((recomm, idx) => (
                <Treatments
                  recommendation={recomm}
                  index={idx}
                  isFinalRecomTreatments={false}
                  sectionCode={section.sectionCode}
                  pathway={pathway}
                  changeArrowIcon={changeArrowIcon}
                />
              ))}
        </>
      )}
    </Box>
  );
};

TreatmentSection.propTypes = {
  section: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  isFinalRecommSec: PropTypes.bool.isRequired,
  pathway: PropTypes.string.isRequired,
};
export default TreatmentSection;
