// React imports
import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
// Material UI imports
import { Box } from '@material-ui/core';
import './Description.css';

const DescriptionBox = ({ bannerImageUrl, treatmentPathQ }) => (
  <Box className="description mobDescription">
    <Box className="desc-box">
      <img alt="info" src={bannerImageUrl} className="desc-box" />
    </Box>
    <Box className="hometext">
      <p className="homePathQ mobHomePathQ">{parse(treatmentPathQ || '')}</p>
    </Box>
  </Box>
);

DescriptionBox.propTypes = {
  bannerImageUrl: PropTypes.string.isRequired,
  treatmentPathQ: PropTypes.string.isRequired,
};
export default DescriptionBox;
