import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const ProductImages = ({ productsData }) => {
  const [mainImage, setMainImage] = useState([]);
  const [thumbnailImages, setThumbnailImages] = useState([]);
  const [allImages, setAllImages] = useState([]);
  const shuffleImages = (idx, prodDesctionTitle) => {
    setMainImage(
      allImages.filter((x) => x.productSectionTitle === prodDesctionTitle)
    );
    setThumbnailImages(
      allImages.filter((x) => x.productSectionTitle !== prodDesctionTitle)
    );
  };
  useEffect(() => {
    const onlyImages = productsData
      .filter((x) => x.productSectionType === 'image')
      .sort((a, b) => a.productSectionOrder - b.productSectionOrder);
    setAllImages(onlyImages);
    setMainImage(onlyImages.filter((x, idx) => idx === 0));
    setThumbnailImages(onlyImages.filter((x, idx) => idx !== 0));
  }, [productsData]);
  return (
    mainImage.length > 0 && (
      <Box>
        <Box>
          <img
            alt="treatment"
            src={mainImage && mainImage[0].productSectionBody}
            style={{
              width: '308px',
              height: '308px',
              objectFit: 'contain',
            }}
          />
        </Box>
        {thumbnailImages.length > 0 && (
          <Box
            style={{
              width: '100%',
              textAlignLast: 'center',
              display: 'flex',
              cursor: 'pointer',
            }}
          >
            {thumbnailImages
              .sort((a, b) => a.productSectionOrder - b.productSectionOrder)
              .map((prop, idx) =>
                idx < 4 ? (
                  <img
                    onClick={() => {
                      shuffleImages(idx, prop.productSectionTitle);
                    }}
                    aria-hidden="true"
                    alt="treatment"
                    src={prop.productSectionBody}
                    style={{
                      margin: '8px',
                      width: '56px',
                      height: '56px',
                      border: '10px solid #F8F8FA',
                    }}
                  />
                ) : (
                  ''
                )
              )}
          </Box>
        )}
      </Box>
    )
  );
};

ProductImages.propTypes = {
  productsData: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default ProductImages;
