// React imports
import React, { useState, useContext, useRef } from 'react';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import { NavLink, useHistory } from 'react-router-dom';
// Material UI imports
import { Box, Button, useMediaQuery, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
// utility imports
import { setTooltipText } from '../utility/utils';

import {
  MOBILE_BREAKPOINT,
  WOUNDGOAL_PAGECODE,
  CHECKBOX_STATES_CHECKED,
} from '../utility/staticText';
import useOutsideclick from '../utility/useOutsideclick';
// component imports
import LightTooltip from '../components/lightTooltip';
import { ApplicationContext } from '../store/ApplicationState';
import { getActivePageConfig, getPageConfigAttributes } from '../api/common';

const GetStartedBox = ({
  treatmentPath1ButtonValue,
  treatmentPath2ButtonValue,
  treatmentPathDescValue,
  treatmentPathImageUrl,
  treatmentPathInfoImageUrl,
  infoTitleValue,
  infoDescriptionValue,
  infoURLValue,
  infoURLTitleValue,
  treatmentPathTitleValue,
  treatmentPathAllValue,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const matches600 = useMediaQuery(theme.breakpoints.down('600'));
  const matchesMobileWidth = useMediaQuery(
    theme.breakpoints.down(MOBILE_BREAKPOINT)
  );
  const [open, setOpen] = useState(false);
  const tooltipTitle = setTooltipText(
    infoTitleValue,
    infoDescriptionValue,
    infoURLValue,
    infoURLTitleValue
  );
  const { dispatch, wndRecommDispatch } = useContext(ApplicationContext);

  const handleInfoClick = () => {
    setOpen(!open);
  };
  async function getPageConfiguration() {
    const responses = await getActivePageConfig();
    return responses;
  }

  async function getPageConfigurationAttribute(reqBody) {
    const responses = await getPageConfigAttributes(reqBody);
    return responses;
  }

  const handleAllTherapyClick = () => {
    dispatch({
      isGoalPathWay: false,
      isAllTherapyWay: true,
      treatmentPathway: 'THRAL',
    });
    wndRecommDispatch({
      treatmentList: [],
      selectAllTreatment: CHECKBOX_STATES_CHECKED,
    });
  };
  const ref = useRef(null);
  useOutsideclick(ref, () => {
    setOpen(false);
  });
  async function getPageConfigDetails(pathway) {
    const allpageID = [];
    let characteristicsPageData = [];
    const responses = await getPageConfiguration();
    if (pathway === 'WNDCH') {
      characteristicsPageData = responses.item.pageConfigListObj.filter(
        (x) => x.pageCode !== WOUNDGOAL_PAGECODE
      );
    } else if (pathway === 'THRGL') {
      characteristicsPageData = responses.item.pageConfigListObj.filter(
        (x) => x.pageCode === WOUNDGOAL_PAGECODE
      );
    }

    characteristicsPageData.forEach((element) => {
      allpageID.push(element.pageId);
    });
    const data = await getPageConfigurationAttribute(allpageID);
    return data.item.pageConfigAttributeListObj;
  }
  async function TreatmentGoalButtonClick() {
    const response = await getPageConfigDetails('THRGL');
    if (response.length > 0) {
      history.push('/woundGoal');
      dispatch({
        treatmentPathway: 'THRGL',
        isAllTherapyWay: false,
      });
      dispatch({ treatmentPathway: 'THRGL' });
    }
  }

  async function WndCharactersticsButtonClick() {
    const response = await getPageConfigDetails('WNDCH');
    if (response.length > 0) {
      history.push('/woundTherapyGuide');
      dispatch({
        treatmentPathway: 'WNDCH',
        isGoalPathWay: false,
        isAllTherapyWay: false,
      });
    }
  }

  return (
    <Box
      className="getStartContainer mobGetStartContainer"
      flexWrap={matches600 ? 'wrap' : 'nowrap'}
    >
      <Box
        style={{
          backgroundImage: `url(${treatmentPathImageUrl})`,
          minHeight: '214px',
          maxHeight: !matchesMobileWidth && '312px',
          backgroundSize: 'cover',
          display: matchesMobileWidth ? 'none' : 'inline-block',
          float: 'left',
          width: '340px',
        }}
      />
      <Box className="homeTPathBox mobHomeTPathBox">
        <div className="tpathDiv mobTPathDiv">
          <Typography className="homeTPathVal mobHomeTPathVal">
            {parse(treatmentPathTitleValue || '')}
            <span className="infoSpan mobInfoSpan">
              <LightTooltip
                title={tooltipTitle}
                ref={ref}
                arrow
                placement="top"
                style={{
                  fontSize: '16px',
                  lineHeight: '20px',
                }}
                open={open}
                onClick={handleInfoClick}
              >
                <img
                  alt="info"
                  src={treatmentPathInfoImageUrl}
                  height="24px"
                  width="24px"
                />
              </LightTooltip>
            </span>
          </Typography>
        </div>
        <p className="homeTPathDescVal mobHomeTPathDescVal">
          {parse(treatmentPathDescValue || '')}
        </p>
        <Box className="homeBtnBox mobHomeBtnBox">
          <Button
            variant="contained"
            color="primary"
            className="homeBtnGrp mobHomeBtnGrp buttonStyle"
            onClick={TreatmentGoalButtonClick}
          >
            {parse(treatmentPath1ButtonValue || '')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="homeBtnGrp mobHomeBtnGrp buttonStyle"
            onClick={WndCharactersticsButtonClick}
          >
            {parse(treatmentPath2ButtonValue || '')}
          </Button>
        </Box>
        <Box className="homeAllTrtBox mobHomeAllTrtBox">
          <p className="fontStyle1 mobFontStyle1">
            {parse(treatmentPathAllValue || '', {
              replace(node) {
                let returnValue = '';
                if (node.parent && node.parent.name === 'span') {
                  returnValue = (
                    <NavLink
                      onClick={handleAllTherapyClick}
                      to="/allTherapies"
                      underline="always"
                      style={{
                        fontSize: '16px',
                        lineHeight: '20px',
                        color: '#0049BD',
                      }}
                    >
                      view all treatment approaches
                    </NavLink>
                  );
                }
                return returnValue;
              },
            })}
          </p>
        </Box>
      </Box>
    </Box>
  );
};

GetStartedBox.propTypes = {
  treatmentPath1ButtonValue: PropTypes.string.isRequired,
  treatmentPath2ButtonValue: PropTypes.string.isRequired,
  treatmentPathDescValue: PropTypes.string.isRequired,
  treatmentPathImageUrl: PropTypes.string.isRequired,
  treatmentPathInfoImageUrl: PropTypes.string.isRequired,
  infoTitleValue: PropTypes.string.isRequired,
  infoDescriptionValue: PropTypes.string.isRequired,
  infoURLValue: PropTypes.string.isRequired,
  infoURLTitleValue: PropTypes.string.isRequired,
  treatmentPathTitleValue: PropTypes.string.isRequired,
  treatmentPathAllValue: PropTypes.string.isRequired,
};
export default GetStartedBox;
