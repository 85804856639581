/* eslint-disable prefer-destructuring */
// React imports
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// Material UI imports
import { Box } from '@material-ui/core';
// component imports
import AlertDialog from '../../components/alertBox';
import CustomButton from '../../components/customButton';
import Infection from './Infection';
import InfectionOther from './InfectionOther';
// Utility imports
import {
  fetchSiteCoreContents,
  checkValidation,
  createBody,
  checkPopup,
  fetchWoundRules,
  captureMiniValidationData,
} from '../../utility/utils';
import {
  WND_CAP_RULE,
  WND_UNK_RULE,
  WND_NONE_RULE,
  STATUS_CURRENT,
  STATUS_NOT_ENTERED,
  STATUS_ENTERED,
  STATUS_VISITED,
} from '../../utility/staticText';
// // Store imports
import { ApplicationContext } from '../../store/ApplicationState';

function checkParametersList(
  parameterList,
  otherDesc,
  isActiveStep,
  otherParameterCode,
  showOtherTextBox
) {
  let status = '';
  const nothingSelected =
    parameterList && parameterList.filter((y) => y.selected).length === 0;
  const otherSelected =
    parameterList &&
    parameterList.filter(
      (y) => y.selected && y.parameterCode === otherParameterCode
    ).length > 0;
  if (nothingSelected) {
    status = isActiveStep ? STATUS_CURRENT : STATUS_NOT_ENTERED;
  } else {
    if (!otherSelected) {
      status = STATUS_ENTERED;
    }
    if (otherSelected && showOtherTextBox) {
      if (otherDesc !== '' && otherDesc) {
        status = STATUS_ENTERED;
      } else {
        status = STATUS_VISITED;
      }
    }
    if (otherSelected && !showOtherTextBox) {
      status = STATUS_ENTERED;
    }
  }

  return status;
}

const WoundInfection = ({ attInfo }) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [enterButtonTextValue, setEnterButtonTextValue] = useState();
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState('');
  const [popUpTitle, setPopUpTitle] = useState('');

  const {
    state: {
      activeStep,
      pageData,
      errorMessage,
      totalStepper,
      minimumValidationData,
    },
    dispatch,
    wndInfectionState: {
      showInfectionOtherTextBox,
      infectionOtherDesc,
      woundInfectionList,
      showLabel,
      otherParameterCode,
      noneParameterCode,
      infUnkParameterCode,
    },
    wndInfectionDispatch,
  } = useContext(ApplicationContext);

  const [att1] = attInfo.sort((a, b) => a.attOrder - b.attOrder);

  // site core content
  fetchSiteCoreContents(att1.contentID).then((typeContent) => {
    const {
      enterButtonText,
    } = typeContent.item.contentDetailsLists[0].contentItems[0].woundCharacteristics;
    setEnterButtonTextValue(enterButtonText);
  });

  function validateNextButton() {
    if (activeStep + 1 === totalStepper) {
      dispatch({ showNextButton: false });
    }
  }
  function handleInfectionOtherDescChange(event) {
    wndInfectionDispatch({
      infectionOtherDesc: event.target.value,
      showLabel: !!event.target.value,
    });
    // eslint-disable-next-line array-callback-return
    pageData[activeStep].data[attInfo[0].attCode].map((x) => {
      if (x.parameterCode === otherParameterCode) {
        Object.assign(x, {
          OtherText: event.target.value,
          displayText: `Other: ${event.target.value}`,
        });
      }
    });
    dispatch({ pageData });
  }

  async function executeRulesIfAny(param) {
    const { hasRules } = param;
    let response;
    let isPopUp;
    let isWndCapRule;
    let isWndNoneRule;
    let isWndUnkRule;
    let noneParamCode;
    let unknownParamCode;
    if (hasRules) {
      response = await fetchWoundRules(param.parameterCode);
      isPopUp = await checkPopup(response);
      isWndCapRule = response.item.captureRules.filter(
        (x) => x.ruleType === WND_CAP_RULE
      );
      isWndNoneRule = response.item.captureRules.filter(
        (x) => x.ruleType === WND_NONE_RULE
      );
      isWndUnkRule = response.item.captureRules.filter(
        (x) => x.ruleType === WND_UNK_RULE
      );

      if (
        isWndCapRule &&
        isWndCapRule[0] &&
        isWndCapRule[0].activateWoundCapture
      ) {
        wndInfectionDispatch({ otherParameterCode: param.parameterCode });
        wndInfectionDispatch({
          showInfectionOtherTextBox:
            isWndCapRule &&
            isWndCapRule[0] &&
            isWndCapRule[0].activateWoundCapture,
        });
      }
      if (
        isWndNoneRule &&
        isWndNoneRule[0] &&
        isWndNoneRule[0].disableOtherParams
      ) {
        noneParamCode = param.parameterCode;
      }
      if (
        isWndUnkRule &&
        isWndUnkRule[0] &&
        isWndUnkRule[0].disableOtherParams
      ) {
        const updatedInfectionList = woundInfectionList.map((x) => ({
          ...x,
          selected: x.parameterCode === param.parameterCode,
          disabled: x.parameterCode !== param.parameterCode,
        }));
        unknownParamCode = param.parameterCode;

        wndInfectionDispatch({
          infUnkParameterCode: param.parameterCode,
          otherParameterCode: '',
          showInfectionOtherTextBox: false,
          infectionOtherText: '',
          woundInfectionList: updatedInfectionList,
        });
      }
      if (isPopUp && isPopUp[0] && param.selected) {
        setPopUpOpen(true);
        setPopUpMessage(isPopUp[1]);
        setPopUpTitle(isPopUp[2]);
      }
    }
    return [isWndNoneRule, noneParamCode, unknownParamCode];
  }

  const captureWoundInfectionData = (selectedCodes) => {
    delete pageData[activeStep].data;
    const updateMinimumValidationDataStart = captureMiniValidationData(
      [attInfo[0].attCode][0],
      minimumValidationData,
      false
    );
    dispatch({ updateMinimumValidationDataStart });
    const allelement = [];
    selectedCodes.forEach(async (element) => {
      let isPopup = false;
      let Title = '';
      let Message = '';
      if (element.hasRules) {
        const response = await fetchWoundRules(element.parameterCode);
        const popup = await checkPopup(response);
        if (popup[0]) {
          isPopup = true;
          Title = popup[2];
          Message = popup[1];
        }
      }
      if (element.parameterCode === otherParameterCode) {
        allelement.push({
          attributeName: '',
          parameterCode: element.parameterCode,
          hasRule: isPopup,
          parameterdesc: element.parameterDesc,
          OtherText: infectionOtherDesc,
          displayText: `Other: ${infectionOtherDesc}`,
          popUpTitle: Title,
          popUpMessage: Message,
          showPopup: false,
        });
      } else {
        allelement.push({
          attributeName: '',
          parameterCode: element.parameterCode,
          hasRule: isPopup,
          parameterdesc: element.parameterDesc,
          OtherText: '',
          displayText: element.parameterDesc,
          popUpTitle: Title,
          popUpMessage: Message,
          showPopup: false,
        });
      }
      const data = {
        [attInfo[0].attCode]: allelement,
      };
      Object.assign(pageData[activeStep], { data });
      const updateMinimumValidationData = captureMiniValidationData(
        [attInfo[0].attCode][0],
        minimumValidationData,
        true
      );
      dispatch({ updateMinimumValidationData });
    });
    if (selectedCodes.length === 0) {
      const data = {
        [attInfo[0].attCode]: [
          {
            attributeName: '',
            parameterCode: '',
            hasRule: false,
            parameterdesc: '',
            OtherText: '',
            displayText: 'Blank',
            popUpTitle: '',
            popUpMessage: '',
            showPopup: false,
          },
        ],
      };

      Object.assign(pageData[activeStep], { data });
    }
    dispatch({ pageData });
  };
  async function handleInfectionChange(idx) {
    let [isWndNone, noneParamCode, unknownParamCode] = [false, '', ''];
    woundInfectionList[idx].selected = !woundInfectionList[idx].selected;
    wndInfectionDispatch({
      infectionOtherDesc: showInfectionOtherTextBox ? infectionOtherDesc : '',
    });
    wndInfectionDispatch({ woundInfectionList });

    if (woundInfectionList[idx].selected) {
      [isWndNone, noneParamCode, unknownParamCode] = await executeRulesIfAny(
        woundInfectionList[idx]
      );
    }
    if (isWndNone && isWndNone[0] && isWndNone[0].disableOtherParams) {
      wndInfectionDispatch({
        noneParameterCode: noneParamCode,
        showInfectionOtherTextBox: false,
        infectionOtherDesc: '',
      });
      wndInfectionDispatch({
        unselectExceptNone:
          isWndNone && isWndNone[0] && isWndNone[0].disableOtherParams,
      });
      if (
        woundInfectionList[idx].selected &&
        woundInfectionList[idx].parameterCode === noneParamCode
      ) {
        woundInfectionList.forEach((x, ix) => {
          if (x.parameterCode !== noneParamCode) {
            woundInfectionList[ix].selected = false;
          }
        });
      }
    } else {
      woundInfectionList.forEach((x, ix) => {
        if (x.parameterCode === noneParameterCode) {
          woundInfectionList[ix].selected = false;
        }
      });
    }
    if (
      woundInfectionList[idx].selected === false &&
      woundInfectionList[idx].parameterCode === otherParameterCode
    ) {
      wndInfectionDispatch({ showInfectionOtherTextBox: false });
    }
    // unknown param

    if (
      woundInfectionList[idx].selected === false &&
      woundInfectionList[idx].parameterCode === infUnkParameterCode
    ) {
      woundInfectionList.forEach((x, ix) => {
        woundInfectionList[ix].disabled = false;
      });
      wndInfectionDispatch({
        otherParameterCode: '',
        woundInfectionList,
      });
    }
    if (
      woundInfectionList[idx].selected === true &&
      woundInfectionList[idx].parameterCode === unknownParamCode
    ) {
      woundInfectionList.forEach((x, ix) => {
        if (x.parameterCode !== unknownParamCode) {
          woundInfectionList[ix].selected = false;
          woundInfectionList[ix].disabled = true;
        }
      });
      wndInfectionDispatch({
        otherParameterCode: '',
        woundInfectionList,
      });
    }
    const selectedCodes = woundInfectionList.filter((x) => x.selected === true);
    captureWoundInfectionData(selectedCodes);
  }

  const handleClose = () => {
    setAlertOpen(false);
  };

  const handlePopUpClose = () => {
    setPopUpOpen(false);
  };
  // handle submit
  function handleSubmit() {
    const { length } = woundInfectionList.filter((x) => x.selected === true);
    const validateBody = [];
    const body1 = createBody(length, 'WDINF');
    validateBody.push(body1);
    if (
      woundInfectionList.filter(
        (x) => x.selected === true && showInfectionOtherTextBox
      ).length > 0
    ) {
      const body2 = createBody(infectionOtherDesc, 'WDINFOT');
      validateBody.push(body2);
    }
    const isValid = checkValidation(validateBody);
    if (isValid) {
      if (pageData[activeStep + 1].enabled) {
        if (activeStep < totalStepper) {
          dispatch({ activeStep: activeStep + 1 });
          if (totalStepper !== activeStep + 1) {
            pageData[activeStep + 1].isActiveStep = true;
          }
        }
        if (activeStep === totalStepper) {
          dispatch({ lastPage: activeStep });
        }
        validateNextButton();
        dispatch({ previousPage: activeStep });
      }
    } else {
      setAlertOpen(true);
    }
  }

  useEffect(() => {
    const validateToggle = () => {
      pageData.forEach((item, idx) => {
        if (idx === activeStep) {
          pageData[idx].status = checkParametersList(
            woundInfectionList,
            infectionOtherDesc,
            pageData[idx].isActiveStep || pageData[activeStep].firstPage,
            otherParameterCode,
            showInfectionOtherTextBox
          );
          dispatch({ pageData });
        }
      });
    };
    validateToggle();
  }, [
    activeStep,
    dispatch,
    pageData,
    JSON.stringify(woundInfectionList),
    infectionOtherDesc,
  ]);

  return (
    <Box className="attributeContainer mobAttributeContainer">
      <Infection
        contentID={att1.contentID}
        attCode={att1.attCode}
        selectedParameterCode={woundInfectionList}
        handleCheckBoxChange={handleInfectionChange}
      />
      {showInfectionOtherTextBox && (
        <InfectionOther
          contentID={att1.contentID}
          attCode={att1.attCode}
          infectionOtherText={infectionOtherDesc}
          handleInfectionOtherChange={handleInfectionOtherDescChange}
          movePlaceholder={showLabel}
        />
      )}
      <CustomButton
        value={enterButtonTextValue}
        handleSubmit={handleSubmit}
        width="100px"
      />
      <AlertDialog
        isOpen={alertOpen}
        handleClose={handleClose}
        popUpPositiveButton={handleClose}
        title="Notification"
      >
        {errorMessage}
      </AlertDialog>
      <AlertDialog
        isOpen={popUpOpen}
        handleClose={handlePopUpClose}
        popUpPositiveButton={handlePopUpClose}
        title={popUpTitle}
        buttonName="Got it"
      >
        {popUpMessage}
      </AlertDialog>
    </Box>
  );
};

WoundInfection.propTypes = {
  attInfo: PropTypes.arrayOf(
    PropTypes.shape({
      attCode: PropTypes.string,
      attOrder: PropTypes.number,
      pageCode: PropTypes.string,
      contentID: PropTypes.string,
    })
  ).isRequired,
};

export default WoundInfection;
