import React, { useEffect, useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
// import Material UI
import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import {
  fetchSiteCoreContents,
  setTooltipText,
  checkValidation,
  createBody,
  fetchWoundParametersContent,
  fetchWoundRules,
  checkPopup,
  capturePageData,
  captureMiniValidationData,
} from '../../utility/utils';
import CustomButton from '../../components/customButton';
// Import Utility
import {
  STATUS_ENTERED,
  STATUS_NOT_ENTERED,
  STATUS_VISITED,
  STATUS_CURRENT,
  WOUND_SIZE_ERROR,
  WND_CAP_RULE,
  NO_WOUND_IS_PRESENT,
  DEPTH_UNKNOWN,
  MOBILE_BREAKPOINT,
} from '../../utility/staticText';
// Store imports
import { ApplicationContext } from '../../store/ApplicationState';
import QuestionHeader from '../../components/questionHeader';
import QuestionDescription from '../../components/questionDescription';
import CustomTextField from '../../components/customTextField';
import AlertDialog from '../../components/alertBox';
import CheckBox from '../../components/checkBox';
import { createObj } from '../../utility/saveAssessment';
import { getWoundDepth } from '../../api/common';
import useFullPageLoader from '../../hooks/useFullPageLoader';

async function getWoundSize(reqBody) {
  const url = `${process.env.REACT_APP_API_HOST}api/woundAlgorithm/getWoundSize`;
  try {
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        ClientID: process.env.REACT_APP_CLIENTID,
      },
      body: JSON.stringify({ area: reqBody }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return new Error('Error while getting wound size calculation!');
  }
}

async function callGetWoundDepth(depthValInput) {
  let value = depthValInput;
  if (depthValInput === '.') {
    value = '0.0';
  }
  const responses = await getWoundDepth(value);
  return responses;
}

const regex = '^([0-9]?[0-9]?[0-9]?)(([.]?)|([.]{1}[0-9]?)?)$';
const regexIt = (value) => {
  const reg = new RegExp(regex);
  const res = reg.test(value);
  return res;
};

const handleZeroes = (value) => {
  let returnValue = true;
  if (
    value === '0' ||
    value === '0.0' ||
    value === '0.' ||
    value === '00' ||
    value === '00.' ||
    value === '00.0' ||
    value === '000' ||
    value === '000.' ||
    value === '000.0' ||
    value === '.0'
  ) {
    returnValue = false;
  } else {
    returnValue = true;
  }
  return returnValue;
};

function WoundSize({ attInfo }) {
  const theme = useTheme();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const matchesMobileWidth = useMediaQuery(
    theme.breakpoints.down(MOBILE_BREAKPOINT)
  );
  attInfo.sort((a, b) => a.attOrder - b.attOrder);
  const [questionTextValue, setQuestionTextValue] = useState();
  const [questionImageURLValue, setQuestionImageURLValue] = useState();
  const [
    QuestionDescriptionTextValue,
    setQuestionDescriptionTextValue,
  ] = useState();
  const [enterButtonTextValue, setEnterButtonTextValue] = useState();
  const [infoTitleValue, setInfoTitleValue] = useState();
  const [infoDescriptionValue, setInfoDescriptionValue] = useState();
  const [infoURLValue, setInfoURLValue] = useState();
  const [infoURLTitleValue, setInfoURLTitleValue] = useState();
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  // const [parameterList, setParameterList] = useState([]);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState('');
  const [popUpTitle, setPopUpTitle] = useState('');
  const depthAttributeCode = 'DEPTH';
  const woundSizeAttribute = 'WS';
  const attC =
    attInfo[1] === undefined ? attInfo[0].attCode : attInfo[1].attCode;
  const isShowDepth =
    attInfo.filter((x) => x.attCode === depthAttributeCode).length > 0;
  const {
    state: {
      activeStep,
      pageData,
      errorMessage,
      totalStepper,
      minimumValidationData,
    },
    dispatch,
    wndSizeState: {
      woundWidth,
      woundHeight,
      woundDepth,
      validateWidth,
      validateHeight,
      validateDepth,
      parameterList,
      canShowSizePopup,
      depthList,
      disableDepthBox,
      disableHeightBox,
      disableWidthBox,
      depthCodeList,
    },
    wndSizeDispatch,
  } = useContext(ApplicationContext);

  const handleClose = () => {
    setAlertOpen(false);
  };
  const tooltipTitle = setTooltipText(
    infoTitleValue,
    infoDescriptionValue,
    infoURLValue,
    infoURLTitleValue
  );

  const fixToZero = (inputValue) => {
    let returnValue = 0;
    let value = inputValue;
    if (value.toString().startsWith('.')) {
      value = `0${value}`;
    }
    returnValue = value.toString().endsWith('.')
      ? parseInt(value, 10).toFixed(1)
      : value;
    return returnValue;
  };
  const validateToggle = () => {
    if (
      (woundWidth.length > 0 &&
        woundHeight.length > 0 &&
        woundDepth.length > 0 &&
        !validateWidth &&
        !validateHeight &&
        depthList.filter((x) => x.selected === true).length > 0) ||
      (woundWidth.length > 0 &&
        woundHeight.length > 0 &&
        !validateWidth &&
        !validateHeight &&
        !isShowDepth) ||
      (isShowDepth && woundDepth.length > 0) ||
      depthList.filter((x) => x.selected === true).length > 0
    ) {
      pageData[activeStep].status = STATUS_ENTERED;
    } else if (
      woundWidth.length > 0 &&
      woundHeight.length > 0 &&
      woundDepth.length > 0 &&
      !validateWidth &&
      !validateHeight &&
      !validateDepth
    ) {
      pageData[activeStep].status = STATUS_ENTERED;
    } else if (
      woundWidth.length > 0 ||
      woundHeight.length > 0 ||
      woundDepth.length > 0 ||
      depthList.filter((x) => x.selected === true).length > 0
    ) {
      pageData[activeStep].status = STATUS_VISITED;
      const updateMinimumValidationData = captureMiniValidationData(
        [attInfo[0].attCode][0],
        minimumValidationData,
        false
      );
      dispatch({ updateMinimumValidationData });
    } else {
      pageData[activeStep].status = pageData[activeStep].enabled
        ? STATUS_CURRENT
        : STATUS_NOT_ENTERED;
      const updateMinimumValidationData = captureMiniValidationData(
        [attInfo[0].attCode][0],
        minimumValidationData,
        false
      );
      dispatch({ updateMinimumValidationData });
    }
    dispatch({ pageData });
  };
  const handlePopUpClose = () => {
    setPopUpOpen(false);
  };

  const captureDisplayTextOnly = (width, height, depth) => {
    let displayText = '';
    if (isShowDepth) {
      if (width.length <= 0 && height.length <= 0 && depth.length <= 0) {
        displayText = 'Blank';
      } else {
        displayText = `${height.length > 0 ? height : '0'}cm x${
          width.length > 0 ? width : '0'
        }cm x  ${depth.length > 0 ? depth : '0'}cm`;
      }
    } else if (width.length <= 0 && height.length <= 0 && depth.length <= 0) {
      displayText = 'Blank';
    } else {
      displayText = `${height.length > 0 ? height : '0'}cm x${
        width.length > 0 ? width : '0'
      }cm   `;
    }

    const saveObj = [];

    saveObj.push(
      createObj('WoundWidth', width.length > 0 ? width : '0'),
      createObj('WoundLength', height.length > 0 ? height : '0'),
      createObj('WoundDepth', depth.length > 0 ? depth : '0')
    );

    const data = {
      [attInfo[0].attCode]: { displayText, saveAssessmentObj: saveObj },
    };
    if (Object.prototype.hasOwnProperty.call(pageData[activeStep], 'data')) {
      if (
        Object.prototype.hasOwnProperty.call(
          pageData[activeStep].data,
          attInfo[0].attCode
        )
      ) {
        Object.assign(pageData[activeStep].data[attInfo[0].attCode], {
          displayText,
          saveAssessmentObj: saveObj,
        });
      } else {
        Object.assign(pageData[activeStep].data, {
          [attInfo[0].attCode]: { displayText, saveAssessmentObj: saveObj },
        });
      }
    } else {
      Object.assign(pageData[activeStep], { data });
    }
    dispatch({ pageData });
  };

  const captureWoundSizeData = (paraCode, selectedData) => {
    let buildDisplayText = '';
    if (isShowDepth) {
      buildDisplayText = `${woundHeight !== '0' ? woundHeight : '0'}cm x${
        woundWidth !== '0' ? woundWidth : '0'
      }cm x  ${woundDepth !== '0' ? woundDepth : '0'}cm`;
    } else {
      buildDisplayText = `${woundHeight !== '0' ? woundHeight : '0'}cm x${
        woundWidth !== '0' ? woundWidth : '0'
      }cm `;
    }

    const saveObj = [];

    saveObj.push(
      createObj('WoundWidth', woundWidth),
      createObj('WoundLength', woundHeight),
      createObj('WoundDepth', woundDepth)
    );

    const updatedPageData = capturePageData(
      [attInfo[0].attCode],
      selectedData === undefined ? '' : paraCode,
      selectedData,
      pageData,
      null,
      activeStep,
      buildDisplayText,
      saveObj,
      woundDepth.length > 0 && woundDepth !== '0.0'
    );
    dispatch({ pageData: updatedPageData });
  };
  function checkRule(code) {
    let selectedData = [];
    if (parameterList && parameterList.length > 0) {
      selectedData =
        parameterList &&
        parameterList.filter((x) => x.parameterCode === code)[0];
      if (selectedData && selectedData.hasRules) {
        fetchWoundRules(code).then((ruledata) => {
          checkPopup(ruledata).then((popup) => {
            if (popup[0]) {
              setPopUpOpen(true);
              setPopUpMessage(popup[1]);
              setPopUpTitle(popup[2]);
            }
          });
        });
      }
    }
  }
  const areaChange = useCallback(
    (width, height, depth, widthValid, heightValid, sizeCode) => {
      if (!widthValid && !heightValid && depth && depth.length > 0) {
        checkRule(sizeCode);
      }
    },
    [wndSizeDispatch, validateHeight, validateWidth, checkRule]
  );

  const updatePageDataDepth = (depthVal) => {
    if (depthList.filter((x) => x.selected === true).length > 0) {
      const param = depthList.filter((x) => x.selected === true);
      const allElement = [];
      allElement.push({
        attributeName: '',
        parameterCode: param[0].parameterCode,
        hasRule: false,
        parameterdesc: null,
        displayText: ' ',
      });
      const data = {
        [attC]: allElement,
      };
      if (Object.prototype.hasOwnProperty.call(pageData[activeStep], 'data')) {
        Object.assign(pageData[activeStep].data, { ...data });
      } else {
        Object.assign(pageData[activeStep], { data });
      }
    } else if (isShowDepth) {
      callGetWoundDepth(depthVal).then((result) => {
        wndSizeDispatch({ woundDepthParameter: result.item.woundDepthCode });
        const allElement = [];
        result.item.woundDepthCode.forEach((element) => {
          if (
            depthCodeList.filter((x) => x.parameterCode === element).length > 0
          ) {
            allElement.push({
              attributeName: '',
              parameterCode: element,
              hasRule: false,
              parameterdesc: null,
              displayText: ' ',
            });
          }
        });

        const data = {
          [attC]: allElement,
        };
        if (
          Object.prototype.hasOwnProperty.call(pageData[activeStep], 'data')
        ) {
          Object.assign(pageData[activeStep].data, { ...data });
        } else {
          Object.assign(pageData[activeStep], { data });
        }
      });
    }
    if (
      woundHeight.length === 0 &&
      woundWidth.length === 0 &&
      woundDepth.length > 0 &&
      isShowDepth
    ) {
      const data = {
        WS: {
          attributeName: '',
          parameterCode: '',
          hasRule: false,
          parameterdesc: '',
          OtherText: '',
          displayText: `${woundDepth !== '0' ? woundDepth : '0'}cm`,
          popUpTitle: '',
          popUpMessage: '',
          showPopup: false,
        },
      };
      if (Object.prototype.hasOwnProperty.call(pageData[activeStep], 'data')) {
        Object.assign(pageData[activeStep].data, { ...data });
      } else {
        Object.assign(pageData[activeStep], { data });
      }
    }
  };

  useEffect(() => {
    validateToggle();
    if (parameterList === undefined || parameterList.length === 0) {
      showLoader();
      fetchWoundParametersContent(attInfo[0] && attInfo[0].attCode)
        .then((response) => {
          wndSizeDispatch({
            parameterList: response.item.parameters[0].parameterGroup,
          });
          hideLoader();
        })
        .catch(() => {
          hideLoader();
        });
    }
    if (depthList === undefined || depthList.length === 0) {
      fetchWoundParametersContent(attC).then((response) => {
        const depList = response.item.parameters[0].parameterGroup
          .filter(
            (x) =>
              x.parameterCode === NO_WOUND_IS_PRESENT ||
              x.parameterCode === DEPTH_UNKNOWN
          )
          .sort((a, b) => (a.sortPriority > b.sortPriority ? 1 : -1))
          .map((x) => ({
            ...x,
            selected: false,
            disabled: false,
          }));

        wndSizeDispatch({
          depthList: depList,
          depthCodeList: response.item.parameters[0].parameterGroup,
        });
      });
    }

    if (Object.prototype.hasOwnProperty.call(pageData[activeStep], 'data')) {
      delete pageData[activeStep].data;
    }

    if (
      (!validateHeight &&
        !validateWidth &&
        woundHeight.length > 0 &&
        woundWidth.length > 0 &&
        woundDepth.length > 0) ||
      (!validateHeight &&
        !validateWidth &&
        woundHeight.length > 0 &&
        woundWidth.length > 0 &&
        !isShowDepth)
    ) {
      wndSizeDispatch({ canShowSizePopup: true });
      let area = parseInt(woundWidth, 10) * parseInt(woundHeight, 10);
      if (woundWidth === 0) {
        area = woundHeight && woundHeight;
      }
      if (woundHeight === 0) {
        area = woundWidth && woundWidth;
      }
      getWoundSize(area).then((res) => {
        wndSizeDispatch({ woundSizeParameter: res.item.sizeCode });
        const selectedData =
          parameterList &&
          parameterList.filter((x) => x.parameterCode === res.item.sizeCode)[0];

        captureWoundSizeData(res.item.sizeCode, selectedData);
        if (isShowDepth) {
          updatePageDataDepth(woundDepth);
        }

        if (!canShowSizePopup && woundDepth !== '0.0') {
          wndSizeDispatch({ canShowSizePopup: true });
          setTimeout(() => {
            areaChange(
              woundWidth,
              woundHeight,
              woundDepth,
              validateWidth,
              validateHeight,
              res.item.sizeCode
            );
          }, 800);
        }
      });
    } else {
      wndSizeDispatch({ canShowSizePopup: false });
    }

    captureDisplayTextOnly(woundWidth, woundHeight, woundDepth);
    if (woundDepth) {
      updatePageDataDepth(woundDepth);
    }
  }, [
    activeStep,
    woundWidth,
    woundHeight,
    woundDepth,
    validateWidth,
    validateHeight,
    JSON.stringify(depthList),
  ]);
  const handleWidthDecimalValue = () => {
    const decWidth = fixToZero(woundWidth);
    wndSizeDispatch({ woundWidth: decWidth });
    captureDisplayTextOnly(decWidth, woundHeight, woundDepth);
  };
  const handleHeightDecimalValue = () => {
    const decHeight = fixToZero(woundHeight);
    wndSizeDispatch({ woundHeight: decHeight });
    captureDisplayTextOnly(woundWidth, decHeight, woundDepth);
  };
  const handleDepthDecimalValue = () => {
    const decDepth = fixToZero(woundDepth);
    wndSizeDispatch({ woundDepth: decDepth });
    captureDisplayTextOnly(woundWidth, woundHeight, decDepth);
  };
  const handleWidthInput = (e) => {
    const { value } = e.target;
    const res = regexIt(value);
    if (res && value) {
      captureDisplayTextOnly(value, woundHeight, woundDepth);
      wndSizeDispatch({ woundWidth: value });
      if (handleZeroes(value)) {
        wndSizeDispatch({ validateWidth: false });
        if (handleZeroes(value)) {
          wndSizeDispatch({ validateWidth: false });
        } else {
          wndSizeDispatch({ validateWidth: true });
        }
      } else {
        wndSizeDispatch({ validateWidth: true });
      }
    }
    if (res && !value) {
      wndSizeDispatch({ woundWidth: value });
      wndSizeDispatch({ validateWidth: false });
    }
  };

  const handleHeightInput = (e) => {
    const { value } = e.target;
    const res = regexIt(value);
    if (res && value) {
      captureDisplayTextOnly(woundWidth, value, woundDepth);
      wndSizeDispatch({ woundHeight: value });
      if (handleZeroes(value)) {
        wndSizeDispatch({ validateHeight: false });
      } else {
        wndSizeDispatch({ validateHeight: true });
      }
    }
    if (res && !value) {
      wndSizeDispatch({ woundHeight: '' });
      wndSizeDispatch({ validateHeight: false });
    }
  };

  const handleDepthInput = (e) => {
    const { value } = e.target;
    const res = regexIt(value);
    if (res && value) {
      captureDisplayTextOnly(woundWidth, woundHeight, value);
      wndSizeDispatch({ woundDepth: value });
      const updateMinimumValidationData = captureMiniValidationData(
        depthAttributeCode,
        minimumValidationData,
        true
      );
      dispatch({ updateMinimumValidationData });
    }
    if (res && !value) {
      wndSizeDispatch({ woundDepth: value });
      wndSizeDispatch({ validateDepth: false });
      const updateMinimumValidationData = captureMiniValidationData(
        depthAttributeCode,
        minimumValidationData,
        false
      );
      dispatch({ updateMinimumValidationData });
    }
  };

  fetchSiteCoreContents(attInfo[0] && attInfo[0].contentID).then(
    (typeContent) => {
      const {
        questionText,
        questionImage,
        questionDescriptionText,
        enterButtonText,
      } = typeContent.item.contentDetailsLists[0].contentItems[0].woundCharacteristics;
      const {
        infoTitle,
        infoDescription,
        infoURL,
        infoURLTitle,
      } = typeContent.item.contentDetailsLists[0].contentItems[0].infoDetails;

      setQuestionDescriptionTextValue(
        questionDescriptionText.replace(/<[^>]+>/g, '')
      );
      setQuestionImageURLValue(questionImage);
      setQuestionTextValue(questionText.replace(/<[^>]+>/g, ''));
      setEnterButtonTextValue(enterButtonText);
      setInfoTitleValue(infoTitle.replace(/<[^>]+>/g, ''));
      setInfoDescriptionValue(infoDescription);
      setInfoURLValue(infoURL);
      setInfoURLTitleValue(infoURLTitle);
    }
  );

  const handleInfoClick = () => {
    setOpen(!open);
  };

  function validateNextButton() {
    if (activeStep + 1 === totalStepper) {
      dispatch({ showNextButton: false });
    }
  }
  function handleSubmit() {
    const validateBody = [];

    if (isShowDepth) {
      const body1 = createBody(woundDepth, attInfo[0] && attInfo[0].pageCode);
      validateBody.push(body1);
    }
    if (woundWidth.length > 0 && woundDepth.length > 0) {
      const body = createBody(woundWidth, attInfo[0] && attInfo[0].pageCode);
      const body2 = createBody(woundHeight, attInfo[0] && attInfo[0].pageCode);
      validateBody.push(body);
      validateBody.push(body2);
    }

    const isValid = checkValidation(validateBody);

    if (
      (isValid && !validateDepth && !validateHeight && !validateWidth) ||
      (depthList.filter((x) => x.selected === true).length > 0 &&
        !validateHeight &&
        !validateWidth &&
        isValid)
    ) {
      if (pageData[activeStep + 1].enabled) {
        dispatch({ activeStep: activeStep + 1 });
        if (totalStepper !== activeStep + 1) {
          if (totalStepper !== activeStep + 1) {
            pageData[activeStep + 1].isActiveStep = true;
          }
        }
        if (activeStep === totalStepper) {
          dispatch({ lastPage: activeStep });
        }
        validateNextButton();
        dispatch({ previousPage: activeStep });
      }
    } else {
      setAlertOpen(true);
    }
  }
  async function checkRulesIfAny(param) {
    const { hasRules } = param;
    let response;
    let isWndCapRule;
    let isPresent = false;

    if (hasRules) {
      response = await fetchWoundRules(param.parameterCode);
      isWndCapRule = response.item.captureRules.filter(
        (x) => x.ruleType === WND_CAP_RULE
      );
      isPresent =
        isWndCapRule && isWndCapRule[0] && isWndCapRule[0].activateWoundCapture;
    }
    return isPresent;
  }
  async function handleDepthCheckboxChange(idx) {
    depthList[idx].selected = !depthList[idx].selected;
    const updateMinimumValidationData = captureMiniValidationData(
      depthAttributeCode,
      minimumValidationData,
      true
    );
    dispatch({ updateMinimumValidationData });
    if (
      depthList[idx].parameterCode === DEPTH_UNKNOWN &&
      depthList[idx].selected
    ) {
      const isRule = await checkRulesIfAny(depthList[idx]);
      const exceptDPU = depthList.map((x, ix) =>
        ix !== idx ? { ...x, selected: false } : x
      );
      let showErrorMessageWidth = false;
      let showErrorMessageHeight = false;
      if (isRule) {
        if (woundHeight === '0.0' && woundWidth === '0.0') {
          showErrorMessageWidth = true;
          showErrorMessageHeight = true;
        }
        wndSizeDispatch({
          disableHeightBox: false,
          disableDepthBox: true,
          disableWidthBox: false,
          woundDepth: '0.0',
          validateHeight: showErrorMessageHeight,
          validateWidth: showErrorMessageWidth,
          depthList: exceptDPU,
        });
      }
    } else if (
      depthList[idx].parameterCode === NO_WOUND_IS_PRESENT &&
      depthList[idx].selected
    ) {
      const isRule = await checkRulesIfAny(depthList[idx]);
      const exceptNWP = depthList.map((x, ix) =>
        ix !== idx ? { ...x, selected: false } : x
      );
      if (isRule) {
        wndSizeDispatch({
          disableHeightBox: true,
          disableDepthBox: true,
          disableWidthBox: true,
          woundDepth: '0.0',
          woundHeight: '0.0',
          woundWidth: '0.0',
          validateHeight: false,
          validateWidth: false,
          depthList: exceptNWP,
        });
      }
    }
    if (depthList.filter((x) => x.selected === true).length === 0) {
      wndSizeDispatch({
        disableHeightBox: false,
        disableDepthBox: false,
        disableWidthBox: false,
      });
      if (woundHeight === '0' || woundHeight === '0.0') {
        wndSizeDispatch({
          validateHeight: true,
        });
      }
      if (woundWidth === '0' || woundWidth === '0.0') {
        wndSizeDispatch({
          validateWidth: true,
        });
      }
      const depReset = depthList.map((x) => ({
        ...x,
        selected: false,
        disabled: false,
      }));
      wndSizeDispatch({ depthList: depReset });
    }
    validateToggle();
  }
  return (
    <Box className="attributeContainer mobAttributeContainer">
      {loader}
      <QuestionHeader
        questionTextValue={questionTextValue}
        questionImageURLValue={questionImageURLValue}
        handleInfoClick={handleInfoClick}
        tooltipTitle={tooltipTitle}
        open={open}
        setRef={() => {
          setOpen(false);
        }}
      />
      <QuestionDescription
        questionDescriptionTextValue={QuestionDescriptionTextValue}
      />
      <Grid
        item
        container
        md={12}
        className="sizeRoot mobSizeRoot mobSizeTxtBoxContainer"
      >
        <Grid
          container
          justify="start"
          spacing={4}
          style={{ padding: matchesMobileWidth && '16px' }}
        >
          {attInfo[0].attCode === woundSizeAttribute && (
            <Grid item className="mobGrid">
              <CustomTextField
                inputLabel="Length (cm)"
                inputVariant="outlined"
                inputValue={woundHeight}
                containerBoxStyle={{ padding: '0px' }}
                handleChange={(e) => handleHeightInput(e)}
                showErrorLabel={validateHeight}
                errorLabel={WOUND_SIZE_ERROR}
                onBlur={(e) => handleHeightDecimalValue(e)}
                disabled={disableHeightBox}
                inputStyle={{
                  width: matchesMobileWidth ? '86px' : '94px',
                  borderRadius: '2px',
                  height: matchesMobileWidth ? '48px' : '54px',
                }}
              />
            </Grid>
          )}
          {attInfo[0].attCode === woundSizeAttribute && (
            <Grid item className="mobGrid">
              <CustomTextField
                inputLabel="Width (cm)"
                inputVariant="outlined"
                inputValue={woundWidth}
                containerBoxStyle={{ padding: '0px' }}
                handleChange={(e) => handleWidthInput(e)}
                showErrorLabel={validateWidth}
                errorLabel={WOUND_SIZE_ERROR}
                onBlur={(e) => handleWidthDecimalValue(e)}
                disabled={disableWidthBox}
                inputStyle={{
                  width: matchesMobileWidth ? '86px' : '94px',
                  borderRadius: '2px',
                  height: matchesMobileWidth ? '48px' : '54px',
                }}
              />
            </Grid>
          )}
          {isShowDepth && (
            <Grid item className="mobGrid">
              <CustomTextField
                inputLabel="Depth (cm)"
                inputVariant="outlined"
                inputValue={woundDepth}
                containerBoxStyle={{ padding: '0px' }}
                handleChange={(e) => handleDepthInput(e)}
                showErrorLabel={validateDepth}
                errorLabel={WOUND_SIZE_ERROR}
                onBlur={(e) => handleDepthDecimalValue(e)}
                disabled={disableDepthBox}
                inputStyle={{
                  width: matchesMobileWidth ? '86px' : '94px',
                  borderRadius: '2px',
                  height: matchesMobileWidth ? '48px' : '54px',
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Box className="sizeRoot mobSizeRoot">
        <CheckBox
          handleCheckBoxChange={(e) => handleDepthCheckboxChange(e)}
          groupValue={depthList && depthList}
          showDescriptor={false}
          labelStyle={{ width: 'auto', pointerEvents: 'none' }}
          containerBoxStyle={{
            margin: matchesMobileWidth ? '40px 0px' : '0px',
          }}
        />
      </Box>
      <CustomButton
        value={enterButtonTextValue}
        handleSubmit={handleSubmit}
        width="100px"
      />
      <AlertDialog
        isOpen={alertOpen}
        handleClose={handleClose}
        popUpPositiveButton={handleClose}
        title="Notification"
      >
        {errorMessage}
      </AlertDialog>
      <AlertDialog
        isOpen={popUpOpen}
        handleClose={handlePopUpClose}
        popUpPositiveButton={handlePopUpClose}
        title={popUpTitle}
        buttonName="Got it"
      >
        {popUpMessage}
      </AlertDialog>
    </Box>
  );
}

WoundSize.propTypes = {
  attInfo: PropTypes.arrayOf(
    PropTypes.shape({
      attCode: PropTypes.string,
      attOrder: PropTypes.number,
      pageCode: PropTypes.string,
      contentID: PropTypes.string,
    })
  ).isRequired,
};

export default WoundSize;
