/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
// React imports
import React, { createContext, useReducer } from 'react';
import { v4 as uuidv4 } from 'uuid';
import WndTypeReducer from '../characteristics/woundType/store/reducer/WndTypeReducer';
import WndTypeInitalState from '../characteristics/woundType/store/initalState/WndTypeInitalState';
import wndAgeInitialState from '../characteristics/woundAge/store/initialState/wndAgeInitialState';
import wndAgeReducer from '../characteristics/woundAge/store/reducer/wndAgeReducer';
import WndSizeReducer from '../characteristics/woundSize/store/reducer/wndSizeReducer';
import WndSizeInitalState from '../characteristics/woundSize/store/initialState/wndSizeInitialState';
import wndExudateInitialState from '../characteristics/woundExudate/store/initialState/wndExudateInitialState';
import wndExudateReducer from '../characteristics/woundExudate/store/reducer/wndExudateReducer';
import wndInfectionInitalState from '../characteristics/woundInfection/store/initialState/wndInfectionInitalState';
import wndInfectionReducer from '../characteristics/woundInfection/store/reducer/wndInfectionReducer';
import wndAppearanceInitialState from '../characteristics/woundAppearance/store/initialState/wndAppearanceInitialState';
import wndAppearanceReducer from '../characteristics/woundAppearance/store/reducer/wndAppearanceReducer';
import wndPainInitialState from '../characteristics/woundPain/store/initialState/wndPainInitialState';
import wndPainReducer from '../characteristics/woundPain/store/reducer/wndPainReducer';
import wndCompressionInitialState from '../characteristics/woundCompression/store/initialState/wndCompressionInitialState';
import wndCompressionReducer from '../characteristics/woundCompression/store/reducer/wndCompressionReducer';
import wndOdorInitialState from '../characteristics/woundOdor/store/initialState/wndOdorInitialState';
import wndOdorReducer from '../characteristics/woundOdor/store/reducer/wndOdorReducer';
import wndEdgesInitialState from '../characteristics/woundEdges/store/initialState/wndEdgesInitialState';
import wndEdgesReducer from '../characteristics/woundEdges/store/reducer/wndEdgesReducer';
import wndPeriwoundInitialState from '../characteristics/woundPeriWound/store/initialState/wndPeriInitialState';
import wndPeriwoundReducer from '../characteristics/woundPeriWound/store/reducer/wndPeriReducer';
import recommInitialState from '../components/recommendation/store/initalState/recommInitialState';
import recommReducer from '../components/recommendation/store/reducer/recommReducer';
import wndGoalInitialState from '../woundGoal/store/initialState/wndGoalInitialState';
import wndGoalReducer from '../woundGoal/store/reducer/wndGoalReducer';
import { ERROR_MESSAGE } from '../utility/staticText';

const initialState = {
  activeStep: 0,
  pageData: [],
  totalStepper: 0,
  showNextButton: true,
  disableNextPrev: false,
  errorMessage: ERROR_MESSAGE,
  treatmentSections: [],
  backToRecommendation: false,
  lastPage: 0,
  showProducts: false,
  switchState: false,
  switchAllTherapy: false,
  minimumValidationData: [],
  lastPageStatus: '',
  treatmentPathway: '',
  previousPage: 0,
  firstLoad: false,
  isMinimumAttributeFilled: false,
  goalPageData: [],
  isGoalPathWay: false,
  isAllTherapyWay: false,
  forceNew: false,
  disclaimerLegalText: '',
  sessionID: uuidv4(),
};

console.log(uuidv4());
function reducer(state, action) {
  switch (action.type) {
    case 'RESET':
      return initialState;
    case 'RESET_STATE':
      return {
        ...initialState,
      };
    default: {
      return {
        ...state,
        ...action,
      };
    }
    // ...
  }
}

export const ApplicationContext = createContext();

export const ApllicationProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [wndTypeState, wndTypeDispatch] = useReducer(
    WndTypeReducer,
    WndTypeInitalState
  );
  const [wndAgeState, wndAgeDispatch] = useReducer(
    wndAgeReducer,
    wndAgeInitialState
  );
  const [wndSizeState, wndSizeDispatch] = useReducer(
    WndSizeReducer,
    WndSizeInitalState
  );
  const [wndExudateState, wndExudateDispatch] = useReducer(
    wndExudateReducer,
    wndExudateInitialState
  );
  const [wndInfectionState, wndInfectionDispatch] = useReducer(
    wndInfectionReducer,
    wndInfectionInitalState
  );

  const [wndAppearanceState, wndAppearanceDispatch] = useReducer(
    wndAppearanceReducer,
    wndAppearanceInitialState
  );
  const [wndPainState, wndPainDispatch] = useReducer(
    wndPainReducer,
    wndPainInitialState
  );
  const [wndCompressionState, wndCompressionDispatch] = useReducer(
    wndCompressionReducer,
    wndCompressionInitialState
  );
  const [wndOdorState, wndOdorDispatch] = useReducer(
    wndOdorReducer,
    wndOdorInitialState
  );
  const [wndEdgesState, wndEdgesDispatch] = useReducer(
    wndEdgesReducer,
    wndEdgesInitialState
  );
  const [wndPeriwoundState, wndPeriwoundDispatch] = useReducer(
    wndPeriwoundReducer,
    wndPeriwoundInitialState
  );
  const [wndRecommState, wndRecommDispatch] = useReducer(
    recommReducer,
    recommInitialState
  );
  const [wndGoalState, wndGoalDispatch] = useReducer(
    wndGoalReducer,
    wndGoalInitialState
  );
  return (
    <ApplicationContext.Provider
      value={{
        state,
        dispatch,
        wndTypeState,
        wndTypeDispatch,
        wndAgeState,
        wndAgeDispatch,
        wndSizeState,
        wndSizeDispatch,
        wndExudateState,
        wndExudateDispatch,
        wndInfectionState,
        wndInfectionDispatch,
        wndAppearanceState,
        wndAppearanceDispatch,
        wndPainState,
        wndPainDispatch,
        wndCompressionState,
        wndCompressionDispatch,
        wndOdorState,
        wndOdorDispatch,
        wndEdgesState,
        wndEdgesDispatch,
        wndPeriwoundState,
        wndPeriwoundDispatch,
        wndRecommState,
        wndRecommDispatch,
        wndGoalState,
        wndGoalDispatch,
      }}
    >
      {props.children}
    </ApplicationContext.Provider>
  );
};
