import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, useMediaQuery } from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import parse from 'html-react-parser';
import { useHistory } from 'react-router-dom';
import getCmsContent from '../api/cms';
import { getActivePageConfig } from '../api/common';
// Component imports
import CustomLargeButton from '../components/customLargeButton/CustomLargeButton';
import ProductProperties from './ProductProperties';
// Utility imports
import { ApplicationContext } from '../store/ApplicationState';
import { MOBILE_BREAKPOINT } from '../utility/staticText';
import useFullPageLoader from '../hooks/useFullPageLoader';

const useStyles = makeStyles(() => ({
  root: { margin: '0 200px', maxWidth: '1040px' },
  detailsContainer: {
    minHeight: 'calc(100vh - 145px)',
    width: '100%',
  },
}));

function getContentID(pageConfigList, pageCode) {
  const pageObj = pageConfigList.filter((page) => page.pageCode === pageCode);
  return pageObj[0].contentID;
}

async function getProductDetails(body) {
  const url = `${process.env.REACT_APP_API_HOST}api/woundProductDetail/getProductDetails`;
  try {
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        ClientID: process.env.REACT_APP_CLIENTID,
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return new Error('Something went wrong!');
  }
}

async function fetchWoundRecommendationContents(contentId) {
  let content = JSON.parse(localStorage.getItem(contentId));
  if (!content) {
    const body = {
      token: '',
      contentId,
    };
    content = await getCmsContent(body);
    localStorage.setItem(contentId, JSON.stringify(content));
  }
  return content;
}
const ProductDetail = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const classes = useStyles();
  const history = useHistory();
  const {
    state: { isAllTherapyWay, disclaimerLegalText },
    wndRecommState: { selectedProductID, isFinalRecommendtation },
  } = useContext(ApplicationContext);
  const [navigationButtonBottomText, setNavigationButtonBottomText] = useState(
    ''
  );
  const theme = useTheme();
  const matchesMobileWidth = useMediaQuery(
    theme.breakpoints.down(MOBILE_BREAKPOINT)
  );
  const [navigationButtonTopText, setNavigationButtonTopText] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [viewMoreDetailButtonText, setViewMoreDetailButtonText] = useState('');
  const [backArrowIconUrl, setBackArrowIcon] = useState('');
  const [prodProps, setProdProps] = useState([]);
  const handleBackClick = () => {
    if (!isFinalRecommendtation) {
      if (isAllTherapyWay) {
        history.push('/allTherapies');
      } else {
        history.push('/woundRecommendation');
      }
    } else {
      history.push('/woundTherapySelection');
    }
  };

  useEffect(() => {
    if (selectedProductID) {
      showLoader();
      const body = {
        productID: selectedProductID,
      };
      getProductDetails(body).then((res) => {
        setProdProps(res.item.productDetails);
      });
      getActivePageConfig().then((content) => {
        const contentID = getContentID(content.item.pageConfigListObj, 'PRODE');
        fetchWoundRecommendationContents(contentID).then(
          (productDetailPageContent) => {
            const {
              productPageReturnIcon,
              productPageTopReturnText,
              productPageButtonReturnText,
              productPageViewMoreButtonText,
            } = productDetailPageContent.item.contentDetailsLists[0].contentItems[0].productDetailPageDetails;
            setNavigationButtonTopText(productPageTopReturnText);
            setNavigationButtonBottomText(productPageButtonReturnText);
            setBackArrowIcon(productPageReturnIcon);
            setViewMoreDetailButtonText(productPageViewMoreButtonText);
            hideLoader();
          }
        );
      });
    }
  }, [selectedProductID]);

  useEffect(() => {
    if (selectedProductID === '' || selectedProductID === null) {
      history.push('/home');
    }
  }, []);

  return (
    <>
      <Box className={classes.detailsContainer}>
        {loader}
        <div className="recommRoot mobRecommRoot mobMargin">
          <div style={{ padding: '40px 0 20px 0' }}>
            <CustomLargeButton
              className="largeButtonStyle mobLargeButtonStyle secondaryButtonStyle"
              buttonText={navigationButtonTopText}
              handleClick={handleBackClick}
              buttonStyle={{
                borderColor: '#0049BD',
                width: matchesMobileWidth ? '100%' : 'auto',
                height: '48px',
              }}
              buttonLeftSideIcon={backArrowIconUrl}
            />
          </div>
          <ProductProperties prodProps={prodProps} />
          <div style={{ padding: '40px 0 20px 0', alignItems: 'end' }}>
            <CustomLargeButton
              className="largeButtonStyle mobLargeButtonStyle secondaryButtonStyle"
              buttonStyle={{
                borderColor: '#0049BD',
                width: matchesMobileWidth ? '100%' : 'auto',
                height: '48px',
              }}
              buttonText={navigationButtonBottomText}
              handleClick={handleBackClick}
              buttonLeftSideIcon={backArrowIconUrl}
            />
          </div>
          <div className="disclaimerBox">
            <p className="disclaimerText">{parse(disclaimerLegalText || '')}</p>
          </div>
        </div>
      </Box>
    </>
  );
};

export default ProductDetail;
