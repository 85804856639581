const useStylesWTG = {
  subHeader: {
    marginTop: '16px',
    fontSize: '16px',
    lineHeight: '20px',
  },
  toolTipDesp: {
    color: '#323234',
    fontSize: '16px',
    lineHeight: '26px',
    marginBottom: '8px',
  },
};

export default useStylesWTG;
