// React imports
import React from 'react';
import PropTypes from 'prop-types';
// Material UI imports
import { Box, TextField, Typography } from '@material-ui/core';

const CustomTextField = ({
  containerBoxStyle,
  inputLabel,
  labelStyle,
  inputType,
  inputStyle,
  inputValue,
  inputVariant,
  handleChange,
  errorLabelStyle,
  errorLabel,
  showErrorLabel,
  onBlur,
  placeHolderText,
  disabled,
  movePlaceholder,
  isRequired,
  autoFocus,
  id,
}) => (
  <Box
    style={containerBoxStyle}
    className={`custom-textbox ${showErrorLabel && ' error-class'}`}
  >
    <span
      style={
        movePlaceholder
          ? {
              width: '170px',
              height: '18px',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '14px',
              lineHeight: '18px',
              visibility: 'visible',
              opacity: 1,
              transition: 'visibility 0s ease-in-out 300ms, opacity 300ms',
              color: '#76767A',
              marginTop: '10px',
            }
          : {
              visibility: 'hidden',
              opacity: 0,
              transition: 'visibility 0s ease-in-out 300ms, opacity 300ms',
            }
      }
    >
      {placeHolderText}
    </span>
    <Typography
      style={labelStyle}
      className={`${showErrorLabel && ' error-class'}`}
    >
      {inputLabel}
      {isRequired && <span style={{ color: 'red' }}>*</span>}
    </Typography>
    <TextField
      className={`textBox ${showErrorLabel && ' error-class'}`}
      variant={inputVariant}
      style={inputStyle}
      type={inputType}
      value={inputValue}
      onChange={(e) => handleChange(e)}
      onBlur={onBlur}
      placeholder={placeHolderText}
      disabled={disabled}
      autoFocus={autoFocus}
      id={id}
    />
    {showErrorLabel && (
      <Typography
        style={errorLabelStyle}
        className={`error-msg ${showErrorLabel && ' error-class'}`}
      >
        {errorLabel}
      </Typography>
    )}
  </Box>
);

CustomTextField.defaultProps = {
  containerBoxStyle: { paddingBottom: '15px' },
  labelStyle: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#76767A',
    marginBottom: '5px',
    pointerEvents: 'none',
  },
  errorLabelStyle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '10px',
    lineHeight: '18px',
    color: 'red',
    paddingTop: '5px',
  },
  showErrorLabel: false,
  errorLabel: '',
  inputStyle: {
    border: '1px solid',
    borderColor: '#fff',
    boxShadow: 'inset 0px 1px 2px rgb(0 0 0 / 10%)',
    borderRadius: '2px',
    maxWidth: '100px',
  },
  inputType: 'text',
  inputVariant: 'outlined',
  handleChange: () => {},
  onBlur: () => {},
  placeHolderText: '',
  disabled: false,
  movePlaceholder: false,
  isRequired: false,
  autoFocus: false,
  id: '',
};

CustomTextField.propTypes = {
  containerBoxStyle: PropTypes.objectOf(PropTypes.string.isRequired),
  labelStyle: PropTypes.objectOf(PropTypes.string.isRequired),
  inputLabel: PropTypes.string.isRequired,
  errorLabelStyle: PropTypes.objectOf(PropTypes.string.isRequired),
  inputType: PropTypes.string,
  inputStyle: PropTypes.objectOf(PropTypes.string.isRequired),
  inputVariant: PropTypes.string,
  handleChange: PropTypes.func,
  inputValue: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  errorLabel: PropTypes.string,
  showErrorLabel: PropTypes.string,
  placeHolderText: PropTypes.string,
  disabled: PropTypes.bool,
  movePlaceholder: PropTypes.bool,
  isRequired: PropTypes.bool,
  autoFocus: PropTypes.bool,
  id: PropTypes.string,
};

export default CustomTextField;
