export const nameValidationRegex = /^[A-Za-z \s]*$/;
export const userNameValidationRegex = /^[a-zA-Z0-9\-+_. @]*$/;
export const zipCodeValidationRegex = /^[0-9]{0,5}$/;
export const serialNumberValidationRegex = /^[a-zA-Z0-9]*$/;

export function validateName(value) {
  return nameValidationRegex.test(value);
}

export function validateUserName(value) {
  return userNameValidationRegex.test(value);
}

export function validateZipCode(value) {
  return zipCodeValidationRegex.test(value);
}

export function validateSerialNumber(value) {
  return serialNumberValidationRegex.test(value);
}
