export default {
  woundInfection: {
    showInfectionOtherTextBox: false,
    infectionOtherDesc: '',
    woundInfectionList: [],
    showLabel: false,
    otherParameterCode: '',
    unselectExceptNone: false,
    noneParameterCode: '',
    infUnkParameterCode: '',
  },
};
