// React imports
import { React, useRef } from 'react';
import PropTypes from 'prop-types';
// Material UI imports
import { Typography } from '@material-ui/core';
// Component imports
import LightTooltip from '../lightTooltip';
import useOutsideclick from '../../utility/useOutsideclick';

const QuestionHeader = ({
  questionTextValue,
  questionImageURLValue,
  handleInfoClick,
  tooltipTitle,
  open,
  showInfo,
  setRef,
}) => {
  const ref = useRef(null);
  useOutsideclick(ref, setRef);

  return (
    <Typography className="attQtn mobAttQtn">
      {questionTextValue}
      {showInfo && questionTextValue && (
        <span>
          <span style={{ position: 'relative', top: '2px' }}>
            <LightTooltip
              title={tooltipTitle}
              ref={ref}
              arrow
              placement="top"
              style={{ marginLeft: '15px' }}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              onClick={handleInfoClick}
            >
              <img
                alt="info"
                src={questionImageURLValue}
                height="24px"
                width="24px"
              />
            </LightTooltip>
          </span>
        </span>
      )}
    </Typography>
  );
};

QuestionHeader.defaultProps = {
  showInfo: true,
};
QuestionHeader.propTypes = {
  questionTextValue: PropTypes.string.isRequired,
  questionImageURLValue: PropTypes.string.isRequired,
  handleInfoClick: PropTypes.func.isRequired,
  tooltipTitle: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  showInfo: PropTypes.bool,
  setRef: PropTypes.func.isRequired,
};
export default QuestionHeader;
