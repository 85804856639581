export default {
  woundWidth: '',
  woundHeight: '',
  woundDepth: '',
  validateWidth: false,
  validateHeight: false,
  validateDepth: false,
  woundSizeParameter: '',
  woundDepthParameter: [],
  canShowSizePopup: false,
  parameterList: [],
  depthList: [],
  disableDepthBox: false,
  disableHeightBox: false,
  disableWidthBox: false,
  depthCodeList: [],
};
