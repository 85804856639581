import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Appearances from './Appearances';
import CustomButton from '../../components/customButton';
import AlertDialog from '../../components/alertBox';
import { ApplicationContext } from '../../store/ApplicationState';

const WoundAppearance = ({ attInfo }) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const {
    state: {
      activeStep,
      errorMessage,
      totalStepper,
      pageData,
      disableNextPrev,
    },
    dispatch,
    wndAppearanceState: { isAppearanceValid },
  } = useContext(ApplicationContext);

  // handle submit
  function handleSubmit() {
    if (isAppearanceValid) {
      if (pageData[activeStep + 1].enabled) {
        if (activeStep < totalStepper) {
          dispatch({ activeStep: activeStep + 1 });
          if (totalStepper !== activeStep + 1) {
            pageData[activeStep + 1].isActiveStep = true;
          }
        }
        if (activeStep + 1 === totalStepper) {
          dispatch({ lastPage: activeStep });
        }
        dispatch({ previousPage: activeStep });
      }
    } else {
      setAlertOpen(true);
    }
  }

  const handleClose = () => {
    setAlertOpen(false);
  };

  return (
    <Box className="attributeContainer mobAttributeContainer">
      <Appearances attInfo={attInfo} />
      <CustomButton
        value="Enter"
        handleSubmit={handleSubmit}
        disableNextPrev={disableNextPrev}
        width="100px"
      />
      <AlertDialog
        isOpen={alertOpen}
        handleClose={handleClose}
        popUpPositiveButton={handleClose}
        title="Notification"
      >
        {errorMessage}
      </AlertDialog>
    </Box>
  );
};

WoundAppearance.propTypes = {
  attInfo: PropTypes.arrayOf(
    PropTypes.shape({
      attCode: PropTypes.string,
      attOrder: PropTypes.number,
      pageCode: PropTypes.string,
      contentID: PropTypes.string,
    })
  ).isRequired,
};
export default WoundAppearance;
