// React imports
import React, { useContext, useEffect, useState } from 'react';
// Material UI imports
import { Box } from '@material-ui/core';
// component imports
import DescriptionBox from './DescriptionBox';
import GetStartedBox from './GetStartedBox';
import SafetyStatementBox from './SafetyStatementBox';
// api imports
import { getActivePageConfig } from '../api/common';
import getCmsContent from '../api/cms';
import useFullPageLoader from '../hooks/useFullPageLoader';
import { ApplicationContext } from '../store/ApplicationState';

function getContentID(pageConfigList) {
  const pageObj = pageConfigList.filter((page) => page.pageCode === 'HOMPG');
  return pageObj[0].contentID;
}
// fetch home contents
async function fetchHomeContents(contentId) {
  let content = JSON.parse(localStorage.getItem(contentId));
  if (!content) {
    const body = {
      token: '',
      contentId,
    };
    content = await getCmsContent(body);
    localStorage.setItem(contentId, JSON.stringify(content));
  }
  return content;
}

const Home = () => {
  const {
    state: { disclaimerLegalText },
  } = useContext(ApplicationContext);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [bannerImageUrl, setBannerImageUrl] = useState();
  const [bannerTextValue, setBannerTextValue] = useState();
  const [treatmentPath1ButtonValue, setTreatmentPath1ButtonValue] = useState();
  const [treatmentPath2ButtonValue, setTreatmentPath2ButtonValue] = useState();
  const [treatmentPathDescValue, setTreatmentPathDescValue] = useState();
  const [treatmentPathImageUrl, setTreatmentPathImageUrl] = useState();
  const [treatmentPathInfoImageUrl, setTreatmentPathInfoImageUrl] = useState();
  const [treatmentPathQValue, setTreatmentPathQValue] = useState();
  const [infoTitleValue, setInfoTitleValue] = useState();
  const [infoDescriptionValue, setInfoDescriptionValue] = useState();
  const [infoURLValue, setInfoURLValue] = useState();
  const [infoURLTitleValue, setInfoURLTitleValue] = useState();
  const [treatmentPathTitleValue, setTreatmentPathTitleValue] = useState();
  const [treatmentPathAllValue, settreatmentPathAllValue] = useState();
  useEffect(() => {
    showLoader();
    getActivePageConfig().then((content) => {
      const contentID = getContentID(content.item.pageConfigListObj);
      fetchHomeContents(contentID).then((homeContent) => {
        const {
          bannerImage,
          bannerText,
          treatmentPath1Button,
          treatmentPath2Button,
          treatmentPathDesc,
          treatmentPathImage,
          treatmentPathInfoImage,
          treatmentPathQ,
          treatmentPathTitle,
          treatmentPathAll,
        } = homeContent.item.contentDetailsLists[0].contentItems[0].homeDetails;
        const {
          infoTitle,
          infoDescription,
          infoURL,
          infoURLTitle,
        } = homeContent.item.contentDetailsLists[0].contentItems[0].infoDetails;
        setBannerImageUrl(bannerImage);
        setBannerTextValue(bannerText);
        setTreatmentPath1ButtonValue(treatmentPath1Button);
        setTreatmentPath2ButtonValue(treatmentPath2Button);
        setTreatmentPathDescValue(treatmentPathDesc);
        setTreatmentPathImageUrl(treatmentPathImage);
        setTreatmentPathInfoImageUrl(treatmentPathInfoImage);
        setTreatmentPathQValue(treatmentPathQ);
        setInfoTitleValue(infoTitle);
        setInfoDescriptionValue(infoDescription);
        setInfoURLValue(infoURL);
        setInfoURLTitleValue(infoURLTitle);
        setTreatmentPathTitleValue(treatmentPathTitle);
        settreatmentPathAllValue(treatmentPathAll);
        hideLoader();
      });
    });
    const queryParam = new URLSearchParams(
      window ? window.location.search : {}
    ).get('userID');
    const clientTypequeryParam = new URLSearchParams(
      window ? window.location.search : {}
    ).get('clientType');
    if (JSON.parse(sessionStorage.getItem('userID')) === null) {
      sessionStorage.setItem('userID', JSON.stringify(queryParam));
    }
    if (JSON.parse(sessionStorage.getItem('clientType')) === null) {
      sessionStorage.setItem(
        'clientType',
        JSON.stringify(clientTypequeryParam)
      );
    }
  }, []);

  return (
    <Box className="homeContainer mobHomeContainer">
      {loader}
      <DescriptionBox
        bannerImageUrl={bannerImageUrl}
        bannerText={bannerTextValue}
        treatmentPathQ={treatmentPathQValue}
      />
      <GetStartedBox
        treatmentPath1ButtonValue={treatmentPath1ButtonValue}
        treatmentPath2ButtonValue={treatmentPath2ButtonValue}
        treatmentPathDescValue={treatmentPathDescValue}
        treatmentPathImageUrl={treatmentPathImageUrl}
        treatmentPathInfoImageUrl={treatmentPathInfoImageUrl}
        infoTitleValue={infoTitleValue}
        infoDescriptionValue={infoDescriptionValue}
        infoURLValue={infoURLValue}
        infoURLTitleValue={infoURLTitleValue}
        treatmentPathTitleValue={treatmentPathTitleValue}
        treatmentPathAllValue={treatmentPathAllValue}
      />
      <SafetyStatementBox safetyStatementValue={disclaimerLegalText} />
    </Box>
  );
};

export default Home;
