import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Material UI imports
import { Box } from '@material-ui/core';
// Component imports
import QuestionHeader from '../../components/questionHeader';
import CustomTextField from '../../components/customTextField';
// Utility imports
import { fetchSiteCoreContents } from '../../utility/utils';

const WoundPeriOther = ({
  contentID,
  handlePeriwoundOtherChange,
  periwoundOtherText,
  movePlaceholder,
}) => {
  const [questionTextValue, setQuestionTextValue] = useState();

  const [questionResponseTextValue, setQuestionResponseTextValue] = useState();
  // site core content
  fetchSiteCoreContents(contentID).then((typeContent) => {
    const {
      question2Text,
      question2ResponseText,
    } = typeContent.item.contentDetailsLists[0].contentItems[0].woundCharacteristics;

    setQuestionResponseTextValue(question2ResponseText.replace(/<[^>]+>/g, ''));
    setQuestionTextValue(question2Text.replace(/<[^>]+>/g, ''));
  });
  return (
    <Box>
      <QuestionHeader
        questionTextValue={questionTextValue}
        showInfo={false}
        headerStyle={{
          paddingBottom: '16px',
          fontSize: '25px',
          fontWeight: 'bold',
        }}
      />
      <CustomTextField
        inputVariant="outlined"
        inputType="text"
        handleChange={handlePeriwoundOtherChange}
        inputValue={periwoundOtherText}
        placeHolderText={questionResponseTextValue}
        inputStyle={{ width: '100%', maxWidth: '573px' }}
        containerBoxStyle={{ padding: '8px 0 30px 0' }}
        movePlaceholder={movePlaceholder}
      />
    </Box>
  );
};

WoundPeriOther.propTypes = {
  contentID: PropTypes.string.isRequired,
  handlePeriwoundOtherChange: PropTypes.func.isRequired,
  periwoundOtherText: PropTypes.string.isRequired,
  movePlaceholder: PropTypes.bool.isRequired,
};

export default WoundPeriOther;
