import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
// Material UI imports
import { Box } from '@material-ui/core';
// Component imports
import QuestionHeader from '../../components/questionHeader';
import QuestionDescription from '../../components/questionDescription';
import RadioButton from '../../components/radioButton';
import AlertDialog from '../../components/alertBox';
import CustomButton from '../../components/customButton';
import WoundOdorOther from './WoundOdorOther';
// Utility imports
import {
  fetchSiteCoreContents,
  setTooltipText,
  fetchWoundParametersContent,
  checkValidation,
  createBody,
  fetchWoundRules,
  checkPopup,
  capturePageDataAsync,
  captureMiniValidationData,
} from '../../utility/utils';
import {
  STATUS_CURRENT,
  STATUS_ENTERED,
  STATUS_NOT_ENTERED,
  STATUS_VISITED,
  WND_CAP_RULE,
} from '../../utility/staticText';
// Store imports
import { ApplicationContext } from '../../store/ApplicationState';
import { createObj } from '../../utility/saveAssessment';
import useFullPageLoader from '../../hooks/useFullPageLoader';

function getPageStatus(odorCode, odorOther, showOtherTextbox, isActiveStep) {
  let status = '';
  if (odorCode && odorCode !== '' && !showOtherTextbox) {
    status = STATUS_ENTERED;
  } else if (showOtherTextbox && odorOther === '') {
    status = STATUS_VISITED;
  } else if (showOtherTextbox && odorOther !== '') {
    status = STATUS_ENTERED;
  } else {
    status = isActiveStep ? STATUS_CURRENT : STATUS_NOT_ENTERED;
  }
  return status;
}

const WoundOdor = ({ attInfo }) => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [questionTextValue, setQuestionTextValue] = useState();
  const [questionImageURLValue, setQuestionImageURLValue] = useState();
  const [
    questionDescriptionTextValue,
    setQuestionDescriptionTextValue,
  ] = useState();
  const [infoTitleValue, setInfoTitleValue] = useState();
  const [infoDescriptionValue, setInfoDescriptionValue] = useState();
  const [infoURLValue, setInfoURLValue] = useState();
  const [infoURLTitleValue, setInfoURLTitleValue] = useState();
  const [parameterList, setParameterList] = useState([]);
  const [enterButtonTextValue, setEnterButtonTextValue] = useState();
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState('');
  const [popUpTitle, setPopUpTitle] = useState('');
  const [attributeName, setAttributeName] = useState([]);
  const {
    state: {
      activeStep,
      pageData,
      errorMessage,
      totalStepper,
      minimumValidationData,
    },
    dispatch,
    wndOdorState: {
      selectedOdorParamterCode,
      odorOtherText,
      showLabel,
      showOtherTextbox,
    },
    wndOdorDispatch,
  } = useContext(ApplicationContext);

  const [attribute] = attInfo
    .sort((a, b) => a.attOrder - b.attOrder)
    .filter((att) => att.pageCode === 'WDODR');

  const handleInfoClick = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setAlertOpen(false);
  };

  const executeRulesIfAny = (selectedValue) => {
    const ruleBasedParameters = parameterList.filter(
      (x) => x.parameterCode === selectedValue
    );
    ruleBasedParameters.forEach(async (param) => {
      const { hasRules } = param;
      let response;
      let isPopUp;
      let isWndCapRule;
      if (hasRules) {
        response = await fetchWoundRules(param.parameterCode);
        isPopUp = await checkPopup(response);
        isWndCapRule = response.item.captureRules.filter(
          (x) => x.ruleType === WND_CAP_RULE
        );
      }
      if (isPopUp && isPopUp[0]) {
        setPopUpOpen(true);
        setPopUpMessage(isPopUp[1]);
        setPopUpTitle(isPopUp[2]);
      }
      wndOdorDispatch({
        showOtherTextbox:
          isWndCapRule &&
          isWndCapRule[0] &&
          isWndCapRule[0].activateWoundCapture,
      });
    });
  };

  async function handleRadioChange(event) {
    const { value } = event.target;
    executeRulesIfAny(value);

    const selectedData = parameterList.filter(
      (x) => x.parameterCode === value
    )[0];
    wndOdorDispatch({
      selectedOdorParamterCode: event.target.value,
      odorOtherText: '',
      showLabel: false,
    });
    const saveObj = [];
    saveObj.push(createObj('WoundOdorCode', value));
    saveObj.push(createObj('woundOdorOtherDesc', ''));
    const updatedPageData = await capturePageDataAsync(
      attInfo[0].attCode,
      value,
      selectedData,
      pageData,
      attributeName,
      activeStep,
      selectedData.parameterDesc,
      saveObj
    );
    dispatch({ pageData: updatedPageData });
    const updateMinimumValidationData = captureMiniValidationData(
      [attInfo[0].attCode][0],
      minimumValidationData,
      true
    );
    dispatch({ updateMinimumValidationData });
  }

  async function handleOdorOtherChange(e) {
    const { value } = e.target;
    wndOdorDispatch({
      odorOtherText: value,
    });
    wndOdorDispatch({
      showLabel: !!value,
    });
    pageData[activeStep].data[attInfo[0].attCode].otherText = value;
    pageData[activeStep].data[
      attInfo[0].attCode
    ].displayText = `Other: ${value}`;
    const sa = pageData[activeStep].data[
      attInfo[0].attCode
    ].saveAssessmentObj.filter((x) => x.key === 'woundOdorOtherDesc');
    if (sa.length > 0) {
      pageData[activeStep].data[attInfo[0].attCode].saveAssessmentObj.forEach(
        (x, idx) => {
          if (x.key === 'woundOdorOtherDesc') {
            pageData[activeStep].data[attInfo[0].attCode].saveAssessmentObj[
              idx
            ].value = e.target.value;
          }
        }
      );
    } else {
      pageData[activeStep].data[attInfo[0].attCode].saveAssessmentObj.push(
        createObj('woundOdorOtherDesc', value)
      );
    }
  }

  const handlePopUpClose = () => {
    setPopUpOpen(false);
  };

  function validateNextButton() {
    if (activeStep + 1 === totalStepper) {
      dispatch({ showNextButton: false });
    }
  }

  // handle submit
  function handleSubmit() {
    const validateBody = [];
    const body1 = createBody(selectedOdorParamterCode, 'WDODR');
    validateBody.push(body1);
    if (showOtherTextbox) {
      const body2 = createBody(odorOtherText, 'WDODR');
      validateBody.push(body2);
    }

    const isValid = checkValidation(validateBody);
    if (isValid) {
      if (pageData[activeStep + 1].enabled) {
        if (activeStep < totalStepper) {
          dispatch({ activeStep: activeStep + 1 });
          if (totalStepper !== activeStep + 1) {
            pageData[activeStep + 1].isActiveStep = true;
          }
        }
        if (activeStep === totalStepper) {
          dispatch({ lastPage: activeStep });
        }
        validateNextButton();
        dispatch({ previousPage: activeStep });
      }
    } else {
      setAlertOpen(true);
    }
  }

  const tooltipTitle = setTooltipText(
    infoTitleValue,
    infoDescriptionValue,
    infoURLValue,
    infoURLTitleValue
  );
  // site core content
  fetchSiteCoreContents(attribute.contentID).then((odorContent) => {
    const {
      questionText,
      questionImage,
      questionDescriptionText,
      enterButtonText,
    } = odorContent.item.contentDetailsLists[0].contentItems[0].woundCharacteristics;
    const {
      infoTitle,
      infoDescription,
      infoURL,
      infoURLTitle,
    } = odorContent.item.contentDetailsLists[0].contentItems[0].infoDetails;

    setQuestionDescriptionTextValue(
      questionDescriptionText.replace(/<[^>]+>/g, '')
    );
    setQuestionImageURLValue(questionImage);
    setQuestionTextValue(questionText.replace(/<[^>]+>/g, ''));
    setInfoTitleValue(infoTitle.replace(/<[^>]+>/g, ''));
    setInfoDescriptionValue(infoDescription);
    setInfoURLValue(infoURL);
    setInfoURLTitleValue(infoURLTitle);
    setEnterButtonTextValue(enterButtonText);
  });

  useEffect(() => {
    showLoader();
    fetchWoundParametersContent(attribute.attCode).then((response) => {
      setAttributeName(response.item.parameters[0].attributeName);
      setParameterList(
        response.item.parameters[0].parameterGroup.sort((a, b) =>
          a.sortPriority > b.sortPriority ? 1 : -1
        )
      );
      hideLoader();
    });
  }, []);

  useEffect(() => {
    const validateToggle = () => {
      pageData.forEach((item, idx) => {
        if (idx === activeStep) {
          pageData[idx].status = getPageStatus(
            selectedOdorParamterCode,
            odorOtherText,
            showOtherTextbox,
            pageData[idx].isActiveStep || pageData[activeStep].firstPage
          );
          dispatch({ pageData });
        }
      });
    };
    validateToggle();
  }, [
    activeStep,
    attribute.attCode,
    dispatch,
    odorOtherText,
    pageData,
    selectedOdorParamterCode,
    showOtherTextbox,
  ]);
  return (
    <Box className="attributeContainer mobAttributeContainer">
      {loader}
      <QuestionHeader
        questionTextValue={questionTextValue}
        questionImageURLValue={questionImageURLValue}
        handleInfoClick={handleInfoClick}
        tooltipTitle={tooltipTitle}
        open={open}
        setRef={() => {
          setOpen(false);
        }}
      />
      <QuestionDescription
        questionDescriptionTextValue={questionDescriptionTextValue}
      />
      <RadioButton
        value={selectedOdorParamterCode}
        handleRadioChange={handleRadioChange}
        groupValue={parameterList && parameterList}
        showDescriptor
        rowAlign={false}
      />
      {showOtherTextbox && (
        <WoundOdorOther
          contentID={attribute.contentID}
          handleOdorOtherChange={handleOdorOtherChange}
          odorOtherText={odorOtherText}
          movePlaceholder={showLabel}
        />
      )}

      <CustomButton
        value={enterButtonTextValue}
        handleSubmit={handleSubmit}
        width="100px"
      />
      <AlertDialog
        isOpen={alertOpen}
        handleClose={handleClose}
        popUpPositiveButton={handleClose}
        title="Notification"
      >
        {errorMessage}
      </AlertDialog>
      <AlertDialog
        isOpen={popUpOpen}
        handleClose={handlePopUpClose}
        popUpPositiveButton={handlePopUpClose}
        title={popUpTitle}
        buttonName="Got it"
      >
        {popUpMessage}
      </AlertDialog>
    </Box>
  );
};

WoundOdor.propTypes = {
  attInfo: PropTypes.arrayOf(
    PropTypes.shape({
      attCode: PropTypes.string,
      attOrder: PropTypes.number,
      pageCode: PropTypes.string,
      contentID: PropTypes.string,
    })
  ).isRequired,
};

export default WoundOdor;
