export const STATUS_VISITED = 'visited';
export const STATUS_CURRENT = 'current';
export const STATUS_ENTERED = 'entered';
export const STATUS_GRAY = 'gray';
export const STATUS_COMPLETED = 'complete';
export const STATUS_NOT_ENTERED = '';
export const WOUND_THERAPY_GUIDE_BREADCRUMB_TEXT = 'Wound Therapy Guide';
export const WOUND_GOAL_BREADCRUMB_TEXT = 'Wound Goal';
export const ERROR_MESSAGE = 'Please complete entries';
export const WOUND_SIZE_ERROR = 'Must be greater than 0';
export const POP_UP = 'popup';
export const WND_CAP_RULE = 'wdcap';
export const WND_UNK_RULE = 'wdunk';
export const WND_NONE_RULE = 'wdnon';
export const NO_WOUND_IS_PRESENT = 'DEPNW';
export const DEPTH_UNKNOWN = 'DEPUK';
export const WOUNDGOAL_PAGECODE = 'WNDGL';
export const WOUND_EXUDATE_ATTR_CODE = 'EXA';
export const MOBILE_BREAKPOINT = '926';
export const RELATED_PRODUCTS = 'Related 3M products';
export const SELECTED_PRODUCTS = 'Selected 3M products';
export const CHECKBOX_STATES_CHECKED = 'Checked';
export const CHECKBOX_STATES_INDETERMINATE = 'Indeterminate';
export const CHECKBOX_STATES_EMPTY = 'Empty';
export const LASTNAME_REQUIRED = 'Last Name is required.';
export const D0B_REQUIRED = 'Date of birth is required.';
export const ZIPCODE_REQUIRED = 'Zip Code is required.';
export const ZIPCODE_REQUIRED_WITH_FIVE = 'Zip Code must be 5 digits.';
