import wndPainInitialState from '../initialState/wndPainInitialState';

const wndPainReducer = (state, action) => {
  switch (action.type) {
    case 'RESET_PAIN_STATE':
      return {
        ...wndPainInitialState,
      };
    default: {
      return {
        ...state,
        ...action,
      };
    }
  }
};

export default wndPainReducer;
