import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

export default function CustomLabel({ value, labelStyle, boxStyle, onClick }) {
  return (
    <Box style={boxStyle}>
      <Typography onClick={onClick} style={labelStyle}>
        {parse(value)}
      </Typography>
    </Box>
  );
}
CustomLabel.defaultProps = {
  labelStyle: {
    fontSize: 'normal',
    fontWeight: 'none',
    width: 'auto',
    textAlign: 'none',
    color: 'normal',
    marginTop: '0px',
    lineHeight: 'auto',
    textAlignLast: 'none',
    cursor: 'default',
    paddingLeft: '0px',
  },
  boxStyle: {
    marginTop: '0px',
    width: 'auto',
  },
};
CustomLabel.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  labelStyle: PropTypes.objectOf(PropTypes.string.isRequired),
  boxStyle: PropTypes.objectOf(PropTypes.string.isRequired),
};
