import wndEdgesInitialState from '../initialState/wndEdgesInitialState';

const wndEdgesReducer = (state, action) => {
  switch (action.type) {
    case 'RESET_EDGES_STATE':
      return {
        ...wndEdgesInitialState,
      };
    default: {
      return {
        ...state,
        ...action,
      };
    }
  }
};

export default wndEdgesReducer;
