import React from 'react';

const FullPageLoader = () => (
  <div className="loaderContainer">
    <svg
      className="spinner"
      width="47px"
      height="47px"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="path"
        fill="none"
        strokeWidth="8"
        strokeLinecap="round"
        cx="24"
        cy="24"
        r="20"
      />
    </svg>
  </div>
);

export default FullPageLoader;
