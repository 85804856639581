import React, { useState, useContext, useEffect, useRef } from 'react';
import parse from 'html-react-parser';
import { NavLink, useHistory } from 'react-router-dom';
// Material UI imports
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  AppBar,
  Toolbar,
} from '@material-ui/core';
import CustomLargeButton from '../components/customLargeButton';
import getCmsContent from '../api/cms';
import CustomLabel from '../components/customLabel/CustomLabel';
import LightTooltip from '../components/lightTooltip/LightTooltip';
import TreatmentOptions from '../components/recommendation/TreatmentOptions';
import { ApplicationContext } from '../store/ApplicationState';
import {
  MOBILE_BREAKPOINT,
  CHECKBOX_STATES_CHECKED,
  CHECKBOX_STATES_EMPTY,
  CHECKBOX_STATES_INDETERMINATE,
} from '../utility/staticText';
import useOutsideclick from '../utility/useOutsideclick';
import useFullPageLoader from '../hooks/useFullPageLoader';
import { getActivePageConfig, saveFinalRecommendtation } from '../api/common';
import { createObj, SaveAssessmentDetail } from '../utility/saveAssessment';
import IndeterminateCheckBox from '../components/indeterminateCheckBox';

function getContentID(pageConfigList) {
  const pageObj = pageConfigList.filter((page) => page.pageCode === 'ALTMT');
  return pageObj[0].contentID;
}

async function fetchWoundRecommendationContents(contentId) {
  let content = JSON.parse(localStorage.getItem(contentId));
  if (!content) {
    const body = {
      token: '',
      contentId,
    };
    content = await getCmsContent(body);
    localStorage.setItem(contentId, JSON.stringify(content));
  }
  return content;
}

const useStyles = makeStyles(() => ({
  buttonStyle: {
    fontSize: '16px',
    'font-weight': '700',
  },
  shellBoxDisplay: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-end',
  },
  mainBox: {
    minHeight: 'calc(100vh - 145px)',
    width: '100%',
  },
  pageTitleStyle: {
    maxWidth: '1040px',
    minHeight: '56px',
  },
}));

const AllTherapies = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const theme = useTheme();
  const matchesMobileWidth = useMediaQuery(
    theme.breakpoints.down(MOBILE_BREAKPOINT)
  );
  const classes = useStyles();
  const history = useHistory();
  const [pageTitle, setPageTitle] = useState('');
  const [pageIntroduction, setPageIntroduction] = useState('');
  const [instruction, setInstruction] = useState('');
  const [instructionTitle, setInstructionTitle] = useState('');
  const [navButtonText, setNavButtonText] = useState('');
  const [finalButtonText, setfinalButtonText] = useState('');
  const [backWardArrowIcon, setbackWardArrowIcon] = useState('');
  const [switchTopText, setSwitchTopText] = useState('');
  const [switchBottomText, setSwitchBottomText] = useState('');
  const [finalButtonOpen, setFinalButtonOpen] = useState(false);
  const [finalToolTipMessage, setFinalToolTipMessage] = useState(false);
  const ref = useRef(null);
  useOutsideclick(ref, () => {
    setFinalButtonOpen(false);
  });

  const {
    state: { treatmentPathway, disclaimerLegalText, sessionID },
    wndRecommState: { treatmentList, selectAllTreatment },
    wndRecommDispatch,
    dispatch,
  } = useContext(ApplicationContext);

  function createBody(selectedList) {
    const obj = [];
    selectedList.forEach((res) => {
      const selectedProductCode = [];
      res.relatedProducts.forEach((displayData) => {
        if (displayData.selectedProduct) {
          selectedProductCode.push(displayData.productCode);
        }
      });
      const selectProd = selectedProductCode.join();
      const treatmentFinalCode = {
        sectionCode: res.sectionCode,
        treatmentCode: res.treatmentCode,
        productCode: selectProd.replace(/,/g, ':'),
      };
      obj.push(treatmentFinalCode);
    });
    const reqBody = {
      sessionID,
      treatmentList: obj,
      treatmentPathWay: treatmentPathway,
    };
    return reqBody;
  }

  const handleClick = () => {
    wndRecommDispatch({
      treatmentList: [],
      selectAllTreatment: CHECKBOX_STATES_CHECKED,
    });
    dispatch({ isGoalPathWay: false, isAllTherapyWay: false });
    history.push('/home');
  };

  function handleFinalButtonClick() {
    saveFinalRecommendtation(
      JSON.stringify(createBody(treatmentList.filter((x) => x.selected)))
    );
    history.push('/woundTherapySelection');
  }

  function handleInfoFinalButtonClick() {
    setFinalButtonOpen(!finalButtonOpen);
    setFinalToolTipMessage(
      <div>
        <h3>Select a treatment</h3>
        <Typography style={{ fontSize: '15px' }}>
          Select at least one treatment to finalize your recommendation.
        </Typography>
      </div>
    );
  }

  useEffect(() => {
    showLoader();
    dispatch({ isAllTherapyWay: true });
    getActivePageConfig().then((content) => {
      const contentID = getContentID(content.item.pageConfigListObj);
      fetchWoundRecommendationContents(contentID).then((woundPageContent) => {
        const {
          woundRecommendationPageTitle,
          woundRecommendationIntroduction,
          woundRecommendationInstruction,
          woundRecommendationTitle,
          navigationButtonText,
          finalizeButtonText,
          backwardArrowIcon,
          woundRecommendationSwitchTop,
          woundRecommendationSwitchBottom,
        } = woundPageContent.item.contentDetailsLists[0].contentItems[0].woundRecommendationDetails;

        setPageTitle(woundRecommendationPageTitle);
        setPageIntroduction(woundRecommendationIntroduction);
        setInstruction(woundRecommendationInstruction);
        setInstructionTitle(woundRecommendationTitle);
        setNavButtonText(navigationButtonText);
        setfinalButtonText(finalizeButtonText);
        setbackWardArrowIcon(backwardArrowIcon);
        setSwitchTopText(woundRecommendationSwitchTop);
        setSwitchBottomText(woundRecommendationSwitchBottom);
      });
      hideLoader();
    });
  }, [dispatch, wndRecommDispatch]);

  useEffect(() => {
    dispatch({
      isGoalPathWay: false,
      isAllTherapyWay: true,
      treatmentPathway: 'THRAL',
    });
    const assessmentObj = [];
    const obj2 = createObj('sessionID', sessionID);
    const obj3 = createObj('treatmentPathway', 'THRAL');
    let obj5 = '';
    if (JSON.parse(sessionStorage.getItem('userID'))) {
      obj5 = createObj('userID', JSON.parse(sessionStorage.getItem('userID')));
    } else {
      const queryParam = new URLSearchParams(
        window ? window.location.search : {}
      ).get('userID');
      sessionStorage.setItem('userID', JSON.stringify(queryParam));
      obj5 = createObj('userID', queryParam);
    }
    let obj6 = '';
    if (JSON.parse(sessionStorage.getItem('clientType'))) {
      obj6 = createObj(
        'clientType',
        JSON.parse(sessionStorage.getItem('clientType'))
      );
    } else {
      const queryParam = new URLSearchParams(
        window ? window.location.search : {}
      ).get('clientType');
      sessionStorage.setItem('clientType', JSON.stringify(queryParam));
      obj6 = createObj('clientType', queryParam);
    }

    assessmentObj.push(obj2, obj3, obj5, obj6);
    SaveAssessmentDetail(assessmentObj);
  }, []);

  useEffect(() => {
    const updatedList = treatmentList.map((x) => ({ ...x, imgUp: false }));
    wndRecommDispatch({
      treatmentList: updatedList,
    });
  }, []);
  function getUpdatedTreatmentList(value) {
    const updatedlist = treatmentList.map((x) => ({
      ...x,
      selected: value,
    }));
    const updatedProductList = updatedlist.map((groupe) => ({
      ...groupe,
      relatedProducts: groupe.relatedProducts.map((element) => ({
        ...element,
        selectedProduct: value,
      })),
    }));
    return updatedProductList;
  }

  function setAlltreatment() {
    let updatedChecked;

    if (selectAllTreatment === CHECKBOX_STATES_CHECKED) {
      updatedChecked = CHECKBOX_STATES_EMPTY;
      const updatedProductList = getUpdatedTreatmentList(false);
      wndRecommDispatch({
        treatmentList: updatedProductList,
      });
    } else if (selectAllTreatment === CHECKBOX_STATES_EMPTY) {
      updatedChecked = CHECKBOX_STATES_CHECKED;
      const updatedProductList = getUpdatedTreatmentList(true);
      wndRecommDispatch({
        treatmentList: updatedProductList,
      });
    } else if (selectAllTreatment === CHECKBOX_STATES_INDETERMINATE) {
      updatedChecked = CHECKBOX_STATES_CHECKED;
      const updatedProductList = getUpdatedTreatmentList(true);
      wndRecommDispatch({
        treatmentList: updatedProductList,
      });
    }
    wndRecommDispatch({ selectAllTreatment: updatedChecked });
  }
  function returnSelectAllTreatment() {
    let returnValue = null;
    returnValue = (
      <IndeterminateCheckBox
        label="Select all treatments"
        value={selectAllTreatment}
        onChange={setAlltreatment}
      />
    );
    return returnValue;
  }

  return (
    <Box className={classes.mainBox}>
      {loader}
      <div className="recommRoot mobRecommRoot">
        <div className="largeButtonStyle mobLargeButtonStyle mobMargin">
          <CustomLargeButton
            handleClick={handleClick}
            buttonStyle={{
              borderColor: '#0049BD',
              width: matchesMobileWidth ? '100%' : 'auto',
              height: '48px',
            }}
            buttonText={navButtonText}
            buttonLeftSideIcon={backWardArrowIcon}
          />
        </div>
        <div className="pageIntroStyle mobPageIntroStyle mobMargin">
          <CustomLabel
            labelStyle={{
              fontSize: matchesMobileWidth ? '28px' : '48px',
              fontWeight: '700',
              lineHeight: matchesMobileWidth ? '32px' : '56px',
            }}
            value={pageTitle}
          />
        </div>
        <p className="pageDesc mobPageIntroStyle mobMargin">
          {parse(pageIntroduction, {
            replace(node) {
              let returnValue = '';
              if (node.parent && node.parent.name === 'span') {
                returnValue = (
                  <NavLink
                    onClick={handleClick}
                    to="/home"
                    underline="always"
                    style={{
                      fontSize: '16px',
                      lineHeight: '20px',
                      color: '#0049BD',
                    }}
                  >
                    return to the beginning
                  </NavLink>
                );
              }
              return returnValue;
            },
          })}
        </p>
        <div className="allTherapyContent mobAllTherapyContent mobMargin">
          <div>
            <CustomLabel
              labelStyle={{
                fontSize: '24px',
                lineHeight: '24px',
                fontWeight: 700,
              }}
              boxStyle={{ marginTop: '0px' }}
              value={instructionTitle}
            />
            <CustomLabel
              labelStyle={{
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: 400,
              }}
              boxStyle={{ marginTop: '16px' }}
              value={instruction}
            />
          </div>
          <TreatmentOptions
            isFinalRecommendation={false}
            topSwitch={switchTopText}
            bottomSwitch={switchBottomText}
            pathway="AllTherapy"
          />
          {treatmentList.filter((x) => x.selected).length > 0 && (
            <AppBar className="recommFloatBar recommMobFloatBar">
              <Toolbar className="recommToolBar recommMobileToolBar">
                {returnSelectAllTreatment()}
                <Button
                  variant="contained"
                  color="primary"
                  className="btnFinalizeAllTherapy mobBtnFinalize mobMargin buttonStyle"
                  onClick={handleFinalButtonClick}
                >
                  <Typography className="finalButtonTextStyle finalMobButtonTextStyle">
                    {finalButtonText}
                  </Typography>
                </Button>
              </Toolbar>
            </AppBar>
          )}
          {treatmentList.filter((x) => x.selected).length === 0 && (
            <AppBar className="recommFloatBar recommMobFloatBar">
              <Toolbar className="recommToolBar recommMobileToolBar">
                {returnSelectAllTreatment()}
                <span>
                  <LightTooltip
                    title={finalToolTipMessage}
                    arrow
                    ref={ref}
                    placement="top"
                    style={{ marginLeft: '2px' }}
                    open={finalButtonOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    onClick={() => handleInfoFinalButtonClick()}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      className="btnFinalizeAllTherapy mobBtnFinalize mobMargin buttonStyle"
                    >
                      <Typography className="finalButtonTextStyle finalMobButtonTextStyle">
                        {finalButtonText}
                      </Typography>
                    </Button>
                  </LightTooltip>
                </span>
              </Toolbar>
            </AppBar>
          )}
        </div>
        <div className="disclaimerFloatingBox mobDisclaimerFloatingBox">
          <p className="disclaimerText">{parse(disclaimerLegalText || '')}</p>
        </div>
      </div>
    </Box>
  );
};

export default AllTherapies;
