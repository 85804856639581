// React imports
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// Material UI imports
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Box } from '@material-ui/core';
// utils imports
import { fetchSiteCoreContents } from '../../utility/utils';
import { ApplicationContext } from '../../store/ApplicationState';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '20px',
    flexGrow: 1,
    position: 'relative',
    bottom: 0,
    width: '100%',
  },
  recomFooter: {
    marginTop: '20px',
    flexGrow: 1,
    position: 'relative',
    bottom: '90px',
    width: '100%',
  },
  linkBox: {
    padding: '8px 0',
  },
  footer: {
    background: '#323234',
    width: '100%',
    padding: '0',
    minHeight: '96px',
  },
  whiteLinks: {
    padding: '10px',
    display: 'inline',
    borderBottom: '1px',
    color: 'white',
    height: '20px',
    '@media(max-width: 1125px)': {
      padding: '0px',
    },
  },
  vertical: {
    backgroundColor: 'white',
    display: 'inline-block',
    width: '1px',
    height: '11px',
    border: '1px',
    alignSelf: 'center',
    margin: '0 8px',
  },
  logo: {
    padding: '10px 10px 10px 0px',
    borderBottom: '1px',
    textDecoration: 'none',
    color: 'white',
    height: '20px',
  },
}));

const Footer = () => {
  const classes = useStyles();
  const [copyRightText, setCopyRightText] = useState();
  const [accessibilityTitle, setAccessibilityTitle] = useState();
  const [accessibilityLink, setAccessibilityLink] = useState();
  const [legalLink, setLegalLink] = useState();
  const [legalTitle, setLegalTitle] = useState();
  const [logoImage, setLogoImage] = useState();
  const [logoUrl, setLogoUrl] = useState();
  const [privacyLink, setPrivacyLink] = useState();
  const [privacyTitle, setPrivacyTitle] = useState();
  const [supportLink, setSupportLink] = useState();
  const [supportTitle, setSupportTitle] = useState();
  const [wcTitle, setWcTitle] = useState();
  const [wcLink, setWcLink] = useState();
  const [fbImage, setFbImage] = useState();
  const [fbLink, setFbLink] = useState();
  const [fbTitle, setFbTitle] = useState();
  const [lnkdinImage, setLnkdinImage] = useState();
  const [lnkdinLink, setLnkdinLink] = useState();
  const [lnkdinTitle, setLnkdinTitle] = useState();
  const [twtrImage, setTwtrImage] = useState();
  const [twtrLink, setTwtrLink] = useState();
  const [twtrTitle, setTwtrTitle] = useState();
  const [ytImage, setYtImage] = useState();
  const [ytLink, setYtLink] = useState();
  const [ytTitle, setYtTitle] = useState();
  const location = useLocation();

  const { dispatch } = useContext(ApplicationContext);
  const setFooterContent = (contents) => {
    const {
      copyRight,
      footerAcessbilityLink,
      footerAcessbilityTitle,
      footerLegalLink,
      footerLegalTitle,
      footerLogoImageURL,
      footerLogoURL,
      footerPrivacyLink,
      footerPrivacyTitle,
      footerSupportLink,
      footerSupportTitle,
      woundCareTitle,
      woundCareURL,
      disclaimerText,
    } = contents.item.contentDetailsLists[0].contentItems[0].footerDetails;
    const {
      faceBookImageURL,
      faceBookLink,
      faceBookTitle,
      linkedinImageURL,
      linkedinLink,
      linkedinTitle,
      twitterImageURL,
      twitterLink,
      twitterTitle,
      youTubeImageURL,
      youTubeLink,
      youtubeTitle,
    } = contents.item.contentDetailsLists[0].contentItems[0].socialMediaItems;
    dispatch({ disclaimerLegalText: disclaimerText });
    setCopyRightText(copyRight);
    setAccessibilityTitle(footerAcessbilityTitle);
    setAccessibilityLink(footerAcessbilityLink);
    setLegalTitle(footerLegalTitle);
    setLegalLink(footerLegalLink);
    setLogoImage(footerLogoImageURL);
    setLogoUrl(footerLogoURL);
    setPrivacyTitle(footerPrivacyTitle);
    setPrivacyLink(footerPrivacyLink);
    setSupportTitle(footerSupportTitle);
    setSupportLink(footerSupportLink);
    setWcTitle(woundCareTitle);
    setWcLink(woundCareURL);
    setFbImage(faceBookImageURL);
    setFbLink(faceBookLink);
    setFbTitle(faceBookTitle);
    setLnkdinImage(linkedinImageURL);
    setLnkdinLink(linkedinLink);
    setLnkdinTitle(linkedinTitle);
    setTwtrImage(twitterImageURL);
    setTwtrLink(twitterLink);
    setTwtrTitle(twitterTitle);
    setYtImage(youTubeImageURL);
    setYtLink(youTubeLink);
    setYtTitle(youtubeTitle);
  };

  useEffect(() => {
    fetchSiteCoreContents('WTGFOOTER').then((contents) => {
      setFooterContent(contents);
    });
  }, []);

  return (
    <div className="footer">
      {window.location.pathname !== '/deleteAccount' && (
        <Box
          className={
            location.pathname === '/woundRecommendation' ||
            location.pathname === '/allTherapies'
              ? 'recomFooter recomMobFooter'
              : classes.root
          }
        >
          <AppBar className="appBar footerBar mobAppBar">
            <Toolbar disableGutters className={classes.footer}>
              <Box className="mainContainer mobMainContainer">
                <Box
                  className="linksContainerLeft mobLinksContainerLeft"
                  flexWrap="wrap"
                >
                  <Box className={classes.linkBox}>
                    <a href={logoUrl} target="_blank" rel="noreferrer">
                      <img
                        alt="logo"
                        src={logoImage}
                        className="footerLogo mobFooterLogo"
                      />
                    </a>
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                      marginRight: '25px',
                    }}
                  >
                    <Box p={1} className={classes.linkBox}>
                      <a
                        href={legalLink}
                        target="_blank"
                        rel="noreferrer"
                        className={classes.whiteLinks}
                      >
                        {legalTitle}
                      </a>
                    </Box>
                    <Box className={classes.vertical} />
                    <Box p={1} className={classes.linkBox}>
                      <a
                        href={privacyLink}
                        target="_blank"
                        rel="noreferrer"
                        className={classes.whiteLinks}
                      >
                        {privacyTitle}
                      </a>
                    </Box>
                    <Box className={classes.vertical} />
                    <Box p={1} className={classes.linkBox}>
                      <a
                        href={supportLink}
                        target="_blank"
                        rel="noreferrer"
                        className={classes.whiteLinks}
                      >
                        {supportTitle}
                      </a>
                    </Box>
                    <Box className={classes.vertical} />
                    <Box p={1} className={classes.linkBox}>
                      <a
                        href={accessibilityLink}
                        target="_blank"
                        rel="noreferrer"
                        className={classes.whiteLinks}
                      >
                        {accessibilityTitle}
                      </a>
                    </Box>
                  </Box>
                  <Box>
                    <p className="copyRight mobCopyRight">{copyRightText}</p>
                  </Box>
                </Box>
                <Box
                  className="linksContainerRight mobLinksContainerRight"
                  flexWrap="wrap"
                >
                  <Box p={1} className={classes.linkBox}>
                    <a
                      href={wcLink}
                      className="whiteSocialLinks mobWhiteSocialLinks"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {wcTitle}
                    </a>
                  </Box>
                  <Box p={1}>
                    <a href={fbLink} target="_blank" rel="noreferrer">
                      <img
                        alt={fbTitle}
                        src={fbImage}
                        className="whiteSocialLinks mobWhiteSocialLinks"
                      />
                    </a>
                  </Box>
                  <Box p={1}>
                    <a href={lnkdinLink} target="_blank" rel="noreferrer">
                      <img
                        alt={lnkdinTitle}
                        src={lnkdinImage}
                        className="whiteSocialLinks mobWhiteSocialLinks"
                      />
                    </a>
                  </Box>
                  <Box p={1}>
                    <a href={twtrLink} target="_blank" rel="noreferrer">
                      <img
                        alt={twtrTitle}
                        src={twtrImage}
                        className="whiteSocialLinks mobWhiteSocialLinks"
                      />
                    </a>
                  </Box>
                  <Box style={{ padding: '0px' }}>
                    <a href={ytLink} target="_blank" rel="noreferrer">
                      <img
                        alt={ytTitle}
                        src={ytImage}
                        className="whiteSocialLinks mobWhiteSocialLinks"
                      />
                    </a>
                  </Box>
                </Box>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
      )}
    </div>
  );
};
export default Footer;
