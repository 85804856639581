/* eslint-disable prefer-destructuring */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
// Material UI imports
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
// Component imports
import QuestionHeader from '../../components/questionHeader';
import QuestionDescription from '../../components/questionDescription';
import AlertDialog from '../../components/alertBox';
import CustomButton from '../../components/customButton';
import WoundEdgesOther from './WoundEdgesOther';
// Utility imports
import {
  fetchSiteCoreContents,
  setTooltipText,
  fetchWoundParametersContent,
  checkValidation,
  createBody,
  fetchWoundRules,
  checkPopup,
  captureMiniValidationData,
} from '../../utility/utils';
import {
  MOBILE_BREAKPOINT,
  STATUS_CURRENT,
  STATUS_ENTERED,
  STATUS_NOT_ENTERED,
  STATUS_VISITED,
  WND_CAP_RULE,
  WND_UNK_RULE,
} from '../../utility/staticText';
// Store imports
import { ApplicationContext } from '../../store/ApplicationState';
import useFullPageLoader from '../../hooks/useFullPageLoader';
import CheckBox from '../../components/checkBox';

function getPageStatus(
  edgesCode,
  edgesOther,
  showEdgesOtherTextbox,
  isActiveStep
) {
  let status = '';
  if (
    edgesCode &&
    edgesCode.some((x) => x.selected) &&
    !showEdgesOtherTextbox
  ) {
    status = STATUS_ENTERED;
  } else if (showEdgesOtherTextbox && edgesOther === '') {
    status = STATUS_VISITED;
  } else if (showEdgesOtherTextbox && edgesOther !== '') {
    status = STATUS_ENTERED;
  } else {
    status = isActiveStep ? STATUS_CURRENT : STATUS_NOT_ENTERED;
  }
  return status;
}

const WoundEdges = ({ attInfo }) => {
  const theme = useTheme();
  const matchesMobileWidth = useMediaQuery(
    theme.breakpoints.down(MOBILE_BREAKPOINT)
  );
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState('');
  const [popUpTitle, setPopUpTitle] = useState('');
  const [questionTextValue, setQuestionTextValue] = useState();
  const [questionImageURLValue, setQuestionImageURLValue] = useState();
  const [
    questionDescriptionTextValue,
    setQuestionDescriptionTextValue,
  ] = useState();
  const [infoTitleValue, setInfoTitleValue] = useState();
  const [infoDescriptionValue, setInfoDescriptionValue] = useState();
  const [infoURLValue, setInfoURLValue] = useState();
  const [infoURLTitleValue, setInfoURLTitleValue] = useState();
  const [enterButtonTextValue, setEnterButtonTextValue] = useState();
  // eslint-disable-next-line no-unused-vars
  const [attributeName, setAttributeName] = useState([]);

  const {
    state: {
      activeStep,
      pageData,
      errorMessage,
      totalStepper,
      minimumValidationData,
    },
    dispatch,
    wndEdgesState: {
      edgesOtherText,
      showEdgesOtherLabel,
      showEdgesOtherTextbox,
      edgewoundList,
      edgesOtherParamCode,
      edgeUnknownParamCode,
    },
    wndEdgesDispatch,
  } = useContext(ApplicationContext);

  const [attribute] = attInfo
    .sort((a, b) => a.attOrder - b.attOrder)
    .filter((att) => att.pageCode === 'WDEDG');

  const handleInfoClick = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setAlertOpen(false);
  };
  const handlePopUpClose = () => {
    setPopUpOpen(false);
  };

  const executeRulesIfAny = async (selectedValue) => {
    const ruleBasedParameters = edgewoundList.filter(
      (x) => x.parameterCode === selectedValue
    );
    const param = ruleBasedParameters[0];
    const { hasRules } = param;
    let isPopUp;
    let isWndCapRule;
    let isWndUnkRule;
    let unknownParamCode;
    if (hasRules) {
      const response = await fetchWoundRules(param.parameterCode);
      isPopUp = await checkPopup(response);
      isWndCapRule = response.item.captureRules.filter(
        (x) => x.ruleType === WND_CAP_RULE
      );
      isWndUnkRule = response.item.captureRules.filter(
        (x) => x.ruleType === WND_UNK_RULE
      );

      if (isPopUp && isPopUp[0]) {
        setPopUpOpen(true);
        setPopUpMessage(isPopUp[1]);
        setPopUpTitle(isPopUp[2]);
      }
      if (
        isWndCapRule &&
        isWndCapRule[0] &&
        isWndCapRule[0].activateWoundCapture
      ) {
        wndEdgesDispatch({
          showEdgesOtherTextbox: isWndCapRule[0].activateWoundCapture,
          edgesOtherParamCode: selectedValue,
        });
      }
      if (
        isWndUnkRule &&
        isWndUnkRule[0] &&
        isWndUnkRule[0].disableOtherParams
      ) {
        const updatedEdgeList = edgewoundList.map((x) => ({
          ...x,
          selected: x.parameterCode === selectedValue,
          disabled: x.parameterCode !== selectedValue,
        }));
        unknownParamCode = selectedValue;
        wndEdgesDispatch({
          disableOtherParams: isWndUnkRule[0].disableOtherParams,
          edgeUnknownParamCode: selectedValue,
          edgesOtherParamCode: '',
          showEdgesOtherTextbox: false,
          edgesOtherText: '',
          edgewoundList: updatedEdgeList,
        });
      }
    }
    return [isWndUnkRule, unknownParamCode];
  };

  function handleEdgesOtherChange(e) {
    const { value } = e.target;
    wndEdgesDispatch({
      edgesOtherText: value,
      showEdgesOtherLabel: !!value,
    });
    if (pageData[activeStep].data) {
      pageData[activeStep].data[attInfo[0].attCode].forEach((x) => {
        if (x.parameterCode === edgesOtherParamCode) {
          Object.assign(x, {
            OtherText: value,
            displayText: `Other: ${value}`,
            hasRule: false,
          });
        }
      });
      dispatch({ pageData });
    }
  }

  function validateNextButton() {
    if (activeStep + 1 === totalStepper) {
      dispatch({ showNextButton: false });
    }
  }
  // handle submit
  function handleSubmit() {
    const { length } = edgewoundList.filter((x) => x.selected);
    const validateBody = [];
    const body1 = createBody(length, 'WDEDG');
    validateBody.push(body1);
    if (showEdgesOtherTextbox) {
      const body2 = createBody(edgesOtherText, 'WDEDG');
      validateBody.push(body2);
    }

    const isValid = checkValidation(validateBody);
    if (isValid) {
      if (pageData[activeStep + 1].enabled) {
        if (activeStep < totalStepper) {
          dispatch({ activeStep: activeStep + 1 });
          if (totalStepper !== activeStep + 1) {
            pageData[activeStep + 1].isActiveStep = true;
          }
        }
        if (activeStep === totalStepper) {
          dispatch({ lastPage: activeStep });
        }
        validateNextButton();
        dispatch({ previousPage: activeStep });
      }
    } else {
      setAlertOpen(true);
    }
  }

  const tooltipTitle = setTooltipText(
    infoTitleValue,
    infoDescriptionValue,
    infoURLValue,
    infoURLTitleValue
  );
  // site core content
  fetchSiteCoreContents(attribute.contentID).then((edgesContent) => {
    const {
      questionText,
      questionImage,
      questionDescriptionText,
      enterButtonText,
    } = edgesContent.item.contentDetailsLists[0].contentItems[0].woundCharacteristics;
    const {
      infoTitle,
      infoDescription,
      infoURL,
      infoURLTitle,
    } = edgesContent.item.contentDetailsLists[0].contentItems[0].infoDetails;

    setQuestionDescriptionTextValue(
      questionDescriptionText.replace(/<[^>]+>/g, '')
    );
    setQuestionImageURLValue(questionImage);
    setQuestionTextValue(questionText.replace(/<[^>]+>/g, ''));
    setInfoTitleValue(infoTitle.replace(/<[^>]+>/g, ''));
    setInfoDescriptionValue(infoDescription);
    setInfoURLValue(infoURL);
    setInfoURLTitleValue(infoURLTitle);
    setEnterButtonTextValue(enterButtonText);
  });

  useEffect(() => {
    showLoader();
    fetchWoundParametersContent(attribute.attCode).then((response) => {
      setAttributeName(response.item.parameters[0].attributeName);
      const edgeParameterList = response.item.parameters[0].parameterGroup
        .sort((a, b) => a.sortPriority - b.sortPriority)
        .map((x) => ({
          ...x,
          selected: false,
          disabled: false,
        }));
      if (edgewoundList.length === 0) {
        wndEdgesDispatch({ edgewoundList: edgeParameterList });
      }
      hideLoader();
    });
  }, []);

  const captureEdgeWoundData = (selectedCodes) => {
    delete pageData[activeStep].data;
    const updateMinimumValidationDataStart = captureMiniValidationData(
      [attInfo[0].attCode][0],
      minimumValidationData,
      true
    );
    dispatch({ updateMinimumValidationDataStart });
    const allelement = [];
    selectedCodes.forEach(async (element) => {
      let isPopup = false;
      let Title = '';
      let Message = '';
      if (element.hasRules) {
        const res = await fetchWoundRules(element.parameterCode);
        const popup = await checkPopup(res);
        if (popup[0]) {
          isPopup = true;
          Title = popup[2];
          Message = popup[1];
        }
      }
      if (element.parameterCode === edgesOtherParamCode) {
        allelement.push({
          attributeName: '',
          parameterCode: element.parameterCode,
          hasRule: false,
          parameterdesc: element.parameterDesc,
          OtherText: edgesOtherText,
          displayText: `Other: ${edgesOtherText}`,
          popUpTitle: Title,
          popUpMessage: Message,
          showPopup: false,
        });
      } else {
        allelement.push({
          attributeName: '',
          parameterCode: element.parameterCode,
          hasRule: isPopup,
          parameterdesc: element.parameterDesc,
          OtherText: '',
          displayText: element.parameterDesc,
          popUpTitle: Title,
          popUpMessage: Message,
          showPopup: false,
        });
      }
      const data = {
        [attInfo[0].attCode]: allelement,
      };
      Object.assign(pageData[activeStep], { data });

      const updateMinimumValidationData = captureMiniValidationData(
        [attInfo[0].attCode][0],
        minimumValidationData,
        true
      );
      dispatch({ updateMinimumValidationData });
      dispatch({ pageData });
    });
    if (selectedCodes.length === 0) {
      const data = {
        [attInfo[0].attCode]: [
          {
            attributeName: '',
            parameterCode: '',
            hasRule: false,
            parameterdesc: '',
            OtherText: '',
            displayText: 'Blank',
            popUpTitle: '',
            popUpMessage: '',
            showPopup: false,
          },
        ],
      };

      Object.assign(pageData[activeStep], { data });
    }
    dispatch({ pageData });
  };

  async function handleEdgeChange(idx) {
    edgewoundList[idx].selected = !edgewoundList[idx].selected;
    let [isWndUnk, unknownParamCode] = [false, ''];
    if (edgewoundList[idx].selected) {
      [isWndUnk, unknownParamCode] = await executeRulesIfAny(
        edgewoundList[idx].parameterCode
      );
    }
    if (
      edgewoundList[idx].selected === false &&
      edgewoundList[idx].parameterCode === edgesOtherParamCode
    ) {
      wndEdgesDispatch({
        showEdgesOtherTextbox: false,
        edgesOtherText: '',
      });
    }
    if (
      edgewoundList[idx].selected === false &&
      edgewoundList[idx].parameterCode === edgeUnknownParamCode
    ) {
      edgewoundList.forEach((x, ix) => {
        edgewoundList[ix].disabled = false;
      });
      wndEdgesDispatch({
        disableOtherParams: false,
        edgeUnknownParamCode: '',
        edgewoundList,
      });
    }
    if (isWndUnk && isWndUnk[0] && isWndUnk[0].disableOtherParams) {
      const selectedData = edgewoundList.filter(
        (x) => x.parameterCode === unknownParamCode
      );
      captureEdgeWoundData(selectedData);
    } else {
      const selectedData = edgewoundList.filter((x) => x.selected);
      captureEdgeWoundData(selectedData);
    }
  }

  useEffect(() => {
    const validateToggle = () => {
      pageData.forEach((item, idx) => {
        if (idx === activeStep) {
          pageData[idx].status = getPageStatus(
            edgewoundList,
            edgesOtherText,
            showEdgesOtherTextbox,
            pageData[idx].isActiveStep || pageData[activeStep].firstPage
          );
          dispatch({ pageData });
        }
      });
    };
    validateToggle();
  }, [
    activeStep,
    attribute.attCode,
    dispatch,
    edgesOtherText,
    pageData,
    showEdgesOtherTextbox,
    edgewoundList,
  ]);
  return (
    <Box className="attributeContainer mobAttributeContainer">
      {loader}
      <QuestionHeader
        questionTextValue={questionTextValue}
        questionImageURLValue={questionImageURLValue}
        handleInfoClick={handleInfoClick}
        tooltipTitle={tooltipTitle}
        open={open}
        setRef={() => {
          setOpen(false);
        }}
      />
      <QuestionDescription
        questionDescriptionTextValue={questionDescriptionTextValue}
      />
      <CheckBox
        handleCheckBoxChange={handleEdgeChange}
        groupValue={edgewoundList && edgewoundList}
        showDescriptor
        labelStyle={{ width: '100%', pointerEvents: 'none' }}
        containerBoxStyle={{
          margin: matchesMobileWidth ? '25px 0px' : '25px',
        }}
      />
      {showEdgesOtherTextbox && (
        <WoundEdgesOther
          contentID={attribute.contentID}
          handleEdgesOtherChange={handleEdgesOtherChange}
          edgesOtherText={edgesOtherText}
          movePlaceholder={showEdgesOtherLabel}
        />
      )}
      <CustomButton
        value={enterButtonTextValue}
        handleSubmit={handleSubmit}
        width="100px"
      />
      <AlertDialog
        isOpen={alertOpen}
        handleClose={handleClose}
        popUpPositiveButton={handleClose}
        title="Notification"
      >
        {errorMessage}
      </AlertDialog>
      <AlertDialog
        isOpen={popUpOpen}
        handleClose={handlePopUpClose}
        popUpPositiveButton={handlePopUpClose}
        title={popUpTitle}
        buttonName="Got it"
      >
        {popUpMessage}
      </AlertDialog>
    </Box>
  );
};

WoundEdges.propTypes = {
  attInfo: PropTypes.arrayOf(
    PropTypes.shape({
      attCode: PropTypes.string,
      attOrder: PropTypes.number,
      pageCode: PropTypes.string,
      contentID: PropTypes.string,
    })
  ).isRequired,
};

export default WoundEdges;
