// React imports
import React from 'react';
import PropTypes from 'prop-types';
// Material UI imports
import { Box, Typography } from '@material-ui/core';

const QuestionDescription = ({ questionDescriptionTextValue }) => (
  <Box style={{ marginTop: '15px' }}>
    <Typography className="attQtnDesc">
      {questionDescriptionTextValue}
    </Typography>
  </Box>
);

QuestionDescription.propTypes = {
  questionDescriptionTextValue: PropTypes.string.isRequired,
};
export default QuestionDescription;
