// React imports
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
// Material UI imports
import { Box } from '@material-ui/core';
// Utility imports
import {
  fetchSiteCoreContents,
  fetchWoundParametersContent,
  setTooltipText,
  checkValidation,
  createBody,
  fetchWoundRules,
  checkPopup,
  capturePageData,
  captureMiniValidationData,
} from '../../utility/utils';
import {
  STATUS_ENTERED,
  STATUS_NOT_ENTERED,
  STATUS_CURRENT,
} from '../../utility/staticText';
// Component imports
import RadioButton from '../../components/radioButton';
import CustomButton from '../../components/customButton';
import QuestionHeader from '../../components/questionHeader';
import QuestionDescription from '../../components/questionDescription';
import AlertDialog from '../../components/alertBox';
// Store imports
import { ApplicationContext } from '../../store/ApplicationState';
import { createObj } from '../../utility/saveAssessment';
import useFullPageLoader from '../../hooks/useFullPageLoader';

function WoundType({ attInfo }) {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [questionTextValue, setQuestionTextValue] = useState();
  const [questionImageURLValue, setQuestionImageURLValue] = useState();
  const [
    questionDescriptionTextValue,
    setQuestionDescriptionTextValue,
  ] = useState();
  const [enterButtonTextValue, setEnterButtonTextValue] = useState();
  const [parameterList, setParameterList] = useState([]);
  const [infoTitleValue, setInfoTitleValue] = useState();
  const [infoDescriptionValue, setInfoDescriptionValue] = useState();
  const [infoURLValue, setInfoURLValue] = useState();
  const [infoURLTitleValue, setInfoURLTitleValue] = useState();
  const [open, setOpen] = useState(false);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState('');
  const [popUpTitle, setPopUpTitle] = useState('');
  const [attributeName, setAttributeName] = useState('');
  const {
    state: {
      activeStep,
      pageData,
      errorMessage,
      totalStepper,
      minimumValidationData,
    },
    dispatch,
    wndTypeState: { selectedParameterCode },
    wndTypeDispatch,
  } = useContext(ApplicationContext);

  const tooltipTitle = setTooltipText(
    infoTitleValue,
    infoDescriptionValue,
    infoURLValue,
    infoURLTitleValue
  );
  const { contentID } = attInfo.filter((att) => att.pageCode === 'WDTYP')[0];
  const [alertOpen, setAlertOpen] = useState(false);

  const handleClose = () => {
    setAlertOpen(false);
  };

  fetchSiteCoreContents(contentID).then((typeContent) => {
    const {
      questionText,
      questionImage,
      questionDescriptionText,
      enterButtonText,
    } = typeContent.item.contentDetailsLists[0].contentItems[0].woundCharacteristics;
    const {
      infoTitle,
      infoDescription,
      infoURL,
      infoURLTitle,
    } = typeContent.item.contentDetailsLists[0].contentItems[0].infoDetails;

    setQuestionDescriptionTextValue(
      questionDescriptionText.replace(/<[^>]+>/g, '')
    );
    setQuestionImageURLValue(questionImage);
    setQuestionTextValue(questionText.replace(/<[^>]+>/g, ''));
    setEnterButtonTextValue(enterButtonText);
    setInfoTitleValue(infoTitle.replace(/<[^>]+>/g, ''));
    setInfoDescriptionValue(infoDescription);
    setInfoURLValue(infoURL);
    setInfoURLTitleValue(infoURLTitle);
  });

  useEffect(() => {
    const validateToggle = () => {
      pageData.map((x, idx) => {
        if (idx === activeStep) {
          if (selectedParameterCode) {
            pageData[idx].status = STATUS_ENTERED;
          } else {
            pageData[idx].status =
              pageData[idx].isActiveStep || pageData[activeStep].firstPage
                ? STATUS_CURRENT
                : STATUS_NOT_ENTERED;
          }
          dispatch({ pageData });
        }
        return true;
      });
    };
    validateToggle();
  }, [activeStep, dispatch, pageData, selectedParameterCode]);

  useEffect(() => {
    showLoader();
    fetchWoundParametersContent(attInfo[0] && attInfo[0].attCode).then(
      (parameterContent) => {
        if (parameterContent.item.parameters.length > 0) {
          const param = parameterContent.item.parameters[0];
          setAttributeName(param.attributeName);
          setParameterList(param.parameterGroup);
        }
        hideLoader();
      }
    );
  }, []);

  const handleInfoClick = () => {
    setOpen(!open);
  };
  const handlePopUpClose = () => {
    setPopUpOpen(false);
  };

  async function handleRadioChange(event) {
    const { value } = event.target;
    const selectedData = parameterList.filter(
      (x) => x.parameterCode === value
    )[0];
    if (selectedData.hasRules) {
      const rules = await fetchWoundRules(value);
      const isPopUp = await checkPopup(rules);
      if (isPopUp[0]) {
        setPopUpOpen(true);
        setPopUpMessage(isPopUp[1]);
        setPopUpTitle(isPopUp[2]);
      }
    }
    wndTypeDispatch({ selectedParameterCode: event.target.value });
    const saveObj = [];
    saveObj.push(createObj('woundType', event.target.value));
    const updatedPageData = capturePageData(
      [attInfo[0].attCode],
      event.target.value,
      selectedData,
      pageData,
      attributeName,
      activeStep,
      selectedData.parameterDesc,
      saveObj
    );
    dispatch({ pageData: updatedPageData });

    const updateMinimumValidationData = captureMiniValidationData(
      [attInfo[0].attCode][0],
      minimumValidationData,
      true
    );
    dispatch({ updateMinimumValidationData });
  }
  function validateNextButton() {
    if (activeStep + 1 === totalStepper) {
      dispatch({ showNextButton: false });
    }
  }

  function handleSubmit() {
    const validateBody = [];
    const body = createBody(
      selectedParameterCode,
      attInfo[0] && attInfo[0].pageCode
    );
    validateBody.push(body);
    const isValid = checkValidation(validateBody);
    if (isValid) {
      if (pageData[activeStep + 1].enabled) {
        dispatch({ activeStep: activeStep + 1 });
        if (totalStepper !== activeStep + 1) {
          if (totalStepper !== activeStep + 1) {
            pageData[activeStep + 1].isActiveStep = true;
          }
        }
        if (activeStep === totalStepper) {
          dispatch({ lastPage: activeStep });
        }
        validateNextButton();
        dispatch({ previousPage: activeStep });
      }
    } else {
      setAlertOpen(true);
    }
  }
  return (
    <Box className="attributeContainer mobAttributeContainer">
      {loader}
      <QuestionHeader
        questionTextValue={questionTextValue}
        questionImageURLValue={questionImageURLValue}
        handleInfoClick={handleInfoClick}
        tooltipTitle={tooltipTitle}
        open={open}
        setRef={() => {
          setOpen(false);
        }}
      />
      <QuestionDescription
        questionDescriptionTextValue={questionDescriptionTextValue}
      />
      <RadioButton
        value={selectedParameterCode}
        handleRadioChange={handleRadioChange}
        groupValue={
          parameterList &&
          parameterList.sort((a, b) => a.sortPriority - b.sortPriority)
        }
      />
      <CustomButton
        value={enterButtonTextValue}
        handleSubmit={handleSubmit}
        width="100px"
      />
      <AlertDialog
        isOpen={alertOpen}
        handleClose={handleClose}
        popUpPositiveButton={handleClose}
        title="Notification"
      >
        {errorMessage}
      </AlertDialog>
      <AlertDialog
        isOpen={popUpOpen}
        handleClose={handlePopUpClose}
        popUpPositiveButton={handlePopUpClose}
        title={popUpTitle}
        buttonName="Got it"
      >
        {popUpMessage}
      </AlertDialog>
    </Box>
  );
}

WoundType.propTypes = {
  attInfo: PropTypes.arrayOf(
    PropTypes.shape({
      attCode: PropTypes.string,
      attOrder: PropTypes.number,
      pageCode: PropTypes.string,
      contentID: PropTypes.string,
      attName: PropTypes.string,
    })
  ).isRequired,
};

export default WoundType;
