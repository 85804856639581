/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
import React, { useState, useContext, useEffect } from 'react';
import parse from 'html-react-parser';
import { useHistory } from 'react-router-dom';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import useDynamicRefs from 'use-dynamic-refs';
// api imports
import { getActivePageConfig } from '../../api/common';
import getCmsContent from '../../api/cms';
import CustomLabel from '../customLabel/CustomLabel';
import ReturnWoundRecommendation from './ReturnWoundRecommendation';
import TreatmentOptions from '../recommendation/TreatmentOptions';
import { ApplicationContext } from '../../store/ApplicationState';
import LightTooltip from '../lightTooltip/LightTooltip';
import { MOBILE_BREAKPOINT } from '../../utility/staticText';
import './woundTherapySelection.style.css';
import './finalize.css';
import useFullPageLoader from '../../hooks/useFullPageLoader';

function getContentID(pageConfigList, pageCode) {
  const pageObj = pageConfigList.filter((page) => page.pageCode === pageCode);
  return pageObj[0].contentID;
}
async function fetchWoundRecommendationContents(contentId) {
  let content = JSON.parse(localStorage.getItem(contentId));
  if (!content) {
    const body = {
      token: '',
      contentId,
    };
    content = await getCmsContent(body);
    localStorage.setItem(contentId, JSON.stringify(content));
  }
  return content;
}

const WoundTherapySelection = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const theme = useTheme();
  const matchesMobileWidth = useMediaQuery(
    theme.breakpoints.down(MOBILE_BREAKPOINT)
  );
  const history = useHistory();
  const [pageTitle, setPageTitle] = useState('');
  const [pageIntroduction, setPageIntroduction] = useState('');
  const [backArrowIconUrl, setBackArrowIcon] = useState('');
  const [downloadIconUrl, setDownloadIcon] = useState('');
  const [printIconUrl, setPrintIcon] = useState('');
  const [redAlertFullIconUrl, setRedAlertFullIcon] = useState('');
  const [navigationButtonBottomTitle, setNavigationButtonBottomText] = useState(
    ''
  );
  const [navigationButtonTopTitle, setNavigationButtonTopText] = useState('');
  const [toolTipMessage, setToolTipMessage] = useState(null);
  const [copyRightText, setcopyRight] = useState();
  const [woundCareTitleText, setwoundCareTitle] = useState();
  const [finalSwitchTopText, setSwitchTopText] = useState('');
  const [finalSwitchBottomText, setSwitchBottomText] = useState('');
  const [infoparameterCode, setInfoParameterCode] = useState('');
  const [getRef, setRef] = useDynamicRefs();
  const {
    state: {
      pageData,
      isGoalPathWay,
      goalPageData,
      isAllTherapyWay,
      disclaimerLegalText,
    },
    dispatch,
    wndRecommState: { treatmentList },
    wndRecommDispatch,
  } = useContext(ApplicationContext);
  if (pageData.length === 0 && !isGoalPathWay && !isAllTherapyWay) {
    history.goBack();
  }

  useEffect(() => {
    showLoader();
    getActivePageConfig().then((content) => {
      const contentID = getContentID(content.item.pageConfigListObj, 'WDTHS');

      fetchWoundRecommendationContents(contentID).then((woundPageContent) => {
        const {
          downloadIcon,
          printIcon,
          redAlertFullIcon,
          navigationButtonBottomText,
          navigationButtonTopText,
          pageTitleText,
          introductionBodyText,
          backArrowIcon,
          switchBottomText,
          switchTopText,
          navigationButtonBottomTextForGoal,
          navigationButtonBottomTextForChar,
          navigationButtonTopTextForGoal,
          navigationButtonTopTextForChar,
        } = woundPageContent.item.contentDetailsLists[0].contentItems[0].woundTherapySelectionDetails;

        setPageTitle(pageTitleText);
        setPageIntroduction(introductionBodyText);
        setBackArrowIcon(backArrowIcon);

        setDownloadIcon(downloadIcon);

        setPrintIcon(printIcon);
        setRedAlertFullIcon(redAlertFullIcon);

        setSwitchTopText(switchBottomText);
        setSwitchBottomText(switchTopText);

        if (isGoalPathWay) {
          setNavigationButtonBottomText(navigationButtonBottomTextForGoal);
          setNavigationButtonTopText(navigationButtonTopTextForGoal);
        } else if (isAllTherapyWay) {
          setNavigationButtonBottomText(navigationButtonBottomText);
          setNavigationButtonTopText(navigationButtonTopText);
        } else {
          setNavigationButtonBottomText(navigationButtonBottomTextForChar);
          setNavigationButtonTopText(navigationButtonTopTextForChar);
        }
        hideLoader();
      });

      fetchWoundRecommendationContents('WTGFOOTER').then((contents) => {
        const {
          copyRight,
          woundCareTitle,
        } = contents.item.contentDetailsLists[0].contentItems[0].footerDetails;
        setcopyRight(copyRight);
        setwoundCareTitle(woundCareTitle);
      });
    });
  }, []);

  function handleInfoClick(idx, popupTitle, popupMessage, pCode) {
    setInfoParameterCode(pCode);
    setToolTipMessage(
      <div
        style={{
          padding: '16px 16px 25px 16px',
          lineHeight: '20px',
          color: '#323234',
        }}
      >
        <p style={{ fontSize: '20px', fontWeight: 'bold' }}>
          {parse(popupTitle || '')}
        </p>
        <div style={{ fontSize: '16px', marginTop: '8px' }}>
          {parse(popupMessage || '')}
        </div>
      </div>
    );
    pageData
      .filter((x) => x.showInShell)
      .map((da) => {
        if (da.data) {
          // eslint-disable-next-line no-empty
          if (
            Object.prototype.hasOwnProperty.call(da.data, 'SOI') ||
            Object.prototype.hasOwnProperty.call(da.data, 'TSP') ||
            Object.prototype.hasOwnProperty.call(da.data, 'WOE')
          ) {
            Object.keys(da.data).forEach((key) => {
              da.data[key].map((x) =>
                x.parameterCode === pCode
                  ? (x.showPopup = !x.showPopup)
                  : (x.showPopup = false)
              );
            });
          } else {
            const result = Object.keys(da.data).map((key) => [da.data[key]]);
            result.map((x) =>
              x[0].parameterCode === pCode
                ? (x[0].showPopup = !x[0].showPopup)
                : (x[0].showPopup = false)
            );
          }
        }
      });

    dispatch({ pageData });
  }
  function handleClickOutside(event) {
    if (infoparameterCode && infoparameterCode) {
      const ref = getRef(infoparameterCode);
      if (ref.current && !ref.current.contains(event.target)) {
        pageData
          .filter((x) => x.showInShell)
          .map((da) => {
            if (da.data) {
              Object.keys(da.data).forEach((key) => {
                if (Array.isArray(da.data[key])) {
                  da.data[key].map((x) =>
                    x.parameterCode === infoparameterCode
                      ? (x.showPopup = false)
                      : x
                  );
                } else {
                  const result = Object.keys(da.data).map((key1) => [
                    da.data[key1],
                  ]);
                  result.map((x) =>
                    x[0].parameterCode === infoparameterCode
                      ? (x[0].showPopup = false)
                      : x
                  );
                }
              });
            }
          });

        dispatch({ pageData });
      }
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [infoparameterCode]);

  function returnShellBlockGoalJSX(label) {
    let returnDisplayTextValue = null;
    if (label.data) {
      const result = Object.keys(label.data).map((key) => [label.data[key]]);
      returnDisplayTextValue = result.map((displayData) => (
        <>
          <dl className="goaldl">
            <dt>{displayData[0].attributeName}</dt>
            <dd className="goaldd goaldd-print">
              {' '}
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    textAlign: 'right',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: '#000',
                    width: '100%',
                  }}
                  className="disp-text"
                >
                  {displayData[0].displayText}
                </div>
              </div>
            </dd>
          </dl>
        </>
      ));

      return returnDisplayTextValue;
    }
    return returnDisplayTextValue;
  }
  function getDisplayText(data) {
    let returnDisplayTextValue = null;
    if (data) {
      if (
        Object.prototype.hasOwnProperty.call(data, 'SOI') ||
        Object.prototype.hasOwnProperty.call(data, 'TSP') ||
        Object.prototype.hasOwnProperty.call(data, 'WOE')
      ) {
        Object.keys(data).forEach((key) => {
          returnDisplayTextValue = data[key].map((displayData, index) => (
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  textAlign: 'right',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: '#000',
                  width: '100%',
                }}
                className="disp-text"
              >
                {displayData.displayText}
              </div>
              {displayData.hasRule && (
                <span style={{ position: 'relative', top: '2px' }}>
                  <LightTooltip
                    title={toolTipMessage}
                    ref={setRef(displayData.parameterCode)}
                    arrow
                    placement="top"
                    style={{ marginLeft: '2px' }}
                    open={displayData.showPopup}
                    onClick={() =>
                      handleInfoClick(
                        index,
                        displayData.popUpTitle,
                        displayData.popUpMessage,
                        displayData.parameterCode
                      )
                    }
                  >
                    <img
                      alt="info"
                      src={redAlertFullIconUrl}
                      height="20px"
                      width="20px"
                    />
                  </LightTooltip>
                </span>
              )}
            </div>
          ));
        });
        return returnDisplayTextValue;
      }
      const result = Object.keys(data).map((key) => [data[key]]);
      returnDisplayTextValue = result.map((lineGroup, ind) => (
        <div style={{ display: 'flex' }}>
          <Box
            style={{
              width: '100%',
            }}
          >
            <Typography
              style={{
                textAlign: 'right',
                fontSize: '14px',
                fontWeight: 'bold',
                color: '#000',
                width: '100%',
              }}
            >
              {lineGroup[0].displayText}
            </Typography>
          </Box>
          {lineGroup[0].hasRule && (
            <span style={{ position: 'relative', top: '2px' }}>
              <LightTooltip
                title={toolTipMessage}
                arrow
                ref={setRef(lineGroup[0].parameterCode)}
                placement="top"
                style={{ marginLeft: '2px' }}
                open={lineGroup[0].showPopup}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                onClick={() =>
                  handleInfoClick(
                    ind,
                    lineGroup[0].popUpTitle,
                    lineGroup[0].popUpMessage,
                    lineGroup[0].parameterCode
                  )
                }
              >
                <img
                  alt="info"
                  src={redAlertFullIconUrl}
                  height="20px"
                  width="20px"
                />
              </LightTooltip>
            </span>
          )}
        </div>
      ));
      return returnDisplayTextValue;
    }
    return (
      <CustomLabel
        boxStyle={{ width: '100%' }}
        labelStyle={{
          textAlign: 'right',
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#000',
          width: '100%',
        }}
        value="Blank"
      />
    );
  }
  function getDisplayTreatment(pathwayValue) {
    return (
      <TreatmentOptions
        isFinalRecommendation
        topSwitch={finalSwitchTopText}
        bottomSwitch={finalSwitchBottomText}
        pathway={pathwayValue}
      />
    );
  }
  useEffect(() => {
    const updatedList = treatmentList.map((x) => ({ ...x, imgUp: false }));
    wndRecommDispatch({
      treatmentList: updatedList,
    });
  }, []);
  return (
    <Box
      style={{ minHeight: 'calc(100vh - 140px)', width: '100%' }}
      id="pdfContent"
    >
      {loader}
      <div className="recommRoot mobRecommRoot">
        <ReturnWoundRecommendation
          buttonText={navigationButtonTopTitle}
          backIcon={backArrowIconUrl}
          printIcon={printIconUrl}
          downloadIcon={downloadIconUrl}
          pageTitle={pageTitle}
          pageIntroduction={pageIntroduction}
          disclaimerText={disclaimerLegalText}
          copyRight={copyRightText}
          woundCare={woundCareTitleText}
        />
        <Box
          className="mobPadding"
          style={{ marginTop: matchesMobileWidth ? '40px' : '56px' }}
        >
          <CustomLabel
            labelStyle={{
              fontSize: matchesMobileWidth ? '28px' : '48px',
              fontWeight: '700',
              lineHeight: '30px',
            }}
            value={pageTitle}
          />
        </Box>
        <Box className="mobPadding">
          <CustomLabel
            labelStyle={{
              fontSize: '16px',
            }}
            boxStyle={{
              marginTop: '16px',
              marginBottom: matchesMobileWidth ? '0px' : '56px',
            }}
            value={pageIntroduction}
          />
        </Box>
        <div className="mobCharactersticsFinStyle block-disp">
          <div>
            <div>
              {!isAllTherapyWay && (
                <div
                  className={
                    isGoalPathWay ? 'goalbounding-box' : 'bounding-box'
                  }
                >
                  <h4>Wound characteristics</h4>
                  <div
                    className={
                      isGoalPathWay ? 'goalcharacteristics' : 'characteristics'
                    }
                  >
                    {isGoalPathWay
                      ? goalPageData
                          .filter((x) => x.showInShell)
                          .map((label, idx) =>
                            returnShellBlockGoalJSX(label, idx)
                          )
                      : pageData
                          .filter((x) => x.showInShell)
                          .map((label) => (
                            <dl>
                              <dt>{label.pageTitle}</dt>
                              <dd>{getDisplayText(label.data)}</dd>
                            </dl>
                          ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <Box className="finalizeTrtmnts mobFinalizeTrtmnts mobPadding">
            {isAllTherapyWay && getDisplayTreatment('AllTherapy')}
            {isGoalPathWay && getDisplayTreatment('GOAL')}
            {!isGoalPathWay &&
              !isAllTherapyWay &&
              getDisplayTreatment('CHARACTERISTICS')}
          </Box>
        </div>
        <div className="print-disclaimer">
          <p
            style={{
              fontSize: 12,
              fontWeight: 'bold',
            }}
          >
            {parse(disclaimerLegalText || '')}
          </p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 30,
            }}
          >
            <img
              src="/3mlogo.png"
              style={{
                float: 'left',
                height: '15px',
                width: '25px',
              }}
              alt="logo"
            />
            <p
              style={{
                fontSize: 12,
                paddingLeft: '20px',
              }}
            >
              {copyRightText}
            </p>
            <p
              style={{
                fontWeight: 700,
                fontSize: 14,
                position: 'absolute',
                right: '70px',
              }}
            >
              {woundCareTitleText}
            </p>
          </div>
        </div>
        <ReturnWoundRecommendation
          buttonText={navigationButtonBottomTitle}
          backIcon={backArrowIconUrl}
          printIcon={printIconUrl}
          downloadIcon={downloadIconUrl}
          pageTitle={pageTitle}
          pageIntroduction={pageIntroduction}
          disclaimerText={disclaimerLegalText}
          copyRight={copyRightText}
          woundCare={woundCareTitleText}
        />
        <div className="disclaimerBox mobDisclaimerBox">
          <p className="disclaimerText">{parse(disclaimerLegalText || '')}</p>
        </div>
      </div>
    </Box>
  );
};

export default WoundTherapySelection;
