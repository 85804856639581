import wndAppearanceInitialState from '../initialState/wndAppearanceInitialState';

const wndAppearanceReducer = (state, action) => {
  switch (action.type) {
    case 'RESET_APPEARANCE_STATE':
      return {
        ...wndAppearanceInitialState,
      };
    default: {
      return {
        ...state,
        ...action,
      };
    }
  }
};

export default wndAppearanceReducer;
