import wndCompressionInitialState from '../initialState/wndCompressionInitialState';

const wndCompressionReducer = (state, action) => {
  switch (action.type) {
    case 'RESET_COMPRESSION_STATE':
      return {
        ...wndCompressionInitialState,
      };
    default: {
      return {
        ...state,
        ...action,
      };
    }
  }
};

export default wndCompressionReducer;
