/* eslint-disable react/jsx-no-undef */
// React imports
import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import parse from 'html-react-parser';
// import parse from 'html-react-parser';
import CustomLabel from '../components/customLabel/CustomLabel';
// Utility imports
import { ApplicationContext } from '../store/ApplicationState';
import ProductImages from './ProductImages';
import { getRelatedProductParameters } from '../api/common';
import RelatedProduct from './RelatedProduct';
import { MOBILE_BREAKPOINT } from '../utility/staticText';

const useStyles = makeStyles(() => ({
  woundGoalBlock: {
    padding: '15px 10px 10px 10px',
  },
  excudateLevelBlock: { padding: '15px 10px 10px 10px' },
  checkIconStyle: { color: '#0049BD', marginRight: '20px' },
  rowStyle: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '13px',
  },
}));

const ProductProperties = ({ boxStyle, prodProps }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMobileWidth = useMediaQuery(
    theme.breakpoints.down(MOBILE_BREAKPOINT)
  );
  const {
    wndRecommState: { selectedProductID, selectedProductURL },
  } = useContext(ApplicationContext);
  const [relatedParameters, setRelatedParameters] = useState([]);
  const [relatedExudateLevel, setRelatedExudateLevel] = useState([]);
  const styles = {
    description: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#323234',
      paddingTop: '40px',
    },
    tagline: {
      fontSize: matchesMobileWidth ? '20px' : '24px',
      lineHeight: matchesMobileWidth ? '24px' : '28px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: '#323234',
      position: 'static',
    },
    header: {
      fontSize: matchesMobileWidth ? '28px' : '48px',
      fontWeight: 'bold',
      color: '#323234',
      lineHeight: matchesMobileWidth ? '32px' : '56px',
    },
    button: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: '14px 20px',
      width: '480px',
      height: '48px',
      borderRadius: '2px',
    },
    contraindications: {
      fontSize: '16px',
      lineHeight: '20px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: '#323234',
    },
    indications: {
      fontSize: '16px',
      lineHeight: '20px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: '#323234',
    },
    warningandprecautions: {
      fontSize: '16px',
      lineHeight: '20px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: '#323234',
    },
  };
  async function getRelatedProductParametersAttr(body) {
    const responses = await getRelatedProductParameters(body);
    return responses;
  }
  useEffect(() => {
    if (selectedProductID) {
      const WoundCareGoalBody = {
        productID: selectedProductID,
        attributeCode: 'PWCGO',
      };
      getRelatedProductParametersAttr(WoundCareGoalBody).then((result) => {
        setRelatedParameters(
          result.item.relatedProductParameter[0].parameterDesc
        );
      });
      const ExudateLevelBody = {
        productID: selectedProductID,
        attributeCode: 'EXA',
      };
      getRelatedProductParametersAttr(ExudateLevelBody).then((result) => {
        setRelatedExudateLevel(
          result.item.relatedProductParameter[0].parameterDesc
        );
      });
    }
  }, [selectedProductID]);

  const displaySections = (prop) => (
    <>
      {prop.productTitleDisplay === 'True' && (
        <div
          style={{
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: matchesMobileWidth ? '20px' : '32px',
            lineHeight: '36px',
            paddingTop: '56px',
            color: '#323234',
          }}
        >
          {parse(prop.productSectionTitle)}
        </div>
      )}
      {prop.productSectionType === 'button' ? (
        <div
          style={{
            paddingTop: '50px',
            textAlign: 'center',
            paddingBottom: '24px',
          }}
        >
          <a
            href={selectedProductURL}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <Button
              variant="contained"
              color="primary"
              className="buttonPropsStyle mobButtonPropsStyle buttonStyle"
            >
              <div
                style={{
                  fontSize: '16px',
                  lineHeight: '20px',
                  fontWeight: 'bold',
                  fontStyle: 'normal',
                }}
              >
                {prop.productSectionBody}
              </div>
            </Button>
          </a>
        </div>
      ) : (
        <CustomLabel
          labelStyle={styles[prop.productSectionType]}
          boxStyle={{
            marginTop: '12px',
            width: 'auto',
          }}
          value={prop.productSectionBody || ''}
        />
      )}
    </>
  );
  return (
    <Box style={boxStyle}>
      {prodProps
        .sort((a, b) => a.productSectionOrder - b.productSectionOrder)
        .filter(
          (x) =>
            x.productSectionType === 'tagline' ||
            x.productSectionType === 'header'
        )
        .map((prop) => displaySections(prop))}
      <Box
        style={{
          display: 'flex',
          flexDirection: matchesMobileWidth ? 'column-reverse' : '',
        }}
      >
        <Box className="leftColumnStyle mobLeftColumnStyle">
          {prodProps
            .filter(
              (x) =>
                x.productSectionType !== 'tagline' &&
                x.productSectionType !== 'header' &&
                x.productSectionType !== 'image'
            )
            .sort((a, b) => a.productSectionOrder - b.productSectionOrder)
            .map((prop) => displaySections(prop))}
          <RelatedProduct />
        </Box>
        <Box className="rightColumnStyle mobRightColumnStyle">
          <Box>
            {prodProps.length > 0 && <ProductImages productsData={prodProps} />}
          </Box>
          {(relatedExudateLevel.length > 0 || relatedParameters.length > 0) && (
            <Box className="blueBorderBlockWithImage mobBlueBorderBlockWithImage">
              {relatedParameters.length > 0 && (
                <Box className={classes.woundGoalBlock}>
                  <CustomLabel
                    labelStyle={{
                      fontSize: '18px',
                      fontWeight: 'bold',
                      color: '#323234',
                      lineHeight: '22px',
                    }}
                    value="Primary wound care goal"
                  />
                  {relatedParameters.map((item) => (
                    <Box className={classes.rowStyle}>
                      <span className={classes.checkIconStyle}>
                        <CheckIcon />
                      </span>
                      <CustomLabel
                        value={item}
                        labelStyle={{
                          fontSize: '16px',
                          color: '#323234',
                          lineHeight: '20px',
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              )}
              {relatedExudateLevel.length > 0 && (
                <Box className={classes.excudateLevelBlock}>
                  <CustomLabel
                    labelStyle={{
                      fontSize: '18px',
                      fontWeight: 'bold',
                      color: '#323234',
                      lineHeight: '22px',
                    }}
                    value="Wound exudate level"
                  />
                  {relatedExudateLevel.map((item) => (
                    <Box className={classes.rowStyle}>
                      <span className={classes.checkIconStyle}>
                        <CheckIcon />
                      </span>
                      <CustomLabel
                        value={item}
                        labelStyle={{
                          fontSize: '16px',
                          color: '#323234',
                          lineHeight: '20px',
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

ProductProperties.defaultProps = {
  boxStyle: {
    fontSize: 'normal',
  },
};

ProductProperties.propTypes = {
  boxStyle: PropTypes.objectOf(PropTypes.string.isRequired),
  prodProps: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default ProductProperties;
