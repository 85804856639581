// React imports
import React from 'react';
import { useLocation, NavLink } from 'react-router-dom';

// Material UI imports
import { Breadcrumbs, makeStyles, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
// Utility import
import {
  WOUND_GOAL_BREADCRUMB_TEXT,
  WOUND_THERAPY_GUIDE_BREADCRUMB_TEXT,
} from '../../utility/staticText';

const useStyles = makeStyles(() => ({
  link: {
    color: '#000000',
    fontSize: '11px',
    lineHeight: '12px',
  },
  noLink: {
    color: '#000000',
    fontSize: '11px',
    lineHeight: '12px',
    paddingTop: '4px',
  },
}));
const SectionBreadcrumbs = () => {
  const classes = useStyles();
  const location = useLocation();
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      <NavLink
        to="/"
        underline="always"
        className={classes.link}
        data-testid="home"
      >
        Home
      </NavLink>
      <Typography className={classes.noLink} data-testid="nextPage">
        {location.pathname === '/woundTherapyGuide' &&
          WOUND_THERAPY_GUIDE_BREADCRUMB_TEXT}
        {location.pathname === '/woundGoal' && WOUND_GOAL_BREADCRUMB_TEXT}
      </Typography>
    </Breadcrumbs>
  );
};

export default SectionBreadcrumbs;
