/* eslint-disable prefer-destructuring */
import React from 'react';
import parse from 'html-react-parser';
import getCmsContent from '../api/cms';
import { getWoundParameters, getWoundRules } from '../api/common';
// Utility imports
import {
  STATUS_ENTERED,
  STATUS_NOT_ENTERED,
  STATUS_VISITED,
  POP_UP,
  STATUS_CURRENT,
} from './staticText';

async function fetchSiteCoreContents(contentId) {
  let content = JSON.parse(localStorage.getItem(contentId));
  if (!content) {
    const body = {
      token: '',
      contentId,
    };
    content = await getCmsContent(body);
    localStorage.setItem(contentId, JSON.stringify(content));
  }
  return content;
}

async function fetchWoundParametersContent(attrCode) {
  const attributes = [];
  const obj = {
    attributeCode: attrCode,
  };
  attributes.push(obj);
  const returnValue = {
    appkey: 'WTG',
    attributes,
  };
  const response = await getWoundParameters(JSON.stringify(returnValue));
  return response;
}

async function fetchWoundParametersContentByArray(attrCode) {
  const attributes = [];
  attrCode.forEach((recAttCode) => {
    const obj = {
      attributeCode: recAttCode,
    };
    attributes.push(obj);
  });
  const returnValue = {
    appkey: 'WTG',
    attributes,
  };
  const response = await getWoundParameters(JSON.stringify(returnValue));
  return response;
}

async function fetchWoundRules(paraCode) {
  let response = JSON.parse(localStorage.getItem(paraCode));
  if (!response) {
    const returnValue = {
      appkey: 'WTG',
      parameterCode: paraCode,
    };
    response = await getWoundRules(JSON.stringify(returnValue));
    localStorage.setItem(paraCode, JSON.stringify(response));
  }

  return response;
}

async function checkPopup(rules) {
  const popUp = rules.item.captureRules.filter((x) => x.ruleType === POP_UP);
  if (popUp.length > 0) {
    return [true, popUp[0].alertMessage, popUp[0].ruleDesc];
  }
  return [false, '', ''];
}

// set tooltip text
function setTooltipText(
  infoTitleValue,
  infoDescriptionValue,
  infoURLValue,
  infoURLTitleValue
) {
  return (
    <div
      style={{
        padding: '16px 16px 25px 16px',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#323234',
      }}
    >
      <p style={{ fontSize: '20px', fontWeight: 'bold' }}>
        {parse(infoTitleValue || '')}
      </p>
      <div style={{ fontSize: '16px', marginTop: '8px' }}>
        {parse(infoDescriptionValue || '')}
      </div>
      <a href={infoURLValue} target="_blank" rel="noreferrer">
        {parse(infoURLTitleValue || '')}
      </a>
    </div>
  );
}
function checkValidation(items) {
  const validarr = [];
  let isValid = false;
  const errorObjs = JSON.parse(JSON.stringify(items));
  errorObjs.forEach((item) => {
    if (item.pageCode !== 'WTGWP' && item.pageCode !== 'WDINF') {
      validarr.push(!!item.itemValue);
    }
    if (item.pageCode === 'WDINF') {
      if (item.itemValue > 0) {
        validarr.push(true);
      } else {
        validarr.push(false);
      }
    }
  });

  isValid = validarr.filter((x) => x === false).length === 0;

  return isValid;
}
function createBody(itemValue, pageCode) {
  const body = {
    itemValue,
    pageCode,
  };
  return body;
}
// checks whether all values are selected
function checkParameters(parameters, isActiveStep) {
  let count = 0;
  let status = '';
  parameters.every((param) => {
    if (param === 'NONE') {
      count = parameters.length;
      return false;
    }
    if (param !== '' && param) {
      count += 1;
    }
    return true;
  });
  if (count === 0) {
    status = isActiveStep ? STATUS_CURRENT : STATUS_NOT_ENTERED;
  }
  if (count === parameters.length) {
    status = STATUS_ENTERED;
  }
  if (count > 0 && count < parameters.length) {
    status = STATUS_VISITED;
  }
  return status;
}
function checkParametersList(parameterList, otherDesc, isActiveStep) {
  let status = '';
  if (
    parameterList &&
    parameterList.filter((y) => y.selected === true).length === 0
  ) {
    status = isActiveStep ? STATUS_CURRENT : STATUS_NOT_ENTERED;
  }
  if (
    (parameterList &&
      parameterList.filter(
        (y) => y.parameterCode === 'SOIOT' && y.selected === true
      ).length > 0 &&
      otherDesc &&
      otherDesc.length > 0) ||
    (parameterList &&
      parameterList.filter((y) => y.selected === true).length > 0)
  ) {
    status = STATUS_ENTERED;
  }
  if (
    parameterList &&
    parameterList.filter(
      (y) => y.parameterCode === 'SOIOT' && y.selected === true
    ).length > 0
  ) {
    if (otherDesc === undefined || otherDesc === '') {
      status = STATUS_VISITED;
    }
  }

  return status;
}

const combineAttAndParameter = (paraList, attributeList) => {
  paraList.forEach((pl) => {
    const { attOrder } = attributeList.filter(
      (x) => x.attCode === pl.attributeCode
    )[0];
    Object.assign(pl, { attOrder });
  });
  return paraList;
};

const capturePageDataAsync = async (
  attributeCode,
  paraCode,
  selectedData,
  pageData,
  attributeName,
  activeStep,
  displayText,
  saveAssessmentObj
) => {
  let isPresent = false;
  let popUpTitle = '';
  let popUpMessage = '';

  if (selectedData.hasRules) {
    const rules = await fetchWoundRules(paraCode);
    const popup = await checkPopup(rules);
    if (popup[0]) {
      isPresent = true;
      popUpTitle = popup[2];
      popUpMessage = popup[1];
      const data = {
        [attributeCode]: {
          attributeName,
          parameterCode: paraCode,
          hasRule: isPresent,
          parameterdesc: selectedData.parameterDesc,
          displayText: displayText.length <= 0 ? 'Blank' : displayText,
          popUpTitle,
          popUpMessage,
          showPopup: false,
          saveAssessmentObj,
        },
      };

      if (Object.prototype.hasOwnProperty.call(pageData[activeStep], 'data')) {
        Object.assign(pageData[activeStep].data, { ...data });
      } else {
        Object.assign(pageData[activeStep], { data });
      }
    } else {
      const data = {
        [attributeCode]: {
          attributeName,
          parameterCode: paraCode,
          hasRule: isPresent,
          parameterdesc: selectedData.parameterDesc,
          displayText: displayText.length <= 0 ? 'Blank' : displayText,
          popUpTitle,
          popUpMessage,
          showPopup: false,
          saveAssessmentObj,
        },
      };

      if (Object.prototype.hasOwnProperty.call(pageData[activeStep], 'data')) {
        Object.assign(pageData[activeStep].data, { ...data });
      } else {
        Object.assign(pageData[activeStep], { data });
      }
    }
  } else {
    const data = {
      [attributeCode]: {
        attributeName,
        parameterCode: paraCode,
        hasRule: isPresent,
        parameterdesc: selectedData.parameterDesc,
        displayText: displayText.length <= 0 ? 'Blank' : displayText,
        popUpTitle,
        popUpMessage,
        showPopup: false,
        saveAssessmentObj,
      },
    };

    if (Object.prototype.hasOwnProperty.call(pageData[activeStep], 'data')) {
      Object.assign(pageData[activeStep].data, { ...data });
    } else {
      Object.assign(pageData[activeStep], { data });
    }
  }

  return pageData;
};

const capturePageData = (
  attributeCode,
  paraCode,
  selectedData,
  pageData,
  attributeName,
  activeStep,
  displayText,
  saveAssessmentObj,
  overrideRule
) => {
  let isPresent = false;
  let popUpTitle = '';
  let popUpMessage = '';

  if (selectedData && selectedData.hasRules) {
    fetchWoundRules(paraCode).then((rules) => {
      checkPopup(rules).then((popup) => {
        if (popup[0]) {
          isPresent = true;
          popUpTitle = popup[2];
          popUpMessage = popup[1];
          const data = {
            [attributeCode]: {
              attributeName,
              parameterCode: paraCode,
              hasRule: overrideRule === false ? false : isPresent,
              parameterdesc: selectedData && selectedData.parameterDesc,
              displayText: displayText.length <= 0 ? 'Blank' : displayText,
              popUpTitle,
              popUpMessage,
              showPopup: false,
              saveAssessmentObj,
            },
          };

          if (
            Object.prototype.hasOwnProperty.call(pageData[activeStep], 'data')
          ) {
            Object.assign(pageData[activeStep].data, { ...data });
          } else {
            Object.assign(pageData[activeStep], { data });
          }
        } else {
          const data = {
            [attributeCode]: {
              attributeName,
              parameterCode: paraCode,
              hasRule: isPresent,
              parameterdesc: selectedData && selectedData.parameterDesc,
              displayText: displayText.length <= 0 ? 'Blank' : displayText,
              popUpTitle,
              popUpMessage,
              showPopup: false,
              saveAssessmentObj,
            },
          };

          if (
            Object.prototype.hasOwnProperty.call(pageData[activeStep], 'data')
          ) {
            Object.assign(pageData[activeStep].data, { ...data });
          } else {
            Object.assign(pageData[activeStep], { data });
          }
        }
      });
    });
  } else {
    const data = {
      [attributeCode]: {
        attributeName,
        parameterCode: paraCode,
        hasRule: isPresent,
        parameterdesc: selectedData && selectedData.parameterDesc,
        displayText: displayText.length <= 0 ? 'Blank' : displayText,
        popUpTitle,
        popUpMessage,
        showPopup: false,
        saveAssessmentObj,
      },
    };

    if (Object.prototype.hasOwnProperty.call(pageData[activeStep], 'data')) {
      Object.assign(pageData[activeStep].data, { ...data });
    } else {
      Object.assign(pageData[activeStep], { data });
    }
  }

  return pageData;
};

function getDisplayText(data) {
  let returnDisplayTextValue = null;
  if (data) {
    if (Object.prototype.hasOwnProperty.call(data, 'SOI')) {
      Object.keys(data).forEach((key) => {
        returnDisplayTextValue = data[key].map(
          (displayData) => displayData.displayText
        );
      });
      return returnDisplayTextValue;
    }
    const result = Object.keys(data).map((key) => [data[key]]);
    returnDisplayTextValue = result.map(
      (lineGroup) => lineGroup[0].displayText
    );
    return returnDisplayTextValue;
  }
  return 'Blank';
}

const captureMiniValidationData = (
  attrCode,
  minimumValidationData,
  updateFlag
) => {
  minimumValidationData.forEach((element) => {
    element.allMinimumAttr.forEach((item) => {
      if (item.attr === attrCode) {
        Object.assign(item, { isValidated: updateFlag });
      }
    });
  });
  return minimumValidationData;
};

export {
  fetchSiteCoreContents,
  fetchWoundParametersContent,
  fetchWoundParametersContentByArray,
  setTooltipText,
  checkValidation,
  createBody,
  checkParameters,
  checkParametersList,
  fetchWoundRules,
  combineAttAndParameter,
  checkPopup,
  capturePageData,
  getDisplayText,
  captureMiniValidationData,
  capturePageDataAsync,
};
