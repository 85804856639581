/* eslint-disable react/jsx-curly-newline */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Material UI imports
import { Box } from '@material-ui/core';
// Component imports
import QuestionHeader from '../../components/questionHeader';
import QuestionDescription from '../../components/questionDescription';
import RadioButton from '../../components/radioButton';
// Utility imports
import {
  fetchSiteCoreContents,
  setTooltipText,
  fetchWoundParametersContent,
} from '../../utility/utils';
import useFullPageLoader from '../../hooks/useFullPageLoader';

const ExudateAmount = ({
  contentID,
  attCode,
  selectedParameterCode,
  handleRadioChange,
}) => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [open, setOpen] = useState(false);
  const [questionTextValue, setQuestionTextValue] = useState();
  const [questionImageURLValue, setQuestionImageURLValue] = useState();
  const [
    questionDescriptionTextValue,
    setQuestionDescriptionTextValue,
  ] = useState();
  const [infoTitleValue, setInfoTitleValue] = useState();
  const [infoDescriptionValue, setInfoDescriptionValue] = useState();
  const [infoURLValue, setInfoURLValue] = useState();
  const [infoURLTitleValue, setInfoURLTitleValue] = useState();
  const [parameterList, setParameterList] = useState([]);
  const [attributeName, setAttributeName] = useState([]);
  const handleInfoClick = () => {
    setOpen(!open);
  };

  const tooltipTitle = setTooltipText(
    infoTitleValue,
    infoDescriptionValue,
    infoURLValue,
    infoURLTitleValue
  );

  useEffect(() => {
    showLoader();
    fetchSiteCoreContents(contentID).then((typeContent) => {
      const {
        questionText,
        questionImage,
        questionDescriptionText,
      } = typeContent.item.contentDetailsLists[0].contentItems[0].woundCharacteristics;
      const {
        infoTitle,
        infoDescription,
        infoURL,
        infoURLTitle,
      } = typeContent.item.contentDetailsLists[0].contentItems[0].infoDetails;

      setQuestionDescriptionTextValue(
        questionDescriptionText.replace(/<[^>]+>/g, '')
      );
      setQuestionImageURLValue(questionImage);
      setQuestionTextValue(questionText.replace(/<[^>]+>/g, ''));
      setInfoTitleValue(infoTitle.replace(/<[^>]+>/g, ''));
      setInfoDescriptionValue(infoDescription);
      setInfoURLValue(infoURL);
      setInfoURLTitleValue(infoURLTitle);

      fetchWoundParametersContent(attCode).then((response) => {
        setAttributeName(response.item.parameters[0].attributeName);
        setParameterList(response.item.parameters[0].parameterGroup);
        hideLoader();
      });
    });
  }, [attCode]);

  return (
    <Box>
      {loader}
      <QuestionHeader
        questionTextValue={questionTextValue}
        questionImageURLValue={questionImageURLValue}
        handleInfoClick={handleInfoClick}
        tooltipTitle={tooltipTitle}
        open={open}
        setRef={() => {
          setOpen(false);
        }}
      />
      <QuestionDescription
        questionDescriptionTextValue={questionDescriptionTextValue}
      />
      <RadioButton
        value={selectedParameterCode}
        handleRadioChange={(event) =>
          handleRadioChange(event, parameterList, attributeName)
        }
        groupValue={parameterList && parameterList}
        groupStyle={{ flexDirection: 'column' }}
        showDescriptor
        rowAlign
      />
    </Box>
  );
};

ExudateAmount.propTypes = {
  attCode: PropTypes.string.isRequired,
  contentID: PropTypes.string.isRequired,
  selectedParameterCode: PropTypes.string.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
};

export default ExudateAmount;
